import { useEffect, useState } from 'react';
import { gql, useQuery, useSubscription, useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { IUnit } from '../../types/unit';
import Floorplans from './Floorplans';
import Worksheet from './Worksheet';
import 'react-18-image-lightbox/style.css';
import LoadingWrapper from '../common/LoadingWrapper';

const FloorplanContainer = (props: any) => {
  const { worksheet } = props;
  const [units, setUnits] = useState<IUnitMarketing[]>([]);
  const [allUnits, setAllUnits] = useState<IUnitMarketing[]>([]);
  const [favourites, setFavourites] = useState<IUnitMarketing[]>([]);
  const { projectid } = useParams();
  const [selectedUnit, setSelectedUnit] = useState(null);

  useSubscription(UNITSUBSCRIPTION, {
    variables: { projectId: projectid },
    onData: async ({ data: { data } }: any) => {
      // Remove unit if not HL or SHL
      let updatedUnits = units
        .map((unit: IUnitMarketing) => {
          let logUnit = data.logUnits.find((logUnit: IUnit) => logUnit._id === unit._id);
          if (logUnit) {
            return {
              ...logUnit,
              marketingFloorPlan: unit.marketingFloorPlan,
            };
          } else return unit;
        })
        .filter((unit: IUnit) => !unit.status || (unit.status && unit.status !== 'A' && unit.status !== 'CH' && unit.status !== 'D'));

      let favouriteUnits = favourites
        .map((unit: IUnitMarketing) => {
          let logUnit = data.logUnits.find((logUnit: IUnit) => logUnit._id === unit._id);
          if (logUnit) {
            return {
              ...logUnit,
              marketingFloorPlan: unit.marketingFloorPlan,
            };
          } else return unit;
        })
        .filter((unit: IUnit) => !unit.status || (unit.status && unit.status !== 'A' && unit.status !== 'CH' && unit.status !== 'D'));

      let logUnits = data.logUnits
        .map((logUnit: IUnit) => {
          let currentUnit = units.find((unit: IUnit) => logUnit._id === unit._id);
          if (!currentUnit) {
            return {
              modelType: logUnit.modelType,
              suite: logUnit.suite,
              unitType: logUnit.unitType,
              basePrice: logUnit.basePrice,
              _id: logUnit._id,
            };
          }
        })
        .filter((unit: IUnit) => unit !== undefined);

      let additionalUnits: any[] = [];

      if (logUnits.length) {
        await getMarketingFloorplan({ variables: { units: logUnits, project: projectid } }).then((res) => {
          additionalUnits = res.data.getMarketingFloorPlans;
        });
      }

      setUnits([...additionalUnits, ...updatedUnits]);
      setFavourites(favouriteUnits);
    },
  });

  const [getMarketingFloorplan] = useLazyQuery(GETMARKETINGFLOORPLANS, {
    onCompleted: (data) => {},
  });

  const { loading } = useQuery(GETMARKETINGFLOORPLANS, {
    skip: !projectid,
    variables: { units: [], project: projectid, status: ['HL'] },
    onCompleted: (data) => {
      setUnits(data.getMarketingFloorPlans);
      setAllUnits(data.getMarketingFloorPlans);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  useEffect(() => {
    setSelectedUnit(null);
  }, [worksheet]);

  return (
    <Box>
      {selectedUnit ? (
        <Worksheet selectedUnit={selectedUnit} setSelectedUnit={setSelectedUnit} />
      ) : !loading ? (
        <Floorplans
          setFavourites={setFavourites}
          favourites={favourites}
          units={units}
          setSelectedUnit={setSelectedUnit}
          setUnits={setUnits}
          allUnits={allUnits}
        />
      ) : (
        <LoadingWrapper title={'Floorplans are loading...'} modal={false} />
      )}
    </Box>
  );
};

const GETMARKETINGFLOORPLANS = gql`
  query getMarketingFloorPlans($units: [NewMarketingInput!], $project: MongoID!, $status: [String]) {
    getMarketingFloorPlans(units: $units, project: $project, status: $status) {
      _id
      suite
      modelType
      unitType
      marketingFloorPlan
      basePrice
    }
  }
`;

const UNITSUBSCRIPTION = gql`
  subscription logUnits($projectId: String!) {
    logUnits(projectId: $projectId) {
      _id
      suite
      modelType
      unitType
      status
      basePrice
    }
  }
`;

export interface IUnitMarketing extends IUnit {
  marketingFloorPlan: string;
}

export default FloorplanContainer;
