import { install } from 'resize-observer';
import { ThemeProvider } from '@emotion/react';
import { Provider } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { store } from './app/store';
import { persistor } from './app/store';
import { withRouter } from './utils/WithRouter';
import { Snackbar } from './features/snackbar/Snackbar';
import RestrictedRoute from './components/routes/RestrictedRoute';
import Portal from './components/login/Portal';
import Login from './components/login/Login';
import AllProjects from './components/allProjects/AllProjects';
import RequireAuth from './components/routes/RequireAuth';
import PublicAuth from './components/routes/PublicAuth';
import DashboardOutlet from './components/routes/DashboardOutlet';
import PriceGrid from './components/priceGrid/PriceGrid';
import CreateProject from './components/createProject/CreateProject';
import CreateMerges from './components/createMerge/CreateMerges';
import UnitIndex from './components/unitMain/UnitIndex';
import SearchPage from './components/search/SearchPage';
import Activity from './components/activity/Activity';
import Outstandings from './components/outstandings/Outstandings';
import MergeCreator from './components/createMerge/MergeCreator';
import PDFGenerator from './components/common/PDFGenerator';
import CreateDealForm from './components/createDeal/CreateDealForm';
import DashboardPage from './components/dashboard/DashboardPage';
import ClosingReportComponent from './components/reports/ClosingTable';
import UnitReportComponent from './components/reports/TypeSummary';
import DailyReportComponent from './components/reports/DailySummary';
import DailyDepositsComponent from './components/reports/DailyDeposits';
import RealtorsComponent from './components/reports/RealtorsSummary';
import PurchasersComponent from './components/reports/PurchasersSummary';
import DecorComponent from './components/reports/ColourSelection';
import ConstructionComponent from './components/reports/ConstructionSummary';
import AddRealtor from './components/realtor/AddRealtor';
import AddPurchaser from './components/purchaser/AddPurchaser';
import WorksheetList from './components/worksheets/WorksheetList';
import Faqs from './components/miscellaneous/Faqs';
import Resources from './components/resources/Resources';
import Error404 from './components/miscellaneous/Error404';
import ResetPassword from './components/resetPassword/ResetPassword';
import { theme } from './theme';
import { PriceGridProvider } from './context/PriceGridContext';
import { UnitProvider } from './context/UnitContext';
import { MergeProvider } from './context/MergeContext';
import { CreateDealProvider } from './context/CreateDealContext';
import PageNotFound from './components/miscellaneous/PageNotFound';
import InPerson from './components/inPerson/InPerson';
import Worksheets from './components/reports/Worksheets';
import Distribution from './components/distribution/Distribution';
import EditDocument from './components/unitMain/EditDocument';
import DecorSelection from './components/inPerson/DecorSelection';
import CombinedSummary from './components/reports/CombinedSummary';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import WorksheetDetails from './components/worksheets/WorksheetDetails';

const App = (props: any) => {
  // Safari Mobile
  install();

  if (process.env.NODE_ENV !== 'production') {
    // Adds messages only in a dev environment
    loadDevMessages();
    loadErrorMessages();
  }

  return (
    <div>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <Snackbar />
            <Routes>
              <Route element={<PublicAuth />}>
                <Route element={<ResetPassword />} path="/reset-password/:token" />
                <Route element={<Login />} path="/" />
                <Route element={<Error404 />} path="/error" />
                <Route path="*" element={<PageNotFound />} />
              </Route>
              <Route element={<RequireAuth />}>
                <Route element={<Portal />} path="/portal" />
                <Route element={<CreateProject type="create" />} path="/create-project" />
                <Route element={<AllProjects />} path="/allProjects" />
                <Route element={<InPerson />} path="/:projectid/signing-portal" />
                <Route element={<DecorSelection />} path="/:projectid/:unitid" />
                <Route element={<DashboardOutlet />} path="/:projectid/dashboard">
                  <Route element={<DashboardPage />} path="" />
                  <Route element={<SearchPage />} path="search/:id" />
                  <Route element={<Faqs />} path="faqs" />
                  <Route element={<Resources />} path="resources" />
                  <Route element={<Distribution />} path="distribution" />
                  <Route element={<AddRealtor />} path="add-realtor" />
                  <Route element={<AddPurchaser />} path="add-purchaser" />
                  <Route element={<WorksheetList />} path="worksheets" />
                  <Route element={<WorksheetDetails />} path="/:projectid/dashboard/worksheet/:worksheetid" />
                  <Route element={<PDFGenerator />} path="create-deal/:suite/pdf" />
                  <Route
                    element={
                      <UnitProvider>
                        <UnitIndex />
                      </UnitProvider>
                    }
                    path=":unitid"
                  />
                  <Route
                    path="edit-document/:documentid"
                    element={
                      <RestrictedRoute restriction={'editDeals'}>
                        <MergeProvider>
                          <EditDocument />
                        </MergeProvider>
                      </RestrictedRoute>
                    }
                  />
                  <Route
                    path="price-grid"
                    element={
                      <RestrictedRoute restriction={'priceGrid'}>
                        <PriceGridProvider>
                          <PriceGrid />
                        </PriceGridProvider>
                      </RestrictedRoute>
                    }
                  />
                  <Route
                    path="closing-report"
                    element={
                      <RestrictedRoute restriction={'viewReports'}>
                        <ClosingReportComponent />
                      </RestrictedRoute>
                    }
                  />
                  <Route
                    path="daily-report"
                    element={
                      <RestrictedRoute restriction={'viewReports'}>
                        <DailyReportComponent />
                      </RestrictedRoute>
                    }
                  />
                  <Route
                    path="combined-report"
                    element={
                      <RestrictedRoute restriction={'viewReports'}>
                        <CombinedSummary />
                      </RestrictedRoute>
                    }
                  />

                  <Route
                    path="deposit-report"
                    element={
                      <RestrictedRoute restriction={'viewReports'}>
                        <DailyDepositsComponent />
                      </RestrictedRoute>
                    }
                  />
                  <Route
                    path="worksheet-report"
                    element={
                      <RestrictedRoute restriction={'viewReports'}>
                        <Worksheets />
                      </RestrictedRoute>
                    }
                  />
                  <Route
                    path="unit-report"
                    element={
                      <RestrictedRoute restriction={'viewReports'}>
                        <UnitReportComponent />
                      </RestrictedRoute>
                    }
                  />
                  <Route
                    path="realtors-report"
                    element={
                      <RestrictedRoute restriction={'viewReports'}>
                        <RealtorsComponent />
                      </RestrictedRoute>
                    }
                  />
                  <Route
                    path="purchasers-report"
                    element={
                      <RestrictedRoute restriction={'viewReports'}>
                        <PurchasersComponent />
                      </RestrictedRoute>
                    }
                  />
                  <Route
                    path="decor-report"
                    element={
                      <RestrictedRoute restriction={'viewReports'}>
                        <DecorComponent />
                      </RestrictedRoute>
                    }
                  />
                  <Route
                    path="construction-report"
                    element={
                      <RestrictedRoute restriction={'viewReports'}>
                        <ConstructionComponent />
                      </RestrictedRoute>
                    }
                  />
                  <Route
                    path="create-merges"
                    element={
                      <RestrictedRoute restriction={'merges'}>
                        <CreateMerges />
                      </RestrictedRoute>
                    }
                  />
                  <Route
                    path="create-merges/new"
                    element={
                      <RestrictedRoute restriction={'merges'}>
                        <MergeProvider>
                          <MergeCreator />
                        </MergeProvider>
                      </RestrictedRoute>
                    }
                  />
                  <Route
                    path="editMerges/:mergeid"
                    element={
                      <RestrictedRoute restriction={'merges'}>
                        <MergeProvider>
                          <MergeCreator />
                        </MergeProvider>
                      </RestrictedRoute>
                    }
                  />
                  <Route
                    path="editMerges/:mergeid/:suiteid"
                    element={
                      <RestrictedRoute restriction={'merges'}>
                        <MergeProvider>
                          <MergeCreator />
                        </MergeProvider>
                      </RestrictedRoute>
                    }
                  />
                  <Route
                    path="upload-deal/:suite/form"
                    element={
                      <RestrictedRoute restriction={'createDeal'}>
                        <MergeProvider>
                          <CreateDealProvider>
                            <CreateDealForm />
                          </CreateDealProvider>
                        </MergeProvider>
                      </RestrictedRoute>
                    }
                  />
                  <Route
                    path="create-deal/:suite/form"
                    element={
                      <RestrictedRoute restriction={'createDeal'}>
                        <MergeProvider>
                          <CreateDealProvider>
                            <CreateDealForm />
                          </CreateDealProvider>
                        </MergeProvider>
                      </RestrictedRoute>
                    }
                  />
                  <Route
                    path="create-deal/:suite/form/:worksheet"
                    element={
                      <RestrictedRoute restriction={'createDeal'}>
                        <MergeProvider>
                          <CreateDealProvider>
                            <CreateDealForm />
                          </CreateDealProvider>
                        </MergeProvider>
                      </RestrictedRoute>
                    }
                  />
                  <Route
                    path="create-deal/:suite/deal/:deal"
                    element={
                      <RestrictedRoute restriction={'createDeal'}>
                        <MergeProvider>
                          <CreateDealProvider>
                            <CreateDealForm />
                          </CreateDealProvider>
                        </MergeProvider>
                      </RestrictedRoute>
                    }
                  />
                  <Route
                    path="project-settings"
                    element={
                      <RestrictedRoute restriction={'projectSettings'}>
                        <CreateProject type={'edit'} />
                      </RestrictedRoute>
                    }
                  />
                  <Route
                    path="project-settings"
                    element={
                      <RestrictedRoute restriction={'distribution'}>
                        <Distribution />
                      </RestrictedRoute>
                    }
                  />
                  <Route
                    path="outstandings"
                    element={
                      <RestrictedRoute restriction={'viewOutstandings'}>
                        <Outstandings userType="all" />
                      </RestrictedRoute>
                    }
                  />
                  <Route
                    path="activity"
                    element={
                      <RestrictedRoute restriction={'viewActivities'}>
                        <Activity />
                      </RestrictedRoute>
                    }
                  />
                </Route>
              </Route>
            </Routes>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </div>
  );
};

export default withRouter(App);
