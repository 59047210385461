import { useState } from 'react';
import { Box, Paper, AppBar, Tabs, Tab, Divider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Dropdown from '../common/formControls/Dropdown';
import { IProject } from '../../types/project';
import MergeTemplateList from './MergeTemplateList';
import ProjectsSummary from './ProjectsSummary';
import Fintrac from './Fintrac';
import Documents from './Documents';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/auth/authSlice';

const AllProjects = () => {
  const user = useSelector(selectUser);
  const [selectedProject, setSelectedProject] = useState<IProject | null>(null);
  const [filter, setFilter] = useState<string>('main');
  const [value, setValue] = useState(0);

  const handleDropdownInput = (event: any) => {
    let selectedProject = user.projectAccess.find((project: any) => {
      return project.project.name === event.target.value;
    });
    setSelectedProject(selectedProject.project);
  };

  const handleProjectNames = () => {
    let projectNames = user.projectAccess.map((project: any) => {
      return project.project.name;
    });
    return projectNames;
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <Box px={2}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index: any) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  return (
    <Box
      sx={{
        p: 2,
        height: '100vh',
        position: 'relative',
      }}
    >
      <Box sx={{ display: 'flex' }}>
        {selectedProject ? (
          <ArrowBackIcon
            sx={{ cursor: 'pointer', mr: 1, alignSelf: 'center' }}
            onClick={() => {
              setSelectedProject(null);
              setFilter('main');
            }}
          />
        ) : null}
        <h1>Overall Report</h1>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        {filter === 'main' ? (
          <Dropdown
            id={'dropdown'}
            title={'Select Project'}
            menuList={handleProjectNames()}
            name={'projectType'}
            handleSelect={(e: any) => handleDropdownInput(e)}
            value={selectedProject ? selectedProject?.name : ''}
          />
        ) : null}
      </Box>
      <div>
        {filter === 'main' ? (
          selectedProject ? (
            <Paper elevation={5}>
              <AppBar position="static" color="transparent" elevation={0}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label="Project Info" {...a11yProps(0)} />
                  <Tab label="Merge Templates" {...a11yProps(1)} />
                  <Tab label="Fintrac" {...a11yProps(2)} />
                  <Tab label="Documents" {...a11yProps(3)} />
                </Tabs>
              </AppBar>
              <Divider />
              <TabPanel value={value} index={0}>
                <MergeTemplateList id={selectedProject._id} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Fintrac project={selectedProject} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Documents project={selectedProject} />
              </TabPanel>
            </Paper>
          ) : (
            <ProjectsSummary />
          )
        ) : null}
      </div>
    </Box>
  );
};

export default AllProjects;
