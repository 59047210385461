import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Button, Typography } from '@mui/material';
import { UnitContext } from '../../../context/UnitContext';
import { selectProject } from '../../../features/project/projectSlice';
import { selectUser } from '../../../features/auth/authSlice';
import { accessAllowed } from '../../../features/project/projectHooks';
import SolicitorForm from './SolicitorForm';

const Solicitor = (props: ChildProps) => {
  const { errors, updateSolicitor } = props;
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const { filteredDeal, setFilteredDeal } = useContext(UnitContext);

  const handleTextInput = async (event: any) => {
    if (event && event.target.id === 'streetAddress') {
      setFilteredDeal({
        ...filteredDeal,
        solicitor: {
          ...filteredDeal.solicitor,
          [event.target.id]: event.target.value,
        },
      });
    }

    if (event && event.target.name) {
      setFilteredDeal({
        ...filteredDeal,
        solicitor: {
          ...filteredDeal.solicitor,
          [event.target.name]: event.target.value,
        },
      });
    }
  };

  const handleStreetAddress = async (value: string, type: number) => {
    setFilteredDeal({
      ...filteredDeal,
      solicitor: {
        ...filteredDeal.solicitor,
        streetAddress: value,
      },
    });
  };

  const handleDetails = async (city: string, province: string, postalCode: string, country: string, address: string) => {
    setFilteredDeal({
      ...filteredDeal,
      solicitor: {
        ...filteredDeal.solicitor,
        streetAddress: address,
        city: city,
        province: province,
        postalCode: postalCode,
      },
    });
  };

  const handleSolicitorSubmit = (e: any) => {
    e.preventDefault();
    const { _id, totalPrice, ...newObject } = filteredDeal;
    let object = newObject.solicitor;

    updateSolicitor({ variables: { _id: filteredDeal._id, record: { solicitor: object } } });
  };
  
  return (
    <div>
      <Typography variant="h2" sx={{ mb: 1 }}>
        <strong>Solicitor Information</strong>
      </Typography>
      <form onSubmit={handleSolicitorSubmit} autoComplete="off">
        <SolicitorForm
          solicitorInfo={filteredDeal.solicitor}
          handleTextInput={handleTextInput}
          handleDetails={handleDetails}
          error={errors}
          handleStreetAddress={handleStreetAddress}
        />
        {accessAllowed(user, project._id, 'editDeals') ? (
          <Button sx={{ mt: 2 }} type={'submit'} name={'update'} color="success" variant="contained">
            Save Solicitor Information
          </Button>
        ) : null}
      </form>
    </div>
  );
};

interface ChildProps {
  errors: any;
  updateSolicitor: any;
}

export default Solicitor;
