import { useState } from 'react';
import { Box, Typography, Autocomplete, TextField, Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { gql, useLazyQuery } from '@apollo/client';
import { IProject } from '../../types/project';
import { Flex } from '../../commonStyles';
import ChartComponent from '../dashboard/ChartComponent';
import LoadingWrapper from '../common/LoadingWrapper';
import { IUnitData, IUnit } from '../../types/unit';
import TypeSummary from '../reports/TypeSummary';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/auth/authSlice';

const CombinedSummary = () => {
  const user = useSelector(selectUser);
  const [project, setProject] = useState<any | null>(null);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [combinedData, setCombinedData] = useState<IStatusData[]>([]);
  const [selectedProject, setSelectedProject] = useState<IStatusData | null>(null);
  const [loading, setLoading] = useState(false);
  const [units, setUnits] = useState<IUnit[]>([]);

  const projects = user.projectAccess.map((project: any) => {
    return project.project;
  });

  const [getProjectStats] = useLazyQuery(GETDEALSTATS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {},
  });

  const [getProject] = useLazyQuery(GETPROJECT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err);
    },
  });

  const [getUnits] = useLazyQuery<IUnitData>(UNITS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setUnits(data.unitMany);
    },
  });

  const handleProjectData = async () => {
    setSelectedProject(null);
    let selectedProjectIds = selectedProjects.map((project: string) => {
      let selectedProject = projects.find((allProject: IProject) => allProject.name === project);
      if (selectedProject) {
        return selectedProject;
      } else return null;
    });

    setLoading(true);

    let data: IStatusData[] = [];

    for await (const project of selectedProjectIds) {
      await getProjectStats({ variables: { project: project?._id } }).then((res: any) => {
        let statusData = { ...res.data.getStatusCount };
        statusData.project = project;
        data.push(statusData);
      });
    }

    setLoading(false);
    setCombinedData(data);
  };

  const handleChange = (event: any, project: string) => {
    if (project === 'Combined') {
      let allData = combinedData;
      let selectedProjectIds = selectedProjects.map((project: string) => {
        let selectedProject = projects.find((allProject: IProject) => allProject.name === project);
        if (selectedProject) {
          return selectedProject;
        } else return null;
      });

      let allTotals = allData.map((data: IStatusData) => {
        let allTotalObjects = data.allTotals.map((allTotal: ITotalCounts) => {
          return { ...allTotal };
        });
        return [...allTotalObjects];
      });
      let commercialTotals = allData.map((data: IStatusData) => {
        let allTotalObjects = data.commercialTotals.map((allTotal: ITotalCounts) => {
          return { ...allTotal };
        });
        return [...allTotalObjects];
      });
      let statusTotals = allData.map((data: IStatusData) => {
        let allTotalObjects = data.statusTotals.map((allTotal: ITotalCounts) => {
          return { ...allTotal };
        });
        return [...allTotalObjects];
      });

      const sumArray = (arr: any) => {
        const res = [];
        let array = [...arr];
        for (let i = 0; i < array.length; i++) {
          const ind = res.findIndex((el: any) => el._id === array[i]._id);
          if (ind === -1) {
            res.push(array[i]);
          } else {
            res[ind].count += array[i].count;
            res[ind].size += array[i].size;
            res[ind].revenue += array[i].revenue;
            res[ind].revenueNet += array[i].revenueNet;
          }
        }
        return res;
      };

      let allTotalsSum = sumArray([...allTotals.flat()]);
      let commercialTotalsSum = sumArray([...commercialTotals.flat()]);
      let statusTotalsSum = sumArray([...statusTotals.flat()]);
      let total = allData.reduce((a: any, b: any) => {
        return a + b.total;
      }, 0);
      let mortgageTotal = allData.reduce((a: any, b: any) => {
        return a + b.mortgageTotal;
      }, 0);

      setProject({
        name: 'Combined',
        logoGetUrl: 'https://s3.ca-central-1.amazonaws.com/app.rdsre.ca/logo.png',
        options: [],
        _id: '',
      });

      setSelectedProject({
        allTotals: allTotalsSum,
        checkCommercial: commercialTotalsSum.length ? true : false,
        commercialTotals: commercialTotalsSum,
        mortgageTotal: mortgageTotal,
        project: {
          _id: 'Combined',
        },
        statusTotals: statusTotalsSum,
        total: total,
      });

      getUnits({ variables: { filter: { projects: selectedProjectIds.map((selectedProjectId) => selectedProjectId._id) } } });
    } else if (project !== null) {
      let selectedData = combinedData.find((data) => data.project._id === project);
      if (selectedData) {
        getProject({ variables: { _id: project } }).then((res) => {
          setProject(res.data.projectById);
          setSelectedProject(selectedData!);
          getUnits({ variables: { filter: { project: project } } });
        });
      }
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography sx={{ mb: 2 }} variant={'h5'}>
        <strong>Combined Summaries</strong>
      </Typography>
      <Flex>
        <Autocomplete
          multiple
          options={projects.map((project: IProject) => project.name)}
          getOptionLabel={(option: string) => option}
          isOptionEqualToValue={(option, value) => option === value}
          disableClearable={false}
          freeSolo={false}
          value={selectedProjects}
          onChange={(event: any, newValue: any | null) => {
            setSelectedProjects(newValue.map((option: string) => option));
          }}
          renderInput={(params) => <TextField sx={{ width: '500px' }} {...params} label="Projects" size="small" />}
        />
        <Button sx={{ ml: 1 }} variant="contained" color="primary" onClick={() => handleProjectData()}>
          Submit
        </Button>
      </Flex>
      {loading ? (
        <LoadingWrapper title={'Loading...'} modal={false} />
      ) : combinedData.length ? (
        <ToggleButtonGroup
          value={selectedProject?.project._id}
          exclusive
          onChange={handleChange}
          aria-label="text alignment"
          sx={{
            my: 2,
            '& .MuiToggleButton-root.Mui-selected': {
              backgroundColor: '#00142a',
              color: '#fff',
            },
            '& .MuiToggleButton-root.Mui-selected:hover': {
              backgroundColor: '#00142a',
              color: '#fff',
            },
          }}
        >
          {combinedData.map((data: IStatusData, index: number) => {
            return (
              <ToggleButton key={index} value={data.project._id} aria-label="centered">
                {data.project.name}
              </ToggleButton>
            );
          })}
          {combinedData.length > 1 ? (
            <ToggleButton value="Combined" aria-label="centered">
              Combined
            </ToggleButton>
          ) : null}
        </ToggleButtonGroup>
      ) : null}
      {selectedProject && project ? (
        <Box sx={{ border: '1px solid #000' }}>
          <ChartComponent project={project} statusData={selectedProject} />
          <TypeSummary allUnits={units} />
        </Box>
      ) : null}
    </Box>
  );
};

interface IStatusData {
  allTotals: ITotalCounts[];
  total: number;
  mortgageTotal: number;
  commercialTotals: ITotalCounts[];
  statusTotals: ITotalCounts[];
  checkCommercial: boolean;
  project: any;
}

interface ITotalCounts {
  _id: string;
  count: number;
  size: number;
  revenue: number;
  revenueNet: number;
}

const UNITS = gql`
  query unitMany($filter: FilterFindManyUnitInput) {
    unitMany(filter: $filter, limit: 10000) {
      _id
      suite
      unit
      modelType
      unitType
      bathroom
      size
      outdoorType
      basePrice
      level
      exposure
      outdoorSize
      status
    }
  }
`;

const GETDEALSTATS = gql`
  query getStatusCount($project: MongoID!) {
    getStatusCount(project: $project) {
      total
      mortgageTotal
      checkCommercial
      allTotals {
        _id
        count
        size
        revenue
        revenueNet
      }
      commercialTotals {
        _id
        count
        size
        revenue
        revenueNet
      }
      statusTotals {
        _id
        count
        size
        revenue
        revenueNet
      }
      statusTiers {
        _id
        name
        count
        size
        revenue
        revenueNet
      }
      commercialTiers {
        _id
        name
        count
        size
        revenue
        revenueNet
      }
    }
  }
`;

const GETPROJECT = gql`
  query projectById($_id: MongoID!) {
    projectById(_id: $_id) {
      _id
      name
      developerName
      email
      trackingPhone
      addresses {
        streetAddress
        city
        province
        postalCode
      }
      options {
        name
        totalAvailable
        price
        allowedUnits
        type
      }
      depositStructures {
        name
        deposits {
          name
          type
          amount
          daysDue
          dateType
          dueDate
        }
        default
      }
      mergeTemplates {
        name
        mergeTemplate {
          _id
        }
        apsTemplates {
          name
          pageNumber
          attachToAps
        }
      }
      status {
        name
        code
        color
      }
      executors {
        name
        email
      }
      mortgageLimit
      lawyer
      salesOffice
      adjustments {
        name
        type
      }
      commissionIncludeOptions
      emailTemplates {
        _id
        name
        subject
        html
      }
      logoGetUrl
      logoPutUrl
      imageGetUrl
      imagePutUrl
      coopStructures {
        name
        coopRates {
          type
          days
          date
          description
          percentage
          fixedAmount
        }
      }
      decorModels {
        modelType
        allowed
      }
      portal {
        primaryColor
      }
    }
  }
`;

export default CombinedSummary;
