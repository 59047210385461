import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { IUnit, IUnitDataGridCard } from '../types/unit';
import { ICoopRate, IDeposits } from '../types/project';
import { IPurchaserInfo, IRealtorInfo, ISuiteInfo, INoRealtor, IDeal, IDealDeposit, IAutoDocuments } from '../types/CreateDealForm';
import { IMerge } from '../types/merge';
import subYears from 'date-fns/subYears';
import { IWorksheetById } from '../types/worksheet';
import { useSelector } from 'react-redux';
import { selectMerges } from '../features/merge/mergeSlice';
import { useAppDispatch } from '../app/hooks';
import { selectUser } from '../features/auth/authSlice';
import { selectProject } from '../features/project/projectSlice';
import { showSuccessSnackbar, showErrorSnackbar } from '../features/snackbar/snackbarSlice';

const CreateDealContext = React.createContext<any>(null);

const CreateDealProvider = (props: any) => {
  const storeDispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { suite, worksheet, deal } = useParams();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const merges = useSelector(selectMerges);

  const [activeStep, setActiveStep] = useState(0);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [noRealtor, setNoRealtor] = useState<INoRealtor>({
    noRealtor: false,
    noRealtorType: 'Public',
  });
  const [purchaserCount, setPurchaserCount] = useState<number>(0);
  const [realtorCount, setRealtorCount] = useState<number>(0);
  const [purchaserId, setPurchaserId] = useState<[]>([]);
  const [realtorId, setRealtorId] = useState<string | null>(null);
  const [selectedUnit, setSelectedUnit] = useState<IUnit>();
  const [depositType, setDepositType] = useState<IDeposits[]>([]);
  const [selectedAps, setSelectedAps] = useState<IMerge>(merges[0]);
  const [selectedMergeTemplates, setSelectedMergeTemplates] = useState<IMerge[]>([]);
  const [apsPdf, setApsPdf] = useState<any>(null);
  const [totalPdf, setTotalPdf] = useState<any>(null);
  const [displayPdf, setDisplayPdf] = useState<any>(null);
  const [pdfBlob, setPdfBlob] = useState<any>(null);
  const [status, setStatus] = useState<string>('');
  const [preparedDate, setPreparedDate] = useState<Date | null>(null);
  const [signDate, setSignDate] = useState<Date | null>(null);
  const [executeDate, setExecuteDate] = useState<Date | null>(null);
  const [firmDate, setFirmDate] = useState<Date | null>(null);
  const urlType: string = location.pathname.split('/')[3];
  const [openCustom, setOpenCustom] = useState<boolean>(false);
  const [validEmails, setValidEmails] = useState<string[]>([]);
  const [worksheetNotes, setWorksheetNotes] = useState<string>('');
  const [worksheetId, setWorksheetId] = useState<string>('');
  const [autoDocuments, setAutoDocuments] = useState<IAutoDocuments[]>([]);
  const [executor, setExecutor] = useState<string>('');
  const [selectedDeposit, setSelectedDeposit] = useState<string>('');
  const [coopRates, setCoopRates] = useState<ICoopRate[]>([]);

  // useSubscription(UNITSUBSCRIPTION, {
  //   variables: { projectId: project._id },
  //   onSubscriptionData: ({ subscriptionData: { data } }: any) => {
  //     console.log(data.logUnit, 'log');
  //     console.log(selectedUnit, 'selected');
  //     if (selectedUnit?._id === data.logUnit._id) {
  //       setSelectedUnit(data.logUnit);
  //     }
  //   },
  // });

  useQuery<IUnitDataGridCard>(GET_UNIT, {
    variables: { _id: suite },
    onCompleted: (data) => {
      setSelectedUnit(data.unitById);
    },
  });

  useQuery<IWorksheetById>(GET_WORKSHEET, {
    skip: !worksheet,
    variables: { _id: worksheet },
    onCompleted: (data) => {
      setWorksheetNotes(data.worksheetById.notes);
      setPurchaserInfo(data.worksheetById.purchasers!);
      let allPurchasers: any = data.worksheetById.purchasers.map((purchaser: IPurchaserInfo) => {
        return purchaser._id;
      });
      setPurchaserId(allPurchasers!);
      setPurchaserCount(allPurchasers.length);
      setSuiteInfo({
        options: data.worksheetById.options!,
        deposit: [],
        tags: [],
        comments: data.worksheetById.notes,
        salesRep: data.worksheetById.salesRep ? data.worksheetById.salesRep._id! : null,
      });
      if (data.worksheetById.realtor) {
        const { _id, ...newObject } = data.worksheetById.realtor;
        setRealtorInfo(newObject);
        setRealtorId(data.worksheetById.realtor._id!);
        setRealtorCount(realtorCount + 1);
        setCoopRates(data.worksheetById.coop);
      }
      if (selectedUnit) {
        let updateUnit = {
          ...selectedUnit,
          unitPrice: selectedUnit.basePrice,
          basePrice: data.worksheetById.worksheetBasePrice,
        };
        setSelectedUnit(updateUnit);
      }
      setWorksheetId(data.worksheetById._id);
    },
  });

  useQuery<IDeal>(GETDEAL, {
    skip: !deal,
    variables: { _id: deal },
    onCompleted: (data) => {
      if (data.dealById) {
        setPurchaserInfo(data.dealById.purchasers!);
        let allPurchasers: any = data?.dealById?.purchasers.map((purchaser: IPurchaserInfo) => {
          return purchaser._id;
        });
        if (data.dealById.realtor.length) {
          const { _id, ...newObject } = data.dealById.realtor[0];
          setRealtorInfo(newObject);
          setRealtorId(data.dealById.realtor[0].realtor._id!);
          setRealtorCount(realtorCount + 1);
          setCoopRates(data.dealById.realtor[0].coopRates);
        }
        if (selectedUnit) {
          let updateUnit = {
            ...selectedUnit,
            basePrice: selectedUnit.basePrice,
          };
          setSelectedUnit(updateUnit);
        }
        setPurchaserId(allPurchasers!);
        setPurchaserCount(allPurchasers.length);
        setSuiteInfo({
          options: data?.dealById?.options!,
          deposit: data?.dealById?.deposit!,
          comments: '',
          tags: [],
          salesRep: data?.dealById?.salesRep ? data.dealById.salesRep._id : null,
        });
      }
    },
  });

  const [uploadDeal] = useMutation(NEWDEAL, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Deal has been uploaded'));
      if (realtorId) {
        updateStatus({
          variables: {
            _id: selectedUnit?._id,
            record: {
              status: `${status}`,
              allocation: `${realtorId ? realtorId : null}`,
              history: [
                selectedUnit?.history,
                {
                  type: 'Status',
                  description: `A deal has been uploaded for ${selectedUnit?.suite}`,
                  timestamp: new Date(),
                  user: user._id,
                },
              ],
            },
          },
        });
      } else {
        updateStatus({
          variables: {
            _id: selectedUnit?._id,
            record: {
              status: `${status}`,
              allocation: null,
              history: [
                selectedUnit?.history,
                {
                  type: 'Status',
                  description: `A deal has been uploaded for ${selectedUnit?.suite}`,
                  timestamp: new Date(),
                  user: user._id,
                },
              ],
            },
          },
        });
      }
      navigate(`/${project._id}/dashboard/${selectedUnit?._id}`);
    },
    onError: (err) => {
      console.log(err, 'error');
    },
  });

  const [createDeposits] = useMutation(CREATEDEPOSITS, {
    onCompleted: (data) => {
      let depositIds = data.depositCreateMany.records.map((deposit: IDealDeposit) => {
        return deposit._id;
      });
      // let solicitor = {
      //   solicitor: "",
      //   firm: "",
      //   streetAddress: "",
      //   city: "",
      //   province: "",
      //   postalCode: "",
      //   email: "",
      //   primaryPhone: "",
      // }

      let dealObject = {
        project: project._id,
        user: user._id,
        comments: suiteInfo.comments,
        unit: selectedUnit?._id,
        purchasers: purchaserId,
        realtor: realtorId,
        deposit: depositIds,
        basePrice: selectedUnit?.basePrice,
        options: suiteInfo.options,
        tags: [...suiteInfo.tags, noRealtor.noRealtorType],
        salesRep: suiteInfo.salesRep,
        // solicitor: solicitor,
        preparedDate: preparedDate,
        signDate: signDate,
        executeDate: executeDate,
        firmDate: firmDate,
      };

      uploadDeal({ variables: dealObject });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateStatus] = useMutation(UPDATEUNIT, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [purchaserInfo, setPurchaserInfo] = useState<IPurchaserInfo[]>([
    {
      project: project._id,
      corp: false,
      firstName: '',
      lastName: '',
      identifications: [],
      dob: subYears(new Date(), 18),
      streetAddress: '',
      city: '',
      province: '',
      country: '',
      postalCode: '',
      idType: "Driver's Licence",
      idNumber: '',
      idJurisdiction: 'Ontario',
      idExpiry: new Date(),
      proofType: '',
      proofNumber: '',
      proofExpiry: null,
      sin: '',
      primaryPhone: '',
      email: '',
      occupation: '',
      employer: '',
      directors: '',
      businessNumber: '',
      signingOfficers: [],
      purchaserType: 'Investor',
    },
  ]);

  const [realtorInfo, setRealtorInfo] = useState<IRealtorInfo | null>({
    firstName: '',
    lastName: '',
    email: '',
    brokerage: '',
    streetAddress: '',
    city: '',
    province: '',
    country: '',
    postalCode: '',
    brokeragePhone: '',
    brokerageFax: '',
    directPhone: '',
  });

  const [suiteInfo, setSuiteInfo] = useState<ISuiteInfo>({
    options: [],
    deposit: [],
    comments: '',
    tags: [],
    salesRep: null,
  });

  const handleNewPurchaser = () => {
    setPurchaserInfo([
      ...purchaserInfo,
      {
        project: project._id,
        corp: false,
        firstName: '',
        lastName: '',
        identifications: [],
        dob: subYears(new Date(), 18),
        streetAddress: '',
        city: '',
        province: '',
        country: '',
        postalCode: '',
        idType: "Driver's Licence",
        idNumber: '',
        idJurisdiction: 'Ontario',
        idExpiry: new Date(),
        proofType: '',
        proofNumber: '',
        proofExpiry: null,
        sin: '',
        primaryPhone: '',
        email: '',
        occupation: '',
        employer: '',
        directors: '',
        businessNumber: '',
        signingOfficers: [],
        purchaserType: 'Investor',
      },
    ]);
  };

  const uploadNewDeal = () => {
    if (!status) {
      storeDispatch(showErrorSnackbar('Status has not been set'));
      return;
    }
    if (status === 'C') {
      if (!signDate || !executeDate) {
        storeDispatch(showErrorSnackbar('Dates have not been set'));
        return;
      }
    }
    if (status === 'F') {
      if (!signDate || !executeDate || !firmDate) {
        storeDispatch(showErrorSnackbar('Dates have not been set'));
        return;
      }
    }

    if (noRealtor.noRealtor) {
      setSuiteInfo({
        ...suiteInfo,
        tags: [...suiteInfo.tags, noRealtor.noRealtorType],
      });
    }

    let deposits = suiteInfo.deposit.map(({ dateType, ...keepAttrs }) => keepAttrs);

    createDeposits({ variables: { records: deposits } });
  };

  return (
    <CreateDealContext.Provider
      value={{
        purchaserInfo,
        setPurchaserInfo,
        handleNewPurchaser,
        realtorInfo,
        setRealtorInfo,
        activeStep,
        setActiveStep,
        suiteInfo,
        setSuiteInfo,
        showForm,
        setShowForm,
        purchaserCount,
        setPurchaserCount,
        noRealtor,
        setNoRealtor,
        realtorCount,
        setRealtorCount,
        purchaserId,
        setPurchaserId,
        realtorId,
        setRealtorId,
        selectedUnit,
        setSelectedUnit,
        depositType,
        setDepositType,
        apsPdf,
        setApsPdf,
        totalPdf,
        setTotalPdf,
        displayPdf,
        setDisplayPdf,
        pdfBlob,
        setPdfBlob,
        selectedAps,
        setSelectedAps,
        selectedMergeTemplates,
        setSelectedMergeTemplates,
        urlType,
        uploadNewDeal,
        status,
        setStatus,
        preparedDate,
        setPreparedDate,
        signDate,
        setSignDate,
        executeDate,
        setExecuteDate,
        firmDate,
        setFirmDate,
        openCustom,
        setOpenCustom,
        validEmails,
        setValidEmails,
        worksheetNotes,
        autoDocuments,
        setAutoDocuments,
        worksheetId,
        executor,
        setExecutor,
        selectedDeposit,
        setSelectedDeposit,
        coopRates,
        setCoopRates,
      }}
    >
      {props.children}
    </CreateDealContext.Provider>
  );
};

const GET_UNIT = gql`
  query unitById($_id: MongoID!) {
    unitById(_id: $_id) {
      project {
        _id
      }
      _id
      suite
      status
      unit
      level
      modelType
      unitType
      basePrice
      rental
      getUrl
      custom
      type
      tier
      firstTentativeOccupancy
      finalTentativeOccupancy
      firmOccupancy
      outsideOccupancy
      floorPlan {
        _id
        mergeFields {
          key
          index
          pageNumber
          x
          y
          fontSize
          format
          wrap
        }
        signFields {
          key
          index
          pageNumber
          x
          y
        }
        totalPages
      }
      row
      col
    }
  }
`;

const CREATEDEPOSITS = gql`
  mutation depositCreateMany($records: [CreateManyDepositInput!]!) {
    depositCreateMany(records: $records) {
      records {
        _id
        deal {
          _id
        }
        name
        amount
        dueDate
        accountNumber
        chequeNumber
        chequeDate
        chequeType
        chequeAmount
      }
    }
  }
`;

const NEWDEAL = gql`
  mutation uploadDeal(
    $basePrice: Float!
    $comments: String!
    $deposit: [MongoID]!
    $unit: MongoID!
    $user: MongoID!
    $salesRep: MongoID
    $options: [NewOptionsInput]!
    $project: MongoID!
    $purchasers: [MongoID]!
    $tags: [String]
    $realtor: MongoID
    $preparedDate: Date!
    $signDate: Date
    $executeDate: Date
    $firmDate: Date
  ) {
    uploadDeal(
      user: $user
      basePrice: $basePrice
      comments: $comments
      deposit: $deposit
      unit: $unit
      tags: $tags
      options: $options
      project: $project
      purchasers: $purchasers
      realtor: $realtor
      salesRep: $salesRep
      preparedDate: $preparedDate
      signDate: $signDate
      executeDate: $executeDate
      firmDate: $firmDate
    ) {
      _id
    }
  }
`;

const UPDATEUNIT = gql`
  mutation unitUpdateById($_id: MongoID!, $record: UpdateByIdUnitInput!) {
    unitUpdateById(_id: $_id, record: $record) {
      record {
        status
      }
    }
  }
`;

const GETDEAL = gql`
  query dealById($_id: MongoID!) {
    dealById(_id: $_id) {
      _id
      project {
        _id
      }
      purchasers {
        _id
        email
        firstName
        lastName
        corp
        sin
        dob
        identifications {
          name
          getUrl
        }
        streetAddress
        city
        province
        country
        postalCode
        occupation
        employer
        directors
        businessNumber
        signingOfficers {
          fullName
          dob
          sin
          primaryPhone
          streetAddress
          email
        }
        purchaserType
        primaryPhone
        idType
        idNumber
        idExpiry
        proofType
        proofNumber
        proofExpiry
        unit
        idJurisdiction
        getUrl
        putUrl
      }
      realtor {
        _id
        email
        firstName
        lastName
        brokerage
        streetAddress
        city
        province
        country
        postalCode
        brokeragePhone
        brokerageFax
        directPhone
        realtor {
          _id
        }
        coopRates {
          type
          days
          date
          description
          percentage
          fixedAmount
        }
      }
      deposit {
        name
        amount
        dueDate
        accountNumber
        chequeNumber
        chequeDate
        chequeType
        chequeAmount
      }
      options {
        name
        purchaseAmount
        amount
      }
      basePrice
      tags
      mortgage {
        lender
        revisions
        amount
        getUrl
        putUrl
      }
      salesRep {
        _id
        fullName
      }
      debit {
        name
        institution
        branch
        account
        accountHolders {
          fullName
          email
        }
      }
      solicitor {
        solicitor
        firm
        streetAddress
        city
        province
        postalCode
        email
        primaryPhone
      }
    }
  }
`;

const GET_WORKSHEET = gql`
  query worksheetById($_id: MongoID!) {
    worksheetById(_id: $_id) {
      _id
      project {
        _id
      }
      purchasers {
        _id
        email
        firstName
        lastName
        corp
        sin
        dob
        identifications {
          name
          getUrl
        }
        streetAddress
        city
        province
        country
        postalCode
        occupation
        employer
        directors
        businessNumber
        signingOfficers {
          fullName
          dob
          sin
          primaryPhone
          streetAddress
          email
        }
        purchaserType
        primaryPhone
        idType
        idNumber
        idExpiry
        proofType
        proofNumber
        proofExpiry
        unit
        idJurisdiction
        getUrl
        putUrl
      }
      realtor {
        _id
        email
        firstName
        lastName
        brokerage
        streetAddress
        city
        province
        country
        postalCode
        brokeragePhone
        brokerageFax
        directPhone
      }
      options {
        name
        purchaseAmount
        amount
      }
      worksheetBasePrice
      notes
      salesRep {
        _id
        fullName
      }
    }
  }
`;

export { CreateDealContext, CreateDealProvider };
