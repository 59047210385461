import React from 'react';
import { Grid } from '@mui/material';
import TextInput from '../../common/formControls/TextInput';
import { IDebit, } from './Debit';

const DebitForm = (props: ChildProps) => {
  const { handleTextInput, index, debit, error } = props;
  return (
    <>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <TextInput
          title={'Financial Institution Name'}
          name={'name'}
          handleTextInput={(e: any) => handleTextInput(e, index)}
          value={debit.name}
          required={true}
          helperText={error.name}
          error={error.name ? true : false}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <TextInput
          title={'Institution Number (3 Digits)'}
          name={'institution'}
          type="number"
          handleTextInput={(e: any) => handleTextInput(e, index)}
          value={debit.institution}
          required={true}
          helperText={error.institution}
          error={error.institution ? true : false}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <TextInput
          title={'Branch Number (5 Digits)'}
          name={'branch'}
          type="number"
          handleTextInput={(e: any) => handleTextInput(e, index)}
          value={debit.branch}
          required={true}
          helperText={error.branch}
          error={error.branch ? true : false}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <TextInput
          title={'Account Number'}
          name={'account'}
          handleTextInput={(e: any) => handleTextInput(e, index)}
          value={debit.account}
          required={true}
          helperText={error.account}
          error={error.account ? true : false}
        />
      </Grid>
    </>
  );
};

interface ChildProps {
  error: any;
  debit: IDebit;
  index: number;
  handleTextInput: any;
}

export default DebitForm;
