import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Buffer } from 'buffer';
import _ from 'lodash';
import Dropzone from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { gql, useMutation, useLazyQuery, useSubscription } from '@apollo/client';
import {
  Box,
  CircularProgress,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { selectProject } from '../../features/project/projectSlice';
import { selectUser } from '../../features/auth/authSlice';
import { useAppDispatch } from '../../app/hooks';
import { showSuccessSnackbar, showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import { accessAllowed } from '../../features/project/projectHooks';
import { useCreateActivity } from '../../features/activity/activityHooks';
import { UnitContext } from '../../context/UnitContext';
import TextInput from '../common/formControls/TextInput';
import Dropdown from '../common/formControls/Dropdown';
import { IDocuments, ISigner, IEnvelopeData, IUnitHistory } from '../../types/unit';
import { IDealRealtor, IRealtorInfo } from '../../types/CreateDealForm';
import { IDealDeposit } from '../../types/CreateDealForm';
import { mergeType } from '../../utils/Constants';
import { IMerge, IMergeData } from '../../types/merge';
import { IExecutor } from '../../types/project';
import { capitalizeFirstLetter, createPdf, convertAllDates, getMergeValues, signField } from '../../utils/Functions';
import CustomDialog from '../common/CustomDialog';
import LoadingWrapper from '../common/LoadingWrapper';
import PdfCard from '../common/PdfCard';
import { FlexBetween, FlexEnd } from '../../commonStyles';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '../../utils/Constants';

const Envelopes = () => {
  const {
    setFilteredDeal,
    filteredDeal,
    setDocuments,
    documents,
    unit,
    removeDeposits,
    updateDealDocuments,
    updatePendingDeposit,
    dealUpdateLoading,
  } = useContext(UnitContext);
  const createActivity = useCreateActivity();
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);

  const [merges, setMerges] = useState<IMerge[]>([]);
  const [selectedMerge, setSelectedMerge] = useState<any[]>([]);
  const [coopMerge, setCoopMerge] = useState<boolean>(false);
  const [selectedRealtor, setSelectedRealtor] = useState<IDealRealtor | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<string>('');
  const [selectedDocument, setSelectedDocument] = useState<IDocuments | null>(null);
  const [executor, setExecutor] = useState<IExecutor | null>(project.executors ? project.executors[0] : null);
  const [title, setTitle] = useState<string>('');
  const [documentType, setDocumentType] = useState<string>('');
  const [fileImage, setFileImage] = useState<any>('');
  const [file, setFile] = useState<any>(null);
  const [auditDate, setAuditDate] = useState<Date | null>(filteredDeal.auditDate ? filteredDeal.auditDate : null);

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg, image/png',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  useSubscription(UPDATED_DOCUMENT, {
    variables: { projectId: project._id },
    onSubscriptionData: ({
      subscriptionData: {
        data: { updatedDocument },
      },
    }) => {
      const newDocuments = _.cloneDeep(documents);
      const foundDocument = newDocuments.find((nd: any) => nd._id === updatedDocument._id);
      if (foundDocument) {
        foundDocument.status = updatedDocument.status;
      }
      setDocuments(newDocuments);
    },
  });

  const [getMerges] = useLazyQuery<IMergeData>(MERGETEMPLATES, {
    variables: { filter: { project: project._id, excludeTypes: ['Floorplan', 'UnitTransfer', 'Options', 'Deposit', 'Archive'] } },
    onCompleted: (data) => {
      setMerges(data.mergeTemplateMany);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateUnitTransfer] = useMutation(UPDATEUNIT, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [resendEnvelope, { loading: resendLoading }] = useLazyQuery(RESENDENVELOPE, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(`${data.resendEnvelope}`));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(`${err}`));
      console.log(err, 'err');
    },
  });

  const [getSigners, { loading: getSignersLoading }] = useLazyQuery(GETSIGNERS, {
    onCompleted: (data) => {
      let signerArray = documents.map((documents: IDocuments) => {
        if (documents._id === data.documentById._id) {
          return {
            ...documents,
            signers: data.documentById.signers,
          };
        } else return documents;
      });
      setDocuments(signerArray);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [getPreviewData, { loading: previewLoading }] = useLazyQuery(GETPREVIEWDATA, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const binaryData = Buffer.from(data.documentById.previewData, 'binary');
      const blob = new Blob([binaryData], { type: 'application/pdf' });
      const previewUrl = URL.createObjectURL(blob);

      const element = document.createElement('a');
      element.href = previewUrl;
      element.target = '_blank';
      element.click();
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [getEnvelopeData, { loading: envelopeLoading }] = useLazyQuery(GETENVELOPEDATA, {
    onCompleted: (data) => {
      let envelopeArray = documents.map((documents: IDocuments) => {
        if (documents._id === data.documentById._id) {
          return {
            ...documents,
            envelopeData: data.documentById.envelopeData,
          };
        } else return documents;
      });
      setDocuments(envelopeArray);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [deleteDocument, { loading: deleteDocumentLoading }] = useMutation(DELETEDOCUMENT, {
    onCompleted: (data) => {
      let removedDocument = documents.filter((document: IDocuments) => {
        return data.documentDeleteById._id !== document._id;
      });
      setDocuments(removedDocument);
      storeDispatch(showSuccessSnackbar(`Document has been deleted/voided!`));
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Delete Document`,
        content: `Document ${data.documentDeleteById.name} has been deleted and void. The status was ${data.documentDeleteById.status}`,
      });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [createDocument, { loading: createDocumentLoading }] = useMutation(CREATEDOCUMENT, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(`Document has been created!`));
      let documentIds = documents.map((document: IDocuments) => {
        return document._id;
      });
      updateDealDocuments({
        variables: { _id: filteredDeal._id, record: { documents: [...documentIds, data.documentCreateOne._id] } },
      });
      setFilteredDeal({
        ...filteredDeal,
        documents: [...documents, data.documentCreateOne],
      });
      setDocuments([...documents, data.documentCreateOne]);
      setTitle('');
      setDocumentType('');
      setFileImage(null);
      setSelectedRealtor(null);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [createMultipleDocument, { loading: createMultipleDocumentLoading }] = useMutation(CREATEMULTIPLEDOCUMENT, {
    onCompleted: (data) => {
      setDialogType('');
      setDialogOpen(false);
      storeDispatch(showSuccessSnackbar(`Document has been created!`));
      let documentIds = documents.map((document: IDocuments) => {
        return document._id;
      });
      updateDealDocuments({
        variables: { _id: filteredDeal._id, record: { documents: [...documentIds, data.createEnvelopeMultipleDocuments._id] } },
      });
      setFilteredDeal({
        ...filteredDeal,
        documents: [...documents, data.createEnvelopeMultipleDocuments],
      });
      setDocuments([...documents, data.createEnvelopeMultipleDocuments]);
      setTitle('');
      setDocumentType('');
      setFileImage(null);
      setSelectedRealtor(null);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.toString()));
      console.log(err, 'err');
    },
  });

  const [sendDocument, { loading: sendDocumentLoading }] = useMutation(SENDDOCUMENT, {
    onCompleted: (data) => {
      const documentsClone = [...documents];
      const updatedDocument = documentsClone.find((doc) => doc._id === data.documentCreateEnvelope._id);
      updatedDocument.status = data.documentCreateEnvelope.status;
      updatedDocument.dsEnvelopeId = data.documentCreateEnvelope.dsEnvelopeId;
      setDocuments(documentsClone);
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Document Sent!`,
        content: `Document ${data.documentCreateEnvelope.name} has been sent!`,
      });
      storeDispatch(showSuccessSnackbar(`Document has been sent!`));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.toString()));
      console.log(err, 'err');
    },
  });

  const [sendDocumentToExecutor, { loading: sendingDocumentToExecutor }] = useMutation(SENDDOCUMENTTOEXECUTOR, {
    onCompleted: (data) => {
      const documentsClone = _.cloneDeep(documents);
      const updatedDocument = documentsClone.find((doc: any) => doc._id === data.documentSendToExecutor._id);
      updatedDocument.status = data.documentSendToExecutor.status;
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Document Sent To Executor`,
        content: `Document ${data.documentSendToExecutor.name} has been sent to the Executor ${executor?.name}`,
      });
      storeDispatch(showSuccessSnackbar(`Document has been sent to Executor ${executor?.name}`));
      setExecutor(null);
      setDocuments(documentsClone);
      setDialogType('');
      setDialogOpen(false);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  // Audit
  const [updateDealSales] = useMutation(UPDATEDEALINFO, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Audit Information Updated'));
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Deal Information`,
        content: `${filteredDeal.auditDate ? `${unit.suite}'s has been audit has been removed` : `${unit.suite}'s has been audited`}`,
      });
      setFilteredDeal({
        ...filteredDeal,
        auditor: filteredDeal.auditor,
        auditDate: filteredDeal.auditDate,
      });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  useEffect(() => {
    if (selectedMerge.length) {
      let merge = merges.find((merge: IMerge) => merge.name === selectedMerge[0]);
      if (merge && merge.type === 'Coop') {
        setCoopMerge(true);
      }
    } else {
      setCoopMerge(false);
    }
  }, [selectedMerge]);

  const handleAudit = (e: any) => {
    if (filteredDeal.auditor) {
      setAuditDate(null);
      updateDealSales({
        variables: {
          _id: filteredDeal._id,
          record: {
            auditor: null,
            auditDate: null,
          },
        },
      });
    } else {
      setAuditDate(new Date());
      updateDealSales({
        variables: {
          _id: filteredDeal._id,
          record: {
            auditor: user._id,
            auditDate: new Date(),
          },
        },
      });
    }
  };

  const messageSigners = (dsEnvelopeId: string, documentId: string, signers: ISigner[]) => {
    const signerStatus = (signer: string) => {
      if (signer === 'completed') {
        return <strong>Signed</strong>;
      } else if (signer === 'autoresponded') {
        return <strong>The email could not be delivered to the recipient</strong>;
      } else return <strong>Awaiting Signature</strong>;
    };

    if (dsEnvelopeId) {
      if (signers) {
        return signers.map((signer: ISigner, index: number) => {
          return (
            <div key={index}>
              <div>
                {capitalizeFirstLetter(signer.type)} {signer.name} - {signerStatus(signer.status)}
              </div>
            </div>
          );
        });
      }
    }
  };

  const messageEnvelope = (dsEnvelopeId: string, documentId: string, envelopeData: IEnvelopeData) => {
    if (dsEnvelopeId) {
      if (envelopeData) {
        return (
          <div>
            <div>
              Initial Sent Date: <strong>{convertAllDates(envelopeData.initialSentDateTime, 'PPpp')}</strong>
            </div>
            <div>
              Recent Sent Date: <strong>{convertAllDates(envelopeData.sentDateTime, 'PPpp')}</strong>
            </div>
            <div>
              Created Date: <strong>{convertAllDates(envelopeData.createdDateTime, 'PPpp')}</strong>
            </div>
            <div>
              Last Modified Date: <strong>{convertAllDates(envelopeData.lastModifiedDateTime, 'PPpp')}</strong>
            </div>
            <div>
              Delivered Date:{' '}
              <strong>{envelopeData.deliveredDateTime ? convertAllDates(envelopeData.deliveredDateTime, 'PPpp') : 'N/A'}</strong>
            </div>
            <div>
              Status Change Date: <strong>{convertAllDates(envelopeData.statusChangedDateTime, 'PPpp')}</strong>
            </div>
            <div>
              Completed Date:{' '}
              <strong>{envelopeData.completedDateTime ? convertAllDates(envelopeData.completedDateTime, 'PPpp') : 'N/A'}</strong>
            </div>
          </div>
        );
      }
    }
  };

  const openDialog = async (type: string, document: IDocuments | null = null) => {
    setSelectedRealtor(null);
    if (type === 'create') {
      getMerges();
    }
    if (type === 'execute' || type === 'sendEnvelope' || type === 'deleteDocument') {
      setSelectedDocument(document);
    }
    await setDialogType(type);
    await setDialogOpen(true);
  };

  const setBackgroundColor = (status: string) => {
    if (status === 'Received') {
      return {
        background: 'rgb(230, 184, 183)',
      };
    } else if (status === 'Signed') {
      return {
        background: 'rgb(233 204 255)',
      };
    } else if (status === 'Verified') {
      return {
        background: 'rgb(0, 176, 240)',
      };
    } else if (status === 'Completed') {
      return {
        background: 'rgb(146, 208, 80)',
      };
    } else return;
  };

  const executorList = () => {
    let executorNames = project.executors.map((executor: IExecutor, index: number) => {
      return executor.name;
    });
    return executorNames;
  };

  const handleExecutorDropdown = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    let executor = project?.executors.find((executor: IExecutor) => executor.name === event.target.value);
    setExecutor(executor!);
  };

  const handleDrop = (acceptedFiles: any) => {
    const file = acceptedFiles[0];
    const fileReader = new FileReader();
    if (file) {
      fileReader.readAsDataURL(file);
    }
    fileReader.onloadend = async () => {
      setFileImage(fileReader.result);
      setFile(acceptedFiles[0]);
    };
  };

  const dialogContent = () => {
    if (dialogType === 'create') {
      return (
        <Box sx={{ mt: 1 }}>
          <Autocomplete
            multiple
            options={merges.map((option: IMerge) => option.name)}
            getOptionLabel={(option: string) => option}
            isOptionEqualToValue={(option, value) => option === value}
            disableClearable={false}
            freeSolo={false}
            value={selectedMerge}
            onChange={(event: any, newValue: any | null) => {
              setSelectedMerge(newValue.map((option: string) => option));
            }}
            renderInput={(params) => <TextField {...params} label="Merge Templates" size="medium" />}
          />
          {coopMerge ? (
            <Box sx={{ mt: 1 }}>
              <FormControl fullWidth>
                <InputLabel id="id-question-label">Realtor</InputLabel>
                <Select
                  value={`${selectedRealtor?.email}`}
                  labelId="id-executor-label"
                  id="id-executor"
                  label="Realtor"
                  name="realtor"
                  onChange={(e: any) => handleSelectedRealtor(e)}
                >
                  {filteredDeal.realtor.map((realtor: IRealtorInfo) => {
                    return (
                      <MenuItem value={realtor.email}>
                        {realtor.firstName} {realtor.lastName} - {realtor.email}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          ) : null}
        </Box>
      );
    } else if (dialogType === 'execute') {
      return (
        <div>
          <Dropdown
            title={'Executor'}
            menuList={executorList()}
            name={'executors'}
            handleSelect={(e: any) => handleExecutorDropdown(e)}
            value={executor ? executor.name : ''}
          />
        </div>
      );
    } else if (dialogType === 'sendEnvelope' || dialogType === 'deleteDocument') {
      return (
        <div>
          <strong>{selectedDocument?.name!}</strong>
        </div>
      );
    } else if (dialogType === 'upload') {
      return (
        <div>
          <Box sx={{ my: 2 }}>
            <TextInput
              title={'Document Title'}
              id={'title'}
              handleTextInput={(e: any) => setTitle(e.target.value)}
              value={title}
              required={true}
            />
          </Box>
          <Box
            sx={{
              mb: 1,
              '& .MuiAccordionSummary-content': {
                display: 'block',
              },
            }}
          >
            <Dropdown
              id={'type'}
              title={'Type'}
              menuList={mergeType}
              name={'type'}
              handleSelect={(e: any) => setDocumentType(e.target.value)}
              value={documentType}
            />
          </Box>
          <div>
            {fileImage ? (
              <div>
                <Grid item xs={12}>
                  <PdfCard file={fileImage} title={title} id={'0'} handleDelete={() => setFileImage(null)} download={true} />
                </Grid>
              </div>
            ) : (
              <Dropzone onDrop={handleDrop} accept="application/pdf">
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      <p>Drag and Drop or Upload a Document (.pdf)</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
          </div>
        </div>
      );
    }
  };

  const handleSelectedRealtor = (event: any) => {
    let realtor = filteredDeal.realtor.find((realtor: IDealRealtor) => realtor.email === event.target.value);
    if (realtor) {
      setSelectedRealtor(realtor);
    }
  };

  const handleCloseSuccess = async () => {
    if (dialogType === 'execute') {
      sendToExecutor();
    } else if (dialogType === 'upload') {
      const args = {
        status: 'Completed',
        name: title,
        project: project._id,
        deal: filteredDeal._id,
        isAPS: false,
        type: documentType,
        mergeTemplate: null,
      };
      setDialogOpen(false);
      createDocument({ variables: { record: args, file: file } });
    } else if (dialogType === 'sendEnvelope') {
      handleSendDocument(selectedDocument!);
    } else if (dialogType === 'deleteDocument') {
      removeDocuments(selectedDocument?._id!, selectedDocument?.type!);
    } else {
      if (selectedMerge.length === 1) {
        let selectedMerges = merges.filter((merge: IMerge) => selectedMerge.some((mergeName: string) => mergeName === merge.name));

        let pdf = await createPdf(
          selectedMerges[0],
          project,
          unit,
          filteredDeal.purchasers,
          filteredDeal.deposit,
          filteredDeal.options,
          selectedRealtor ? selectedRealtor : filteredDeal.realtor[0],
          null,
          null,
          null,
          filteredDeal
        );

        let sign = selectedMerges[0].signFields.map((sign: any) => {
          return {
            index: sign.index,
            key: sign.key,
            pageNumber: sign.pageNumber,
            x: sign.x,
            y: sign.y,
          };
        });

        let mergeName = selectedMerges[0].name;

        if (selectedRealtor) {
          mergeName = `${selectedMerges[0].name} - ${selectedRealtor.firstName} ${selectedRealtor.lastName} - ${selectedRealtor.email}`;
        }

        const args = {
          status: 'Prepared',
          name: mergeName,
          project: project._id,
          deal: filteredDeal._id,
          signFields: sign,
          // signers,
          isAPS: false,
          type: selectedMerges[0].type,
          mergeTemplate: selectedMerges[0] ? selectedMerges[0]._id : null,
        };
        setDialogOpen(false);
        createDocument({ variables: { record: args, file: pdf } });
      } else if (selectedMerge.length > 1) {
        const mergedPdf = await PDFDocument.create();
        let selectedMerges = merges.filter((merge: IMerge) => selectedMerge.some((mergeName: string) => mergeName === merge.name));
        let documents = await Promise.all(
          selectedMerges.map(async (document: IMerge) => {
            let autoMergeTemplate = merges.find((mergeTemplate: IMerge) => mergeTemplate.name === document.name);

            let autoDocument = await fetch(autoMergeTemplate?.getUrl!).then(async (res) => await res.arrayBuffer());
            const documentPdf = await PDFDocument.load(autoDocument);
            let helveticaFont = await documentPdf.embedFont(StandardFonts.Helvetica);

            let pages = documentPdf.getPages();
            await autoMergeTemplate?.mergeFields.forEach(async (mergeField: any) => {
              let mergePages = pages[mergeField.pageNumber];
              let mergeValue = getMergeValues(
                mergeField,
                project,
                unit,
                null,
                filteredDeal.purchasers,
                filteredDeal.deposit,
                filteredDeal.options,
                selectedRealtor ? selectedRealtor : filteredDeal.realtor[0],
                null,
                null,
                null,
                null,
                filteredDeal
              );

              if (
                mergeField.key === 'P1Check' ||
                (mergeField.key === 'P2Check' && filteredDeal.purchasers.length > 1) ||
                (mergeField.key === 'P3Check' && filteredDeal.purchasers.length > 2) ||
                (mergeField.key === 'P4Check' && filteredDeal.purchasers.length > 3)
              ) {
                mergePages.drawLine({
                  start: { x: mergeField.x + 3, y: mergePages.getHeight() - mergeField.y - 3 },
                  end: { x: mergeField.x + 8, y: mergePages.getHeight() - mergeField.y + 4 },
                  thickness: 1,
                  color: rgb(0, 0, 0),
                  opacity: 0.75,
                });
                mergePages.drawLine({
                  start: { x: mergeField.x, y: mergePages.getHeight() - mergeField.y + 1 },
                  end: { x: mergeField.x + 3, y: mergePages.getHeight() - mergeField.y - 3 },
                  thickness: 1,
                  color: rgb(0, 0, 0),
                  opacity: 0.75,
                });
              } else if (
                mergeField.key !== 'P1Check' &&
                mergeField.key !== 'P2Check' &&
                mergeField.key !== 'P3Check' &&
                mergeField.key !== 'P4Check'
              ) {
                mergePages.drawText(mergeValue, {
                  x: mergeField.x,
                  y: mergePages.getHeight() - mergeField.y - 3,
                  size: mergeField.fontSize,
                  font: helveticaFont,
                  color: rgb(0, 0, 0),
                });
              }
            });

            let copiedPages = await mergedPdf.copyPages(documentPdf, documentPdf.getPageIndices());
            copiedPages.forEach((page: any) => mergedPdf.addPage(page));

            const documentDoc = await documentPdf.save();

            let mergeSignFields = autoMergeTemplate?.signFields.map((signFields: any, index: number) => {
              return {
                index: signFields.index,
                key: signFields.key,
                pageNumber: signFields.pageNumber,
                x: Math.round(signFields.x),
                y: Math.round(signFields.y),
              };
            });

            let blob = await new Blob([documentDoc]);
            let file = await new File([blob], `${document.name}.pdf`, { type: 'application/pdf' });

            return {
              file: file,
              name: document.name,
              mergeTemplate: document._id,
              signFields: mergeSignFields,
              isAPS: false,
              type: 'Other',
            };
          })
        );

        createMultipleDocument({ variables: { project: project._id, deal: filteredDeal._id, documents: documents } });
        setDialogOpen(false);
      }
    }
    setSelectedMerge([]);
  };

  const handleCloseSecond = async () => {
    const mergedPdf = await PDFDocument.create();
    let documents = await Promise.all(
      selectedMerge.map(async (document: IMerge) => {
        let autoMergeTemplate = merges.find((mergeTemplate: IMerge) => mergeTemplate.name === document.name);

        let autoDocument = await fetch(autoMergeTemplate?.getUrl!).then(async (res) => await res.arrayBuffer());
        const documentPdf = await PDFDocument.load(autoDocument);
        let helveticaFont = await documentPdf.embedFont(StandardFonts.Helvetica);

        let pages = documentPdf.getPages();
        await autoMergeTemplate?.mergeFields.forEach(async (mergeField: any) => {
          let mergePages = pages[mergeField.pageNumber];
          let mergeValue = getMergeValues(
            mergeField,
            project,
            unit,
            null,
            filteredDeal.purchasers,
            filteredDeal.deposit,
            filteredDeal.options,
            selectedRealtor ? selectedRealtor : filteredDeal.realtor[0],
            null,
            null,
            null,
            null,
            filteredDeal
          );

          if (
            mergeField.key === 'P1Check' ||
            (mergeField.key === 'P2Check' && filteredDeal.purchasers.length > 1) ||
            (mergeField.key === 'P3Check' && filteredDeal.purchasers.length > 2) ||
            (mergeField.key === 'P4Check' && filteredDeal.purchasers.length > 3)
          ) {
            mergePages.drawLine({
              start: { x: mergeField.x + 3, y: mergePages.getHeight() - mergeField.y - 3 },
              end: { x: mergeField.x + 8, y: mergePages.getHeight() - mergeField.y + 4 },
              thickness: 1,
              color: rgb(0, 0, 0),
              opacity: 0.75,
            });
            mergePages.drawLine({
              start: { x: mergeField.x, y: mergePages.getHeight() - mergeField.y + 1 },
              end: { x: mergeField.x + 3, y: mergePages.getHeight() - mergeField.y - 3 },
              thickness: 1,
              color: rgb(0, 0, 0),
              opacity: 0.75,
            });
          } else if (
            mergeField.key !== 'P1Check' &&
            mergeField.key !== 'P2Check' &&
            mergeField.key !== 'P3Check' &&
            mergeField.key !== 'P4Check'
          ) {
            mergePages.drawText(mergeValue, {
              x: mergeField.x,
              y: mergePages.getHeight() - mergeField.y - 3,
              size: mergeField.fontSize,
              font: helveticaFont,
              color: rgb(0, 0, 0),
            });
          }
        });

        await autoMergeTemplate?.signFields.forEach(async (mergeField: any) => {
          let mergePages = pages[mergeField.pageNumber];
          let mergeValue = getMergeValues(
            mergeField,
            project,
            unit,
            null,
            filteredDeal.purchasers,
            filteredDeal.deposit,
            filteredDeal.options,
            selectedRealtor ? selectedRealtor : filteredDeal.realtor[0],
            null,
            null,
            null,
            null,
            filteredDeal
          );

          mergePages.drawText(`${signField(mergeField.index)}${mergeValue}`, {
            x: mergeField.x,
            y: mergePages.getHeight() - mergeField.y - 3,
            size: 8,
            font: helveticaFont,
            color: rgb(1, 0, 0),
          });
        });

        let copiedPages = await mergedPdf.copyPages(documentPdf, documentPdf.getPageIndices());
        copiedPages.forEach((page: any) => mergedPdf.addPage(page));
      })
    );

    let pdf = await mergedPdf.save();

    const blob = await new Blob([pdf]);
    const fileUrl = window.URL.createObjectURL(blob);

    let alink = document.createElement('a');
    alink.href = fileUrl;
    alink.download = `${selectedMerge.map((merge: any) => merge.name).join('-')}.pdf`;
    alink.click();
  };

  const removeDocuments = async (documentId: string, type: string) => {
    setDialogOpen(false);
    if (type === 'UnitTransfer') {
      if (filteredDeal.pendingUnit._id !== unit._id) {
        updateUnitTransfer({
          variables: {
            _id: filteredDeal.pendingUnit._id,
            record: {
              status: 'A',
              history: [
                ...unit.history.map((history: IUnitHistory) => {
                  return {
                    ...history,
                    user: history.user ? history.user._id : null,
                  };
                }),
                {
                  type: 'Unit Transfer',
                  description: `Purchaser ${filteredDeal.purchasers[0].firstName} ${filteredDeal.purchasers[0].lastName} has cancelled the unit transfer`,
                  timestamp: new Date(),
                  user: user._id,
                },
              ],
            },
          },
        });
        updateUnitTransfer({
          variables: {
            _id: unit._id,
            record: {
              history: [
                ...unit.history.map((history: IUnitHistory) => {
                  return {
                    ...history,
                    user: history.user ? history.user._id : null,
                  };
                }),
                {
                  type: 'Unit Transfer',
                  description: `Purchaser ${filteredDeal.purchasers[0].firstName} ${filteredDeal.purchasers[0].lastName} has cancelled the unit transfer`,
                  timestamp: new Date(),
                  user: user._id,
                },
              ],
            },
          },
        });
      }
    }
    if (type === 'UnitTransfer' || type === 'Deposit' || type === 'Options') {
      let depositIds = filteredDeal.pendingDeposits.map((deposit: IDealDeposit) => {
        return deposit._id?.toString();
      });
      if (type === 'Options') {
        let filtered = await filteredDeal.additionalDeposits
          .filter((additional: IDealDeposit, index: number) => {
            let pendingDeposit = depositIds.find((id: string) => id === additional._id);
            if (!pendingDeposit) return additional;
          })
          .map((deposit: IDealDeposit) => deposit._id);
        await updatePendingDeposit({
          variables: {
            _id: filteredDeal._id,
            record: { additionalDeposits: filtered },
          },
        });
      }
      let currentDeposits = filteredDeal.deposit.map((deposit: IDealDeposit) => {
        return deposit._id?.toString();
      });
      let sameDeposit = depositIds.some((r: string) => currentDeposits.includes(r));
      if (!sameDeposit) {
        await removeDeposits({ variables: { filter: { depositId: depositIds } } });
      }
      await deleteDocument({ variables: { _id: documentId, deleteFile: true, deal: filteredDeal._id, deleteDeposits: true } });
    } else {
      await deleteDocument({ variables: { _id: documentId, deleteFile: true, deal: filteredDeal._id } });
    }
  };

  const sendToExecutor = () => {
    sendDocumentToExecutor({
      variables: {
        documentId: selectedDocument!._id,
        executor,
      },
    });
    setDialogOpen(false);
  };

  const handleSendDocument = async (document: IDocuments) => {
    let dealRealtorId = null;
    let selectedRealtor = filteredDeal.realtor.find((realtor: IDealRealtor) => document.name.includes(`${realtor.email}`));
    if (selectedRealtor && document.type === 'Coop') {
      dealRealtorId = selectedRealtor._id;
    }

    sendDocument({
      variables: {
        documentId: document._id,
        dealRealtorId: dealRealtorId,
      },
    });
    setDialogOpen(false);
  };

  const accordianContent = (document: IDocuments) => {
    if (document.status !== 'Prepared') {
      return (
        <Box
          sx={{
            mb: 2,
            width: '100%',
          }}
        >
          <Box
            sx={{
              mb: 3,
              '& .MuiAccordionSummary-content': {
                display: 'block',
              },
            }}
          >
            {messageSigners(document.dsEnvelopeId, document?._id!, document.signers)}
          </Box>
          {messageEnvelope(document.dsEnvelopeId, document?._id!, document.envelopeData)}
          <FlexBetween sx={{ mt: 2 }}>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              <Button sx={{ mr: 1, fontSize: '0.7rem' }} onClick={() => previewDocument(document)} color="primary" variant="contained">
                {previewLoading ? <CircularProgress size={30} color="secondary" /> : 'Preview Document'}
              </Button>
              {!document.signers && document.dsEnvelopeId ? (
                <Button
                  sx={{ mr: 1, fontSize: '0.7rem' }}
                  onClick={() => getSigners({ variables: { _id: document?._id } })}
                  color="primary"
                  variant="contained"
                >
                  {getSignersLoading ? <CircularProgress size={30} color="secondary" /> : 'Get Status of Signers'}
                </Button>
              ) : null}
              {!document.envelopeData && document.dsEnvelopeId ? (
                <Button sx={{ mr: 1, fontSize: '0.7rem' }} onClick={() => envelopeData(document)} color="primary" variant="contained">
                  {envelopeLoading ? <CircularProgress size={30} color="secondary" /> : 'Envelope Data'}
                </Button>
              ) : null}
              {document.status !== 'Completed' && document.dsEnvelopeId ? (
                <Button sx={{ fontSize: '0.7rem' }} onClick={() => resend(document)} color="primary" variant="contained">
                  {resendLoading ? <CircularProgress size={30} color="secondary" /> : 'Resend Envelope'}
                </Button>
              ) : null}
            </Box>
            {!filteredDeal.cancelled.dateCancelled && !filteredDeal.draft && !filteredDeal.rescission.datedRescinded ? (
              <FlexEnd>
                {(document.status === 'Signed' || document.status === 'Verified') &&
                accessAllowed(user, project._id, 'editDeals') &&
                !filteredDeal.cancelled.dateCancelled &&
                !filteredDeal.rescission.dateRescinded ? (
                  <Button
                    sx={{ mr: 1, fontSize: '0.7rem' }}
                    onClick={() => openDialog('execute', document)}
                    color="success"
                    variant="contained"
                  >
                    {sendingDocumentToExecutor ? (
                      <CircularProgress size={24} color="secondary" />
                    ) : (
                      `Send to ${document.status === 'Verified' ? 'Different' : ''} Executor`
                    )}
                  </Button>
                ) : null}
                {accessAllowed(user, project._id, 'editDeals') &&
                !filteredDeal.cancelled.dateCancelled &&
                !filteredDeal.rescission.dateRescinded ? (
                  <>
                    {!document.dsEnvelopeId ||
                    (document.status === 'Completed' && !document.dsEnvelopeId) ||
                    (!document.isAPS && document.status !== 'Completed') ? (
                      <Button
                        sx={{ fontSize: '0.7rem' }}
                        onClick={() => openDialog('deleteDocument', document)}
                        color="error"
                        variant="contained"
                      >
                        {'Void and Delete Document'}
                      </Button>
                    ) : null}
                  </>
                ) : null}
              </FlexEnd>
            ) : null}
          </FlexBetween>
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            mb: 2,
            width: '100%',
          }}
        >
          <div>
            <div>This document has not been sent out yet</div>
          </div>
          <FlexBetween sx={{ mt: 2 }}>
            <div>
              <Button sx={{ fontSize: '0.7rem' }} onClick={() => previewDocument(document)} color="primary" variant="contained">
                {previewLoading ? <CircularProgress size={30} color="secondary" /> : 'Preview Document'}
              </Button>
            </div>
            {accessAllowed(user, project._id, 'editDeals') &&
            !filteredDeal.cancelled.dateCancelled &&
            !filteredDeal.rescission.dateRescinded ? (
              <FlexEnd>
                <Button
                  component={Link}
                  to={`/${project._id}/dashboard/edit-document/${document._id}`}
                  color="primary"
                  variant="contained"
                  sx={{ mr: 1, fontSize: '0.7rem' }}
                >
                  Edit Document
                </Button>
                <FlexBetween>
                  <Button
                    sx={{ mr: 1, fontSize: '0.7rem' }}
                    onClick={() => openDialog('deleteDocument', document)}
                    color="error"
                    variant="contained"
                  >
                    Delete Document
                  </Button>
                </FlexBetween>
                <Button
                  sx={{ fontSize: '0.7rem' }}
                  onClick={() => openDialog('sendEnvelope', document)}
                  color="success"
                  variant="contained"
                >
                  {document.type === 'Coop' ? 'Send to Realtor' : 'Send to Purchaser(s)'}
                </Button>
              </FlexEnd>
            ) : null}
          </FlexBetween>
        </Box>
      );
    }
  };

  const dialogBox = () => {
    let removeButton = 'Cancel';
    let successButton = selectedMerge.length > 1 ? 'Send combined document' : 'Yes, I would like to create this amendment';
    let title = 'Please select an amendment you would like to create.';
    if (dialogType === 'execute') {
      successButton = 'Send to Executor';
      title = 'Please select an Executor to send Document';
    }
    if (dialogType === 'upload') {
      successButton = 'Upload Document';
      title = 'Please select a type for this document';
    }
    if (dialogType === 'sendEnvelope') {
      successButton = 'Send Envelope';
      title = 'Are you sure you would like to send this envelope?';
    }
    if (dialogType === 'deleteDocument') {
      successButton = 'Delete/Void Document';
      title = 'Are you sure you would like to delete/void this document?';
    }
    return (
      <CustomDialog
        handleClose={() => setDialogOpen(false)}
        handleCloseRemove={() => setDialogOpen(false)}
        handleCloseSuccess={handleCloseSuccess}
        handleCloseSecond={handleCloseSecond}
        open={dialogOpen}
        removeButton={removeButton}
        secondOption={dialogType === 'create' ? 'Preview' : ''}
        successButton={successButton}
        dialogContent={dialogContent()}
        dialogTitle={title}
      />
    );
  };

  const previewDocument = (doc: any) => {
    getPreviewData({
      variables: {
        _id: doc._id,
      },
    });
  };

  const envelopeData = (doc: any) => {
    getEnvelopeData({
      variables: {
        _id: doc._id,
      },
    });
  };

  const resend = (doc: any) => {
    resendEnvelope({
      variables: {
        _id: doc._id,
      },
    });
  };

  return (
    <Box
      sx={{
        '& .MuiAccordion-root': {
          border: '1px solid #000',
          padding: '4px',
        },
        '& .MuiAccordionDetails-root': {
          display: 'block',
        },
      }}
    >
      {dialogBox()}
      <FlexBetween>
        <h2>Documents</h2>
        {user.type !== 'Developer' ? (
          <Box sx={{ mb: 1, alignSelf: 'center' }}>
            <FormControlLabel
              onChange={handleAudit}
              control={<Checkbox checked={auditDate ? true : false} color="primary" />}
              label={'RDS Accounting Review'}
            />
          </Box>
        ) : null}
      </FlexBetween>
      {filteredDeal ? (
        createDocumentLoading || sendDocumentLoading || dealUpdateLoading || createMultipleDocumentLoading || deleteDocumentLoading ? (
          <LoadingWrapper title="Loading..." modal={false} />
        ) : (
          <>
            {documents.length > 0 ? (
              <div>
                {documents.map((document: IDocuments, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <Accordion
                        sx={{
                          ...setBackgroundColor(document.status),
                          mb: 1,
                        }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                          <div>
                            <h3>{document.name}</h3>
                            <div>
                              Status: <strong>{document.status}</strong>
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>{accordianContent(document)}</AccordionDetails>
                      </Accordion>
                    </React.Fragment>
                  );
                })}
              </div>
            ) : null}
            {accessAllowed(user, project._id, 'editDeals') &&
            !filteredDeal.cancelled.dateCancelled &&
            !filteredDeal.rescission.dateRescinded ? (
              <FlexBetween sx={{ mt: 2 }}>
                <FlexBetween>
                  <div>
                    <Button onClick={() => openDialog('create')} color="primary" variant="contained">
                      Create a Document
                    </Button>
                  </div>
                </FlexBetween>
                <FlexBetween>
                  <div>
                    <Button onClick={() => openDialog('upload')} color="primary" variant="contained">
                      Upload a Document
                    </Button>
                  </div>
                </FlexBetween>
              </FlexBetween>
            ) : null}
          </>
        )
      ) : (
        <div>
          <em>There are no documents.</em>
        </div>
      )}
    </Box>
  );
};

const CREATEDOCUMENT = gql`
  mutation documentCreateOne($record: CreateOneDocumentInput, $file: Upload!) {
    documentCreateOne(record: $record, file: $file) {
      _id
      name
      project {
        _id
      }
      type
      getUrl
      status
      isAPS
      dsEnvelopeId
    }
  }
`;

const CREATEMULTIPLEDOCUMENT = gql`
  mutation createEnvelopeMultipleDocuments($project: MongoID!, $deal: MongoID!, $documents: [NewDocumentInput]) {
    createEnvelopeMultipleDocuments(project: $project, deal: $deal, documents: $documents) {
      _id
      name
      project {
        _id
      }
      type
      getUrl
      status
      isAPS
      dsEnvelopeId
    }
  }
`;

const DELETEDOCUMENT = gql`
  mutation documentDeleteById($_id: MongoID!, $deleteFile: Boolean!, $deal: MongoID, $deleteDeposits: Boolean) {
    documentDeleteById(_id: $_id, deleteFile: $deleteFile, deal: $deal, deleteDeposits: $deleteDeposits) {
      name
      status
      _id
    }
  }
`;

const GETSIGNERS = gql`
  query documentById($_id: MongoID!) {
    documentById(_id: $_id) {
      _id
      signers {
        name
        type
        status
      }
    }
  }
`;

const GETPREVIEWDATA = gql`
  query documentById($_id: MongoID!) {
    documentById(_id: $_id) {
      _id
      previewData
    }
  }
`;

const SENDDOCUMENT = gql`
  mutation documentCreateEnvelope($documentId: MongoID!, $dealRealtorId: MongoID) {
    documentCreateEnvelope(documentId: $documentId, sendOut: true, dealRealtorId: $dealRealtorId) {
      name
      _id
      status
      dsEnvelopeId
    }
  }
`;

const SENDDOCUMENTTOEXECUTOR = gql`
  mutation documentSentToExecutor($documentId: MongoID!, $executor: executorData) {
    documentSendToExecutor(documentId: $documentId, executor: $executor) {
      _id
      status
      name
    }
  }
`;

const UPDATED_DOCUMENT = gql`
  subscription updatedDocument($projectId: String!) {
    updatedDocument(projectId: $projectId) {
      _id
      name
      status
    }
  }
`;

const GETENVELOPEDATA = gql`
  query documentById($_id: MongoID!) {
    documentById(_id: $_id) {
      _id
      envelopeData {
        createdDateTime
        lastModifiedDateTime
        initialSentDateTime
        sentDateTime
        statusChangedDateTime
        completedDateTime
        deliveredDateTime
      }
    }
  }
`;

const UPDATEUNIT = gql`
  mutation unitUpdateById($_id: MongoID!, $record: UpdateByIdUnitInput!) {
    unitUpdateById(_id: $_id, record: $record) {
      record {
        _id
      }
    }
  }
`;

const RESENDENVELOPE = gql`
  query resendEnvelope($_id: MongoID!) {
    resendEnvelope(_id: $_id)
  }
`;

const UPDATEDEALINFO = gql`
  mutation dealUpdateById($_id: MongoID!, $record: UpdateByIdDealInput!) {
    dealUpdateById(_id: $_id, record: $record) {
      record {
        auditor {
          _id
          fullName
        }
        auditDate
      }
    }
  }
`;

const MERGETEMPLATES = gql`
  query mergeTemplateMany($filter: FilterFindManyMergeTemplateInput) {
    mergeTemplateMany(filter: $filter, limit: 10000) {
      _id
      project {
        _id
      }
      name
      mergeFields {
        key
        index
        pageNumber
        x
        y
        fontSize
        format
        wrap
      }
      signFields {
        key
        index
        pageNumber
        x
        y
      }
      default
      type
      getUrl
      putUrl
    }
  }
`;

export default Envelopes;
