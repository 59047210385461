import { useState, useMemo, useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Box, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { convertAllDates, downloadExcel, downloadPdf, sortNestedSuites } from '../../utils/Functions';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import StandardTable from '../tables/StandardTable';
import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import { IDeal, IDealRealtor } from '../../types/CreateDealForm';
import { IPurchaserInfo } from '../../types/CreateDealForm';
import { selectUser } from '../../features/auth/authSlice';

const PurchasersSummary = () => {
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const [purchaserType, setPurchaserType] = useState<string>('deals');
  const [purchasers, setPurchasers] = useState<any[]>([]);
  const [deals, setDeals] = useState<IDeal[]>([]);

  const [allDealPurchasers, { loading: dealLoading }] = useLazyQuery(PURCHASERDEALS, {
    onCompleted: (data) => {
      let sorted = [...data.getDeals];
      sorted = sorted
        .map((deal: IDeal) => {
          let purchasers = deal.purchasers.map((purchaser: IPurchaserInfo) => {
            return {
              ...deal,
              purchasers: [purchaser],
            };
          });
          return purchasers;
        })
        .flat();
      sorted = sortNestedSuites(sorted, 'unit', 'suite');
      setDeals(sorted);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [allPurchasers, { loading }] = useLazyQuery(PURCHASERS, {
    variables: { project: project._id },
    onCompleted: (data) => {
      let purchasers = [...data.getPurchaserSummary];

      let output: any[] = [];

      purchasers.forEach(function (item: any) {
        var existing = output.filter(function (v, i) {
          return v._id.purchaser._id === item._id.purchaser._id;
        });
        if (existing.length) {
          var existingIndex = output.indexOf(existing[0]);
          output[existingIndex].unit = [
            ...output[existingIndex].unit,
            { unit: item._id.unit.suite, rescinded: item.rescinded, cancelled: item.cancelled },
          ];
        } else {
          let newItem = { ...item };
          newItem.unit = [{ unit: item._id.unit.suite, rescinded: item.rescinded, cancelled: item.cancelled }];
          output.push(newItem);
        }
      });

      setPurchasers(output);
    },
  });

  useEffect(() => {
    if (purchaserType === 'all') {
      allPurchasers({ variables: { project: project._id } });
    } else {
      allDealPurchasers({ variables: { project: project._id, type: 'purchaserDeals' } });
    }
  }, [project._id, purchaserType]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Full Name',
        accessor: (rowData: any) => {
          return rowData._id.purchaser.fullName;
        },
      },
      {
        Header: 'Units',
        accessor: (rowData: any) => {
          let units = rowData.unit.map((unit: any, index: number) => {
            return (
              <span key={index}>
                {unit.unit}
                {unit.rescinded ? '(R)' : unit.cancelled ? '(C)' : null},&nbsp;
              </span>
            );
          });
          return units;
        },
      },
      {
        Header: 'Email',
        accessor: (rowData: any) => rowData._id.purchaser.email,
      },
      {
        Header: 'Date of Birth',
        accessor: (rowData: any) => convertAllDates(rowData._id.purchaser.dob, 'P'),
      },
      {
        Header: 'Street Address',
        accessor: (rowData: any) =>
          `${rowData._id.purchaser.unit ? rowData._id.purchaser.unit : ''} ${rowData._id.purchaser.streetAddress}`,
      },
      {
        Header: 'City',
        accessor: (rowData: any) => rowData._id.purchaser.city,
      },
      {
        Header: 'Province',
        accessor: (rowData: any) => rowData._id.purchaser.province,
      },
      {
        Header: 'Postal Code',
        accessor: (rowData: any) => rowData._id.purchaser.postalCode,
      },
      {
        Header: 'Phone Number',
        accessor: (rowData: any) => rowData._id.purchaser.primaryPhone,
      },
      {
        Header: 'SIN',
        accessor: (rowData: any) => rowData._id.purchaser.sin,
      },
      {
        Header: 'Occupation',
        accessor: (rowData: any) => rowData._id.purchaser.occupation,
      },
      {
        Header: 'Purchaser Type',
        accessor: (rowData: any) => rowData._id.purchaser.purchaserType,
      },
      {
        Header: 'ID Type',
        accessor: (rowData: any) => rowData._id.purchaser.idType,
      },
      {
        Header: 'ID Number',
        accessor: (rowData: any) => rowData._id.purchaser.idNumber,
      },
      {
        Header: 'ID Expiry',
        accessor: (rowData: any) => convertAllDates(rowData._id.purchaser.idExpiry, 'P'),
      },
      {
        Header: 'ID Jurisdiction',
        accessor: (rowData: any) => rowData._id.purchaser.idJurisdiction,
      },
      {
        Header: 'Signing Officers',
        accessor: (rowData: any) => {
          let signingOfficers = rowData._id.purchaser.signingOfficers.map((signingOfficer: any, index: number) => {
            return <span key={index}>{signingOfficer.fullName}</span>;
          });
          return signingOfficers;
        },
      },
    ];
  }, []);

  const dealColumns = useMemo(() => {
    return [
      {
        Header: 'Suite',
        accessor: (rowData: any) => rowData.unit.suite,
      },
      {
        Header: 'Purchaser(s)',
        accessor: (rowData: any) => {
          return rowData.purchasers
            .map((purchaser: IPurchaserInfo, typeIndex: number) => {
              return purchaser.fullName;
            })
            .reduce(function (p: any, d: any, i: any) {
              return p + (i === rowData.purchasers.length - 1 ? ' & ' : ', ') + d;
            });
        },
      },
      {
        Header: 'Purchaser Email',
        accessor: (rowData: any) => {
          return rowData.purchasers
            .map((purchaser: IPurchaserInfo, typeIndex: number) => {
              return purchaser.email;
            })
            .join(', ');
        },
      },
      {
        Header: 'Purchaser Phone',
        accessor: (rowData: any) => {
          return rowData.purchasers
            .map((purchaser: IPurchaserInfo, typeIndex: number) => {
              return purchaser.primaryPhone;
            })
            .join(', ');
        },
      },
      {
        Header: 'Purchaser Address',
        accessor: (rowData: any) => {
          return rowData.purchasers
            .map((purchaser: IPurchaserInfo, typeIndex: number) => {
              return `${purchaser.streetAddress}, ${purchaser.city}, ${purchaser.province}, ${purchaser.postalCode}`;
            })
            .join(', ');
        },
      },
      {
        Header: 'Realtor',
        accessor: (rowData: any) => {
          if (rowData.tags.includes('Friends and Family')) {
            return 'Friends and Family';
          } else {
            if (rowData.realtor.length) {
              return rowData.realtor[0].fullName;
            } else return 'Public';
          }
        },
      },
      {
        Header: 'Realtor Email',
        accessor: (rowData: any) => {
          if (rowData.realtor.length) {
            return rowData.realtor[0].email;
          } else return '-';
        },
      },
    ];
  }, []);

  const purchaserDealDownload = (type: string, data: any) => {
    let dealColumns = [
      {
        label: 'Suite',
        id: 'suite',
      },
      {
        label: 'Purchaser',
        id: 'purchaserFullNames',
      },
      {
        label: 'Purchaser Email',
        id: 'purchaserEmail',
      },
      {
        label: 'Purchaser Phone',
        id: 'purchaserPhone',
      },
      {
        label: 'Purchaser Address',
        id: 'purchaserAddress',
      },
      {
        label: 'Purchaser Sin',
        id: 'purchaserSin',
      },
      {
        label: 'Realtor',
        id: 'realtorFullName',
      },
      {
        label: 'Realtor Email',
        id: 'realtorEmail',
      },
    ];

    let widths = {
      suite: 15,
      purchaserFullNames: 15,
      purchaserEmail: 15,
      purchaserPhone: 15,
      purchaserAddress: 15,
      purchaserSin: 15,
      realtorFullName: 15,
      realtorEmail: 15,
    };

    let pdfWidths = {
      suite: 200,
      purchaserFullNames: 200,
      purchaserEmail: 200,
      purchaserPhone: 200,
      purchaserAddress: 200,
      purchaserSin: 200,
      realtorFullName: 200,
      realtorEmail: 200,
    };

    let sheetTitle = `${project.name} - Purchaser Deals Summary`;

    let allData = data.map((data: any) => {
      return {
        suite: data.unit.suite,
        purchaserFullNames: data.purchasers
          .map((purchaser: IPurchaserInfo, typeIndex: number) => {
            return purchaser.fullName;
          })
          .reduce(function (p: any, d: any, i: any) {
            return p + (i === data.purchasers.length - 1 ? ' & ' : ', ') + d;
          }),
        purchaserEmail: data.purchasers
          .map((purchaser: IPurchaserInfo, typeIndex: number) => {
            return purchaser.email;
          })
          .join(', '),
        purchaserPhone: data.purchasers
          .map((purchaser: IPurchaserInfo, typeIndex: number) => {
            return purchaser.primaryPhone;
          })
          .join(', '),
        purchaserAddress: data.purchasers
          .map((purchaser: IPurchaserInfo, typeIndex: number) => {
            return `${purchaser.streetAddress}, ${purchaser.city}, ${purchaser.province}, ${purchaser.postalCode}`;
          })
          .join(', '),
        purchaserSin: user.type === 'Manager' || user.type === 'Developer' ? data.purchasers
          .map((purchaser: IPurchaserInfo, typeIndex: number) => {
            return purchaser.sin;
          })
          .join(', ') : data.purchasers[0].sin ? 'received' : 'N/A',
        realtorFullName: data.tags.includes('Friends and Family')
          ? 'Friends and Family'
          : data.realtor.length
          ? data.realtor
              .map((realtor: IDealRealtor, typeIndex: number) => {
                return realtor.fullName;
              })
              .join(', ')
          : 'Public',
        realtorEmail: data.realtor.length
          ? data.realtor
              .map((realtor: IDealRealtor, typeIndex: number) => {
                return realtor.email;
              })
              .join(', ')
          : '-',
      };
    });

    if (type === 'excel') {
      downloadExcel([allData], [dealColumns], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([allData], [dealColumns], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  const download = (type: string, data: any) => {
    let unitColumns = [
      {
        label: 'Full Name',
        id: 'fullName',
      },
      {
        label: 'Units',
        id: 'units',
      },
      {
        label: 'Email',
        id: 'email',
      },
      {
        label: 'Date of Birth',
        id: 'dateOfBirth',
      },
      {
        label: 'Street Address',
        id: 'streetAddress',
      },
      {
        label: 'City',
        id: 'city',
      },
      {
        label: 'Province',
        id: 'province',
      },
      {
        label: 'Postal Code',
        id: 'postalCode',
      },
      {
        label: 'Phone Number',
        id: 'phoneNumber',
      },
      {
        label: 'SIN',
        id: 'sin',
      },
      {
        label: 'Occupation',
        id: 'occupation',
      },
      {
        label: 'Purchaser Type',
        id: 'purchaserType',
      },
      {
        label: 'ID Type',
        id: 'idType',
      },
      {
        label: 'ID Number',
        id: 'idNumber',
      },
      {
        label: 'ID Expiry',
        id: 'idExpiry',
      },
      {
        label: 'ID Jurisdiction',
        id: 'idJurisdiction',
      },
      {
        label: 'Signing Officers',
        id: 'signingOfficers',
      },
    ];

    let widths = {
      fullName: 15,
      units: 15,
      email: 15,
      dateOfBirth: 15,
      streetAddress: 15,
      city: 15,
      province: 15,
      postalCode: 15,
      phoneNumber: 15,
      sin: 15,
      occupation: 15,
      purchaserType: 15,
      idType: 15,
      idNumber: 15,
      idExpiry: 15,
      idJurisdiction: 15,
      signingOfficers: 15,
    };

    let pdfWidths = {
      fullName: 200,
      units: 200,
      email: 200,
      dateOfBirth: 200,
      streetAddress: 200,
      city: 200,
      province: 200,
      postalCode: 200,
      phoneNumber: 200,
      sin: 200,
      occupation: 200,
      purchaserType: 200,
      idType: 200,
      idNumber: 200,
      idExpiry: 200,
      idJurisdiction: 200,
      signingOfficers: 200,
    };

    let sheetTitle = `${project.name} - Purchaser Summary`;

    let allData = data.map((data: any) => {
      let units;
      if (data.unit) {
        units = data.unit
          .map((unit: any) => {
            return `${unit.unit}${unit.rescinded ? '(R)' : unit.cancelled ? '(C)' : ''}`;
          })
          .reduce(function (p: any, d: any, i: any) {
            return p + (i === data.unit.length - 1 ? ', ' : ', ') + d;
          });
      } else {
        units = 'N/A';
      }

      let signingOfficers = data._id.purchaser.signingOfficers.map((signingOfficer: any, index: number) => {
        return signingOfficer.fullName;
      });

      return {
        fullName: data._id.purchaser.fullName,
        units: data.unit ? units : 'N/A',
        email: data._id.purchaser.email,
        dateOfBirth: convertAllDates(data._id.purchaser.dob, 'PP'),
        streetAddress: `${data._id.purchaser.unit ? data._id.purchaser.unit : ''} ${data._id.purchaser.streetAddress}`,
        city: data._id.purchaser.city,
        province: data._id.purchaser.province,
        postalCode: data._id.purchaser.postalCode,
        phoneNumber: data._id.purchaser.primaryPhone,
        sin: user.type === 'Manager' || user.type === 'Developer' ? data._id.purchaser.sin : 'N/A',
        occupation: data._id.purchaser.occupation,
        purchaserType: data._id.purchaser.purchaserType,
        idType: data._id.purchaser.idType,
        idNumber: data._id.purchaser.idNumber,
        idExpiry: convertAllDates(data._id.purchaser.idExpiry, 'PP'),
        idJurisdiction: data._id.purchaser.idJurisdiction,
        signingOfficers: signingOfficers,
      };
    });

    if (type === 'excel') {
      downloadExcel([allData], [unitColumns], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([allData], [unitColumns], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  const handlePurchaserType = (event: any, purchaserType: string) => {
    setPurchaserType(purchaserType);
  };

  return (
    <Box sx={{ m: 2 }}>
      <Typography variant={'h5'} gutterBottom>
        Purchasers
      </Typography>
      <ToggleButtonGroup
        value={purchaserType}
        exclusive
        onChange={handlePurchaserType}
        aria-label="text alignment"
        sx={{
          marginBottom: '10px',
          '& .MuiToggleButton-root.Mui-selected': {
            backgroundColor: '#00142a',
            color: '#fff',
          },
          '& .MuiToggleButton-root.Mui-selected:hover': {
            backgroundColor: '#00142a',
            color: '#fff',
          },
        }}
      >
        <ToggleButton value="deals" aria-label="centered">
          Purchaser Deals
        </ToggleButton>
        <ToggleButton value="all" aria-label="right aligned">
          All Purchasers
        </ToggleButton>
      </ToggleButtonGroup>
      {purchaserType === 'deals' ? (
        <StandardTable columns={dealColumns} data={deals} loading={dealLoading} download={purchaserDealDownload} />
      ) : null}
      {purchaserType === 'all' ? <StandardTable columns={columns} data={purchasers} loading={loading} download={download} /> : null}
    </Box>
  );
};

const PURCHASERS = gql`
  query getPurchaserSummary($project: MongoID!) {
    getPurchaserSummary(project: $project) {
      _id {
        purchaser {
          _id
          email
          fullName
          corp
          sin
          dob
          identifications {
            name
            getUrl
          }
          streetAddress
          city
          province
          country
          postalCode
          occupation
          employer
          directors
          businessNumber
          signingOfficers {
            fullName
            dob
            sin
            primaryPhone
            streetAddress
            email
          }
          purchaserType
          primaryPhone
          idType
          idNumber
          idExpiry
          unit
          idJurisdiction
        }
        unit {
          _id
          suite
        }
      }
      rescinded
      cancelled
    }
  }
`;

const PURCHASERDEALS = gql`
  query getDeals($project: MongoID!, $type: String!) {
    getDeals(project: $project, type: $type) {
      _id
      unit {
        _id
        suite
      }
      purchasers {
        fullName
        email
        streetAddress
        city
        province
        postalCode
        primaryPhone
        sin
      }
      realtor {
        fullName
        email
      }
      tags
    }
  }
`;

export default PurchasersSummary;
