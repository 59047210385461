import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography, Button, Box } from '@mui/material';
import DebitForm from './DebitForm';
import { UnitContext } from '../../../context/UnitContext';
import { Flex, FlexEnd } from '../../../commonStyles';
import { accessAllowed } from '../../../features/project/projectHooks';
import { selectProject } from '../../../features/project/projectSlice';
import { selectUser } from '../../../features/auth/authSlice';
import { useAppDispatch } from '../../../app/hooks';
import { showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';
import TextInput from '../../common/formControls/TextInput';
import { DeleteForever } from '@mui/icons-material';

const Debit = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const { errors, updateDebit } = props;
  const { filteredDeal, setFilteredDeal } = useContext(UnitContext);

  const addDebit = () => {
    setFilteredDeal({
      ...filteredDeal,
      debit: [
        ...filteredDeal.debit,
        {
          name: '',
          institution: '',
          branch: '',
          account: '',
          accountHolders: [
            {
              fullName: '',
              email: '',
            },
          ],
        },
      ],
    });
  };

  const addAccountHolder = (index: number) => {
    let selectDebit = filteredDeal.debit.map((debit: IDebit, numIndex: number) => {
      if (numIndex === index) {
        return {
          ...debit,
          accountHolders: [
            ...debit.accountHolders,
            {
              fullName: '',
              email: '',
            },
          ],
        };
      } else return debit;
    });

    setFilteredDeal({
      ...filteredDeal,
      debit: selectDebit,
    });
  };

  const handleTextInput = async (event: any, numIndex: number) => {
    if (event && event.target.name) {
      setFilteredDeal({
        ...filteredDeal,
        debit: filteredDeal.debit.map((debit: IDebit, index: number) => {
          if (index === numIndex) {
            return {
              ...debit,
              [event.target.name]: event.target.value,
            };
          } else return debit;
        }),
      });
    }
  };

  const handleAccountHolderInput = async (event: any, debitIndex: number, accountIndex: number) => {
    if (event && event.target.name) {
      setFilteredDeal({
        ...filteredDeal,
        debit: filteredDeal.debit.map((debit: IDebit, index: number) => {
          if (index === debitIndex) {
            return {
              ...debit,
              accountHolders: debit.accountHolders.map((accountHolder: IAccountHolder, numIndex: number) => {
                if (numIndex === accountIndex) {
                  return {
                    ...accountHolder,
                    [event.target.name]: event.target.value,
                  };
                } else return accountHolder;
              }),
            };
          } else return debit;
        }),
      });
    }
  };

  const handleDebitSubmit = (e: any) => {
    e.preventDefault();
    if (errors.date) {
      storeDispatch(showErrorSnackbar('Invalid Date'));
      return;
    }

    updateDebit({ variables: { _id: filteredDeal._id, record: { debit: filteredDeal.debit } } });
  };

  const deleteAccountHolder = (debitIndex: number, accountIndex: number) => {
    setFilteredDeal({
      ...filteredDeal,
      debit: filteredDeal.debit.map((debit: IDebit, index: number) => {
        if (index === debitIndex) {
          return {
            ...debit,
            accountHolders: debit.accountHolders.filter((accountHolder: IAccountHolder, numIndex: number) => accountIndex !== numIndex),
          };
        } else return debit;
      }),
    });
  };

  return (
    <div>
      <Typography variant="h2" sx={{ mb: 0 }}>
        <strong>Pre-authorized Debit Information</strong>
      </Typography>
      <form onSubmit={handleDebitSubmit} autoComplete="off">
        {filteredDeal.debit.length ? (
          filteredDeal.debit.map((debit: IDebit, index: number) => {
            return (
              <React.Fragment key={index}>
                <Grid container spacing={2} sx={{ my: 2 }}>
                  <DebitForm error={errors} debit={debit} key={index} handleTextInput={handleTextInput} index={index} />
                </Grid>
                {debit.accountHolders.map((accountHolder: IAccountHolder, numIndex: number) => {
                  return (
                    <Grid sx={{ mb: 2 }} container spacing={2} key={numIndex}>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextInput
                          title={'Account Holder Full Name'}
                          name={'fullName'}
                          required
                          handleTextInput={(e: any) => handleAccountHolderInput(e, index, numIndex)}
                          value={accountHolder.fullName}
                          helperText={errors.fullName}
                          error={errors.fullName ? true : false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextInput
                          title={'Account Holder Email'}
                          name={'email'}
                          type="email"
                          required
                          handleTextInput={(e: any) => handleAccountHolderInput(e, index, numIndex)}
                          value={accountHolder.email}
                          helperText={errors.email}
                          error={errors.email ? true : false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} sx={{ alignSelf: 'center' }}>
                        <DeleteForever
                          onClick={() => deleteAccountHolder(index, numIndex)}
                          sx={{ alignSelf: 'center', cursor: 'pointer' }}
                          color="error"
                        />
                      </Grid>
                    </Grid>
                  );
                })}
                <FlexEnd>
                  <Button onClick={() => addAccountHolder(index)} name={'update'} color="primary" variant="contained">
                    Add Account Holder
                  </Button>
                </FlexEnd>
              </React.Fragment>
            );
          })
        ) : (
          <Flex>
            <Button sx={{ mt: 2  }} onClick={() => addDebit()} name={'update'} color="primary" variant="contained">
              Add Pre-authorized Debit
            </Button>
          </Flex>
        )}
        <Flex>
          {accessAllowed(user, project._id, 'editDeals') && filteredDeal.debit.length > 0 ? (
            <Button sx={{ mr: 1}} type={'submit'} name={'update'} color="success" variant="contained">
              Save Pre-authorized Debit
            </Button>
          ) : null}
        </Flex>
      </form>
    </div>
  );
};

interface ChildProps {
  errors: any;
  updateDebit: any;
}

export interface IDebit {
  name: string;
  institution: string;
  branch: string;
  account: string;
  accountHolders: IAccountHolder[];
}

export interface IAccountHolder {
  fullName: string;
  email: string;
}

export default Debit;
