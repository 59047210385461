import { Dispatch, SetStateAction } from 'react'
import { Box, Button, Typography, Checkbox, FormControlLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import GooglePlaces from '../../common/formControls/GooglePlaces';
import { FlexBetween } from '../../../commonStyles';

const Location = (props: ChildProps) => {
  const { locations, setLocations, hideLocation, setHideLocation } = props;

  const handleDetails = async (
    city: string,
    province: string,
    postalCode: string,
    country: string,
    streetAddress: string,
    type: number
  ) => {
    let updatelocations = locations.map((address: string, index: number) => {
      if (type === index) {
        return `${streetAddress}, ${city}, ${province}, ${postalCode}`;
      } else return address;
    });
    setLocations(updatelocations);
  };

  const handleStreetAddress = async (value: string, type: number) => {
    let updatelocations = locations.map((address: string, index: number) => {
      if (type === index) {
        return value;
      } else return address;
    });
    setLocations(updatelocations);
  };

  return (
    <Box>
      <FlexBetween sx={{ mb: 2 }}>
        <Typography variant={'h5'} gutterBottom>
          <strong>Locations</strong>
        </Typography>
        <Button onClick={() => setLocations([...locations, ''])} variant="contained" color="primary">
          Add Location
        </Button>
      </FlexBetween>
      {locations.length === 1 ?
        <FormControlLabel
          style={{
            marginTop: '10px',
            marginBottom: '15px',
            display: 'flex',
            alignItems: 'center',
          }}
          key={`hideLocation`}
          control={
            <Checkbox
              checked={hideLocation}
              onChange={(e: any) => setHideLocation(!hideLocation)}
            />
          }
          label={'Hide Location Until Reservation'}
        />
      : null}
      {locations.map((address: string, index: number) => {
        return (
          <FlexBetween sx={{ mt: 2 }}>
            <Box sx={{ flexGrow: 1 }}>
              <GooglePlaces
                disabled={false}
                name={'streetAddress'}
                handleDetails={handleDetails}
                handleStreetAddress={handleStreetAddress}
                address={address}
                type={index}
                id={'appointment'}
              />
            </Box>
            <CloseIcon
              sx={{ cursor: 'pointer', alignSelf: 'center', ml: 2 }}
              onClick={() => setLocations(locations.filter((address: string, numIndex: number) => index !== numIndex))}
            />
          </FlexBetween>
        );
        })}
    </Box>
  )
}

interface ChildProps {
  locations: string[];
  setLocations: Dispatch<SetStateAction<string[]>>;
  hideLocation: boolean;
  setHideLocation: Dispatch<SetStateAction<boolean>>;
}

export default Location