import { useState, useMemo, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Box, Typography, AppBar, Tab, Tabs } from '@mui/material';
import { useSelector } from 'react-redux';

import { numToCurrency, capitalizeFirstLetter, downloadExcel, downloadPdf } from '../../utils/Functions';
import { IUnit, IUnitData } from '../../types/unit';
import StandardTable from '../tables/StandardTable';
import { selectProject } from '../../features/project/projectSlice';
import { customTierUnits, customTiers } from '../../utils/CustomSettings';

const TypeSummary = (props: ChildProps) => {
  const { allUnits } = props;
  const project = useSelector(selectProject);
  const [units, setUnits] = useState<IUnit[]>([]);
  const [tierType, setTierType] = useState<string>('Overall');
  const unitTypes = units
    .map((unit) => unit.unitType)
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort();

  const { loading } = useQuery<IUnitData>(UNITS, {
    skip: !project._id,
    variables: { filter: { project: project._id } },
    onCompleted: (data) => {
      setUnits(data.unitMany);
    },
  });

  useEffect(() => {
    if (allUnits) {
      setUnits(allUnits);
    }
  }, [allUnits]);

  const handleTierChange = (e: any, value: string) => {
    setTierType(value);
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'Unit Type',
        accessor: (rowData: any) => rowData.unitType,
      },
      {
        Header: 'Outdoor Type',
        accessor: (rowData: any) => rowData.outdoorType,
      },
      {
        Header: 'Average PPSF',
        accessor: (rowData: any) => rowData.averagePpsf,
      },
      {
        Header: 'Average Price',
        accessor: (rowData: any) => rowData.averagePrice,
      },
      {
        Header: 'Average Size',
        accessor: (rowData: any) => rowData.averageSize,
      },
      {
        Header: 'Lowest Price',
        accessor: (rowData: any) => rowData.lowestPrice,
      },
      {
        Header: 'Highest Price',
        accessor: (rowData: any) => rowData.highestPrice,
      },
      {
        Header: 'Median',
        accessor: (rowData: any) => rowData.median,
      },
      {
        Header: 'Units Total',
        accessor: (rowData: any) => `${rowData.unitsTotal} (${Math.round((rowData.unitsTotal / units.length) * 100)}%)`,
      },
      {
        Header: 'Cumulative',
        accessor: (rowData: any) => rowData.cumulative,
      },
      {
        Header: 'Units Available',
        accessor: (rowData: any) => rowData.unitsAvailable,
      },
      {
        Header: 'Units Pending',
        accessor: (rowData: any) => rowData.unitsPending,
      },
      {
        Header: 'Units Sold (C, F)',
        accessor: (rowData: any) =>
          `${rowData.unitsSold} (${rowData.unitsTotal ? Math.round((rowData.unitsSold / rowData.unitsTotal) * 10000) / 100 : '0'}%)`,
      },
      {
        Header: 'Sold Revenue',
        accessor: (rowData: any) => `${rowData.soldRevenue}`,
      },
      {
        Header: 'Total Revenue',
        accessor: (rowData: any) => `${rowData.totalRevenue} (${rowData.totalRevenuePercentage})`,
      },
    ];
  }, [units]);

  const modelColumns = useMemo(() => {
    return [
      {
        Header: 'Unit Type',
        accessor: (rowData: any) => rowData.unitType,
      },
      {
        Header: 'Model Type',
        accessor: (rowData: any) => rowData.modelType,
      },
      {
        Header: 'Outdoor Type',
        accessor: (rowData: any) => rowData.outdoorType,
      },
      {
        Header: 'Average PPSF',
        accessor: (rowData: any) => rowData.averagePpsf,
      },
      {
        Header: 'Average Price',
        accessor: (rowData: any) => rowData.averagePrice,
      },
      {
        Header: 'Average Size',
        accessor: (rowData: any) => rowData.averageSize,
      },
      {
        Header: 'Lowest Price',
        accessor: (rowData: any) => rowData.lowestPrice,
      },
      {
        Header: 'Highest Price',
        accessor: (rowData: any) => rowData.highestPrice,
      },
      {
        Header: 'Median',
        accessor: (rowData: any) => rowData.median,
      },
      {
        Header: 'Units Total',
        accessor: (rowData: any) => `${rowData.unitsTotal} (${Math.round((rowData.unitsTotal / units.length) * 100)}%)`,
      },
      {
        Header: 'Units Available',
        accessor: (rowData: any) => rowData.unitsAvailable,
      },
      {
        Header: 'Units Pending',
        accessor: (rowData: any) => rowData.unitsPending,
      },
      {
        Header: 'Units Sold (C, F)',
        accessor: (rowData: any) => rowData.unitsSold,
      },
      {
        Header: 'Sold Revenue',
        accessor: (rowData: any) => rowData.totalRevenue,
      },
      {
        Header: 'Total Revenue',
        accessor: (rowData: any) => rowData.totalRevenue,
      },
    ];
  }, [units]);

  const rangeColumns = useMemo(() => {
    return [
      {
        Header: 'Range',
        accessor: (rowData: any) => {
          return `${numToCurrency.format(rowData.minRange)} to ${numToCurrency.format(rowData.maxRange)}`;
        },
      },
      {
        Header: 'Total Units',
        accessor: (rowData: any) => `${rowData.units} (${((rowData.units / rowData.total) * 100).toFixed(2)}%)`,
      },
      {
        Header: 'Accumulative Unit Count',
        accessor: (rowData: any) => `${rowData.accumulativeUnit} (${((rowData.accumulativeUnit / rowData.total) * 100).toFixed(2)}%)`,
      },
      {
        Header: 'Sold',
        accessor: (rowData: any) => `${rowData.sold} (${((rowData.sold / rowData.total) * 100).toFixed(2)}%)`,
      },
      {
        Header: 'Accumulative Sold',
        accessor: (rowData: any) => `${rowData.accumulativeSold} (${((rowData.accumulativeSold / rowData.total) * 100).toFixed(2)}%)`,
      },
      {
        Header: 'Available',
        accessor: (rowData: any) => `${rowData.available} (${((rowData.available / rowData.total) * 100).toFixed(2)}%)`,
      },
      {
        Header: 'Accumulative Available',
        accessor: (rowData: any) =>
          `${rowData.accumulativeAvailable} (${((rowData.accumulativeAvailable / rowData.total) * 100).toFixed(2)}%)`,
      },
      {
        Header: 'Revenue',
        accessor: (rowData: any) => rowData.revenue,
      },
      {
        Header: 'Accumulative Revenue',
        accessor: (rowData: any) => rowData.accumulativeRevenue,
      },
    ];
  }, []);

  const download = (type: string, data: any) => {
    let unitColumns = [
      {
        label: 'Unit Types',
        id: 'unitType',
      },
      {
        label: 'Outdoor Type',
        id: 'outdoorType',
      },
      {
        label: 'Average Price',
        id: 'averagePrice',
      },
      {
        label: 'Average Size',
        id: 'averageSize',
      },
      {
        label: 'Average PPSF',
        id: 'averagePpsf',
      },
      {
        label: 'Lowest Price',
        id: 'lowestPrice',
      },
      {
        label: 'Highest Price',
        id: 'highestPrice',
      },
      {
        label: 'Median',
        id: 'median',
      },
      {
        label: 'Units Total',
        id: 'unitsTotal',
      },
      {
        label: 'Cumulative',
        id: 'cumulative',
      },
      {
        label: 'Units Available',
        id: 'unitsAvailable',
      },
      {
        label: 'Units Pending',
        id: 'unitsPending',
      },
      {
        label: 'Units Sold (C, F)',
        id: 'unitsSold',
      },
      {
        label: 'Sold Revenue',
        id: 'soldRevenue',
      },
      {
        label: 'Total Revenue',
        id: 'totalRevenue',
      },
    ];

    let widths = {
      unitType: 15,
      outdoorType: 15,
      averagePrice: 15,
      averageSize: 15,
      averagePpsf: 15,
      lowestPrice: 15,
      highestPrice: 15,
      median: 15,
      unitsTotal: 15,
      cumulative: 15,
      unitsAvailable: 15,
      unitsPending: 15,
      unitsSold: 15,
      soldRevenue: 15,
      totalRevenue: 15,
    };

    let pdfWidths = {
      unitType: 200,
      outdoorType: 200,
      averagePrice: 200,
      averageSize: 200,
      averagePpsf: 200,
      lowestPrice: 200,
      highestPrice: 200,
      median: 200,
      unitsTotal: 200,
      cumulative: 200,
      unitsAvailable: 200,
      unitsPending: 200,
      unitsSold: 200,
      soldRevenue: 15,
      totalRevenue: 15,
    };

    let sheetTitle = `${project.name} - Unit Summary`;

    if (type === 'excel') {
      downloadExcel([data], [unitColumns], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([data], [unitColumns], [], [pdfWidths], [sheetTitle], sheetTitle, project.logoGetUrl);
    }
  };

  const downloadRange = (type: string, data: any) => {
    let headers = [
      {
        label: 'Range',
        id: 'range',
      },
      {
        label: 'Unit Count',
        id: 'units',
      },
      {
        label: 'Accumulative Unit Count',
        id: 'accumulativeUnit',
      },
      {
        label: 'Sold',
        id: 'sold',
      },
      {
        label: 'Available',
        id: 'available',
      },
      {
        label: 'Revenue',
        id: 'revenue',
      },
      {
        label: 'Accumulative Revenue',
        id: 'accumulativeRevenue',
      },
    ];

    let widths = {
      range: 15,
      units: 15,
      accumulativeUnit: 15,
      sold: 15,
      available: 15,
      revenue: 15,
      accumulativeRevenue: 15,
    };

    let pdfWidths = {
      range: 200,
      units: 200,
      accumulativeUnit: 200,
      sold: 200,
      available: 200,
      revenue: 200,
      accumulativeRevenue: 200,
    };

    let sheetTitle = `${project.name} - Daily Summary`;

    let units = data.map((data: any) => {
      return {
        ...data,
        range: `${numToCurrency.format(data.minRange)} to ${numToCurrency.format(data.maxRange)}`,
      };
    });

    if (type === 'excel') {
      downloadExcel([units], [headers], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([units], [headers], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  const data = () => {
    let allUnits = units;

    if (tierType) {
      allUnits = units.filter((unit: IUnit) => customTierUnits(project._id, units, tierType).includes(unit.tier));
    }

    let unitData = unitTypes.map((type, index: number) => {
      const totalRevenueAllUnits: number = allUnits.map((unit) => unit.basePrice).reduce((a, b) => a + b, 0);
      const validUnits = allUnits.filter((unit) => unit.unitType === type);
      const validUnitPrices = allUnits.filter((unit) => unit.unitType === type).map((unit) => unit.basePrice);
      const totalPrice = validUnits.map((unit) => unit.basePrice).reduce((a, b) => a + b, 0);
      const totalSize = validUnits.map((unit) => unit.size).reduce((a, b) => a + b, 0);
      const mid = Math.floor(validUnitPrices.length / 2);
      const nums = [...validUnitPrices].sort((a, b) => a - b);
      const typeRevenue = allUnits
        .filter((unit) => unit.unitType === type)
        .map((unit) => unit.basePrice)
        .reduce((a, b) => a + b, 0);
      const soldRevenue = allUnits
        .filter((unit) => unit.unitType === type && (unit.status === 'F' || unit.status === 'C'))
        .map((unit) => unit.basePrice)
        .reduce((a, b) => a + b, 0);
      const totalUnits = allUnits.filter((unit) => unit.unitType === type).length;
      const availableUnits = allUnits.filter(
        (unit) =>
          unit.unitType === type &&
          unit.status !== 'F' &&
          unit.status !== 'C' &&
          unit.status !== 'S' &&
          unit.status !== 'O' &&
          unit.status !== 'IP' &&
          unit.status !== 'P'
      ).length;
      const pendingUnits = allUnits.filter(
        (unit) => unit.unitType === type && (unit.status === 'P' || unit.status === 'O' || unit.status === 'S')
      ).length;
      const soldUnits = allUnits.filter((unit) => unit.unitType === type && (unit.status === 'F' || unit.status === 'C')).length;

      let cumulative = 0;
      for (let i = 0; i <= index; i++) {
        cumulative += allUnits.filter((unit) => unit.unitType === unitTypes[i]).length;
      }

      return {
        unitType: type,
        outdoorType: 'N/A',
        averagePrice: validUnits.length ? numToCurrency.format(totalPrice / validUnits.length) : 'N/A',
        averageSize: validUnits.length ? (totalSize / validUnits.length).toFixed(2) : 'N/A',
        averagePpsf: totalSize ? numToCurrency.format(totalPrice / totalSize) : 'N/A',
        lowestPrice: validUnitPrices.length > 0 ? numToCurrency.format(Math.min(...validUnitPrices)) : 'N/A',
        highestPrice: validUnitPrices.length > 0 ? numToCurrency.format(Math.max(...validUnitPrices)) : 'N/A',
        median:
          validUnitPrices.length === 0
            ? 'N/A'
            : validUnitPrices.length % 2 !== 0
            ? numToCurrency.format(nums[mid])
            : numToCurrency.format((nums[mid - 1] + nums[mid]) / 2),
        soldRevenue: numToCurrency.format(soldRevenue),
        soldRevenuePercentage: isNaN(Math.round((soldRevenue / typeRevenue) * 10000) / 100)
          ? '$0'
          : Math.round((soldRevenue / totalRevenueAllUnits) * 10000) / 100 + '%',
        totalRevenue: numToCurrency.format(typeRevenue),
        totalRevenuePercentage: isNaN(Math.round((typeRevenue / totalRevenueAllUnits) * 10000) / 100)
          ? '$0'
          : Math.round((typeRevenue / totalRevenueAllUnits) * 10000) / 100 + '%',
        unitsTotal: totalUnits,
        cumulative: `${cumulative} (${Math.round((cumulative / units.length) * 100)}%)`,
        unitsAvailable: availableUnits,
        unitsPending: pendingUnits,
        unitsSold: soldUnits,
      };
    });

    const allUnitPrices = allUnits.map((unit) => unit.basePrice);
    const totalRevenue = allUnits.map((unit) => unit.basePrice).reduce((a, b) => a + b, 0);
    const totalSize = allUnits.map((unit) => unit.size).reduce((a, b) => a + b, 0);
    const totalSoldRevenue = allUnits
      .filter((unit) => unit.status === 'F' || unit.status === 'C')
      .map((unit) => unit.basePrice)
      .reduce((a, b) => a + b, 0);
    const totalAvailableUnits = allUnits.filter(
      (unit) =>
        unit.status !== 'F' &&
        unit.status !== 'C' &&
        unit.status !== 'S' &&
        unit.status !== 'O' &&
        unit.status !== 'IP' &&
        unit.status !== 'P'
    ).length;
    const totalPendingUnits = allUnits.filter((unit) => unit.status === 'P' || unit.status === 'O' || unit.status === 'S').length;
    const totalSoldUnits = allUnits.filter((unit) => unit.status === 'F' || unit.status === 'C').length;
    const midAll = Math.floor(allUnitPrices.length / 2);
    const numsAll = [...allUnitPrices].sort((a, b) => a - b);

    let totalUnitData = {
      unitType: 'Total',
      outdoorType: 'N/A',
      averagePrice: numToCurrency.format(totalRevenue / units.length),
      averageSize: (totalSize / units.length).toFixed(2),
      averagePpsf: numToCurrency.format(totalRevenue / totalSize),
      lowestPrice: allUnitPrices.length > 0 ? numToCurrency.format(Math.min(...allUnitPrices)) : 'N/A',
      highestPrice: allUnitPrices.length > 0 ? numToCurrency.format(Math.max(...allUnitPrices)) : 'N/A',
      median:
        allUnitPrices.length === 0
          ? 'N/A'
          : allUnitPrices.length % 2 !== 0
          ? numToCurrency.format(numsAll[midAll])
          : numToCurrency.format((numsAll[midAll - 1] + numsAll[midAll]) / 2),
      soldRevenue: numToCurrency.format(totalSoldRevenue),
      soldRevenuePercentage: isNaN(Math.round((totalSoldRevenue / totalRevenue) * 10000) / 100)
        ? '$0'
        : Math.round((totalSoldRevenue / totalRevenue) * 10000) / 100 + '%',
      totalRevenue: numToCurrency.format(totalRevenue),
      totalRevenuePercentage: '100%',
      unitsTotal: allUnits.length,
      cumulative: allUnits.length,
      unitsAvailable: totalAvailableUnits,
      unitsPending: totalPendingUnits,
      unitsSold: totalSoldUnits,
    };

    return [...unitData, totalUnitData];
  };

  const outdoorData = unitTypes
    .map((type, index: number) => {
      const totalUnits = units.filter((unit) => unit.unitType === type);
      const unitOutdoorTypes = totalUnits.map((unit) => unit.outdoorType);
      const outdoorTypes = unitOutdoorTypes.filter((v, i, a) => a.indexOf(v) === i);
      const outdoorTypeData = outdoorTypes.map((outdoorType) => {
        const totalRevenueAllUnits: number = units.map((unit) => unit.basePrice).reduce((a, b) => a + b, 0);
        const validUnits = units.filter((unit) => unit.unitType === type && unit.outdoorType === outdoorType);
        const validUnitPrices = validUnits.map((unit) => unit.basePrice);
        const totalPrice = validUnits.map((unit) => unit.basePrice).reduce((a, b) => a + b, 0);
        const totalSize = validUnits.map((unit) => unit.size).reduce((a, b) => a + b, 0);
        const mid = Math.floor(validUnitPrices.length / 2);
        const nums = [...validUnitPrices].sort((a, b) => a - b);
        const soldRevenue = units
          .filter((unit) => unit.unitType === type && unit.outdoorType === outdoorType && (unit.status === 'F' || unit.status === 'C'))
          .map((unit) => unit.basePrice)
          .reduce((a, b) => a + b, 0);
        const typeRevenue = units
          .filter((unit) => unit.unitType === type && unit.outdoorType === outdoorType)
          .map((unit) => unit.basePrice)
          .reduce((a, b) => a + b, 0);
        const totalUnits = validUnits.length;
        const availableUnits = units.filter(
          (unit) =>
            unit.unitType === type &&
            unit.status !== 'F' &&
            unit.status !== 'C' &&
            unit.status !== 'S' &&
            unit.status !== 'O' &&
            unit.status !== 'IP' &&
            unit.status !== 'P' &&
            unit.outdoorType === outdoorType
        ).length;
        const pendingUnits = units.filter(
          (unit) => unit.unitType === type && (unit.status === 'P' || unit.status === 'O' || unit.status === 'S')
        ).length;
        const soldUnits = units.filter(
          (unit) => unit.unitType === type && (unit.status === 'F' || unit.status === 'C') && unit.outdoorType === outdoorType
        ).length;

        let cumulative = 0;
        for (let i = 0; i <= index; i++) {
          cumulative += units.filter((unit) => unit.unitType === unitTypes[i]).length;
        }

        return {
          unitType: type,
          outdoorType: outdoorType,
          averagePrice: numToCurrency.format(totalPrice / validUnits.length),
          averageSize: (totalSize / validUnits.length).toFixed(2),
          averagePpsf: numToCurrency.format(totalPrice / totalSize),
          lowestPrice: validUnitPrices.length > 0 ? numToCurrency.format(Math.min(...validUnitPrices)) : 'N/A',
          highestPrice: validUnitPrices.length > 0 ? numToCurrency.format(Math.max(...validUnitPrices)) : 'N/A',
          median:
            validUnitPrices.length === 0
              ? 'N/A'
              : validUnitPrices.length % 2 !== 0
              ? numToCurrency.format(nums[mid])
              : numToCurrency.format((nums[mid - 1] + nums[mid]) / 2),
          soldRevenue: numToCurrency.format(soldRevenue),
          soldRevenuePercentage: isNaN(Math.round((soldRevenue / typeRevenue) * 10000) / 100)
            ? '$0'
            : Math.round((soldRevenue / totalRevenueAllUnits) * 10000) / 100 + '%',
          totalRevenue: numToCurrency.format(typeRevenue),
          totalRevenuePercentage: isNaN(Math.round((typeRevenue / totalRevenueAllUnits) * 10000) / 100)
            ? '$0'
            : Math.round((typeRevenue / totalRevenueAllUnits) * 10000) / 100 + '%',
          unitsTotal: totalUnits,
          cumulative: `${cumulative} (${Math.round((cumulative / units.length) * 100)}%)`,
          unitsAvailable: availableUnits,
          unitsPending: pendingUnits,
          unitsSold: soldUnits,
          percentageSold: `${Math.round((soldUnits / totalUnits) * 10000) / 100}%`,
        };
      });
      return outdoorTypeData;
    })
    .flat();

  const modelData = () => {
    let allUnits = units;

    if (tierType) {
      allUnits = units.filter((unit: IUnit) => customTierUnits(project._id, units, tierType).includes(unit.tier));
    }
    let unitData = unitTypes
      .map((type, index: number) => {
        const totalUnits = allUnits.filter((unit) => unit.unitType === type);
        const unitModelTypes = totalUnits.map((unit) => unit.modelType);
        const modelTypes = unitModelTypes.filter((v, i, a) => a.indexOf(v) === i);
        const modelTypeData = modelTypes.map((modelType) => {
          const totalRevenueAllUnits: number = allUnits
            .filter((unit) => unit.status === 'F')
            .map((unit) => unit.basePrice)
            .reduce((a, b) => a + b, 0);
          const validUnits = allUnits.filter((unit) => unit.unitType === type && unit.modelType === modelType);
          const validUnitPrices = validUnits.map((unit) => unit.basePrice);
          const totalPrice = validUnits.map((unit) => unit.basePrice).reduce((a, b) => a + b, 0);
          const totalSize = validUnits.map((unit) => unit.size).reduce((a, b) => a + b, 0);
          const totalPPSF = validUnits.map((unit) => unit.basePrice / unit.size).reduce((a, b) => a + b, 0);
          const mid = Math.floor(validUnitPrices.length / 2);
          const nums = [...validUnitPrices].sort((a, b) => a - b);
          const typeRevenue = allUnits
            .filter((unit) => unit.unitType === type && unit.modelType === modelType)
            .map((unit) => unit.basePrice)
            .reduce((a, b) => a + b, 0);
          const soldRevenue = allUnits
            .filter((unit) => unit.unitType === type && unit.modelType === modelType && (unit.status === 'F' || unit.status === 'C'))
            .map((unit) => unit.basePrice)
            .reduce((a, b) => a + b, 0);
          const totalUnits = validUnits.length;
          const availableUnits = allUnits.filter(
            (unit) =>
              unit.modelType === modelType &&
              unit.status !== 'F' &&
              unit.status !== 'C' &&
              unit.status !== 'S' &&
              unit.status !== 'O' &&
              unit.status !== 'IP' &&
              unit.status !== 'P'
          ).length;
          const pendingUnits = allUnits.filter(
            (unit) => unit.modelType === modelType && (unit.status === 'P' || unit.status === 'O' || unit.status === 'S')
          ).length;
          const soldUnits = allUnits.filter((unit) => unit.modelType === modelType && (unit.status === 'F' || unit.status === 'C')).length;
          return {
            modelType: modelType,
            unitType: type,
            averagePrice: numToCurrency.format(totalPrice / validUnits.length),
            averageSize: (totalSize / validUnits.length).toFixed(0),
            averagePpsf: numToCurrency.format(totalPrice / totalSize),
            lowestPrice: validUnitPrices.length > 0 ? numToCurrency.format(Math.min(...validUnitPrices)) : 'N/A',
            highestPrice: validUnitPrices.length > 0 ? numToCurrency.format(Math.max(...validUnitPrices)) : 'N/A',
            median:
              validUnitPrices.length === 0
                ? 'N/A'
                : validUnitPrices.length % 2 !== 0
                ? numToCurrency.format(nums[mid])
                : numToCurrency.format((nums[mid - 1] + nums[mid]) / 2),
            soldRevenue: numToCurrency.format(soldRevenue),
            soldRevenuePercentage: isNaN(Math.round((soldRevenue / typeRevenue) * 10000) / 100),
            totalRevenue: numToCurrency.format(typeRevenue),
            totalRevenuePercentage: isNaN(Math.round((typeRevenue / totalRevenueAllUnits) * 10000) / 100),
            unitsTotal: totalUnits,
            unitsAvailable: availableUnits,
            unitsPending: pendingUnits,
            unitsSold: soldUnits,
            percentageSold: `${Math.round((soldUnits / totalUnits) * 10000) / 100}%`,
          };
        });
        return modelTypeData;
      })
      .flat();

    const allUnitPrices = allUnits.map((unit) => unit.basePrice);
    const totalRevenue = allUnits.map((unit) => unit.basePrice).reduce((a, b) => a + b, 0);
    const totalSize = allUnits.map((unit) => unit.size).reduce((a, b) => a + b, 0);
    const totalSoldRevenue = allUnits
      .filter((unit) => unit.status === 'F' || unit.status === 'C')
      .map((unit) => unit.basePrice)
      .reduce((a, b) => a + b, 0);
    const totalAvailableUnits = allUnits.filter(
      (unit) =>
        unit.status !== 'F' &&
        unit.status !== 'C' &&
        unit.status !== 'S' &&
        unit.status !== 'O' &&
        unit.status !== 'IP' &&
        unit.status !== 'P'
    ).length;
    const totalPendingUnits = allUnits.filter((unit) => unit.status === 'P' || unit.status === 'O' || unit.status === 'S').length;
    const totalSoldUnits = allUnits.filter((unit) => unit.status === 'F' || unit.status === 'C').length;
    const midAll = Math.floor(allUnitPrices.length / 2);
    const numsAll = [...allUnitPrices].sort((a, b) => a - b);

    let totalUnitData = {
      modelType: 'N/A',
      unitType: 'Total',
      outdoorType: 'N/A',
      averagePrice: numToCurrency.format(totalRevenue / units.length),
      averageSize: (totalSize / units.length).toFixed(2),
      averagePpsf: numToCurrency.format(totalRevenue / totalSize),
      lowestPrice: allUnitPrices.length > 0 ? numToCurrency.format(Math.min(...allUnitPrices)) : 'N/A',
      highestPrice: allUnitPrices.length > 0 ? numToCurrency.format(Math.max(...allUnitPrices)) : 'N/A',
      median:
        allUnitPrices.length === 0
          ? 'N/A'
          : allUnitPrices.length % 2 !== 0
          ? numToCurrency.format(numsAll[midAll])
          : numToCurrency.format((numsAll[midAll - 1] + numsAll[midAll]) / 2),
      soldRevenue: numToCurrency.format(totalSoldRevenue),
      soldRevenuePercentage: isNaN(Math.round((totalSoldRevenue / totalRevenue) * 10000) / 100)
        ? '$0'
        : Math.round((totalSoldRevenue / totalRevenue) * 10000) / 100 + '%',
      totalRevenue: numToCurrency.format(totalRevenue),
      totalRevenuePercentage: '100%',
      unitsTotal: allUnits.length,
      cumulative: allUnits.length,
      unitsAvailable: totalAvailableUnits,
      unitsPending: totalPendingUnits,
      unitsSold: totalSoldUnits,
    };

    return [...unitData, totalUnitData];
  };

  const rangeData = () => {
    if (!units.length) return [];
    let allUnits = units;

    if (tierType) {
      allUnits = units.filter((unit: IUnit) => customTierUnits(project._id, units, tierType).includes(unit.tier));
    }

    let min =
      Math.floor(allUnits.reduce((min: any, unit: any) => (unit.basePrice < min ? unit.basePrice : min), allUnits[0].basePrice) / 100000) *
      100000;
    let max = allUnits.reduce((max: any, unit: any) => (unit.basePrice > max ? unit.basePrice : max), allUnits[0].basePrice);

    let rangeArray: IRange[] = [];

    for (let i = min / 100000; i < max / 100000; i++) {
      let total = allUnits.filter((unit: IUnit) => i * 100000 <= unit.basePrice && unit.basePrice <= i * 100000 + 99999).length;
      let sold = allUnits.filter(
        (unit: IUnit) =>
          i * 100000 <= unit.basePrice && unit.basePrice <= i * 100000 + 99999 && (unit.status === 'C' || unit.status === 'F')
      ).length;
      let available = allUnits.filter(
        (unit: IUnit) => i * 100000 <= unit.basePrice && unit.basePrice <= i * 100000 + 99999 && unit.status !== 'C' && unit.status !== 'F'
      ).length;
      let revenue = allUnits
        .filter((unit: IUnit) => i * 100000 <= unit.basePrice && unit.basePrice <= i * 100000 + 99999)
        .reduce((a: any, b: any) => a + b.basePrice, 0);

      let accumulative = total;
      let accumulativeRevenue = revenue;
      let accumulativeSold = sold;
      let accumulativeAvailable = available;

      for (let j = min / 100000; j < i; j++) {
        let accumulativeAmount = allUnits.filter(
          (unit: IUnit) => j * 100000 <= unit.basePrice && unit.basePrice <= j * 100000 + 99999
        ).length;
        accumulative += accumulativeAmount;

        let sold = allUnits.filter(
          (unit: IUnit) =>
            j * 100000 <= unit.basePrice && unit.basePrice <= j * 100000 + 99999 && (unit.status === 'C' || unit.status === 'F')
        ).length;
        accumulativeSold += sold;

        let available = allUnits.filter(
          (unit: IUnit) =>
            j * 100000 <= unit.basePrice && unit.basePrice <= j * 100000 + 99999 && unit.status !== 'C' && unit.status !== 'F'
        ).length;
        accumulativeAvailable += available;

        let accumulativeRev = allUnits
          .filter((unit: IUnit) => j * 100000 <= unit.basePrice && unit.basePrice <= j * 100000 + 99999)
          .reduce((a: any, b: any) => a + b.basePrice, 0);
        accumulativeRevenue += accumulativeRev;
      }

      let rangeObject = {
        minRange: i * 100000,
        maxRange: i * 100000 + 99999,
        total: allUnits.length,
        units: total,
        accumulativeUnit: accumulative,
        sold: sold,
        accumulativeSold: accumulativeSold,
        available: available,
        accumulativeAvailable: accumulativeAvailable,
        revenue: numToCurrency.format(revenue),
        accumulativeRevenue: numToCurrency.format(accumulativeRevenue),
      };

      rangeArray.push(rangeObject);
    }

    return rangeArray;
  };

  return (
    <Box sx={{ m: 2 }}>
      <Box sx={{ mb: 2 }}>
        <Typography gutterBottom variant="h2">
          Unit Type Summary
        </Typography>
        {customTiers(project._id, units).length ? (
          <AppBar position="sticky">
            <Tabs value={tierType} scrollButtons="auto" onChange={handleTierChange} aria-label="scrollable auto tabs unit type">
              {customTiers(project._id, units).map((tier: string, index: number) => {
                return <Tab key={index} value={tier} label={tier} />;
              })}
              ;
            </Tabs>
          </AppBar>
        ) : null}
        <StandardTable download={download} columns={columns} data={data()} loading={loading} />
      </Box>
      {units.some((unit: IUnit) => unit.outdoorType) ? (
        <Box sx={{ mb: 2 }}>
          <Typography gutterBottom variant="h2">
            Unit Type Summary (Outdoor)
          </Typography>
          <StandardTable download={download} columns={columns} data={outdoorData} loading={loading} />
        </Box>
      ) : null}
      <Box>
        <Typography gutterBottom variant="h2">
          Model Type Summary
        </Typography>
        <StandardTable download={download} columns={modelColumns} data={modelData()} loading={loading} />
      </Box>
      <Box sx={{ my: 2 }}>
        <Typography variant={'h5'} gutterBottom>
          Range Report
        </Typography>
        <StandardTable download={downloadRange} columns={rangeColumns} data={rangeData()} loading={loading} />
      </Box>
    </Box>
  );
};

interface ChildProps {
  allUnits?: IUnit[];
}

interface IRange {
  minRange: number;
  maxRange: number;
  total: number;
  sold: number;
  available: number;
  accumulativeUnit: number;
  revenue: string;
  accumulativeRevenue: string;
}

const UNITS = gql`
  query unitMany($filter: FilterFindManyUnitInput) {
    unitMany(filter: $filter, limit: 10000) {
      _id
      suite
      unit
      modelType
      unitType
      bathroom
      size
      outdoorType
      basePrice
      level
      exposure
      outdoorSize
      status
      tier
    }
  }
`;

export default TypeSummary;
