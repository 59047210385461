import { useState, useContext, useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Box, TextField, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { numToCurrency, convertAllDates } from '../../utils/Functions';
import { UnitContext } from '../../context/UnitContext';
import { netHST } from '../../utils/Functions';
import { useAppDispatch } from '../../app/hooks';
import { showSuccessSnackbar, showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/auth/authSlice';
import { useCreateActivity } from '../../features/activity/activityHooks';
import { selectProject } from '../../features/project/projectSlice';
import { IUnitHistory } from '../../types/unit';
import Unit from '../common/Unit';

const UnitInfo = () => {
  const storeDispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const project = useSelector(selectProject);
  const createActivity = useCreateActivity();
  const { unit, filteredDeal, setUnit } = useContext(UnitContext);
  const [edit, setEdit] = useState<boolean>(false);
  const [level, setLevel] = useState<string>(unit.level ? unit.level : '');
  const [rental, setRental] = useState<string>(unit.rental ? unit.rental : '');
  const [unitNumber, setUnitNumber] = useState<string>(unit.unit ? unit.unit : '');
  const [size, setSize] = useState<string>(unit.size ? unit.size.toString() : '');
  const [modelType, setModelType] = useState<string>(unit.modelType ? unit.modelType : '');
  const [unitType, setUnitType] = useState<string>(unit.unitType ? unit.unitType : '');
  const [bathroom, setBathroom] = useState<string>(unit.bathroom ? unit.bathroom.toString() : '');
  const [exposure, setExposure] = useState<string>(unit.exposure ? unit.exposure : '');
  const [outdoorSize, setOutdoorSize] = useState<string>(unit.outdoorSize && unit.outdoorSize.toString() ? unit.outdoorSize : '');
  const [outdoorType, setOutdoorType] = useState<string>(unit.outdoorType ? unit.outdoorType : '');
  const [firstTentativeOccupancy, setFirstTentativeOccupancy] = useState<Date | null>(
    unit.firstTentativeOccupancy ? unit.firstTentativeOccupancy : null
  );
  const [finalTentativeOccupancy, setFinalTentativeOccupancy] = useState<Date | null>(
    unit.finalTentativeOccupancy ? unit.finalTentativeOccupancy : null
  );
  const [firmOccupancy, setFirmOccupancy] = useState<Date | null>(unit.firmOccupancy ? unit.firmOccupancy : null);
  const [outsideOccupancy, setOutsideOccupancy] = useState<Date | null>(unit.outsideOccupancy ? unit.outsideOccupancy : null);

  useEffect(() => {
    setLevel(unit.level);
    setUnitNumber(unit.unit);
    setSize(unit.size);
    setRental(unit.rental ? unit.rental : '');
    setModelType(unit.modelType);
    setUnitType(unit.unitType);
    setBathroom(unit.bathroom);
    setExposure(unit.exposure);
    setOutdoorSize(unit.outdoorSize);
    setOutdoorType(unit.outdoorType);
    setFirstTentativeOccupancy(unit.firstTentativeOccupancy);
    setFinalTentativeOccupancy(unit.finalTentativeOccupancy);
    setFirmOccupancy(unit.firmOccupancy);
    setOutsideOccupancy(unit.outsideOccupancy);
  }, [edit, unit]);

  const [updateUnit] = useMutation(UPDATEUNIT, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const getTotalPrice = () => {
    let total = filteredDeal.options.reduce((a: any, b: any) => {
      return a + b.amount;
    }, filteredDeal.basePrice);
    return total;
  };

  const handleSaveUnit = (e: any) => {
    e.preventDefault();
    let history: any[] = [];
    if (level !== unit.level) {
      history.push({
        type: 'Level',
        description: `Level has changed from ${unit.level} to ${level}`,
        timestamp: new Date(),
        user: user._id,
      });
    }
    if (unit.unit !== unitNumber) {
      history.push({
        type: 'Unit',
        description: `Unit has been changed ${unit.unit} to ${unitNumber}`,
        timestamp: new Date(),
        user: user._id,
      });
    }
    if (unit.unit !== unitNumber) {
      history.push({
        type: 'Rental',
        description: `Rental has been changed ${unit.rental} to ${rental}`,
        timestamp: new Date(),
        user: user._id,
      });
    }
    if (unit.size.toString() !== size.toString()) {
      history.push({
        type: 'Size',
        description: `Size has been changed ${unit.size.toString()} to ${size}`,
        timestamp: new Date(),
        user: user._id,
      });
    }
    if (unit.modelType !== modelType) {
      history.push({
        type: 'Model Type',
        description: `Model Type has been changed ${unit.modelType} to ${modelType}`,
        timestamp: new Date(),
        user: user._id,
      });
    }
    if (unit.unitType !== unitType) {
      history.push({
        type: 'Unit Type',
        description: `Unit Type has been changed ${unit.unitType} to ${unitType}`,
        timestamp: new Date(),
        user: user._id,
      });
    }
    if (unit.bathroom.toString() !== bathroom.toString()) {
      history.push({
        type: 'Bathroom',
        description: `Bathroom has been changed ${unit.bathroom} to ${bathroom}`,
        timestamp: new Date(),
        user: user._id,
      });
    }
    if (unit.exposure !== exposure) {
      history.push({
        type: 'Exposure',
        description: `Exposure has been changed ${unit.exposure} to ${exposure}`,
        timestamp: new Date(),
        user: user._id,
      });
    }
    if (unit.outdoorSize && unit.outdoorSize.toString() !== outdoorSize.toString()) {
      history.push({
        type: 'Outdoor Size',
        description: `Outdoor Size has been changed ${unit.outdoorSize} to ${outdoorSize}`,
        timestamp: new Date(),
        user: user._id,
      });
    }
    if (unit.outdoorType !== outdoorType) {
      history.push({
        type: 'Outdoor Type',
        description: `Outdoor Type has been changed ${unit.outdoorType} to ${outdoorType}`,
        timestamp: new Date(),
        user: user._id,
      });
    }
    if (convertAllDates(new Date(unit.firstTentativeOccupancy), 'PP') !== convertAllDates(new Date(firstTentativeOccupancy!), 'PP')) {
      history.push({
        type: 'First Tentative Occupancy',
        description: `First Tentative Occupancy has been changed ${convertAllDates(
          unit.firstTentativeOccupancy,
          'PP'
        )} to ${convertAllDates(firstTentativeOccupancy, 'PP')}`,
        timestamp: new Date(),
        user: user._id,
      });
    }
    if (convertAllDates(new Date(unit.finalTentativeOccupancy), 'PP') !== convertAllDates(new Date(finalTentativeOccupancy!), 'PP')) {
      history.push({
        type: 'Final Tentative Occupancy',
        description: `Final Tentative Occupancy has been changed ${convertAllDates(
          unit.finalTentativeOccupancy,
          'PP'
        )} to ${convertAllDates(finalTentativeOccupancy, 'PP')}`,
        timestamp: new Date(),
        user: user._id,
      });
    }
    if (convertAllDates(new Date(unit.firmOccupancy), 'PP') !== convertAllDates(new Date(firmOccupancy!), 'PP')) {
      history.push({
        type: 'Firm Occupancy',
        description: `Firm Occupancy has been changed ${convertAllDates(unit.firmOccupancy, 'PP')} to ${convertAllDates(
          firmOccupancy,
          'PP'
        )}`,
        timestamp: new Date(),
        user: user._id,
      });
    }
    if (convertAllDates(new Date(unit.outsideOccupancy), 'PP') !== convertAllDates(new Date(outsideOccupancy!), 'PP')) {
      history.push({
        type: 'Outside Occupancy',
        description: `Outside Occupancy has been changed ${convertAllDates(unit.outsideOccupancy, 'PP')} to ${convertAllDates(
          outsideOccupancy,
          'PP'
        )}`,
        timestamp: new Date(),
        user: user._id,
      });
    }
    if (!history.length) return storeDispatch(showErrorSnackbar('No Changes Made'));
    updateUnit({
      variables: {
        _id: unit._id,
        record: {
          level: level,
          unit: unitNumber,
          size: parseInt(size, 10),
          rental: parseInt(rental, 10),
          modelType: modelType,
          unitType: unitType,
          bathroom: parseInt(bathroom, 10),
          exposure: exposure,
          outdoorSize: parseInt(outdoorSize),
          outdoorType: outdoorType,
          firstTentativeOccupancy: firstTentativeOccupancy,
          finalTentativeOccupancy: finalTentativeOccupancy,
          firmOccupancy: firmOccupancy,
          outsideOccupancy: outsideOccupancy,
          history: [
            ...unit.history.map((history: IUnitHistory) => {
              return {
                ...history,
                user: history.user ? history.user._id : null,
              };
            }),
            ...history,
          ],
        },
      },
    }).then((res) => {
      if (history.length) {
        createActivity({
          project: project._id,
          user: user._id,
          deal: null,
          title: `Unit Update ${unit.suite}`,
          content: history.map((history: any) => history.description).join(', '),
        });
      }
      storeDispatch(showSuccessSnackbar('Unit Updated!'));
      setUnit(res.data.unitUpdateById.record);
      setEdit(false);
    });
  };

  const handleDropdown = (e: any) => {
    if (e.target.name === 'exposure') {
      setExposure(e.target.value);
    }
    if (e.target.name === 'outdoorType') {
      setOutdoorType(e.target.value);
    }
    if (e.target.name === 'unitType') {
      setUnitType(e.target.value);
    }
  };

  return (
    <Box
      sx={{
        padding: '20px',
        width: '200px',
        border: '1px solid #000',
        position: 'sticky',
        top: '20px',
        '@media (max-width: 600px)': {
          width: '100%',
        },
      }}
    >
      {user.type === 'Admin' || user.type === 'Manager' ? (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <EditIcon onClick={() => setEdit(!edit)} sx={{ cursor: 'pointer', ml: 1 }} fontSize="small" />
          {edit ? (
            <IconButton sx={{ p: 0 }} type="submit" form="unitForm">
              <SaveIcon sx={{ cursor: 'pointer', color: 'success.main', ml: 1 }} fontSize="small" />
            </IconButton>
          ) : null}
        </Box>
      ) : null}
      {!edit ? (
        <Box>
          <p>
            Status: <strong>{unit.status}</strong>
          </p>
          <p>
            Opening Day Price: <strong>{numToCurrency.format(unit.originalPrice)}</strong>
          </p>
          <Box sx={{ mb: 2 }}>
            <div>Unit Price:</div>
            {filteredDeal && (filteredDeal.cancelled.dateCancelled || filteredDeal.rescission.dateRescinded) ? (
              <div>
                <strong>{numToCurrency.format(filteredDeal.basePrice)}</strong>
              </div>
            ) : (
              <div>
                <strong>{numToCurrency.format(unit.basePrice)}</strong>
              </div>
            )}
          </Box>
          {filteredDeal ? (
            <>
              {unit.basePrice !== filteredDeal.totalPrice ? (
                <Box sx={{ mb: 2 }}>
                  <div>Total Price:</div>
                  <div>
                    <strong>{numToCurrency.format(getTotalPrice())}</strong>
                  </div>
                </Box>
              ) : null}
              {filteredDeal.options.map((option: any, index: number) => {
                if (option.purchaseAmount) {
                  return (
                    <Box sx={{ mb: 2 }} key={index}>
                      <div>{option.name}:</div>
                      <div>
                        <strong>
                          {option.purchaseAmount}x - {numToCurrency.format(option.amount)}
                        </strong>
                      </div>
                    </Box>
                  );
                } else return null;
              })}
            </>
          ) : null}
          <div>
            <div>Price Per SF:</div>
            {filteredDeal && (filteredDeal.cancelled.dateCancelled || filteredDeal.rescission.dateRescinded) ? (
              <div>
                <strong>{numToCurrency.format(Math.round(filteredDeal.basePrice / unit.size))}</strong>
              </div>
            ) : (
              <div>
                <strong>{numToCurrency.format(Math.round(unit.basePrice / unit.size))}</strong>
              </div>
            )}
          </div>
          <Box sx={{ mt: 1 }}>
            {filteredDeal && (filteredDeal.cancelled.dateCancelled || filteredDeal.rescission.dateRescinded) ? (
              <div>
                Current Price (Net):{' '}
                <strong>
                  {unit.type === 'commercial'
                    ? numToCurrency.format(filteredDeal.basePrice)
                    : numToCurrency.format(netHST(filteredDeal.basePrice))}
                </strong>
              </div>
            ) : (
              <div>
                Current Price (Net):{' '}
                <strong>
                  {unit.type === 'commercial' ? numToCurrency.format(unit.basePrice) : numToCurrency.format(netHST(unit.basePrice))}
                </strong>
              </div>
            )}
          </Box>
          {unit.rental ? (
            <p>
              Rental: <strong>{numToCurrency.format(unit.rental)}</strong>
            </p>
          ) : null}
          <p>
            Level: <strong>{unit.level}</strong>
          </p>
          <p>
            Unit: <strong>{unit.unit}</strong>
          </p>
          <p>
            Size: <strong>{unit.size}</strong>
          </p>
          <p>
            Model Type: <strong>{unit.modelType}</strong>
          </p>
          <p>
            Unit Type: <strong>{unit.unitType}</strong>
          </p>
          <p>
            Bathrooms: <strong>{unit.bathroom}</strong>
          </p>
          <p>
            Exposure: <strong>{unit.exposure}</strong>
          </p>
          <p>
            Outdoor Size: <strong>{unit.outdoorSize}</strong>
          </p>
          <p>
            Outdoor Type: <strong>{unit.outdoorType ? unit.outdoorType : 'N/A'}</strong>
          </p>
          <p>
            First Tentative Occupancy:{' '}
            <strong>{unit.firstTentativeOccupancy ? convertAllDates(unit.firstTentativeOccupancy, 'PP') : 'N/A'}</strong>
          </p>
          <p>
            Final Tentative Occupancy:{' '}
            <strong>{unit.finalTentativeOccupancy ? convertAllDates(unit.finalTentativeOccupancy, 'PP') : 'N/A'}</strong>
          </p>
          <p>
            Firm Occupancy: <strong>{unit.firmOccupancy ? convertAllDates(unit.firmOccupancy, 'PP') : 'N/A'}</strong>
          </p>
          <p>
            Outside Occupancy: <strong>{unit.outsideOccupancy ? convertAllDates(unit.outsideOccupancy, 'PP') : 'N/A'}</strong>
          </p>
          {unit.custom ? (
            <p>
              Custom Changes: <strong>{unit.custom}</strong>
            </p>
          ) : null}
        </Box>
      ) : (
        <form id="unitForm" onSubmit={handleSaveUnit}>
          <Unit
            unit={unit}
            level={level}
            setLevel={setLevel}
            unitNumber={unitNumber}
            setUnitNumber={setUnitNumber}
            rental={rental}
            setRental={setRental}
            size={size}
            setSize={setSize}
            modelType={modelType}
            setModelType={setModelType}
            handleDropdown={handleDropdown}
            unitType={unitType}
            bathroom={bathroom}
            setBathroom={setBathroom}
            exposure={exposure}
            outdoorSize={outdoorSize}
            setOutdoorSize={setOutdoorSize}
            outdoorType={outdoorType}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              inputFormat="yyyy/MM/dd"
              label={'First Tentative Occupancy Date (YYYY/MM/DD)'}
              value={firstTentativeOccupancy}
              onChange={(newValue) => setFirstTentativeOccupancy(newValue)}
              renderInput={(params) => <TextField sx={{ mt: 2 }} fullWidth {...params} />}
            />

            <DatePicker
              inputFormat="yyyy/MM/dd"
              label={'Final Tentative Occupancy Date'}
              value={finalTentativeOccupancy}
              onChange={(newValue) => setFinalTentativeOccupancy(newValue)}
              renderInput={(params) => <TextField sx={{ mt: 2 }} fullWidth {...params} />}
            />
            <DatePicker
              inputFormat="yyyy/MM/dd"
              label={'Firm Occupancy Date'}
              value={firmOccupancy}
              onChange={(newValue) => setFirmOccupancy(newValue)}
              renderInput={(params) => <TextField sx={{ mt: 2 }} fullWidth {...params} />}
            />
            <DatePicker
              inputFormat="yyyy/MM/dd"
              label={'Outside Occupancy Date'}
              value={outsideOccupancy}
              onChange={(newValue) => setOutsideOccupancy(newValue)}
              renderInput={(params) => <TextField sx={{ mt: 2 }} fullWidth {...params} />}
            />
          </LocalizationProvider>
        </form>
      )}
    </Box>
  );
};

const UPDATEUNIT = gql`
  mutation unitUpdateById($_id: MongoID!, $record: UpdateByIdUnitInput!) {
    unitUpdateById(_id: $_id, record: $record) {
      record {
        _id
        suite
        unit
        level
        modelType
        basePrice
        size
        status
        getUrl
        putUrl
        bathroom
        rental
        exposure
        status
        outdoorSize
        outdoorType
        unitType
        originalPrice
        basePrice
        custom
        tier
        type
        firstTentativeOccupancy
        finalTentativeOccupancy
        firmOccupancy
        outsideOccupancy
        allocation {
          _id
          fullName
        }
        history {
          type
          description
          timestamp
          user {
            _id
            fullName
          }
          _id
        }
      }
    }
  }
`;

export default UnitInfo;
