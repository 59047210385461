import { useState, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { numToCurrency, downloadExcel, downloadPdf, capitalizeFirstLetter, normalToCamel } from '../../utils/Functions';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import StandardTable from '../tables/StandardTable';
import { Flex } from '../../commonStyles';

const DailyDeposits = () => {
  const project = useSelector(selectProject);
  const [deposits, setDeposits] = useState<any[]>([]);
  const [depositColumns, setDepositColumns] = useState<string[]>([]);

  const { loading } = useQuery(DEPOSITS, {
    variables: { project: project._id },
    onCompleted: async (data) => {
      let unique: any[] = [...new Set(data.getDepositSummary.map((deposit: any) => deposit._id.deposit))].sort();
      if (unique.includes('Initial')) {
        unique.sort(function (x, y) {
          return x === 'Initial' ? -1 : y === 'Initial' ? 1 : 0;
        });
      }

      let output: any[] = [];
      let deposits = [...data.getDepositSummary];

      await deposits.forEach(function (item: any) {
        var existing = output.filter(function (v, i) {
          return v._id.year == item._id.year && v._id.month == item._id.month;
        });
        if (existing.length) {
          var existingIndex = output.indexOf(existing[0]);
          output[existingIndex].total += item.total;
          output[existingIndex].deposits = [
            ...output[existingIndex].deposits,
            { name: item._id.deposit, amount: item.total, receivedTotal: item.receivedTotal },
          ];
        } else {
          let newItem = { ...item };
          newItem.deposits = [{ name: item._id.deposit, amount: item.total, receivedTotal: item.receivedTotal }];
          output.push(newItem);
        }
      });

      setDeposits(output);
      setDepositColumns(unique);
    },
  });

  const columns = useMemo(() => {
    let date = [
      {
        Header: 'Date',
        accessor: (rowData: any) => {
          return (
            <strong>
              {rowData._id.month}/{rowData._id.year}
            </strong>
          );
        },
      },
    ];

    let depositCols = depositColumns.map((unique: string) => {
      return {
        Header: `${unique}`,
        accessor: (rowData: any) => {
          let selectedDeposit = rowData.deposits.find((deposit: any) => deposit.name === unique);
          if (selectedDeposit) {
            return (
              <Flex>
                <Box>{numToCurrency.format(selectedDeposit.amount)}</Box>
                {selectedDeposit.receivedTotal ? (
                  <Box sx={{ ml: 0.5, color: 'green' }}>({numToCurrency.format(selectedDeposit.receivedTotal)})</Box>
                ) : null}
              </Flex>
            );
          }
          return '-';
        },
      };
    });

    let total = [
      {
        Header: `Monthly Total`,
        accessor: (rowData: any) => {
          return (
            <Flex>
              <Box>{numToCurrency.format(rowData.total)}</Box>
              {rowData.deposits.length ? (
                <Box sx={{ ml: 0.5, color: 'green' }}>
                  (
                  {numToCurrency.format(
                    rowData.deposits.reduce((a: any, b: any) => {
                      return a + b.receivedTotal;
                    }, 0)
                  )}
                  )
                </Box>
              ) : null}
            </Flex>
          );
        },
      },
    ];
    let accumulative = [
      {
        Header: `Accumulative`,
        accessor: (rowData: any, index: number) => {
          let amount = 0;
          let receivedTotal = 0;
          for (let i = 0; i <= index; i++) {
            amount += deposits[i].total;
          }
          for (let i = 0; i <= index; i++) {
            receivedTotal += deposits[i].deposits.reduce((a: any, b: any) => {
              return a + b.receivedTotal;
            }, 0);
          }

          return (
            <Flex>
              <Box>{numToCurrency.format(amount)}</Box>
              {receivedTotal ? <Box sx={{ ml: 0.5, color: 'green' }}>({numToCurrency.format(receivedTotal)})</Box> : null}
            </Flex>
          );
        },
      },
    ];

    return [...date, ...depositCols, ...total, ...accumulative];
  }, [deposits]);

  const download = (type: string, data: any) => {
    let unitColumns = data.map((depositData: any, index: number) => {
      let amount = 0;
      let receivedTotal = 0;
      for (let i = 0; i <= index; i++) {
        amount += deposits[i].total;
        receivedTotal += deposits[i].deposits.reduce((a: any, b: any) => {
          return a + b.receivedTotal;
        }, 0);
      }
      let newDepositColumn = depositColumns.map((unique: string) => {
        let selectedDeposit = depositData.deposits.find((deposit: any) => deposit.name === unique);
        if (selectedDeposit) {
          return `${numToCurrency.format(selectedDeposit.amount)} (${numToCurrency.format(selectedDeposit.receivedTotal)})`;
        } else return '-';
      });

      let depositObjects = depositColumns.map((unique: string, index: number) => {
        return {
          [normalToCamel(unique)]: newDepositColumn[index],
        };
      });

      let mergeObjects = depositObjects.reduce(function (result, currentObject) {
        for (var key in currentObject) {
          if (currentObject.hasOwnProperty(key)) {
            result[key] = currentObject[key];
          }
        }
        return result;
      }, {});

      let allObjects = {
        date: `${depositData._id.month}/${depositData._id.year}`,
        monthlyTotal: numToCurrency.format(depositData.total),
        monthlyReceived: numToCurrency.format(
          depositData.deposits.reduce((a: any, b: any) => {
            return a + b.receivedTotal;
          }, 0)
        ),
        accumulative: numToCurrency.format(amount),
        accumulativeReceived: numToCurrency.format(receivedTotal),
      };

      return { ...allObjects, ...mergeObjects };
    });

    let headers = [
      {
        label: 'Date',
        id: 'date',
      },
    ];

    let depositHeaders = depositColumns.map((deposit: any) => {
      return {
        label: deposit,
        id: normalToCamel(deposit),
      };
    });

    let totalHeaders = [
      {
        label: 'Monthly Total',
        id: 'monthlyTotal',
      },
      {
        label: 'Monthly Received',
        id: 'monthlyReceived',
      },
      {
        label: 'Accumulative',
        id: 'accumulative',
      },
      {
        label: 'Accumulative Received',
        id: 'accumulativeReceived',
      },
    ];

    let allHeaders = [...headers, ...depositHeaders, ...totalHeaders];

    let excelWidths = depositColumns.map((unique: any) => {
      return {
        [normalToCamel(unique)]: 15,
      };
    });

    let pdfWidths = depositColumns.map((unique: any) => {
      return {
        [normalToCamel(unique)]: 200,
      };
    });

    let mergeObjects = pdfWidths.reduce(function (result, currentObject) {
      for (var key in currentObject) {
        if (currentObject.hasOwnProperty(key)) {
          result[key] = currentObject[key];
        }
      }
      return result;
    }, {});

    let mergeExcelObjects = excelWidths.reduce(function (result, currentObject) {
      for (var key in currentObject) {
        if (currentObject.hasOwnProperty(key)) {
          result[key] = currentObject[key];
        }
      }
      return result;
    }, {});

    let exWidths = {
      date: 15,
      monthlyTotal: 15,
      monthlyReceived: 15,
      accumulative: 15,
      accumulativeReceived: 15,
    };

    let fixedWidths = {
      date: 200,
      monthlyTotal: 200,
      monthlyReceived: 200,
      accumulative: 200,
      accumulativeReceived: 200,
    };

    let allExcelWidths = {
      ...mergeExcelObjects,
      ...exWidths,
    };

    let allPdfWidths = {
      ...mergeObjects,
      ...fixedWidths,
    };

    let sheetTitle = `${project.name} - ${capitalizeFirstLetter('Monthly Deposit Summary')}`;

    if (type === 'excel') {
      downloadExcel([unitColumns], [allHeaders], [], [[allExcelWidths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([unitColumns], [allHeaders], [], [allPdfWidths], [sheetTitle], sheetTitle);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant={'h5'} gutterBottom>
        Monthly Deposits
      </Typography>
      <Box sx={{ mb: 2 }}>The amount shown in green indicates the total amount that has been cleared.</Box>
      <StandardTable columns={columns} data={deposits} loading={loading} download={download} />
    </Box>
  );
};

const DEPOSITS = gql`
  query getDepositSummary($project: MongoID!) {
    getDepositSummary(project: $project) {
      _id {
        month
        year
        deposit
      }
      total
      receivedTotal
    }
  }
`;

export default DailyDeposits;
