import { useState } from 'react';
import { Box, Paper, AppBar, Tabs, Tab, Divider, Grid } from '@mui/material';
import FirmingTable from './FirmingTable';
import FirmedTable from './FirmedTable';
import AllocationTable from './AllocationTable';
import { selectUser } from '../../features/auth/authSlice';
import { useSelector } from 'react-redux';

const DashboardTable = (props: ChildProps) => {
  const user = useSelector(selectUser);
  const { allFirmedRows, allDeals } = props;
  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: any) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  return (
    <Grid
      sx={{
        height: '100%',
        backgroundColor: '#f5f5f5',
        padding: 2,
      }}
      container
    >
      <Grid item xs={12}>
        <Paper
          sx={{
            height: '100%',
          }}
          elevation={5}
        >
          <AppBar position="static" color="transparent" elevation={0}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Presale" {...a11yProps(0)} />
              <Tab label="Conditional" {...a11yProps(1)} />
              {user.type !== 'Sales' ? <Tab label="Firmed" {...a11yProps(2)} /> : null}
            </Tabs>
          </AppBar>
          <Divider />
          <Box>
            {value === 0 ? (
              <Box>
                <AllocationTable allDeals={allDeals} />
              </Box>
            ) : value === 1 ? (
              <Box>
                {/* units scheduled to be firmed today that are currently still conditional */}
                <FirmingTable />
              </Box>
            ) : value === 2 ? (
              <Box
                sx={{
                  tableContainer: {
                    height: '100%',
                  },
                }}
              >
                {/* units scheduled to be firmed today that have already been firmed */}
                <FirmedTable allFirmedRows={allFirmedRows} />
              </Box>
            ) : null}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

interface ChildProps {
  allDeals: any;
  allFirmedRows: any;
  allFirmingRows: any;
  setAllFirmedRows: any;
  setAllFirmingRows: any;
}

export default DashboardTable;
