import { useState, useMemo, useEffect, ChangeEvent } from 'react';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { Box, Typography, Button, TextField, AppBar, Tabs, Tab, Checkbox } from '@mui/material';
import StandardTable from '../tables/StandardTable';

import { selectProject } from '../../features/project/projectSlice';
import { IPurchaserInfo } from '../../types/CreateDealForm';
import { IDocuments } from '../../types/unit';
import { convertAllDates } from '../../utils/Functions';
import { GlobalModal } from '../../features/modal/Modal';
import { Flex, FlexBetween } from '../../commonStyles';
import { useAppDispatch } from '../../app/hooks';
import { handleModal } from '../../features/modal/modalSlice';
import { showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import LoadingWrapper from '../common/LoadingWrapper';

const Deals = () => {
  const project = useSelector(selectProject);
  const [documents, setDocuments] = useState<IDocuments[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const storeDispatch = useAppDispatch();
  const [password, setPassword] = useState<string>('');
  const [selectedDocument, setSelectedDocument] = useState<any>(null);
  const [witness, setWitness] = useState<string>('');
  const [documentType, setDocumentType] = useState<string>('APS');
  const [purchasers, setPurchasers] = useState<any>([]);
  const [signLoading, setSignLoading] = useState<boolean>(false);

  const [getDocuments, { loading }] = useLazyQuery(GETDOCUMENTS, {
    onCompleted: (data) => {
      setDocuments(data.getDocuments.documents);
    },
  });

  const [getSigners, { loading: getSignersLoading }] = useLazyQuery(GETSIGNERS, {
    onCompleted: (data) => {
      let purchasers = data.documentById.signers
        .filter(
          (purchaser: any) =>
            (purchaser.status === 'sent' || purchaser.status === 'completed' || purchaser.status === 'delivered') &&
            purchaser.type === 'purchaser'
        )
        .map((purchaser: any, index: number) => {
          return {
            ...purchaser,
            name: purchaser.name,
            checked: false,
          };
        });
      setPurchasers(purchasers);
    },
    onError: (err) => {
      setPurchasers([]);
      console.log(err, 'err');
    },
  });

  const [signInPerson] = useMutation(SIGNINPERSON, {
    onCompleted: (data) => {
      if (data) {
        window.location.href = data.signInPerson;
      }
      setSignLoading(false);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar('Error'));
      console.log(err);
    },
  });

  useEffect(() => {
    getDocuments({
      variables: { project: project._id, type: documentType, page: pageNumber, perPage: 15 },
    });
  }, [pageNumber, project._id, documentType]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Suite',
        accessor: (rowData: any) => (rowData.deal ? rowData.deal.unit.suite : 'N/A'),
      },
      {
        Header: 'Document',
        accessor: (rowData: any) => rowData.name,
      },
      {
        Header: 'Purchaser(s)',
        accessor: (rowData: any) => {
          if (rowData.deal) {
            return rowData.deal.purchasers.map((purchaser: IPurchaserInfo, typeIndex: number) => {
              return (
                <Box sx={{ mt: 1 }} key={typeIndex}>
                  <Box>{purchaser.fullName}</Box>
                </Box>
              );
            });
          } else return 'N/A';
        },
      },
      {
        Header: 'Realtor',
        accessor: (rowData: any) => {
          if (rowData.deal) {
            return rowData.deal.realtor.length ? rowData.deal.realtor[0].fullName : 'Public';
          } else return 'N/A';
        },
      },
      {
        Header: 'Created At',
        accessor: (rowData: any) => {
          if (rowData.deal) {
            return convertAllDates(rowData.deal.createdAt, 'PPpp');
          } else return 'N/A';
        },
      },
      {
        Header: 'Sign',
        accessor: (rowData: any) => (
          <Button variant="contained" color="primary" onClick={() => handleSignModal(rowData)}>
            Sign
          </Button>
        ),
      },
    ];
  }, []);

  const handleChange = (e: any, value: any) => {
    setDocumentType(value);
  };
  const handleSignModal = (document: IDocuments) => {
    setPurchasers([]);
    setSelectedDocument(document);
    getSigners({ variables: { _id: document?._id } });
    storeDispatch(handleModal(true));
  };

  const signDeal = (e: any) => {
    e.preventDefault();
    if (password !== `${project.name.slice(-4)}`) {
      return storeDispatch(showErrorSnackbar('Incorrect Password'));
    }

    let selectedPurchaser = purchasers.some((purchaser: any) => purchaser.checked);

    if (!selectedPurchaser) return storeDispatch(showErrorSnackbar('No Purchaser Selected'));

    if (selectedDocument && selectedDocument.deal && witness) {
      setSignLoading(true);
      signInPerson({
        variables: {
          project: project._id,
          deal: selectedDocument.deal._id,
          document: selectedDocument._id,
          purchasers: purchasers,
          witness: witness,
        },
      });
    }
  };

  const handlePurchasers = (e: any, index: number) => {
    let selectedPurchasers = purchasers.map((purchaser: any, numIndex: number) => {
      if (index === numIndex) {
        return {
          ...purchaser,
          checked: purchaser.checked ? false : true,
        };
      } else return purchaser;
    });

    setPurchasers(selectedPurchasers);
  };

  return signLoading ? (
    <LoadingWrapper title="Redirecting to Docusign..." modal={true} />
  ) : (
    <Box sx={{ px: 2, py: 1 }}>
      <GlobalModal>
        {getSignersLoading ? (
          <LoadingWrapper title="Loading..." modal={false} />
        ) : (
          <Box>
            <Typography variant={'h5'}>
              You are about to sign <strong>{selectedDocument?.deal.unit.suite}</strong>
            </Typography>
            <Box sx={{ mt: 2 }}>
              <TextField
                type="text"
                required
                name="witness"
                label="Witness Full Name"
                placeholder="Witness Full Name"
                value={witness}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setWitness(e.target.value)}
              />
            </Box>
            {purchasers.length ? (
              <Box>
                <p>Please select the purchasers that are signing in person.</p>
                {purchasers.map((purchaser: any, index: number) => {
                  return (
                    <Flex key={index}>
                      {purchaser.status === 'sent' || purchaser.status === 'delivered' ? (
                        <Box>
                          <Checkbox checked={purchaser.checked} onChange={(e) => handlePurchasers(e, index)} />
                        </Box>
                      ) : null}
                      <Box sx={{ ml: purchaser.status === 'sent' || purchaser.status === 'delivered' ? 0 : '42px', alignSelf: 'center' }}>
                        <strong>
                          {purchaser.name} {purchaser.status === 'completed' ? '- Signed' : null}
                        </strong>
                      </Box>
                    </Flex>
                  );
                })}
              </Box>
            ) : null}
            <p>Please enter the password to proceed</p>
            <form onSubmit={signDeal}>
              <Box sx={{ mt: 2 }}>
                <TextField
                  type="password"
                  required
                  name="password"
                  label="Password"
                  placeholder="Please enter the password"
                  value={password}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                />
              </Box>
              <FlexBetween sx={{ mt: 2 }}>
                <Button onClick={() => storeDispatch(handleModal(false))} variant="contained" color="info">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="success">
                  Sign
                </Button>
              </FlexBetween>
            </form>
          </Box>
        )}
      </GlobalModal>
      <Typography component={'div'} variant={'h5'} gutterBottom>
        <strong>Documents To Sign</strong>
      </Typography>
      <AppBar sx={{ mb: 2 }} position="static">
        <Tabs value={documentType} onChange={handleChange} aria-label="simple tabs example">
          <Tab value="APS" label="APS" />
          <Tab value="Amendments" label="Amendments" />
        </Tabs>
      </AppBar>
      <Box>
        <StandardTable columns={columns} data={documents} />
      </Box>
      <Box sx={{ textAlign: 'center', mt: 1 }}>
        <Button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>
          {'<'}
        </Button>
        <span>{pageNumber}</span>
        <Button disabled={pageNumber === Math.ceil(count / 15)} onClick={() => setPageNumber(pageNumber + 1)}>
          {'>'}
        </Button>
      </Box>
    </Box>
  );
};

const GETDOCUMENTS = gql`
  query getDocuments($project: MongoID!, $type: String!, $page: Float!, $perPage: Float!) {
    getDocuments(project: $project, type: $type, page: $page, perPage: $perPage) {
      documents {
        _id
        name
        dsEnvelopeId
        deal {
          _id
          unit {
            _id
            suite
            status
          }
          purchasers {
            fullName
          }
          realtor {
            fullName
          }
          createdAt
        }
      }
      count
    }
  }
`;

const SIGNINPERSON = gql`
  mutation signInPerson($project: MongoID!, $deal: MongoID!, $document: MongoID!, $purchasers: [NewSignerInput], $witness: String!) {
    signInPerson(project: $project, deal: $deal, document: $document, purchasers: $purchasers, witness: $witness)
  }
`;

const GETSIGNERS = gql`
  query documentById($_id: MongoID!) {
    documentById(_id: $_id) {
      _id
      signers {
        name
        type
        status
      }
    }
  }
`;

export default Deals;
