import { useState, useMemo } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Box, Card, CardContent, Typography, Skeleton, Paper, Tooltip, TextField } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import ArchiveIcon from '@mui/icons-material/Archive';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { useSelector } from 'react-redux';

import { selectProject } from '../../features/project/projectSlice';
import CustomDialog from '../common/CustomDialog';
import { Flex, FlexBetween } from '../../commonStyles';
import "pdfjs-dist/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const PdfCard = (props: ChildProps) => {
  const {
    id,
    title,
    handleDelete,
    download,
    file,
    index,
    downloadIcon,
    handleMoveImage,
    handleCopy,
    handleEdit,
    handleArchive,
    archiveIcon,
  } = props;
  const project = useSelector(selectProject);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isPdf, setIsPdf] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<string>('');
  const [name, setName] = useState<string>('');

  // sendUrl with Promise React-PDF
  const opt = useMemo(() => {
    return {
      cMapUrl: '/bcmaps/',
      cMapPacked: true,
    };
  }, []);

  const handleDialogOpen = (type: string) => {
    setDialogType(type);
    setDialogOpen(true);
  };

  const handleCloseSuccess = () => {
    if (dialogType === 'delete') {
      handleDelete(id!, title, index);
    } else if (dialogType === 'move') {
      handleMoveImage(id!, title, index);
    } else if (dialogType === 'copy') {
      handleCopy(id!, title, index);
    } else if (dialogType === 'archive') {
      handleArchive(id!, title, index);
    } else if (dialogType === 'edit') {
      handleEdit(name, id!, index);
      setName('');
    }
    setDialogOpen(false);
  };

  const dialogContent = () => {
    if (dialogType === 'delete') {
      return (
        <Box sx={{ mb: 2 }}>
          Are you sure you would like to delete <strong>{title}</strong>?
        </Box>
      );
    } else if (dialogType === 'move') {
      return (
        <Box sx={{ mb: 2 }}>
          Are you sure you would like to move <strong>{title}</strong> to Miscellaneous?
        </Box>
      );
    } else if (dialogType === 'copy') {
      return (
        <Box sx={{ mb: 2 }}>
          Are you sure you would like to copy <strong>{title}</strong>?
        </Box>
      );
    } else if (dialogType === 'archive') {
      return (
        <Box sx={{ mb: 2 }}>
          Are you sure you would like to archive <strong>{title}</strong>?
        </Box>
      );
    } else if (dialogType === 'edit') {
      return (
        <Box sx={{ my: 2 }}>
          <Box sx={{ mb: 2 }}>You will not need to include the extension below. ie. .pdf, .jpg</Box>
          <TextField
            title={'Rename File'}
            name={'name'}
            fullWidth
            value={name}
            label={'Rename File'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
          />
        </Box>
      );
    }
  };

  const dialogTitle = () => {
    if (dialogType === 'delete') {
      return 'Delete?';
    } else if (dialogType === 'copy') {
      return 'Copy?';
    } else if (dialogType === 'edit') {
      return `Edit ${title}?`;
    } else if (dialogType === 'archive') {
      return `Archive ${title}?`;
    } else {
      return 'Move to Miscellaneous?';
    }
  };

  const displayImage = () => {
    if (file?.includes('.pdf') || file?.includes('/pdf') || isPdf) {
      return (
        <Document
          key={index}
          style={{ height: '200px' }}
          options={opt}
          loading={
            <Box sx={{ width: '100%', height: '100%' }}>
              <Skeleton variant="rectangular" animation="wave" height={500} width={500} />
            </Box>
          }
          file={file}
        >
          <Page renderTextLayer={false} loading={''} pageNumber={1} />
        </Document>
      );
    } else
      return <img src={file} onError={() => setIsPdf(true)} loading="lazy" alt="fileImage" style={{ maxWidth: '100%', height: '100%' }} />;
  };

  return (
    <div>
      <CustomDialog
        handleClose={() => setDialogOpen(false)}
        handleCloseRemove={() => setDialogOpen(false)}
        handleCloseSuccess={handleCloseSuccess}
        open={dialogOpen}
        removeButton={dialogType === 'edit' ? 'Cancel' : 'No'}
        successButton={dialogType === 'edit' ? 'Rename' : 'Yes'}
        dialogContent={dialogContent()}
        dialogTitle={dialogTitle()}
      />
      <Card
        sx={{
          width: '100%',
          border: '1px solid #000',
          mb: 2,
          position: 'relative',
        }}
      >
        <Paper
          elevation={24}
          sx={{
            height: '246px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& .MuiSvgIcon-root': {
              color: '#fff',
            },
            '& .react-pdf__Page__canvas': {
              width: '100% !important',
            },
            '& .react-pdf__Page': {
              position: 'static !important',
            },
          }}
        >
          <a
            style={{ zIndex: 1 }}
            href={download ? file : `/${project._id}/dashboard/editmerges/${id}`}
            download={download}
            rel="noreferrer"
            target={download ? '_blank' : ''}
          >
            {displayImage()}
          </a>
        </Paper>
        <CardContent
          sx={{
            p: 1.5,
            '&:last-child': {
              pb: 1,
            },
            zIndex: 2,
            backgroundColor: file?.includes('.pdf') || file?.includes('/pdf') ? '#f25963' : '#fff',
            color: file?.includes('.pdf') || file?.includes('/pdf') ? '#fff' : '#000',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <Typography component="h4">
            <FlexBetween>
              <Box sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{title}</Box>
              <Flex>
                {archiveIcon ? (
                  <Tooltip title="Archive">
                    <ArchiveIcon onClick={() => handleDialogOpen('archive')} sx={{ cursor: 'pointer', mr: 1 }} />
                  </Tooltip>
                ) : null}
                {downloadIcon ? (
                  <a rel="noreferrer" target="_blank" style={{ textDecoration: 'none', color: '#fff' }} href={file}>
                    <Tooltip title="Download">
                      <DownloadIcon sx={{ cursor: 'pointer', mr: 1 }} />
                    </Tooltip>
                  </a>
                ) : null}
                {handleMoveImage && !file?.includes('base64') ? (
                  <Tooltip title="Move to Miscellaneous">
                    <MoveUpIcon sx={{ mr: 1, cursor: 'pointer' }} onClick={() => handleDialogOpen('move')} />
                  </Tooltip>
                ) : null}
                {handleCopy ? (
                  <Tooltip title="Copy">
                    <ContentCopyIcon sx={{ cursor: 'pointer', mr: 1 }} onClick={() => handleDialogOpen('copy')} />
                  </Tooltip>
                ) : null}
                {handleEdit ? (
                  <Tooltip title="Rename">
                    <DriveFileRenameOutlineIcon sx={{ cursor: 'pointer', mr: 1 }} onClick={() => handleDialogOpen('edit')} />
                  </Tooltip>
                ) : null}
                <Tooltip title="Delete">
                  <DeleteForeverIcon sx={{ cursor: 'pointer' }} onClick={() => handleDialogOpen('delete')} />
                </Tooltip>
              </Flex>
            </FlexBetween>
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

interface ChildProps {
  id?: string | number;
  title: string;
  file?: string;
  handleImage?: any;
  handleMoveImage?: any;
  handleCopy?: any;
  handleDelete: any;
  handleArchive?: any;
  downloadIcon?: boolean;
  archiveIcon?: boolean;
  mergeIcon?: boolean;
  download: boolean;
  index?: number;
  handleEdit?: any;
}

export default PdfCard;
