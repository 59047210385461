import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { Box, Button, Typography } from '@mui/material';
import { IThirdParty } from '../../../types/CreateDealForm';
import { UnitContext } from '../../../context/UnitContext';
import { selectProject } from '../../../features/project/projectSlice';
import { selectUser } from '../../../features/auth/authSlice';
import { useAppDispatch } from '../../../app/hooks';
import { showSuccessSnackbar, showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';
import CustomDialog from '../../common/CustomDialog';
import { accessAllowed } from '../../../features/project/projectHooks';
import ThirdPartyForm from './ThirdPartyForm';
import { FlexEnd } from '../../../commonStyles';

const ThirdParty = (props: ChildProps) => {
  const { errors, updateDealThirdParty } = props;
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const { filteredDeal, setFilteredDeal } = useContext(UnitContext);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<string>('');
  const [selected, setSelected] = useState<any>(null);

  const [deleteThirdParty] = useMutation(DELETETHIRDPARTY, {
    onCompleted: (data) => {
      let removeThirdParty = filteredDeal.thirdParty.filter(
        (thirdParty: IThirdParty) => thirdParty._id !== data.thirdPartyRemoveById.record._id
      );
      let removeThirdPartyId = removeThirdParty.map((thirdParty: IThirdParty) => thirdParty._id);
      updateDealThirdParty({ variables: { _id: filteredDeal._id, record: { thirdParty: removeThirdPartyId } } });
      storeDispatch(showSuccessSnackbar('Third Party Info has been deleted'));
      setFilteredDeal({
        ...filteredDeal,
        thirdParty: removeThirdParty,
      });
      setDialogOpen(false);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [createThirdParty] = useMutation(CREATETHIRDPARTY, {
    onCompleted: (data) => {
      let thirdPartyIds = filteredDeal.thirdParty
        .filter((thirdParty: IThirdParty) => {
          if (thirdParty._id) {
            return thirdParty;
          }
        })
        .map((thirdParty: IThirdParty) => thirdParty._id);
      updateDealThirdParty({
        variables: { _id: filteredDeal._id, record: { thirdParty: [...thirdPartyIds, data.thirdPartyCreateOne.record._id] } },
      });
      storeDispatch(showSuccessSnackbar('Third Party Info has been uploaded'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateThirdParty] = useMutation(UPDATETHIRDPARTY, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Third Party Info has been saved!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleThirdPartyInput = async (event: any, type: number) => {
    if (event && event.target.name) {
      let thirdPartyArray = await filteredDeal?.thirdParty.map((thirdParty: IThirdParty, index: number) => {
        if (index === type) {
          return { ...thirdParty, [event.target.name]: event.target.value };
        } else {
          return thirdParty;
        }
      });
      setFilteredDeal({ ...filteredDeal, thirdParty: thirdPartyArray });
    }
  };

  const handleThirdPartySubmit = (e: any) => {
    e.preventDefault();
    if (errors.date) {
      storeDispatch(showErrorSnackbar('Invalid Date'));
      return;
    }

    filteredDeal.thirdParty.forEach((thirdParty: IThirdParty) => {
      if (thirdParty._id) {
        let { _id, ...newObject } = thirdParty;
        updateThirdParty({ variables: { _id: _id, record: newObject } });
      } else {
        createThirdParty({ variables: { record: thirdParty } });
      }
    });
  };

  const handleDateChange = (date: any, type: number) => {
    let datePickerArray = filteredDeal.thirdParty.map((thirdParty: IThirdParty, index: number) => {
      if (index === type) {
        return { ...thirdParty, dob: date };
      } else {
        return thirdParty;
      }
    });
    setFilteredDeal({ ...filteredDeal, thirdParty: datePickerArray });
  };

  const addThirdParty = () => {
    setFilteredDeal({
      ...filteredDeal,
      thirdParty: [
        ...filteredDeal.thirdParty,
        {
          deal: filteredDeal._id,
          project: filteredDeal.project._id,
          fullName: '',
          streetAddress: '',
          dob: new Date(),
          primaryPhone: '',
          email: '',
          occupation: '',
          corpNumber: '',
          relationship: '',
        },
      ],
    });
  };

  const openDialog = async (type: string, selected: any = null, numIndex: number) => {
    if (type === 'deleteThirdParty') {
      if (selected && selected._id) {
        setSelected(selected);
      } else {
        let removeThirdParty = filteredDeal.thirdParty.filter((thirdParty: IThirdParty, index: number) => numIndex !== index);
        setFilteredDeal({
          ...filteredDeal,
          thirdParty: removeThirdParty,
        });
        return;
      }
    }
    await setDialogType(type);
    await setDialogOpen(true);
  };

  const handleCloseSuccess = async () => {
    if (dialogType === 'deleteThirdParty') {
      if (selected) {
        deleteThirdParty({ variables: { _id: selected._id } });
      }
    }
  };

  const dialogContent = () => {
    return 'The information cannot be recovered.';
  };

  const dialogBox = () => {
    let removeButton = 'Cancel';
    let successButton = 'Delete Third Party Information';
    let title = 'Are you sure you would like to delete this third party information?';
    return (
      <CustomDialog
        handleClose={() => setDialogOpen(false)}
        handleCloseRemove={() => setDialogOpen(false)}
        handleCloseSuccess={handleCloseSuccess}
        open={dialogOpen}
        removeButton={removeButton}
        successButton={successButton}
        dialogContent={dialogContent()}
        dialogTitle={title}
      />
    );
  };

  return (
    <div>
      {dialogBox()}
      <Typography variant="h2" sx={{ mb: 1 }}>
        <strong>Third Party Information</strong>
      </Typography>
      <form onSubmit={handleThirdPartySubmit} autoComplete="off">
        {filteredDeal.thirdParty.length > 0 ? (
          <div>
            <p>You can upload an image of the third party document in Miscellaneous below.</p>
            {filteredDeal.thirdParty.map((thirdParty: IThirdParty, index: number) => {
              return (
                <div>
                  <ThirdPartyForm
                    key={index}
                    thirdParty={thirdParty}
                    handleTextInput={handleThirdPartyInput}
                    handleDateChange={handleDateChange}
                    error={errors}
                    type={index}
                  />
                  <FlexEnd>
                    <Button
                      onClick={() => openDialog('deleteThirdParty', thirdParty, index)}
                      name={'update'}
                      color="error"
                      variant="contained"
                      sx={{ mt: 1 }}
                    >
                      Delete Third Party Info
                    </Button>
                  </FlexEnd>
                </div>
              );
            })}
          </div>
        ) : (
          <Box sx={{ my: 1 }}>
            <em>There are no third parties for this deal.</em>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mt: 2
          }}
        >
          {accessAllowed(user, project._id, 'editDeals') && filteredDeal.thirdParty.length > 0 ? (
            <Button sx={{ mr: 1 }} type={'submit'} name={'update'} color="success" variant="contained">
              Save Third Party Info
            </Button>
          ) : null}
          <Button onClick={() => addThirdParty()} name={'update'} color="primary" variant="contained">
            Add Third Party Information
          </Button>
        </Box>
      </form>
    </div>
  );
};

interface ChildProps {
  errors: any;
  updateDealThirdParty: any;
}

const CREATETHIRDPARTY = gql`
  mutation thirdPartyCreateOne($record: CreateOneThirdPartyInput!) {
    thirdPartyCreateOne(record: $record) {
      record {
        _id
        fullName
        streetAddress
        dob
        primaryPhone
        email
        occupation
        corpNumber
        relationship
      }
    }
  }
`;

const UPDATETHIRDPARTY = gql`
  mutation thirdPartyUpdateById($_id: MongoID!, $record: UpdateByIdThirdPartyInput!) {
    thirdPartyUpdateById(_id: $_id, record: $record) {
      record {
        _id
        streetAddress
      }
    }
  }
`;

const DELETETHIRDPARTY = gql`
  mutation thirdPartyRemoveById($_id: MongoID!) {
    thirdPartyRemoveById(_id: $_id) {
      record {
        _id
      }
    }
  }
`;

export default ThirdParty;
