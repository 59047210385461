import { useState } from 'react';
import { Buffer } from 'buffer';
import { List, ListItem, Divider, ListItemText, ListItemSecondaryAction, Button } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { IDeal, IDealArray } from '../../types/CreateDealForm';
import LoadingWrapper from '../common/LoadingWrapper';
import { IProject } from '../../types/project';
import { combinePdfs, download, downloadDocuments } from '../../utils/Functions';
import { IDocuments } from '../../types/unit';

const Documents = (props: ChildProps) => {
  const { project } = props;
  const [deals, setDeals] = useState<IDeal[]>([]);
  const [downloading, setDownloading] = useState<boolean>(false);

  const { loading } = useQuery<IDealArray>(GETDEALS, {
    variables: {
      filter: {
        AND: [{ cancelled: { dateCancelled: null } }, { draft: false }, { rescission: { dateRescinded: null } }],
        project: project._id,
      },
    },
    onCompleted: (data) => {
      let firmedDeals = data.dealMany
        .filter((deal: IDeal) => deal.unit.status === 'F')
        .sort((a, b) => {
          let suiteNameA = a.unit.suite.toLowerCase();
          let suiteNameB = b.unit.suite.toLowerCase();
          if (suiteNameA < suiteNameB) return -1;
          if (suiteNameA > suiteNameB) return 1;
          return 0;
        });
      setDeals(firmedDeals);
    },
  });

  const { refetch: getDocument } = useQuery(GETPREVIEWDATA, {
    skip: true,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const generateAllDocuments = async () => {
    setDownloading(true);
    let documents = await Promise.all(
      deals.map(async (deal: IDeal) => {
        if (deal.documents.length > 0) {
          let completedDocuments = await deal.documents.filter((document: IDocuments) => document.status === 'Completed');
          return {
            pdf: await Promise.all(
              completedDocuments.map(async (document: IDocuments) => {
                return getDocument({ _id: document._id }).then((resp) => {
                  const binaryData = Buffer.from(resp.data.documentById.previewData, 'binary');
                  const blob = new Blob([binaryData], { type: 'application/pdf' });
                  return blob;
                });
              })
            ),
            suite: deal.unit.suite,
          };
        } else return null;
      })
    );
    let filteredDocuments = documents.filter((document: any) => document !== null);
    if (filteredDocuments.length > 0) {
      let suitePdf = await Promise.all(
        filteredDocuments.map(async (document: any) => {
          let mergedPdf = await combinePdfs(document.pdf);
          return {
            pdf: mergedPdf,
            suite: document.suite,
          };
        })
      );
      await downloadDocuments(suitePdf, `${project.name} - ALL`);
    }
    setDownloading(false);
  };

  const generateAllCoops = async () => {
    setDownloading(true);
    let documents = await Promise.all(
      deals.map(async (deal: IDeal) => {
        if (deal.documents.length > 0) {
          let coop = await deal.documents.find((document: IDocuments) => document.type === 'Coop' && document.status === 'Completed');
          if (coop) {
            return {
              pdf: await getDocument({ _id: coop._id }).then((resp) => {
                const binaryData = Buffer.from(resp.data.documentById.previewData, 'binary');
                const blob = new Blob([binaryData], { type: 'application/pdf' });
                return blob;
              }),
              suite: deal.unit.suite,
            };
          } else return null;
        } else return null;
      })
    );
    let filteredDocuments = documents.filter((document: any) => document !== null);
    if (filteredDocuments.length > 0) {
      await downloadDocuments(filteredDocuments, `${project.name} - ALL COOPS`);
    }
    setDownloading(false);
  };

  const generateDocuments = async (deal: IDeal) => {
    setDownloading(true);
    let documents = await Promise.all(
      deal.documents.map(async (document: IDocuments) => {
        if (document.status === 'Completed') {
          return getDocument({ _id: document._id }).then((resp) => {
            const binaryData = Buffer.from(resp.data.documentById.previewData, 'binary');
            const blob = new Blob([binaryData], { type: 'application/pdf' });
            return blob;
          });
        } else return null;
      })
    );

    let filteredDocuments = await documents.filter((document: any) => document !== null);

    if (documents.length > 0) {
      let mergedPdf = await combinePdfs(filteredDocuments);
      download(mergedPdf, `${project.name} - ${deal.unit.suite}`, 'application/pdf');
    }
    setDownloading(false);
  };

  const generateCoop = async (deal: IDeal) => {
    setDownloading(true);
    let document = await deal.documents.find((document: IDocuments) => document.type === 'Coop' && document.status === 'Completed');
    getDocument({ _id: document._id }).then((resp) => {
      const binaryData = Buffer.from(resp.data.documentById.previewData, 'binary');
      const blob = new Blob([binaryData], { type: 'application/pdf' });
      download(blob, `${project.name} - ${deal.unit.suite} - Coop`, 'application/pdf');
    });
    setDownloading(false);
  };

  return (
    <div>
      {loading ? (
        <LoadingWrapper title="Deals are loading..." modal={false} />
      ) : downloading ? (
        <LoadingWrapper title="Downloading Files...This may take a while, please use this time to go to the washroom" modal={false} />
      ) : (
        <div>
          {deals.length > 0 ? (
            <div>
              <Button sx={{ mt: 1, mr: 1, mb: 1 }} onClick={() => generateAllDocuments()} variant="contained" color="secondary">
                Download All APS and Amendments
              </Button>
              <Button sx={{ mt: 1, mr: 1, mb: 1 }} onClick={() => generateAllCoops()} variant="contained" color="secondary">
                Download All Coops
              </Button>
            </div>
          ) : null}
          <List component="nav" aria-label="mergeTemplates">
            {deals.length > 0 ? (
              deals.map((deal: IDeal, index: number) => {
                return (
                  <div key={index}>
                    <ListItem>
                      <ListItemText primary={`${deal.unit.suite}`} />
                      <ListItemSecondaryAction>
                        {deal.documents.find((document: IDocuments) => document.type === 'Coop' && document.status === 'Completed') ? (
                          <Button onClick={() => generateCoop(deal)} sx={{ mr: 1 }} variant="contained" color="secondary">
                            Download Coop
                          </Button>
                        ) : null}
                        <Button onClick={() => generateDocuments(deal)} sx={{ mr: 1 }} variant="contained" color="secondary">
                          Download Documents
                        </Button>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </div>
                );
              })
            ) : (
              <div>There are no firmed deals for this project</div>
            )}
          </List>
        </div>
      )}
    </div>
  );
};

interface ChildProps {
  project: IProject;
}

const GETDEALS = gql`
  query dealMany($filter: FilterFindManyDealInput) {
    dealMany(filter: $filter, limit: 1000) {
      _id
      unit {
        _id
        suite
        status
      }
      project {
        _id
      }
      documents {
        _id
        dsEnvelopeId
        isAPS
        type
        name
        status
      }
    }
  }
`;

const GETPREVIEWDATA = gql`
  query documentById($_id: MongoID!) {
    documentById(_id: $_id) {
      _id
      previewData
    }
  }
`;

export default Documents;
