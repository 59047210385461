/* Dependency Imports */
import { Fragment, useState } from 'react';
import {
  Autocomplete,
  Box,
  ClickAwayListener,
  Divider,
  Paper,
  Popper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  AccountCircle,
  ArrowDropDown,
  BorderColor,
  Code,
  DataObject,
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatClear,
  FormatColorText,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatQuote,
  FormatStrikethrough,
  FormatUnderlined,
  HorizontalRule,
  KeyboardReturn,
  Link,
  Redo,
  Undo,
  FormatIndentIncrease,
  FormatIndentDecrease,
} from '@mui/icons-material';
import TableChartIcon from '@mui/icons-material/TableChart';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import ConstructionIcon from '@mui/icons-material/Construction';
import { Editor } from '@tiptap/react';
import { CompactPicker, RGBColor } from 'react-color';

/* Project Imports */
import { SourceDialog, LinkDialog } from './MenuDialogs';

const MenuBar = ({ editor, imgQuery }: { editor: Editor | null; imgQuery: any }) => {
  const [showSource, setShowSource] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [linkType, setLinktype] = useState('');
  const [tokenAnchor, setTokenAnchor] = useState<null | HTMLElement>(null);
  const [colorAnchor, setColorAnchor] = useState<null | HTMLElement>(null);
  const [highlightAnchor, setHighlightAnchor] = useState<null | HTMLElement>(null);

  const [fontColor, setFontColor] = useState<RGBColor>({ r: 244, g: 78, b: 59 } as RGBColor);
  const [highlight, setHighlight] = useState<RGBColor>({ r: 252, g: 220, b: 0 } as RGBColor);

  const handleShowLink = (type: string) => {
    setShowLink(true);
    setLinktype(type);
  };

  if (!editor) {
    return null;
  }

  const fonts = [
    'Arial',
    'Brush Script MT',
    'Courier New',
    'Garamond',
    'Georgia',
    'Helvetica',
    'Tahoma',
    'Times New Roman',
    'Trebuchet MS',
    'Verdana',
  ];

  const fontSizes = [
    '8pt',
    '9pt',
    '10pt',
    '11pt',
    '12pt',
    '14pt',
    '16pt',
    '18pt',
    '20pt',
    '22pt',
    '24pt',
    '26pt',
    '28pt',
    '36pt',
    '48pt',
    '72pt',
  ];

  const styles = ['Paragraph', 'Heading 1', 'Heading 2', 'Heading 3', 'Heading 4', 'Heading 5', 'Heading 6'];

  const paragraphSpacings = ['1', '1.5', '2', '2.5'];

  const menu: IMenu[] = [
    {
      ariaLabel: 'history',
      items: [
        {
          title: 'Undo (Ctrl + Z)',
          ariaLabel: 'undo',
          onClick: () => editor.chain().focus().undo().run(),
          item: <Undo />,
        },
        {
          title: 'Redo (Ctrl + Y)',
          ariaLabel: 'redo',
          onClick: () => editor.chain().focus().redo().run(),
          item: <Redo />,
        },
      ],
    },
    {
      ariaLabel: 'font',
      items: [
        {
          ariaLabel: 'font',
          item: (
            <Autocomplete
              disableClearable
              size="small"
              options={[''].concat(fonts)}
              filterOptions={(options, state) => options.slice(1)}
              onChange={(event: any, newValue: string | null) =>
                editor
                  .chain()
                  .focus()
                  .setFontFamily(newValue || '')
                  .run()
              }
              value={editor.getAttributes('textStyle').fontFamily || ''}
              sx={{ width: 200, my: 'auto', mx: 1, py: '4px' }}
              renderOption={(props, option) => (
                <span {...props} style={{ fontFamily: option }}>
                  {option}
                </span>
              )}
              renderInput={(params) => (
                <Tooltip title="Font" placement="top">
                  <TextField variant="standard" {...params} />
                </Tooltip>
              )}
            />
          ),
        },
        {
          ariaLabel: 'font size',
          item: (
            <Autocomplete
              disableClearable
              size="small"
              options={[''].concat(fontSizes)}
              filterOptions={(options, state) => options.slice(1)}
              getOptionLabel={(option) => option.slice(0, -2)}
              onChange={(event: any, newValue: string | null) => {
                editor
                  .chain()
                  .focus()
                  .setFontSize(newValue || '')
                  .run();
              }}
              value={editor.getAttributes('textStyle').fontSize || ''}
              sx={{ width: 60, my: 'auto', mx: 1, py: '4px' }}
              renderInput={(params) => (
                <Tooltip title="Font Size" placement="top">
                  <TextField variant="standard" {...params} />
                </Tooltip>
              )}
            />
          ),
        },
      ],
    },
    {
      ariaLabel: 'text format',
      items: [
        {
          title: 'Bold (Ctrl + B)',
          ariaLabel: 'bold',
          onClick: () => editor.chain().focus().toggleBold().run(),
          selected: editor.isActive('bold'),
          item: <FormatBold />,
        },
        {
          title: 'Italic (Ctrl + I)',
          ariaLabel: 'italic',
          onClick: () => editor.chain().focus().toggleItalic().run(),
          selected: editor.isActive('italic'),
          item: <FormatItalic />,
        },
        {
          title: 'Underline (Ctrl + U)',
          ariaLabel: 'underline',
          onClick: () => editor.chain().focus().toggleUnderline().run(),
          selected: editor.isActive('underline'),
          item: <FormatUnderlined />,
        },
        {
          ariaLabel: 'font color',
          item: (
            <StyledToggleButtonGroup sx={{ '& .MuiToggleButtonGroup-grouped': { margin: 0 }, ml: '-4px' }}>
              <ToggleButton
                value=""
                sx={{ p: '7px', pr: 0.5 }}
                onClick={() => editor.chain().focus().setColor(`rgb(${fontColor.r}, ${fontColor.g}, ${fontColor.b})`).run()}
              >
                <FormatColorText sx={{ color: `rgb(${fontColor.r}, ${fontColor.g}, ${fontColor.b})` }} />
              </ToggleButton>
              <ToggleButton
                value=""
                sx={{ p: '7px', px: 0 }}
                onClick={(event: React.MouseEvent<HTMLElement>) => setColorAnchor(colorAnchor ? null : event.currentTarget)}
                selected={Boolean(colorAnchor)}
              >
                <ArrowDropDown />
              </ToggleButton>
            </StyledToggleButtonGroup>
          ),
        },
        {
          ariaLabel: 'highlight color',
          item: (
            <StyledToggleButtonGroup sx={{ '& .MuiToggleButtonGroup-grouped': { margin: 0 } }}>
              <ToggleButton
                value=""
                sx={{ p: '7px', pr: 0.5 }}
                onClick={() =>
                  editor
                    .chain()
                    .focus()
                    .setHighlight({ color: `rgb(${highlight.r}, ${highlight.g}, ${highlight.b})` })
                    .run()
                }
              >
                <BorderColor sx={{ color: `rgb(${highlight.r}, ${highlight.g}, ${highlight.b})` }} />
              </ToggleButton>
              <ToggleButton
                value=""
                sx={{ p: '7px', px: 0 }}
                onClick={(event: React.MouseEvent<HTMLElement>) => setHighlightAnchor(highlightAnchor ? null : event.currentTarget)}
                selected={Boolean(highlightAnchor)}
              >
                <ArrowDropDown />
              </ToggleButton>
            </StyledToggleButtonGroup>
          ),
        },
      ],
    },
    {
      ariaLabel: 'text alignment',
      items: [
        {
          title: 'Align Left (Ctrl + Shift + L)',
          ariaLabel: 'align left',
          onClick: () => editor.chain().focus().setTextAlign('left').run(),
          selected: editor.isActive({ textAlign: 'left' }),
          item: <FormatAlignLeft />,
        },
        {
          title: 'Center (Ctrl + Shift + E)',
          ariaLabel: 'center',
          onClick: () => editor.chain().focus().setTextAlign('center').run(),
          selected: editor.isActive({ textAlign: 'center' }),
          item: <FormatAlignCenter />,
        },
        {
          title: 'Align Right (Ctrl + Shift + R)',
          ariaLabel: 'align right',
          onClick: () => editor.chain().focus().setTextAlign('right').run(),
          selected: editor.isActive({ textAlign: 'right' }),
          item: <FormatAlignRight />,
        },
        {
          title: 'Justify (Ctrl + Shift + J)',
          ariaLabel: 'justify',
          onClick: () => editor.chain().focus().setTextAlign('justify').run(),
          selected: editor.isActive({ textAlign: 'justify' }),
          item: <FormatAlignJustify />,
        },
      ],
    },
    {
      ariaLabel: 'blocks',
      items: [
        {
          ariaLabel: 'blocks',
          item: (
            <Autocomplete
              disableClearable
              size="small"
              options={[''].concat(styles)}
              filterOptions={(options, state) => options.slice(1)}
              onChange={(event: any, newValue: string | null) => {
                if (newValue === 'Paragraph') {
                  editor.chain().focus().setParagraph().run();
                } else {
                  editor
                    .chain()
                    .focus()
                    .toggleHeading({ level: parseInt(newValue!.slice(8)) as any })
                    .run();
                }
              }}
              value={styles[editor.getAttributes('heading').level || 0] || ''}
              sx={{ width: 120, my: 'auto', mx: 1, py: '4px' }}
              renderOption={(props, option) => (
                <Tooltip title={'Ctrl + Alt + ' + styles.indexOf(option)} placement="top" key={option}>
                  <span {...props}>{option}</span>
                </Tooltip>
              )}
              renderInput={(params) => (
                <Tooltip title="Blocks" placement="top">
                  <TextField variant="standard" {...params} />
                </Tooltip>
              )}
            />
          ),
        },
      ],
    },
    {
      ariaLabel: 'lists',
      items: [
        {
          ariaLabel: 'spacing',
          item: (
            <Autocomplete
              disableClearable
              size="small"
              options={[''].concat(paragraphSpacings)}
              onChange={(event: any, newValue: string | null) => {
                if (!newValue) return;
                editor.chain().focus().setLineHeight(parseFloat(newValue)).run();
              }}
              value={editor.getAttributes('paragraph')?.lineHeight?.toString() || ''}
              sx={{ width: 120, my: 'auto', mx: 1, py: '4px' }}
              renderOption={(props, option) => (
                <span key={option} {...props}>
                  {option}
                </span>
              )}
              renderInput={(params) => (
                <Tooltip title="Line Spacing" placement="top">
                  <TextField variant="standard" {...params} />
                </Tooltip>
              )}
            />
          ),
        },
        {
          title: 'Bullets (Ctrl + Shift + 8)',
          ariaLabel: 'bullets',
          onClick: () => editor.chain().focus().toggleBulletList().run(),
          selected: editor.isActive('bulletList'),
          item: <FormatListBulleted />,
        },
        {
          title: 'Numbering (Ctrl + Shift + 7)',
          ariaLabel: 'numbering',
          onClick: () => editor.chain().focus().toggleOrderedList().run(),
          selected: editor.isActive('orderedList'),
          item: <FormatListNumbered />,
        },
      ],
    },
    {
      ariaLabel: 'table',
      items: [
        {
          title: 'Add Table',
          ariaLabel: 'Add Table',
          onClick: () => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run(),
          item: <TableChartIcon sx={{ color: 'green' }} />,
        },
        {
          title: 'Delete Table',
          ariaLabel: 'Delete Table',
          onClick: () => editor.chain().focus().deleteTable().run(),
          item: <TableChartIcon sx={{ color: 'red' }} />,
        },
        {
          title: 'Add Row',
          ariaLabel: 'Add Row',
          onClick: () => editor.chain().focus().addRowAfter().run(),
          item: <TableRowsIcon sx={{ color: 'green' }} />,
        },
        {
          title: 'Delete Row',
          ariaLabel: 'Add Row',
          onClick: () => editor.chain().focus().deleteRow().run(),
          item: <TableRowsIcon sx={{ color: 'red' }} />,
        },
        {
          title: 'Add Column',
          ariaLabel: 'Add Column',
          onClick: () => editor.chain().focus().addColumnAfter().run(),
          item: <ViewColumnIcon sx={{ color: 'green' }} />,
        },
        {
          title: 'Delete Column',
          ariaLabel: 'Add Column',
          onClick: () => editor.chain().focus().deleteColumn().run(),
          item: <ViewColumnIcon sx={{ color: 'red' }} />,
        },
        {
          title: 'Fix Tables',
          ariaLabel: 'Fix Tables',
          onClick: () => editor.chain().focus().fixTables().run(),
          item: <ConstructionIcon />,
        },
      ],
    },
    {
      ariaLabel: 'media',
      items: [
        {
          title: 'Link',
          ariaLabel: 'link',
          onClick: () => handleShowLink('text'),
          selected: linkType === 'text' && showLink ? true : false,
          item: <Link />,
        },
      ],
    },
    {
      ariaLabel: 'other format',
      items: [
        {
          title: 'Indent Text Selection',
          ariaLabel: 'indent',
          onClick: () => editor.chain().focus().indent().run(),
          item: <FormatIndentIncrease />,
        },
        {
          title: 'Outdent Text Selection',
          ariaLabel: 'outdent',
          onClick: () => editor.chain().focus().outdent().run(),
          item: <FormatIndentDecrease />,
        },
        {
          title: 'Strikethrough (Ctrl + Shift + X)',
          ariaLabel: 'strikethrough',
          onClick: () => editor.chain().focus().toggleStrike().run(),
          selected: editor.isActive('strike'),
          item: <FormatStrikethrough />,
        },
        {
          title: 'Blockquote (Ctrl + Shift + B)',
          ariaLabel: 'blockquote',
          onClick: () => editor.chain().focus().toggleBlockquote().run(),
          selected: editor.isActive('blockquote'),
          item: <FormatQuote />,
        },
        {
          title: 'Code (Ctrl + E)',
          ariaLabel: 'code',
          onClick: () => editor.chain().focus().toggleCode().run(),
          selected: editor.isActive('code'),
          item: <Code />,
        },
        {
          title: 'Clear Fomatting',
          ariaLabel: 'clear formatting',
          onClick: () => editor.chain().focus().unsetAllMarks().clearNodes().run(),
          item: <FormatClear />,
        },
      ],
    },
    {
      ariaLabel: 'insert',
      items: [
        {
          title: 'Horizontal Rule',
          ariaLabel: 'horizontal rule',
          onClick: () => editor.chain().focus().setHorizontalRule().run(),
          item: <HorizontalRule />,
        },
        {
          title: 'Line Break (Shift + Enter)',
          ariaLabel: 'line break',
          onClick: () => editor.chain().focus().setHardBreak().run(),
          item: <KeyboardReturn />,
        },
        {
          title: 'Token',
          ariaLabel: 'token',
          onClick: (event: React.MouseEvent<HTMLElement>) => setTokenAnchor(tokenAnchor ? null : event.currentTarget),
          item: <AccountCircle />,
        },
      ],
    },
    {
      ariaLabel: 'source code',
      items: [
        {
          title: 'Source Code',
          ariaLabel: 'source code',
          onClick: () => setShowSource(true),
          item: <DataObject />,
        },
      ],
    },
  ];

  const getMenuFormat = (menu: IMenu[]) => {
    return (
      <>
        {menu.map((group, i) => (
          <Fragment key={i}>
            {i === 0 ? <></> : <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />}
            <StyledToggleButtonGroup key={i} size="small" aria-label={group.ariaLabel}>
              {group.items.map((item, j) =>
                item.title ? (
                  <Tooltip title={item.title} placement="top" key={j}>
                    <ToggleButton value={item.ariaLabel} aria-label={item.ariaLabel} onClick={item.onClick} selected={item.selected}>
                      {item.item}
                    </ToggleButton>
                  </Tooltip>
                ) : (
                  <Box key={j}>{item.item}</Box>
                )
              )}
            </StyledToggleButtonGroup>
          </Fragment>
        ))}
      </>
    );
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          top: 0,
          display: 'flex',
          border: (theme) => `1px solid ${theme.palette.divider}`,
          flexWrap: 'wrap',
        }}
      >
        {getMenuFormat(menu)}
        {showLink && linkType && <LinkDialog type={linkType} editor={editor} setOpen={setShowLink} imgQuery={imgQuery} />}
        {showSource && <SourceDialog editor={editor} setOpen={setShowSource} />}
        {colorAnchor && (
          <ClickAwayListener onClickAway={() => setColorAnchor(null)}>
            <Popper
              open={Boolean(colorAnchor)}
              anchorEl={colorAnchor}
              placement="bottom-start"
              modifiers={[
                {
                  name: 'flip',
                  enabled: true,
                },
                {
                  name: 'preventOverflow',
                  enabled: true,
                },
              ]}
            >
              <CompactPicker
                color={fontColor}
                onChange={(color) => {
                  setFontColor(color.rgb);
                }}
              />
            </Popper>
          </ClickAwayListener>
        )}
        {highlightAnchor && (
          <ClickAwayListener onClickAway={() => setHighlightAnchor(null)}>
            <Popper
              open={Boolean(highlightAnchor)}
              anchorEl={highlightAnchor}
              placement="bottom-start"
              modifiers={[
                {
                  name: 'flip',
                  enabled: true,
                },
                {
                  name: 'preventOverflow',
                  enabled: true,
                },
              ]}
            >
              <CompactPicker
                color={highlight}
                onChange={(color) => {
                  setHighlight(color.rgb);
                }}
              />
            </Popper>
          </ClickAwayListener>
        )}
      </Paper>
    </>
  );
};

/* Components */
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

/* Types */
interface IMenu {
  ariaLabel: string;
  items: {
    title?: string;
    ariaLabel: string;
    onClick?: (() => boolean) | (() => void) | ((event: React.MouseEvent<HTMLElement>) => void);
    selected?: boolean;
    item: JSX.Element;
  }[];
}

export default MenuBar;
