import { useState, SetStateAction, Dispatch, useContext } from 'react';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { Box, Checkbox, FormControlLabel, FormControl, FormGroup, Select, InputLabel, MenuItem } from '@mui/material';
import CustomDialog from '../../../common/CustomDialog';
import { useAppDispatch } from '../../../../app/hooks';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../../features/snackbar/snackbarSlice';
import { UnitContext } from '../../../../context/UnitContext';
import { purchaserContent, createPdf } from '../../../../utils/Functions';
import { selectProject } from '../../../../features/project/projectSlice';
import { selectUser } from '../../../../features/auth/authSlice';
import { useCreateActivity } from '../../../../features/activity/activityHooks';
import { IDocuments } from '../../../../types/unit';
import { IPurchaserInfo } from '../../../../types/CreateDealForm';
import { IMerge, IMergeData } from '../../../../types/merge';

const PurchaserDialog = (props: ChildProps) => {
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const storeDispatch = useAppDispatch();
  const createActivity = useCreateActivity();
  const { unit, filteredDeal, setFilteredDeal, setDocuments, originalPurchasers, updateDealDocuments, documents } = useContext(UnitContext);
  const { dialogType, setDialogType, dialogOpen, setDialogOpen, purchaserIndex } = props;

  const [selectedPurchaserAmendment, setSelectedPurchaserAmendment] = useState<string>('');
  const [purchaserMergeTemplates, setPurchaserMergeTemplates] = useState<IMerge[]>([]);

  const [getPurchaserInformationMerges] = useLazyQuery<IMergeData>(MERGETEMPLATES, {
    variables: { filter: { project: project._id, type: 'PurchaserInformation' } },
    onCompleted: (data) => {
      setPurchaserMergeTemplates(data.mergeTemplateMany);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [checked, setChecked] = useState<boolean>(false);

  const [createAmendment] = useMutation(CREATEDOCUMENT, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Amendment has been created'));
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Amendment`,
        content: 'Amendment has been created',
      });
      let documentIds = documents.map((document: IDocuments) => {
        return document._id;
      });
      updateDealDocuments({
        variables: { _id: filteredDeal._id, record: { documents: [...documentIds, data.documentCreateOne._id] } },
      });
      setDialogOpen(false);
      setDialogType('');
      setDocuments([...documents, data.documentCreateOne]);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [deletePurchaser] = useMutation(DELETEPURCHASER, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Purchaser has been removed from the deal'));
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Delete Purchaser`,
        content: `Purchaser has been removed from the deal`,
      });
      setFilteredDeal({
        ...filteredDeal,
        purchasers: data.dealUpdateById.record.purchasers,
      });
      setDialogOpen(false);
      setDialogType('');
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleCloseSuccess = async () => {
    if (dialogType === 'purchaserAmendment') {
      if (!selectedPurchaserAmendment) {
        return storeDispatch(showErrorSnackbar('No Merge Template Found'));
      }

      let purchaserAmendment = purchaserMergeTemplates.find((mergeTemplate: IMerge) => mergeTemplate.name === selectedPurchaserAmendment);

      if (!purchaserAmendment) return storeDispatch(showErrorSnackbar('No Merge Template Found'));

      let pdf = await createPdf(
        purchaserAmendment,
        project,
        unit,
        originalPurchasers,
        filteredDeal.deposit,
        filteredDeal.options,
        null,
        null,
        null,
        null,
        filteredDeal,
        filteredDeal.purchasers
      );

      let sign = purchaserAmendment.signFields.map((sign: any) => {
        return {
          index: sign.index,
          key: sign.key,
          pageNumber: sign.pageNumber,
          x: sign.x,
          y: sign.y,
        };
      });
      const args = {
        status: 'Prepared',
        name: purchaserAmendment.name,
        project: project._id,
        deal: filteredDeal._id,
        signFields: sign,
        // signers,
        type: 'PurchaserInformation',
        isAPS: false,
        mergeTemplate: purchaserAmendment._id,
      };
      await createAmendment({ variables: { record: args, file: pdf } });
    } else if (dialogType === 'deletePurchaser') {
      if (!checked) {
        storeDispatch(showErrorSnackbar(`The checkbox/reason is missing`));
        return;
      } else {
        let removedPurchaser = filteredDeal.purchasers.filter((purchaser: IPurchaserInfo, index: number) => index !== purchaserIndex);
        if (filteredDeal.purchasers[purchaserIndex].hasOwnProperty('_id')) {
          let purchaserIds = removedPurchaser
            .filter((purchaser: IPurchaserInfo) => {
              if (purchaser._id) {
                return purchaser._id;
              }
            })
            .map((purchaser: IPurchaserInfo) => purchaser._id);

          deletePurchaser({ variables: { _id: filteredDeal._id, record: { purchasers: purchaserIds } } });
        } else {
          setFilteredDeal({
            ...filteredDeal,
            purchasers: removedPurchaser,
          });
          setDialogOpen(false);
          setDialogType('');
          setChecked(false);
        }
      }
    }
  };

  const dialogContent = () => {
    if (dialogType === 'purchaserAmendment') {
      return (
        <div>
          <h4 style={{ margin: 0 }}>List of Changes</h4>
          <p>
            <em>
              Please note that this amendment is for changing the information for purchasers. This is not an amendment to add purchaser!
            </em>
          </p>
          {filteredDeal.purchasers.map((purchaser: IPurchaserInfo, index: number) => {
            if (purchaser._id) {
              return (
                <Box sx={{ mt: 2 }}>
                  <div>Purchaser {index + 1}</div>
                  {purchaserContent(purchaser, originalPurchasers[index])}
                </Box>
              );
            } else return null;
          })}
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="purchaser-information-label">Purchaser Information Amendments</InputLabel>
              <Select
                labelId="purchaser-information-label"
                id="purchaser-information-id"
                label="Purchaser Information Amendments"
                onOpen={() => getPurchaserInformationMerges()}
                onChange={(e: any) => setSelectedPurchaserAmendment(e.target.value)}
              >
                {purchaserMergeTemplates.map((mergeTemplate: IMerge, index: number) => {
                  return (
                    <MenuItem value={mergeTemplate.name} key={index}>
                      {mergeTemplate.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </div>
      );
    } else if (dialogType === 'deletePurchaser') {
      return (
        <div>
          <div>
            <strong>
              Please check if you have a sent and received an executed delete purchaser amendment before removing this purchaser.
            </strong>
          </div>
          <FormControl sx={{ mt: 2 }} component="fieldset">
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    required={true}
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label="Yes, I have sent a delete purchaser amendment and received an executed copy back."
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
        </div>
      );
    }
  };

  return (
    <CustomDialog
      handleClose={() => {
        setDialogOpen(false);
        setDialogType('');
      }}
      handleCloseRemove={() => {
        setDialogOpen(false);
        setDialogType('');
      }}
      handleCloseSuccess={handleCloseSuccess}
      open={dialogOpen}
      removeButton={'Cancel'}
      successButton={
        dialogType === 'purchaserAmendment' ? 'Create Purchaser Information Amendment' : 'Yes I would like to remove this purchaser.'
      }
      dialogContent={dialogContent()}
      dialogTitle={
        dialogType === 'purchaserAmendment'
          ? 'Please select a Purchaser Amendment'
          : 'Are you sure you would like to remove this purchaser?'
      }
      maxWidth={'xl'}
    />
  );
};

interface ChildProps {
  dialogOpen: boolean;
  dialogType: string;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
  setDialogType: Dispatch<SetStateAction<string>>;
  purchaserIndex: number;
}

const DELETEPURCHASER = gql`
  mutation dealUpdateById($_id: MongoID!, $record: UpdateByIdDealInput!) {
    dealUpdateById(_id: $_id, record: $record) {
      record {
        purchasers {
          _id
          email
          firstName
          lastName
          identifications {
            _id
            name
            getUrl
          }
          corp
          sin
          dob
          streetAddress
          city
          province
          postalCode
          occupation
          employer
          purchaserType
          primaryPhone
          idType
          idNumber
          idExpiry
          unit
          idJurisdiction
          getUrl
          putUrl
          directors
          businessNumber
          signingOfficers {
            fullName
            dob
            sin
            primaryPhone
            streetAddress
            email
          }
        }
      }
    }
  }
`;

const CREATEDOCUMENT = gql`
  mutation documentCreateOne($record: CreateOneDocumentInput, $file: Upload!) {
    documentCreateOne(record: $record, file: $file) {
      _id
      name
      project {
        _id
      }
      status
      isAPS
      type
      dsEnvelopeId
    }
  }
`;

const MERGETEMPLATES = gql`
  query mergeTemplateMany($filter: FilterFindManyMergeTemplateInput) {
    mergeTemplateMany(filter: $filter) {
      _id
      project {
        _id
      }
      name
      mergeFields {
        key
        index
        pageNumber
        x
        y
        fontSize
        format
        wrap
      }
      signFields {
        key
        index
        pageNumber
        x
        y
        name
      }
      default
      totalPages
      type
      getUrl
      putUrl
    }
  }
`;

export default PurchaserDialog;
