import { useState, useContext, memo, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeGrid as Grid, areEqual } from 'react-window';
import memoize from 'memoize-one';

import { Box } from '@mui/material';
import { IUnit } from '../../../types/unit';
import StackInfoCard from './StackInfoCard';
import StackUnitCard from './StackUnitCard';
import LoadingWrapper from '../../common/LoadingWrapper';
import { IUserArray } from '../../../types/user';
import { IRealtorInfo } from '../../../types/CreateDealForm';
import { getStackPosition } from '../../../utils/CustomSettings';
import { useSelector } from 'react-redux';
import { selectProject } from '../../../features/project/projectSlice';
import { PriceGridContext } from '../../../context/PriceGridContext';
import '../mediaPrint.css';

const Cell = memo((props: any) => {
  const { rowIndex, columnIndex, style, data } = props;
  let newStyle = style;
  let suite = {};
  let oldStack = {};
  let stackLength = data.reverseArray.length;
  const stack = data.reverseArray[rowIndex][columnIndex];

  if (!stack.stack) {
    suite = data.suites.find((suite: IUnit) => stack._id === suite._id);
  }
  if (stack.stack) {
    oldStack = data.oldStack.find((suite: any) => stack._id === suite._id);
  }
  if (!stack) return null;
  if (stack.stack) {
    newStyle = {
      ...style,
      top: style.top + 20,
    };
  }

  const unique: any = [0, ...new Set(data.stack.map((item: any) => item.stackRow))];
  unique.sort(function (a: number, b: number) {
    return a - b;
  });
  let index: number = 0;
  let value: number = 0;
  for (let i = 0; i < unique.length; i++) {
    if (stack.stackRow >= unique[i]) {
      index = i;
      value = unique[i];
    }
  }

  if (data.printState && stack && Object.keys(stack).length !== 0) {
    newStyle = getStackPosition(stack, style, stackLength, index, value, data.projectId);
  }

  return (
    <div className="grid-card" style={{ ...newStyle }}>
      {stack.stack ? (
        <StackInfoCard unit={stack} handleBlur={data.handleBlur} handleChange={data.handleChange} oldStack={oldStack} />
      ) : (
        <StackUnitCard
          handleBlur={data.handleBlur}
          handleChange={data.handleChange}
          handleInputChange={data.handleInputChange}
          handleSearchInput={data.handleSearchInput}
          handleSelect={data.handleSelect}
          unit={stack}
          setEditedStack={data.setEditedStack}
          editedStack={data.editedStack}
          oldUnit={suite}
          realtors={data.realtors}
        />
      )}
    </div>
  );
}, areEqual);

const Stack = (props: ChildProps) => {
  const { units, printState } = props;
  const {
    oldStack,
    setOldStack,
    editedStack,
    setEditedStack,
    allCards,
    setAllCards,
    stackRow,
    setStackRow,
    stack,
    setStack,
    editedStackInfo,
    setEditedStackInfo,
  } = useContext(PriceGridContext);
  const project = useSelector(selectProject);
  const [realtors, setRealtors] = useState<IRealtorInfo[]>([]);
  const [maxColumns, setMaxColumns] = useState<number>(0);
  // const [units, setUnits] = useState<any>([]);

  useQuery<IUserArray>(GETREALTORS, {
    variables: { filter: { type: 'Realtor', locked: false } },
    onCompleted: (data) => setRealtors(data.userMany),
  });

  const { loading } = useQuery<any>(STACKS, {
    variables: { filter: { project: project._id } },
    onCompleted: (data) => {
      if (stack.length === 0) {
        let stackMany = data.stackMany.map((stack: any) => {
          return {
            ...stack,
            stack: true,
          };
        });
        let allCards = [...units, ...stackMany];
        setStackRow(
          Math.max.apply(
            Math,
            allCards.map((card: any) => card.stackRow)
          )
        );
        setStack(stackMany);
        setOldStack(stackMany);
        setAllCards(allCards);
      }
    },
  });

  const createItemData = memoize(
    (
      editedStack: any,
      editedStackInfo: any,
      allCards: any,
      reverseArray: any,
      setEditedStack: any,
      setEditedStackInfo: any,
      handleChange: any,
      handleBlur: any,
      handleSelect: any,
      suites: IUnit[],
      printState,
      handleSearchInput: any,
      handleInputChange: any,
      realtors: IRealtorInfo[],
      stack: any,
      oldStack: any,
      projectId: string
    ) => ({
      editedStack,
      editedStackInfo,
      allCards,
      reverseArray,
      setEditedStack,
      setEditedStackInfo,
      handleChange,
      handleBlur,
      handleSelect,
      suites,
      printState,
      handleSearchInput,
      handleInputChange,
      realtors,
      stack,
      oldStack,
      projectId,
    })
  );

  const columns = allCards.map((card: any) => {
    return card.stackCol;
  });

  useEffect(() => {
    if (columns.length > 0) {
      setMaxColumns(isNaN(Math.max(...columns)) ? 0 : Math.max(...columns));
    }
  }, [columns, units]);

  let arr: any[] = [];
  for (let i = 0; i < stackRow; i++) {
    if (!arr[i]) {
      arr[i] = [];
    }
    for (let j = 0; j < maxColumns + 1; j++) {
      arr[i].push({});
      allCards.forEach((card: any) => {
        if (card.stackRow === i + 1 && card.stackCol === j + 1) {
          arr[i][j] = card;
        }
      });
    }
  }

  let reverseArray = arr.reverse();

  const handleSearchInput = (id: string) => (e: any, values: any) => {
    let newCards = [];
    let selectedRealtor = realtors.find((realtor: IRealtorInfo) => realtor.fullName === values);
    newCards = allCards.map((card: any) =>
      card._id === id
        ? {
            ...card,
            allocation: {
              _id: selectedRealtor?.realtor._id,
              fullName: selectedRealtor?.fullName,
            },
            tempAllocation: null,
          }
        : card
    );
    setAllCards(newCards);
  };

  const handleInputChange = (e: any, id: string) => {
    let newCards = [];
    newCards = allCards.map((card: any) => (card._id === id ? { ...card, tempAllocation: e.target.value, allocation: null } : card));
    setAllCards(newCards);
  };

  const handleChange = (e: any, id: string, type: string) => {
    let newCards = [];
    if (type === 'basePrice') {
      newCards = allCards.map((card: any) => (card._id === id ? { ...card, [e.target.name]: parseInt(e.target.value, 10) } : card));
    } else {
      newCards = allCards.map((card: any) => (card._id === id ? { ...card, [e.target.name]: e.target.value } : card));
    }
    setAllCards(newCards);
  };

  const handleSelect = (e: any, id: string) => {
    let newCards = allCards.map((card: any) => (card._id === id ? { ...card, [e.target.name]: e.target.value } : card));
    setAllCards(newCards);
  };

  const handleBlur = async (id: string, type: string) => {
    let selectedUnit = await units.find((unit: any) => unit._id === id);
    let selectedStack = await editedStack.find((unit: any) => unit._id === selectedUnit?._id);
    let changeUnit: any = await allCards.find((unit: any) => unit._id === id);
    let newCards = [];

    if (!selectedUnit) {
      // Stack Info
      let newUnitInfo = [];
      let selectedUnitInfo: any = await stack.find((stack: any) => stack._id === id);
      let selectedStackInfo = await editedStackInfo.find((unit: any) => unit._id === id); // Check Stack

      if (selectedUnitInfo) {
        const unique: any = [0, ...new Set(stack.map((item: any) => item.stackRow))];
        let numIndex = unique.indexOf(selectedUnitInfo.stackRow);
        if (
          selectedUnitInfo.exposure === changeUnit.exposure &&
          selectedUnitInfo.unitType === changeUnit.unitType &&
          selectedUnitInfo.modelType === changeUnit.modelType &&
          selectedUnitInfo.size === changeUnit.size &&
          selectedUnitInfo.bathroom === changeUnit.bathroom &&
          selectedUnitInfo.outdoorSize === changeUnit.outdoorSize
        ) {
          let removeStack = editedStackInfo.filter((unit: any) => unit._id !== selectedUnitInfo._id);
          setEditedStackInfo(removeStack);

          let newEditStack = [...editedStack];

          let allSuites = units
            .map((suite: any) => {
              if (
                unique[numIndex - 1] < suite.stackRow &&
                unique[numIndex] > suite.stackRow &&
                parseInt(suite.unit, 10) == selectedUnitInfo.unit
              ) {
                return suite;
              }
            })
            .filter((unit: any) => unit !== undefined);

          let removeUnitStack = newEditStack.filter((selectedUnit: any) => {
            let selectedSuite = allSuites.find((suite: any) => suite.suite === selectedUnit.suite);
            // Remove units that are the same
            if (
              selectedUnit?.basePrice === selectedSuite.basePrice &&
              selectedUnit?.tempAllocation === selectedSuite.tempAllocation &&
              selectedUnit?.status === selectedSuite.status &&
              selectedUnit?.allocation === selectedSuite.allocation &&
              selectedSuite.exposure === changeUnit.exposure &&
              selectedSuite.unitType === changeUnit.unitType &&
              selectedSuite.modelType === changeUnit.modelType &&
              selectedSuite.size === changeUnit.size &&
              selectedSuite.bathroom === changeUnit.bathroom &&
              selectedSuite.outdoorSize === changeUnit.outdoorSize
            ) {
              return selectedUnit;
            }
          });

          let filterEditedStack = newEditStack
            .filter((selected: any) => {
              let newSuite = removeUnitStack.find((selectedSuite: any) => selectedSuite.suite === selected.suite);
              if (!newSuite) return selected;
            })
            .map((filtered: any) => {
              return {
                ...filtered,
                [type]: changeUnit[type],
              };
            });

          setEditedStack(filterEditedStack);
        } else {
          if (selectedStackInfo) {
            newUnitInfo = editedStackInfo.map((card: any) => (card._id === id ? { ...card, [type]: changeUnit[type] } : card));
            setEditedStackInfo(newUnitInfo);

            let allSuites = units
              .map((suite: any) => {
                if (
                  unique[numIndex - 1] < suite.stackRow &&
                  unique[numIndex] > suite.stackRow &&
                  parseInt(suite.unit, 10) == parseInt(selectedUnitInfo.unit, 10)
                ) {
                  return suite;
                }
              })
              .filter((unit: any) => unit !== undefined);

            let newEditStack = [...editedStack];
            allSuites.forEach((card: any, index: number) => {
              let editedStackSuite = newEditStack.find((suite: any) => card._id === suite._id);
              if (editedStackSuite) {
                let numIndex = newEditStack.findIndex((unit) => unit.suite === editedStackSuite.suite);
                newEditStack[numIndex] = {
                  ...editedStackSuite,
                  [type]: changeUnit[type],
                };
              } else {
                newEditStack.push({
                  ...card,
                  [type]: changeUnit[type],
                });
              }
            });

            setEditedStack(newEditStack);
          } else {
            setEditedStackInfo([
              ...editedStackInfo,
              {
                ...changeUnit,
                _id: changeUnit._id,
                exposure: changeUnit.exposure,
                unitType: changeUnit.unitType,
                modelType: changeUnit.modelType,
                size: changeUnit.size,
                outdoorSize: changeUnit.outdoorSize,
                bathroom: changeUnit.bathroom,
              },
            ]);

            //Search for suites that are in the same as stack change
            let allSuites = units
              .map((suite: any) => {
                if (
                  unique[numIndex - 1] < suite.stackRow &&
                  unique[numIndex] > suite.stackRow &&
                  parseInt(suite.unit, 10) == selectedUnitInfo.unit
                ) {
                  return suite;
                }
              })
              .filter((unit: any) => unit !== undefined);
            let newEditStack = [...editedStack];
            allSuites.forEach((card: any, index) => {
              let editedStackSuite = newEditStack.find((suite: any) => card._id === suite._id);
              if (editedStackSuite) {
                let numIndex = newEditStack.findIndex((unit) => unit.suite === editedStackSuite.suite);
                newEditStack[numIndex] = {
                  ...editedStackSuite,
                  [type]: changeUnit[type],
                };
              } else {
                newEditStack.push({
                  ...card,
                  [type]: changeUnit[type],
                });
              }
            });

            setEditedStack(newEditStack);
          }
        }
      }
      return;
    }

    if (selectedStack) {
      // Unit Info
      if (
        selectedUnit?.basePrice === changeUnit.basePrice &&
        selectedUnit?.tempAllocation === changeUnit.tempAllocation &&
        selectedUnit?.status === changeUnit.status &&
        selectedUnit?.allocation === changeUnit.allocation &&
        selectedUnit.exposure === changeUnit.exposure &&
        selectedUnit.unitType === changeUnit.unitType &&
        selectedUnit.modelType === changeUnit.modelType &&
        selectedUnit.size === changeUnit.size &&
        selectedUnit.bathroom === changeUnit.bathroom &&
        selectedUnit.outdoorSize === changeUnit.outdoorSize
      ) {
        let removeStack = editedStack.filter((unit: any) => unit._id !== selectedStack._id);
        setEditedStack(removeStack);
      } else {
        if (type === 'basePrice') {
          newCards = editedStack.map((card: any) => (card._id === id ? { ...card, [type]: changeUnit.basePrice } : card));
        } else if (type === 'allocation') {
          newCards = editedStack.map((card: any) =>
            card._id === id ? { ...card, allocation: changeUnit.allocation, tempAllocation: changeUnit.tempAllocation } : card
          );
        } else {
          newCards = editedStack.map((card: any) => (card._id === id ? { ...card, [type]: changeUnit.status } : card));
        }
        setEditedStack(newCards);
      }
    } else {
      setEditedStack([
        ...editedStack,
        {
          ...changeUnit,
          _id: changeUnit._id,
          basePrice: changeUnit.basePrice,
          tempAllocation: changeUnit.tempAllocation,
          allocation: changeUnit.allocation,
          status: changeUnit.status,
        },
      ]);
    }
  };

  const itemData = createItemData(
    editedStack,
    editedStackInfo,
    allCards,
    reverseArray,
    setEditedStack,
    setEditedStackInfo,
    handleChange,
    handleBlur,
    handleSelect,
    units,
    printState,
    handleSearchInput,
    handleInputChange,
    realtors,
    stack,
    oldStack,
    project?._id!
  );

  const columnWidth = () => {
    return 120;
  };

  const rowHeights = new Array(stackRow)
    .fill(true)
    .map((_, index: number) => {
      let row = allCards.find((card: any) => card.stackRow === index + 1);
      if (row) {
        if (row.stack) {
          return 160;
        } else return 67;
      } else return 0;
    })
    .reverse();

  return loading ? (
    <LoadingWrapper title="Stack is loading..." modal={false} />
  ) : (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '500px',
            flex: 1,
          }}
        >
          <AutoSizer defaultHeight={1080} defaultWidth={1920}>
            {({ height, width }) => {
              return (
                <Grid
                  className={'priceGridMediaPrint'}
                  style={{
                    backgroundColor: '#f5f5f5',
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    breakInside: 'avoid',
                  }}
                  itemData={itemData}
                  columnCount={maxColumns}
                  columnWidth={(index) => columnWidth()}
                  height={height}
                  rowCount={stackRow}
                  rowHeight={(index) => rowHeights[index]}
                  width={width - 1}
                  useIsScrolling={false}
                >
                  {Cell}
                </Grid>
              );
            }}
          </AutoSizer>
        </Box>
      </Box>
    </>
  );
};

interface ChildProps {
  units: IUnit[];
  printState: boolean;
}

const GETREALTORS = gql`
  query userMany($filter: FilterFindManyUserInput) {
    userMany(filter: $filter, limit: 10000) {
      _id
      firstName
      lastName
      fullName
      realtor {
        _id
      }
      locked
    }
  }
`;

const STACKS = gql`
  query stackMany($filter: FilterFindManyStackInput) {
    stackMany(filter: $filter, limit: 10000) {
      _id
      unit
      modelType
      unitType
      outdoorType
      bathroom
      exposure
      size
      outdoorSize
      stackCol
      stackRow
    }
  }
`;

export default Stack;
