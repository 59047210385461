import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { selectProject } from '../../features/project/projectSlice';
import { selectUser } from '../../features/auth/authSlice';
import DashboardComponent from './DashboardComponent';
import DashboardTable from './DashboardTable';
import ChartComponent from './ChartComponent';
import { IDeal, IDealArray } from '../../types/CreateDealForm';
import LoadingWrapper from '../common/LoadingWrapper';
import Outstandings from '../outstandings/Outstandings';
import Packages from '../createProject/packages/Packages';

const DashboardPage = () => {
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);

  const [statusData, setStatusData] = useState(null);
  const [deals, setDeals] = useState<IDeal[]>([]);
  const [allFirmedRows, setAllFirmedRows] = useState<any>([]);
  const [allFirmingRows, setAllFirmingRows] = useState<any>([]);
  const [outstandingDeals, setOutstandingDeals] = useState<IDeal[]>([]);

  const { loading } = useQuery(GETDEALSTATS, {
    fetchPolicy: 'cache-first',
    variables: { project: project._id },
    onCompleted: (data) => {
      setStatusData(data.getStatusCount);
    },
  });

  const { loading: salesLoading } = useQuery<IDealArray>(DEALMANY, {
    skip: user.type !== 'Sales',
    variables: {
      filter: {
        AND: [{ cancelled: { dateCancelled: null } }, { draft: false }, { rescission: { dateRescinded: null } }],
        project: project._id,
        salesRep: user._id,
      },
    },
    onCompleted: (data) => {
      setOutstandingDeals(data.dealMany);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <>
      {loading || salesLoading ? (
        <LoadingWrapper title="Dashboard is loading..." modal={false} />
      ) : (
        <>
          {user.type !== 'Sales' ? (
            <>
              <DashboardComponent statusData={statusData} />
              <Packages editable={false} />
              <DashboardTable
                allDeals={deals}
                allFirmedRows={allFirmedRows}
                allFirmingRows={allFirmingRows}
                setAllFirmedRows={setAllFirmedRows}
                setAllFirmingRows={setAllFirmingRows}
              />
              <ChartComponent project={project} statusData={statusData} />
            </>
          ) : (
            <>
              <Packages editable={false} />
              <DashboardTable
                allDeals={deals}
                allFirmedRows={allFirmedRows}
                allFirmingRows={allFirmingRows}
                setAllFirmedRows={setAllFirmedRows}
                setAllFirmingRows={setAllFirmingRows}
              />
              <Outstandings userType="sales" />
            </>
          )}
        </>
      )}
    </>
  );
};

const GETDEALSTATS = gql`
  query getStatusCount($project: MongoID!) {
    getStatusCount(project: $project) {
      total
      mortgageTotal
      checkCommercial
      allTotals {
        _id
        count
        size
        revenue
        revenueNet
      }
      commercialTotals {
        _id
        count
        size
        revenue
        revenueNet
      }
      statusTotals {
        _id
        count
        size
        revenue
        revenueNet
      }
      statusTiers {
        _id
        name
        count
        size
        revenue
        revenueNet
      }
      commercialTiers {
        _id
        name
        count
        size
        revenue
        revenueNet
      }
    }
  }
`;

const DEALMANY = gql`
  query dealMany($filter: FilterFindManyDealInput!) {
    dealMany(filter: $filter) {
      _id
      project {
        _id
      }
      unit {
        _id
        status
        suite
      }
      purchasers {
        _id
        firstName
        lastName
        fullName
        email
        primaryPhone
      }
      documents {
        type
      }
      realtor {
        _id
        email
        firstName
        lastName
        brokerage
        streetAddress
        city
        province
        country
        postalCode
        brokeragePhone
        brokerageFax
        directPhone
      }
      deposit {
        name
        amount
        dueDate
        accountNumber
        chequeNumber
        chequeDate
        chequeType
        chequeAmount
        deal {
          _id
        }
      }
      options {
        name
        purchaseAmount
        amount
      }
      basePrice
      totalPrice
      tags
      mortgage {
        lender
        revisions
        amount
        getUrl
        putUrl
      }
      rescission {
        dateRescinded
        reason
      }
      cancelled {
        dateCancelled
      }
      draft
    }
  }
`;

export default DashboardPage;
