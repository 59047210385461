import { useState, useEffect } from 'react';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { withRouter } from '../../utils/WithRouter';
import { Box, Button, TextField } from '@mui/material';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

import { useAppDispatch } from '../../app/hooks';
import { showSuccessSnackbar, showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import TextInput from '../common/formControls/TextInput';
import { useUserQuery, useLogin } from '../../features/auth/authHooks';
import { Navigate } from 'react-router-dom';

const Login = (props: any) => {
  const storeDispatch = useAppDispatch();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [forgotPassword, setForgotPassword] = useState<boolean>(false);
  const client = useApolloClient();
  const { loading, data } = useUserQuery(false);
  const login = useLogin();

  const [userReset] = useMutation(RESETPASSWORD, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Password has been sent. Please check your email.'));
      setForgotPassword(false);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(`${err.toString()}`));
    },
  });

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    login(email, password);
  };

  const handleResetSubmit = (e: any) => {
    e.preventDefault();
    userReset({ variables: { email: email } });
  };

  useEffect(() => {
    client.cache.reset();
    client.resetStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <div>Loading...</div>
  ) : data?.getUser ? (
    <>
      <Navigate to="/portal" />
    </>
  ) : (
    <Box
      sx={{
        backgroundColor: '#00142a',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          top: '50%',
          left: '50%',
          width: '500px',
          display: 'flex',
          padding: '30px 20px',
          position: 'fixed',
          transform: 'translate(-50%, -50%)',
          borderRadius: '5px',
          flexDirection: 'column',
          paddingBottom: '30px',
          backgroundColor: '#ffffff',
          '@media (max-width: 500px)': {
            width: 'auto',
          },
        }}
      >
        {forgotPassword ? (
          <Box
            sx={{
              marginRight: 'auto',
            }}
          >
            <KeyboardReturnIcon onClick={() => setForgotPassword(false)} sx={{ cursor: 'pointer' }} />
          </Box>
        ) : null}
        <Box
          sx={{
            maxWidth: '200px',
            margin: '0 auto',
          }}
        >
          <img
            style={{
              maxWidth: '200px',
            }}
            src="https://s3.ca-central-1.amazonaws.com/app.rdsre.ca/logo.png"
            alt="logo"
          />
        </Box>
        {!forgotPassword ? (
          <form onSubmit={handleFormSubmit} autoComplete="off">
            <Box sx={{ mb: 1 }}>
              <TextField
                title={'Email'}
                name={'email'}
                onChange={(e: any) => setEmail(e.target.value)}
                value={email}
                required={true}
                placeholder={'Email'}
                sx={{ width: '100%' }}
              />
            </Box>
            <Box sx={{ mb: 1 }}>
              <TextField
                title={'Password'}
                type={'password'}
                name={'password'}
                onChange={(e: any) => setPassword(e.target.value)}
                value={password}
                required={true}
                placeholder={'Password'}
                sx={{ width: '100%' }}
              />
            </Box>
            <span
              style={{
                fontSize: '12px',
                cursor: 'pointer',
              }}
              onClick={() => setForgotPassword(true)}
            >
              <em>Forgot Password</em>
            </span>
            <div>
              <Button
                type="submit"
                sx={{
                  width: '100%',
                  marginTop: '20px',
                }}
                variant="contained"
                color="primary"
              >
                Login
              </Button>
            </div>
          </form>
        ) : (
          <form onSubmit={handleResetSubmit} autoComplete="off">
            <div>
              <p>Please enter your email. If there is an account associated to this email, a password reset link will be sent.</p>
              <TextInput
                title={'Email'}
                name={'email'}
                handleTextInput={(e: any) => setEmail(e.target.value)}
                value={email}
                required={true}
              />
              <Button
                type="submit"
                sx={{
                  width: '100%',
                  marginTop: '20px',
                }}
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </div>
          </form>
        )}
      </Box>
    </Box>
  );
};

const RESETPASSWORD = gql`
  mutation userEmailResetPassword($email: String!) {
    userEmailResetPassword(email: $email)
  }
`;

export default withRouter(Login);
