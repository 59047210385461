import React, { useState, useRef, useEffect, useMemo } from 'react';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { Document, Page, pdfjs } from 'react-pdf';
import { Box, CircularProgress, Pagination } from '@mui/material';

import LoadingWrapper from '../common/LoadingWrapper';

import 'pdfjs-dist/build/pdf.worker.entry';
pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();
const options = {
  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
  cMapPacked: true,
  cMapUrl: 'cmaps/',
  verbosity: pdfjs.VerbosityLevel.ERROR,
};

const PDFGenerator = (props: ChildProps) => {
  const mergeRef = useRef<any>(null);
  const [ref, setRef] = useState<any>(null);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const { merge, handleRef, height } = props;

  const file = useMemo(() => (typeof merge === 'string' ? merge : { data: merge, useSystemFonts: true }), [merge]);

  function onDocumentLoadSuccess({ numPages: nextNumPages }: { numPages: any }) {
    setTotalPages(nextNumPages);
    setRef(ref);
  }

  useEffect(() => {
    if (handleRef) {
      handleRef(ref);
    }
    setRef(mergeRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merge, ref]);

  return (
    <Box
      sx={{
        height: '100%',
        '& .react-pdf__Document': {
          height: '100%',
        },
        '& .react-pdf__Page': {
          height: '100%',
        },
        '& .react-pdf__Page__canvas': {
          margin: '0 auto',
          border: '1px solid #000',
          backgroundColor: '#fff',
          width: '100% !important',
          height: '100% !important',
        },
        '& .react-pdf__Page__textContent': {
          width: '100% !important',
          height: '100% !important',
        },
        '& .react-pdf__message--loading': {
          display: 'flex',
          justifyContent: 'center',
          alignSelf: 'center',
        },
        '& .react-pdf__Page__annotations': {
          height: height ? `${height} !important` : 'auto',
        },
        position: 'relative',
        maxWidth: props.floorPlan ? '612px' : '900px',
        '@media screen and (min-width: 1200px)': {
          maxWidth: '612px',
        },
        margin: '0 auto',
      }}
    >
      <div style={{ height: '100%' }}>
        {merge ? (
          <a style={{ textDecoration: 'none', color: '#000' }} href={merge} target="_blank" rel="noopener noreferrer">
            <Document
              key={2}
              options={options}
              loading={<LoadingWrapper title="PDF is being loaded" modal={false} />}
              onLoadSuccess={onDocumentLoadSuccess}
              file={file}
            >
              <Page renderTextLayer={false} canvasRef={ref} pageNumber={pageNum} scale={1.0} />
            </Document>
          </a>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignSelf: 'center', height: '70vh', textAlign: 'center' }}>
            <Box sx={{ alignSelf: 'center' }}>
              <CircularProgress />
              <Box>Loading...</Box>
            </Box>
          </Box>
        )}
      </div>

      {totalPages > 1 ? (
        <Box sx={{ textAlign: 'center', mt: 1 }}>
          <Pagination
            sx={{ '& .MuiPagination-ul': { justifyContent: 'center' } }}
            color="secondary"
            onChange={(e, page) => setPageNum(page)}
            count={totalPages}
          />
        </Box>
      ) : null}
    </Box>
  );
};

interface ChildProps {
  merge?: any;
  type?: string;
  floorPlan?: boolean;
  handleRef?: any;
  height?: string | number;
}

export default PDFGenerator;
