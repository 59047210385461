import * as ReactDOM from 'react-dom';
import { Modal, Backdrop, Box } from '@mui/material';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectOpenModal, handleModal } from './modalSlice';

export function GlobalModal(props: any) {
  const open = useAppSelector(selectOpenModal);
  const dispatch = useAppDispatch();

  return ReactDOM.createPortal(
    <>
      <Modal
        open={open}
        onClose={() => dispatch(handleModal(false))}
        closeAfterTransition
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: {
              xs: 350,
              sm: 700,
              md: 800,
              lg: 1200,
            },
            maxHeight: {
              xs: 500,
              sm: 600,
              md: 600,
              lg: 650,
              xl: 850,
            },
            overflowY: 'auto',
            backgroundColor: '#fff',
            padding: '20px',
            border: '2px solid #000',
          }}
        >
          {props.children}
        </Box>
      </Modal>
    </>,
    document.getElementById('modal') as Element
  );
}
