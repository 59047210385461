/* Dependency Imports */
import { useMemo } from 'react';
import { Outlet, useNavigate, Navigate } from 'react-router-dom';
import storage from 'redux-persist/lib/storage';
import { ApolloClient, InMemoryCache, ApolloProvider, split, ApolloLink } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';

import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { useSelector } from 'react-redux';
// @ts-ignore
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { createClient } from 'graphql-ws';
import { useAppDispatch } from '../../app/hooks';
import { setUser } from '../../features/auth/authSlice';
import { selectUser } from '../../features/auth/authSlice';
import UserAuth from './UserAuth';

/* Project Imports */
import env from '../../config/env';

const RequireAuth = () => {
  /* Redux */
  const user = useSelector(selectUser);
  /* Router */

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const uploadLink = createUploadLink({
    uri: `${env.basePath}/graphql`,
    credentials: 'include',
    headers: { 'Apollo-Require-Preflight': 'true' },
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: `${env.socketPath}/graphql`,
    })
  );

  const link = split(
    ({ query }) => {
      const { kind, operation }: Definition = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    uploadLink
  );

  const errorLink = onError(({ graphQLErrors, networkError, response, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        // Here you may display a message to indicate graphql error
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        if (extensions.code === 'UNAUTHENTICATED') {
          localStorage.clear();
          storage.removeItem('persist:root');
          client.cache.reset();
          client.clearStore().then(() => {
            dispatch(setUser(null));
            navigate('/');
          });
        }
        // props.history.push({
        //   pathname: '/error',
        //   state: {
        //     error: message,
        //     locations: locations,
        //     path: path,
        //     extensions: extensions?.code
        //   }
        // })
      });
      return;
    }
    if (networkError) {
      // Here you may display a message to indicate network error
      console.log(`[Network error]: ${networkError}`);
      if (networkError && !user) {
        navigate('/');
      }
      if (networkError.message.includes('401')) {
        dispatch(setUser(null));
        navigate('/');
      }
      // props.history.push({
      //   pathname: "/error",
      //   state: {
      //     error: networkError,
      //   },
      // });
    }
    return;
  });

  const client = useMemo(
    () =>
      new ApolloClient({
        link: ApolloLink.from([errorLink as any, link]),
        cache: new InMemoryCache({
          addTypename: false,
        }),
        credentials: 'include',
      }),
    [errorLink, link]
  );

  return (
    <ApolloProvider client={client}>
      <UserAuth>
        <Outlet />
      </UserAuth>
    </ApolloProvider>
  )
  // Redirect them to the /login page, but save the current location they were
  // trying to go to when they were redirected. This allows us to send them
  // along to that page after they login, which is a nicer user experience
  // than dropping them off on the home page.
};

interface Definition {
  kind: string;
  operation?: string;
}

export default RequireAuth;
