import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Box, Grid, Paper, Button, FormControlLabel, FormControl, FormGroup, Checkbox, Typography } from '@mui/material';
import { IPurchaser, IDealRealtor, IRealtorInfo, IDealArray, IDeal, IMortgage, IDealDeposit } from '../../types/CreateDealForm';
import { convertAllDates, numToCurrency, realtorContent } from '../../utils/Functions';
import PurchaserForm from '../common/forms/PurchaserForm';
import RealtorForm from '../common/forms/RealtorForm';
import { convertDate, sortSuites } from '../../utils/Functions';
import CustomDialog from '../common/CustomDialog';
import { IDocuments } from '../../types/unit';
import LoadingWrapper from '../common/LoadingWrapper';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import { selectUser } from '../../features/auth/authSlice';
import { useCreateActivity } from '../../features/activity/activityHooks';
import { useAppDispatch } from '../../app/hooks';
import { showSuccessSnackbar, showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import { ICoopRate } from '../../types/project';
import { Flex } from '../../commonStyles';

const SearchPage = () => {
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const createActivity = useCreateActivity();
  const storeDispatch = useAppDispatch();
  const location: any = useLocation();
  const { id } = useParams();

  const [info, setInfo] = useState<any>({});
  const [realtor, setRealtor] = useState<any>({});
  const [dealRealtorId, setDealRealtorId] = useState<string>('');
  const [errors, setErrors] = useState<any>({});
  const [deals, setDeals] = useState<IDeal[]>([]);
  const [coopRates, setCoopRates] = useState([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [coops, setCoops] = useState<any>([]);
  const [sendCoops, setSendCoops] = useState<string[]>([]);

  useEffect(() => {
    setCoops([]);
    setSendCoops([]);
  }, [realtor]);

  useQuery<IPurchaser>(GETPURCHASER, {
    skip: location?.state?.type! === 'realtor' ? true : false,
    variables: { _id: id, project: project._id },
    onCompleted: (data) => {
      setInfo(data.purchaserById);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const { loading: realtorLoading } = useQuery(GETREALTOR, {
    skip: location?.state?.type! === 'purchaser' ? true : false,
    variables: { _id: id },
    onCompleted: (data) => {
      getDealRealtors({
        variables: {
          filter: { realtors: [id, ...data.realtorById.aliases.map((realtor: IRealtorInfo) => realtor._id)], project: project._id },
        },
      });
      setRealtor(data.realtorById);
      setInfo(data.realtorById);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [getDealRealtors, { loading: dealLoading }] = useLazyQuery<any>(GETDEALREALTORS, {
    onCompleted: (data) => {
      let deals = data.dealRealtorMany.map((dealRealtor: IRealtorInfo) => {
        return dealRealtor.deal;
      });
      let coopRates = data.dealRealtorMany.map((dealRealtor: any) => {
        return {
          deal: dealRealtor.deal ? dealRealtor.deal._id : null,
          coopRates: dealRealtor.coopRates,
        };
      });

      let filteredDeals: any[] = [...new Map(deals.map((deal: any) => [deal['unit']['_id'], deal])).values()];

      setDealRealtorId(data.dealRealtorMany[0]._id);
      setDeals(filteredDeals.filter((deal: IDeal) => deal.unit));
      setCoopRates(coopRates);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const { loading } = useQuery<IDealArray>(GETDEALS, {
    skip: location?.state?.type! === 'realtor' ? true : false,
    variables: { filter: { purchaserIds: id, project: project._id } },
    onCompleted: (data) => {
      setDeals(data.dealMany.filter((deal: IDeal) => deal.unit));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateRealtor] = useMutation(UPDATEREALTOR, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Realtor Information have been updated!'));
      setInfo(data.realtorUpdateById.record);
      createActivity({
        project: project._id,
        user: user._id,
        deal: null,
        title: `Update Realtor Information ${data.realtorUpdateById.record.firstName} ${data.realtorUpdateById.record.lastName}`,
        content: realtorContent(data.realtorUpdateById.record, realtor!),
      });
      getDealRealtors({
        variables: {
          filter: {
            realtors: [id, ...data.realtorUpdateById.record.aliases.map((realtor: IRealtorInfo) => realtor._id)],
            project: project._id,
          },
        },
      });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [sendSelectedCoops, { loading: sendCoopsLoading }] = useMutation(SENDCOOPS, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Coops have been sent!'));
      setSendCoops([]);
      setCoops([]);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.toString()));
      console.log(err, 'err');
    },
  });

  const handleTextInput = async (event: any) => {
    if (event && event.target.name) {
      setInfo({
        ...info,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleDateChange = (id: string) => (event: any, date: Date | null) => {
    setInfo({
      ...info,
      [id]: date,
    });
    if (event === 'Invalid Date') {
      setErrors(event);
    }
  };

  const handleEndvestorInput = (event: any, value: string, field: string) => {
    setInfo({
      ...info,
      purchaserType: value,
    });
  };

  const handleIdTypeInput = (event: any, idType: string) => {
    setInfo({
      ...info,
      [idType]: event.target.innerText,
    });
  };

  const handleIdIssuedByInput = (event: any, value: string, field: string) => {
    setInfo({
      ...info,
      idJurisdiction: value,
    });
  };

  const handleDetails = async (city: string, province: string, postalCode: string, country: string) => {
    setInfo({
      ...info,
      city: city,
      province: province,
      country: country,
      postalCode: postalCode,
    });
  };

  const handleStreetAddress = async (value: string, type: number) => {};

  const mortgageCheck = (mortgage: IMortgage) => {
    if (mortgage.getUrl && mortgage.lender && mortgage.amount && !mortgage.revisions.length) {
      return 'Mortgage Received';
    } else {
      return 'Mortgage Missing';
    }
  };

  const depositCheck = (deposits: IDealDeposit[]) => {
    const allDeposits = deposits.every(
      (deposit) =>
        deposit.chequeType === 'received' ||
        deposit.chequeType === 'distributed' ||
        deposit.chequeType === 'cleared' ||
        deposit.chequeType === 'wire' ||
        deposit.chequeType === 'floating' ||
        deposit.name === 'Occupancy Deposit' ||
        deposit.name === 'Occupancy'
    );
    if (allDeposits) {
      return 'Deposits Received';
    } else {
      return 'Deposits Missing';
    }
  };

  const commissionCheck = (deal: string, documents: IDocuments[], realtor: any) => {
    let coopRate: any = coopRates.find((coopRate: ICoopDeal) => coopRate.deal === deal);
    if (coopRate) {
      let rates = coopRate.coopRates.reduce((a: any, b: any) => {
        return a + b.percentage;
      }, 0);
      if (rates) {
        let coopDocuments = documents.filter((document: IDocuments) => document.type === 'Coop');
        if (coopDocuments.length === 0) {
          return `${rates}% (None Created)`;
        }
        if (coopDocuments.length === 1) {
          return `${rates}% (${coopDocuments[0].status})`;
        }
        if (coopDocuments.length > 1) {
          let selected = coopDocuments.find((document: IDocuments) => document.name.includes(realtor[0].fullName));
          if (selected) {
            return `${rates}% (${selected.status})`;
          }
        }
      } else return 'None';
    } else return 'N/A';
  };

  const handleRealtorSubmit = (e: any) => {
    e.preventDefault();
    const { _id, fullName, ...newObject } = info;
    updateRealtor({
      variables: {
        _id: id,
        record: {
          ...newObject,
          aliases: newObject.aliases.map((alias: IRealtorInfo) => alias._id),
        },
      },
    });
  };

  const handleCloseSuccess = () => {
    if (sendCoops.length === 0) {
      storeDispatch(showErrorSnackbar('No coops were selected'));
      return;
    }
    sendSelectedCoops({
      variables: {
        documentIds: sendCoops,
        dealRealtorId: dealRealtorId,
      },
    });
    setCoops([]);
    setSendCoops([]);
    setDialogOpen(false);
  };

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (sendCoops.includes(event.target.id)) {
      let removeCoop = sendCoops.filter((id) => id !== event.target.id);
      setSendCoops(removeCoop);
    } else {
      setSendCoops([...sendCoops, event.target.id]);
    }
  };

  const dialogContent = () => {
    deals.forEach((deals: IDeal) => {
      if (deals && !deals.cancelled.dateCancelled && !deals.rescission.dateRescinded) {
        deals.documents.forEach((documents: IDocuments) => {
          if (
            documents.type === 'Coop' &&
            documents.status === 'Prepared' &&
            !documents.dsEnvelopeId &&
            documents.name.includes(realtor.fullName)
          ) {
            if (!coops.some((coop: any) => coop._id === documents._id)) {
              coops.push({
                ...documents,
                suite: deals.unit.suite,
              });
            }
          }
        });
      }
    });

    return (
      <div>
        {coops.length > 0 ? (
          coops.map((coop: any) => {
            return (
              <div>
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          id={coop._id}
                          required={true}
                          onChange={handleCheckChange}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={`${coop.suite} - ${coop.name}`}
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              </div>
            );
          })
        ) : (
          <div>There are no deals with a coop template created. Please create coops on the deals amendment page</div>
        )}
      </div>
    );
  };

  const dialogBox = () => {
    if (dialogOpen) {
      return (
        <CustomDialog
          handleClose={() => setDialogOpen(false)}
          handleCloseRemove={() => setDialogOpen(false)}
          handleCloseSuccess={handleCloseSuccess}
          open={dialogOpen}
          removeButton={'Cancel'}
          successButton={'Yes, I would like to send the coops'}
          dialogContent={dialogContent()}
          dialogTitle={'Please select which coops you would like to send to the realtor'}
          maxWidth={'xl'}
        />
      );
    } else return null;
  };

  const sortedDeals = (type: string) => {
    let sortedDeals: any[] = [];
    if (type === 'active') {
      sortedDeals = deals
        .filter((deal: IDeal) => !deal.cancelled.dateCancelled && !deal.rescission.dateRescinded)
        .map((deal: IDeal) => {
          return {
            ...deal,
            suite: deal.unit.suite,
          };
        });
    } else {
      sortedDeals = deals
        .filter((deal: IDeal) => deal.cancelled.dateCancelled || deal.rescission.dateRescinded)
        .map((deal: IDeal) => {
          return {
            ...deal,
            suite: deal.unit.suite,
          };
        });
    }
    sortedDeals = sortSuites(sortedDeals, 'suite');
    return sortedDeals;
  };

  const statusDate = (deal: IDeal) => {
    if (deal.unit.status === 'F') {
      return <strong>({convertAllDates(new Date(deal.firmDate!), 'PP')})</strong>;
    }
    if (deal.unit.status === 'S') {
      return <strong>({convertAllDates(new Date(deal.signDate!), 'PP')})</strong>;
    }
    if (deal.unit.status === 'C') {
      return <strong>({convertAllDates(new Date(deal.executeDate!), 'PP')})</strong>;
    }
    if (deal.unit.status === 'O') {
      return <strong>({convertAllDates(new Date(deal.createdAt), 'PP')})</strong>;
    }
    return '';
  };

  const statusColour = (deal: IDeal) => {
    if (deal.unit.status === 'F') {
      return '#8ad6f1';
    }
    if (deal.unit.status === 'S') {
      return '#d5a2ff';
    }
    if (deal.unit.status === 'C') {
      return '#d3edb7';
    }
    if (deal.unit.status === 'O') {
      return '#e6b8b7';
    }
    return 'white';
  };

  const handleSearchInput = (event: any, values: any) => {
    setInfo({
      ...info,
      aliases: values,
    });
  };

  const disableRealtor = (disable: boolean) => {
    updateRealtor({
      variables: {
        _id: id,
        record: {
          disable: disable,
        },
      },
    });
  };

  return (
    <Box sx={{ p: 3 }}>
      {loading || sendCoopsLoading || dealLoading || realtorLoading ? (
        <LoadingWrapper title="Loading..." modal={false} />
      ) : (
        <div>
          {dialogBox()}
          <Typography variant={'h5'} gutterBottom>
            Search Results: {location?.state?.search!}
          </Typography>
          {Object.keys(info).length > 0 ? (
            location?.state?.type! === 'realtor' ? (
              <Box
                sx={{
                  border: '1px solid #000',
                  position: 'relative',
                  borderRadius: '8px',
                  padding: '20px',
                }}
              >
                <Box>
                  <form onSubmit={handleRealtorSubmit} autoComplete="off">
                    <RealtorForm
                      realtorInfo={info}
                      handleTextInput={handleTextInput}
                      aliases={true}
                      handleSearchInput={handleSearchInput}
                    />
                    <Flex sx={{ mt: 2 }}>
                      <Button sx={{ mr: 1 }} type="submit" color="success" variant="contained">
                        Update Realtor
                      </Button>
                      <Button onClick={() => disableRealtor(!info.disable)} color={info.disable ? 'success' : 'error'} variant="contained">
                        {info.disable ? 'Enable Realtor' : 'Disable Realtor'}
                      </Button>
                    </Flex>
                  </form>
                </Box>
              </Box>
            ) : (
              <div>
                <Box
                  sx={{
                    border: '1px solid #000',
                    position: 'relative',
                    borderRadius: '8px',
                    padding: '20px',
                  }}
                >
                  <PurchaserForm
                    pInfo={info}
                    handleTextInput={handleTextInput}
                    handleDateChange={handleDateChange}
                    handleEndvestorInput={handleEndvestorInput}
                    handleIdTypeInput={handleIdTypeInput}
                    handleIdIssuedByInput={handleIdIssuedByInput}
                    handleStreetAddress={handleStreetAddress}
                    handleDetails={handleDetails}
                    disabled={true}
                  />
                </Box>
              </div>
            )
          ) : null}
          {!loading || !dealLoading ? (
            <div>
              <h2>Related Deals</h2>
              {deals.length > 0 ? (
                <div>
                  <div>
                    <h3>Active Deals</h3>
                    <Grid container spacing={2}>
                      {sortedDeals('active').map((deal: IDeal, index: number) => {
                        if (deal) {
                          return (
                            <React.Fragment key={index}>
                              <Grid item lg={2} md={4} sm={6} xs={12}>
                                <Link style={{ textDecoration: 'none' }} to={`/${project._id}/dashboard/${deal?.unit._id}`}>
                                  <Paper
                                    elevation={12}
                                    sx={{
                                      p: 2,
                                      width: '100%',
                                      position: 'relative',
                                      transition: 'transform 0.5s ease-in-out',
                                      '&:hover': {
                                        transform: 'scale(1.03)',
                                      },
                                      backgroundColor: statusColour(deal),
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <strong>Suite {deal.unit.suite}</strong>
                                    <div style={{ fontSize: '12px' }}>
                                      {deal.realtor.map((realtor: IDealRealtor) => realtor.email).join(', ')}
                                    </div>
                                    <div>
                                      Status: {deal.unit.status} {statusDate(deal)}
                                    </div>
                                    <div>Unit Price: {numToCurrency.format(deal.basePrice)}</div>
                                    {location.state.type === 'realtor' ? (
                                      <div>Commission: {commissionCheck(deal._id!, deal.documents, deal.realtor)}</div>
                                    ) : null}
                                    <div>
                                      <span style={{ color: mortgageCheck(deal.mortgage) === 'Mortgage Received' ? 'green' : '#af0000' }}>
                                        {mortgageCheck(deal.mortgage)}
                                      </span>
                                    </div>
                                    <div>
                                      <span style={{ color: depositCheck(deal.deposit) === 'Deposits Received' ? 'green' : '#af0000' }}>
                                        {depositCheck(deal.deposit)}
                                      </span>
                                    </div>
                                  </Paper>
                                </Link>
                              </Grid>
                            </React.Fragment>
                          );
                        }
                      })}
                    </Grid>
                    <h3>Cancelled/Rescinded Deals</h3>
                    <Grid container spacing={2}>
                      {sortedDeals('rescinded').map((deal: IDeal, index: number) => {
                        if (deal) {
                          return (
                            <React.Fragment key={index}>
                              <Grid item lg={2} md={4} sm={6} xs={12}>
                                <Link style={{ textDecoration: 'none' }} to={`/${project._id}/dashboard/${deal?.unit._id}`}>
                                  <Paper
                                    elevation={12}
                                    sx={{
                                      height: 150,
                                      width: '100%',
                                      position: 'relative',
                                      transition: 'transform 0.5s ease-in-out',
                                      '&:hover': {
                                        transform: 'scale(1.03)',
                                      },
                                      cursor: 'pointer',
                                      padding: '15px',
                                    }}
                                  >
                                    <strong>Suite {deal.unit.suite}</strong>
                                    {!deal.rescission.dateRescinded && !deal.cancelled.dateCancelled ? (
                                      <div>Status: {deal.unit.status}</div>
                                    ) : null}
                                    <div>Unit Price: {numToCurrency.format(deal.basePrice)}</div>
                                    {deal.cancelled.dateCancelled ? (
                                      <div>
                                        <span style={{ color: 'red' }}>This deal has been cancelled</span>
                                      </div>
                                    ) : null}
                                    {deal.rescission.dateRescinded ? (
                                      <div>
                                        <span style={{ color: 'red' }}>
                                          This deal has been rescinded on {convertDate(deal.rescission.dateRescinded)}
                                        </span>
                                      </div>
                                    ) : null}
                                  </Paper>
                                </Link>
                              </Grid>
                            </React.Fragment>
                          );
                        }
                      })}
                    </Grid>
                  </div>
                  {location?.state?.type! === 'realtor' ? (
                    <Box sx={{ mt: 2 }}>
                      <Button onClick={() => setDialogOpen(true)} color="primary" variant="contained">
                        Send Coops
                      </Button>
                    </Box>
                  ) : null}
                </div>
              ) : (
                <div>There are no deals for this search result</div>
              )}
            </div>
          ) : null}
        </div>
      )}
    </Box>
  );
};

interface ICoopDeal {
  deal: string;
  coopRate: ICoopRate[];
}

const GETPURCHASER = gql`
  query purchaserById($_id: MongoID!) {
    purchaserById(_id: $_id) {
      _id
      email
      firstName
      lastName
      corp
      sin
      dob
      streetAddress
      city
      province
      country
      postalCode
      occupation
      employer
      purchaserType
      primaryPhone
      idType
      idNumber
      idExpiry
      unit
      proofType
      proofNumber
      proofExpiry
      idJurisdiction
      signingOfficers {
        fullName
        dob
        sin
        primaryPhone
        streetAddress
        email
      }
      getUrl
      putUrl
    }
  }
`;

const GETREALTOR = gql`
  query realtorById($_id: MongoID!) {
    realtorById(_id: $_id) {
      _id
      email
      firstName
      lastName
      fullName
      brokerage
      streetAddress
      city
      province
      country
      postalCode
      brokeragePhone
      brokerageFax
      directPhone
      disable
      aliases {
        _id
        fullName
        email
      }
    }
  }
`;

const GETDEALS = gql`
  query dealMany($filter: FilterFindManyDealInput) {
    dealMany(filter: $filter, limit: 100000) {
      _id
      project {
        _id
      }
      unit {
        _id
        status
        suite
      }
      firmDate
      signDate
      executeDate
      createdAt
      purchasers {
        _id
      }
      documents {
        type
        name
      }
      realtor {
        _id
        email
        firstName
        lastName
        fullName
        brokerage
        streetAddress
        city
        province
        country
        postalCode
        brokeragePhone
        brokerageFax
        directPhone
      }
      options {
        name
        purchaseAmount
        amount
      }
      basePrice
      totalPrice
      tags
      deposit {
        name
        chequeType
        chequeAmount
      }
      mortgage {
        _id
        lender
        revisions
        amount
        getUrl
        putUrl
      }
      rescission {
        dateRescinded
        reason
      }
      cancelled {
        dateCancelled
      }
      draft
    }
  }
`;

const GETDEALREALTORS = gql`
  query dealRealtorMany($filter: FilterFindManyDealRealtorInput) {
    dealRealtorMany(filter: $filter, limit: 10000) {
      _id
      project {
        _id
      }
      coopRates {
        type
        percentage
        fixedAmount
      }
      deal {
        _id
        project {
          _id
        }
        unit {
          _id
          status
          suite
        }
        documents {
          _id
          type
          status
          dsEnvelopeId
          name
        }
        purchasers {
          _id
        }
        options {
          name
          purchaseAmount
          amount
        }
        realtor {
          email
          fullName
        }
        basePrice
        totalPrice
        tags
        deposit {
          name
          chequeType
          chequeAmount
        }
        mortgage {
          _id
          lender
          revisions
          amount
          getUrl
          putUrl
        }
        rescission {
          dateRescinded
          reason
        }
        cancelled {
          dateCancelled
        }
        firmDate
        signDate
        executeDate
        createdAt
      }
    }
  }
`;

const UPDATEREALTOR = gql`
  mutation realtorUpdateById($_id: MongoID!, $record: UpdateByIdRealtorInput!) {
    realtorUpdateById(_id: $_id, record: $record) {
      record {
        email
        firstName
        lastName
        streetAddress
        brokerage
        brokerageFax
        brokeragePhone
        city
        province
        country
        disable
        postalCode
        directPhone
        aliases {
          _id
          fullName
          email
        }
      }
    }
  }
`;

const SENDCOOPS = gql`
  mutation documentCreateEnvelopeMultipleCoops($documentIds: [MongoID!], $dealRealtorId: MongoID) {
    documentCreateEnvelopeMultipleCoops(documentIds: $documentIds, sendOut: true, dealRealtorId: $dealRealtorId) {
      _id
    }
  }
`;

export default SearchPage;
