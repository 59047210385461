import { useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Box, Typography, Grid } from '@mui/material';
import { downloadAllFloorplans, downloadExcel, sortSuites, convertAllDates, numToCurrency } from '../../utils/Functions';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import { IUnit } from '../../types/unit';
import LoadingWrapper from '../common/LoadingWrapper';
import { GlobalModal } from '../../features/modal/Modal';
import { handleModal } from '../../features/modal/modalSlice';
import { ICommentArray } from '../../types/comment';
import ResourceCard from './ResourceCard';
import ResourceModal from './ResourceModal';
import { IPurchaserInfo } from '../../types/CreateDealForm';

const Resources = () => {
  const project = useSelector(selectProject);
  const storeDispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [suites, setSuites] = useState([]);
  const [selectedSuites, setSelectedSuites] = useState<any[]>([]);
  const [type, setType] = useState<string>('');
  const [decorNoPrice, setDecorNoPrice] = useState<boolean>(false);

  const [getDeposits] = useLazyQuery<ICommentArray>(GETPAD, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [getComments] = useLazyQuery<ICommentArray>(GETCOMMENTS, {
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [getAllFloorplans] = useLazyQuery(UNITS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {},
  });

  const [getAllMarketing] = useLazyQuery(MARKETING, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {},
  });

  const [getAllDocuments, { loading: getDocumentsLoading }] = useLazyQuery(DOCUMENTS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {},
  });

  const [getDecorDocuments, { loading: getDecorLoading }] = useLazyQuery(DECORDOCUMENTS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {},
  });

  const getFloorplans = (type: string, fileType: string) => {
    setLoading(true);
    if (type === 'Unique' && fileType === 'jpg') {
      getAllMarketing({ variables: { filter: { project: project._id } } }).then(async (res: any) => {
        if (res.data.marketingFloorPlansMany.length) {
          let files = await Promise.all(
            res.data.marketingFloorPlansMany.map(async (unit: any) => {
              if (unit.getUrl) {
                let response = await fetch(unit.getUrl); // will fetch the data from s3 blob
                let data = await response.blob();

                let unitFile = {
                  pdf: data,
                  name: unit.modelType,
                  file: unit.getUrl,
                };

                return unitFile;
              }
            })
          );
          await downloadAllFloorplans(files, `${project.name} - Marketing`);
          setLoading(false);
        } else return storeDispatch(showErrorSnackbar('No floor plans found'));
      });
    } else {
      getAllFloorplans({ variables: { filter: { project: project._id } } }).then(async (res: any) => {
        if (res.data.unitMany.length) {
          let files: any = await Promise.all(
            res.data.unitMany.map(async (unit: IUnit) => {
              if (unit.getUrl) {
                let response = await fetch(unit.getUrl); // will fetch the data from s3 blob
                let data = await response.blob();

                let unitFile = {
                  pdf: data,
                  name: unit.suite,
                  file: unit.getUrl,
                };

                return unitFile;
              }
            })
          );
          if (type === 'Unique') {
            const unique = [...new Map(res.data.unitMany.map((unit: IUnit) => [unit['modelType'], unit])).values()];
            files = await Promise.all(
              unique.map(async (unit: any) => {
                if (unit.getUrl) {
                  let response = await fetch(unit.getUrl); // will fetch the data from s3 blob
                  let data = await response.blob();

                  let unitFile = {
                    pdf: data,
                    name: unit.modelType,
                    file: unit.getUrl,
                  };

                  return unitFile;
                }
              })
            );
          }
          await downloadAllFloorplans(files, `${project.name} - ${type} Legals`);
          setLoading(false);
        } else return storeDispatch(showErrorSnackbar('No floor plans found'));
      });
    }
  };

  const handleDisplayModal = async (type: string) => {
    setSelectedSuites([]);
    setType(type);
    storeDispatch(handleModal(true));

    if (type === 'decor') {
      if (decorNoPrice) {
      } else {
        await getDecorDocuments({ variables: { project: project._id } }).then(async (res: any) => {
          if (res.data.getDistribution.length) {
            let suites = res.data.getDistribution;
            let sortedSuites = sortSuites(
              suites.map((suite: any) => {
                return {
                  ...suite,
                  _id: suite._id,
                  suite: suite.unit.suite,
                };
              }),
              'suite'
            );
            suites = res.data.getDistribution.filter((suite: any) => {
              let document = suite.documents.some((document: any) => document.type === 'Decor' && document.status === 'Completed');
              if (document) return suite;
            });
            setSuites(sortedSuites);
          }
        });
      }
    } else {
      await getAllDocuments({ variables: { project: project._id } }).then(async (res: any) => {
        if (res.data.getDistribution.length) {
          let suites = res.data.getDistribution;
          if (type === 'mortgage') {
            suites = res.data.getDistribution.filter(
              (suite: any) => suite.mortgage.amount && suite.mortgage.getUrl && suite.mortgage.lender && !suite.mortgage.revisions.length
            );
          } else if (type === 'amendments') {
            suites = res.data.getDistribution.filter((suite: any) => {
              let document = suite.documents.some(
                (document: any) => document.type !== 'APS' && document.type !== 'Coop' && document.status === 'Completed'
              );
              if (document) return suite;
            });
          } else if (type === 'coops') {
            suites = res.data.getDistribution.filter((suite: any) => {
              let document = suite.documents.some((document: any) => document.type === 'Coop' && document.status === 'Completed');
              if (document) return suite;
            });
          }
          let sortedSuites = sortSuites(
            suites.map((suite: any) => {
              return {
                ...suite,
                _id: suite._id,
                suite: suite.unit.suite,
              };
            }),
            'suite'
          );
          setSuites(sortedSuites);
        }
      });
    }
  };

  const handleDownloadNotes = () => {
    getComments({ variables: { filter: { project: project._id } } }).then(async (res: any) => {
      if (res.data) {
        let unitColumns = [
          {
            label: 'Suite',
            id: 'suite',
          },
          {
            label: 'Status',
            id: 'status',
          },
          {
            label: 'Comment',
            id: 'comment',
          },
          {
            label: 'Cancelled',
            id: 'cancelled',
          },
          {
            label: 'Rescinded',
            id: 'rescinded',
          },
          {
            label: 'Type',
            id: 'type',
          },
          {
            label: 'Realtor',
            id: 'realtor',
          },
          {
            label: 'User',
            id: 'user',
          },
          {
            label: 'Updated At',
            id: 'updatedAt',
          },
        ];

        let widths = {
          suite: 15,
          status: 15,
          comment: 15,
          cancelled: 15,
          rescinded: 15,
          type: 15,
          realtor: 15,
          user: 15,
          updatedAt: 15,
        };

        let sheetTitle = `${project.name} - Notes Summary`;

        let allData = res.data.commentMany.map((data: any) => {
          return {
            suite: data.unit ? data.unit.suite : '-',
            status: data.unit ? data.unit.status : '-',
            comment: data.comment,
            cancelled: data.deal
              ? data.deal.cancelled.dateCancelled
                ? convertAllDates(data.deal.cancelled.dateCancelled, 'PP')
                : '-'
              : '-',
            rescinded: data.deal
              ? data.deal.rescission.dateRescinded
                ? convertAllDates(data.deal.rescission.dateRescinded, 'PP')
                : '-'
              : '-',
            type: data.type,
            realtor: data.deal ? (data.deal.realtor.length ? data.deal.realtor[0].fullName : 'Public') : '-',
            user: data.user ? data.user.fullName : '-',
            updatedAt: convertAllDates(data.updatedAt, 'PP'),
          };
        });

        downloadExcel([allData], [unitColumns], [], [[widths]], [sheetTitle], sheetTitle);
      }
    });
  };

  const handleDownloadPad = async () => {
    let deposits: any = await getDeposits({
      variables: {
        filter: {
          deposits: 20,
          project: project._id,
          AND: [{ cancelled: { dateCancelled: null } }, { draft: false }, { rescission: { dateRescinded: null } }],
        },
      },
    });
    if (deposits.data.dealMany && deposits.data.dealMany.length) {
      let unitColumns = [
        {
          label: 'Suite',
          id: 'suite',
        },
        {
          label: 'Purchasers',
          id: 'purchasers',
        },
        {
          label: 'Financial Institution',
          id: 'name',
        },
        {
          label: 'Institution #',
          id: 'institution',
        },
        {
          label: 'Branch',
          id: 'branch',
        },
        {
          label: 'Account',
          id: 'account',
        },
        {
          label: 'Monthly Deposit',
          id: 'depositAmount',
        },
        {
          label: 'Last Deposit Amount',
          id: 'lastDeposit',
        },
        {
          label: 'Last Deposit Date',
          id: 'lastDepositDate',
        },
      ];

      let widths = {
        suite: 15,
        purchasers: 15,
        name: 15,
        institution: 15,
        branch: 15,
        account: 15,
        depositAmount: 15,
        lastDeposit: 15,
        lastDepositDate: 15,
      };

      let sheetTitle = `${project.name} - PAD Summary`;

      let allData = deposits.data.dealMany.map((data: any) => {
        return {
          suite: data.unit.suite,
          purchasers: data.purchasers.map((purchaser: IPurchaserInfo) => purchaser.fullName).join(', '),
          name: data.debit.length ? data.debit[0].name : '-',
          institution: data.debit.length ? data.debit[0].institution : '-',
          branch: data.debit.length ? data.debit[0].branch : '-',
          account: data.debit.length ? data.debit[0].account : '-',
          depositAmount: numToCurrency.format(data.deposit[20].amount),
          lastDeposit: numToCurrency.format(data.deposit[data.deposit.length - 2].amount),
          lastDepositDate: convertAllDates(data.deposit[data.deposit.length - 2].dueDate, 'PP'),
        };
      });

      downloadExcel([sortSuites(allData, 'suite')], [unitColumns], [], [[widths]], [sheetTitle], sheetTitle);
    }
  };

  return loading ? (
    <LoadingWrapper modal={false} title="Downloading...This may take a while." />
  ) : (
    <Box sx={{ p: 3 }}>
      <GlobalModal>
        {getDocumentsLoading || getDecorLoading ? (
          <LoadingWrapper title="Loading..." modal={false} />
        ) : (
          <ResourceModal
            suites={suites}
            selectedSuites={selectedSuites}
            setSelectedSuites={setSelectedSuites}
            type={type}
            setType={setType}
            decorNoPrice={decorNoPrice}
            setDecorNoPrice={setDecorNoPrice}
            setLoading={setLoading}
          />
        )}
      </GlobalModal>
      <Typography sx={{ mb: 2 }} variant={'h5'}>
        <strong>Resources</strong>
      </Typography>
      <Grid container spacing={2}>
        <ResourceCard
          title={'All Legal Floor Plans for Every Suite'}
          subtitle={"This will download every unit's legal floor plan."}
          button={() => getFloorplans('All', 'pdf')}
          buttonText={'Download'}
        />
        <ResourceCard
          title={'All Unique Floorplans'}
          subtitle={'This will only download all unique model types.'}
          button={() => getFloorplans('All', 'pdf')}
          buttonText={'Legal'}
          secondButton={() => getFloorplans('Unique', 'jpg')}
          secondButtonText="Marketing"
        />
        <ResourceCard
          title={'APS + Amendments + Coops + MPA + Deposit Images + IDs'}
          subtitle={'This will only download documents for all firmed units.'}
          button={() => handleDisplayModal('all')}
          buttonText={'Download'}
        />
        <ResourceCard
          title={'APS + Amendments + Coops'}
          subtitle={'This will only download documents for all firmed units.'}
          button={() => handleDisplayModal('documents')}
          buttonText={'Download'}
        />
        <ResourceCard
          title={'Amendments + Coops'}
          subtitle={'This will only download documents for all firmed units.'}
          button={() => handleDisplayModal('amendments')}
          buttonText={'Download'}
        />
        <ResourceCard
          title={'Coops and Mortgage Pre-Approvals'}
          subtitle={'This will only download Coops and MPA for all firmed units.'}
          button={() => handleDisplayModal('coopMortgages')}
          buttonText={'Download'}
        />
        <ResourceCard
          title={'Coops'}
          subtitle={'This will only download coops for all firmed units.'}
          button={() => handleDisplayModal('coops')}
          buttonText={'Download'}
        />
        <ResourceCard
          title={'Mortgage Pre-Approvals'}
          subtitle={'This will only download MPA for all firmed units.'}
          button={() => handleDisplayModal('mortgage')}
          buttonText={'Download'}
        />
        <ResourceCard
          title={'Decor'}
          subtitle={'This downloads all Decor Amendments.'}
          button={() => handleDisplayModal('decor')}
          buttonText={'Download'}
        />
        <ResourceCard
          title={'Notes'}
          subtitle={'This will download all notes for every unit and deal.'}
          button={() => handleDownloadNotes()}
          buttonText={'Download'}
          color="success"
        />
        <ResourceCard
          title={'Pre Authorized Debit'}
          subtitle={'This will download all PAD and Deposit Amounts.'}
          button={() => handleDownloadPad()}
          buttonText={'Download'}
          color="success"
        />
      </Grid>
    </Box>
  );
};

const UNITS = gql`
  query unitMany($filter: FilterFindManyUnitInput) {
    unitMany(filter: $filter, limit: 10000) {
      suite
      project {
        _id
      }
      getUrl
      modelType
    }
  }
`;

const MARKETING = gql`
  query marketingFloorPlansMany($filter: FilterFindManymarketingFloorPlansInput) {
    marketingFloorPlansMany(filter: $filter, limit: 10000) {
      modelType
      project {
        _id
      }
      getUrl
    }
  }
`;

const DOCUMENTS = gql`
  query getDistribution($project: MongoID!) {
    getDistribution(project: $project) {
      _id
      unit {
        _id
        unit
        level
        suite
      }
      purchasers {
        _id
        fullName
        project {
          _id
        }
        identifications {
          getUrl
          name
          distribution
        }
      }
      depositImages {
        getUrl
        name
        distribution
      }
      mortgage {
        amount
        lender
        revisions
        getUrl
        distribution
      }
      documents {
        _id
        name
        status
        distribution
        type
        isAPS
      }
    }
  }
`;

const DECORDOCUMENTS = gql`
  query getDistribution($project: MongoID!) {
    getDistribution(project: $project) {
      _id
      unit {
        _id
        unit
        level
        suite
      }
      purchasers {
        _id
        fullName
        project {
          _id
        }
        identifications {
          getUrl
          name
          distribution
        }
      }
      depositImages {
        getUrl
        name
        distribution
      }
      mortgage {
        amount
        lender
        revisions
        getUrl
        distribution
      }
      documents {
        _id
        name
        status
        distribution
        type
        isAPS
      }
      upgradeSetOne {
        date
        salesRep {
          _id
          fullName
        }
        collectionTemplate {
          _id
          name
          upgradeTemplates {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            schemes
            unitsAllowed
            modelsAllowed
            sessions
            freeUpgrades
            upgradeType
          }
          unitsAllowed
          modelsAllowed
        }
        upgradeSet {
          _id
          name
          price
          mainCategory
          subCategory
          quantity
          upgradeTemplate {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            unitsAllowed
            modelsAllowed
            freeUpgrades
            upgradeType
            getUrl
          }
        }
      }
      upgradeSetTwo {
        date
        salesRep {
          _id
          fullName
        }
        collectionTemplate {
          _id
          name
          upgradeTemplates {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            schemes
            unitsAllowed
            modelsAllowed
            sessions
            freeUpgrades
            upgradeType
          }
          unitsAllowed
          modelsAllowed
        }
        upgradeSet {
          _id
          name
          price
          mainCategory
          subCategory
          quantity
          upgradeTemplate {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            unitsAllowed
            modelsAllowed
            freeUpgrades
            upgradeType
            getUrl
          }
        }
      }
      upgradeSetThree {
        date
        salesRep {
          _id
          fullName
        }
        collectionTemplate {
          _id
          name
          upgradeTemplates {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            schemes
            unitsAllowed
            modelsAllowed
            sessions
            freeUpgrades
            upgradeType
          }
          unitsAllowed
          modelsAllowed
        }
        upgradeSet {
          _id
          name
          price
          mainCategory
          subCategory
          quantity
          upgradeTemplate {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            unitsAllowed
            modelsAllowed
            freeUpgrades
            upgradeType
            getUrl
          }
        }
      }
    }
  }
`;

const GETCOMMENTS = gql`
  query commentMany($filter: FilterFindManyCommentInput) {
    commentMany(filter: $filter, limit: 10000) {
      _id
      user {
        fullName
      }
      unit {
        status
        suite
      }
      deal {
        _id
        realtor {
          fullName
        }
        rescission {
          dateRescinded
        }
        cancelled {
          dateCancelled
        }
      }
      comment
      type
      updatedAt
    }
  }
`;

const GETPAD = gql`
  query dealMany($filter: FilterFindManyDealInput) {
    dealMany(filter: $filter, limit: 10000) {
      _id
      unit {
        suite
      }
      purchasers {
        fullName
      }
      basePrice
      debit {
        name
        institution
        branch
        account
      }
      deposit {
        name
        amount
        dueDate
      }
    }
  }
`;

export default Resources;
