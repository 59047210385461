import { Dispatch, SetStateAction } from 'react';
import { TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { unitTypes, exposures, outdoorTypes } from '../../utils/Constants';
import { IUnit } from '../../types/unit';

const Unit = (props: ChildProps) => {
  const {
    unit,
    level,
    setLevel,
    unitNumber,
    setUnitNumber,
    rental,
    setRental,
    size,
    setSize,
    modelType,
    setModelType,
    handleDropdown,
    unitType,
    bathroom,
    setBathroom,
    exposure,
    outdoorSize,
    setOutdoorSize,
    outdoorType,
  } = props;
  return (
    <div>
      <TextField
        title={'Level'}
        name={'level'}
        fullWidth
        value={level}
        label={'Level'}
        required
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLevel(e.target.value)}
      />
      <TextField
        sx={{ mt: 2 }}
        title={'Unit'}
        name={'unitNumber'}
        fullWidth
        value={unitNumber}
        label={'Unit'}
        required
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUnitNumber(e.target.value)}
      />
      {unit && unit.rental ? 
        <TextField
          sx={{ mt: 2 }}
          title={'Rental'}
          name={'rental'}
          fullWidth
          value={rental}
          label={'Rental'}
          required
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRental(e.target.value)}
        />
        :
        null
      }
      <TextField
        sx={{ mt: 2 }}
        title={'Size'}
        name={'size'}
        type="number"
        onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
        fullWidth
        value={size}
        label={'Size'}
        required
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSize(e.target.value)}
      />
      <TextField
        sx={{ mt: 2 }}
        title={'Model Type'}
        name={'modelType'}
        fullWidth
        value={modelType}
        label={'Model Type'}
        required
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setModelType(e.target.value)}
      />
      <FormControl required sx={{ mt: 2 }} fullWidth>
        <InputLabel id="id-select-label">Unit Type</InputLabel>
        <Select required labelId="id-label" id="unitType" value={unitType} label="Unit Type" name="unitType" onChange={handleDropdown}>
          {unitTypes.map((unitType: string) => {
            return <MenuItem value={unitType}>{unitType}</MenuItem>;
          })}
        </Select>
      </FormControl>
      <TextField
        sx={{ mt: 2 }}
        type="number"
        onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
        title={'Bathroom'}
        name={'bathroom'}
        fullWidth
        value={bathroom}
        label={'Bathroom'}
        required
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBathroom(e.target.value)}
      />
      <FormControl required sx={{ mt: 2 }} fullWidth>
        <InputLabel id="id-select-label">Exposure</InputLabel>
        <Select
          labelId="id-label"
          id="exposure"
          value={exposure}
          label="Exposure"
          name="exposure"
          onChange={handleDropdown}
          required={true}
        >
          {exposures.map((exposure: string) => {
            return <MenuItem value={exposure}>{exposure}</MenuItem>;
          })}
        </Select>
      </FormControl>
      <TextField
        sx={{ mt: 2 }}
        title={'Outdoor Size'}
        name={'outdoorSize'}
        fullWidth
        type="number"
        onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
        value={outdoorSize}
        label={'Outdoor Size'}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOutdoorSize(e.target.value)}
      />
      <FormControl sx={{ mt: 2 }} fullWidth>
        <InputLabel id="id-select-label">Outdoor Type</InputLabel>
        <Select labelId="id-label" id="outdoorType" value={outdoorType} label="Outdoor Type" name="outdoorType" onChange={handleDropdown}>
          {outdoorTypes.map((outdoorType: string) => {
            return <MenuItem value={outdoorType}>{outdoorType}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </div>
  );
};

interface ChildProps {
  unit?: IUnit;
  level: string;
  setLevel: Dispatch<SetStateAction<string>>;
  unitNumber: string;
  setUnitNumber: Dispatch<SetStateAction<string>>;
  rental: string;
  setRental: Dispatch<SetStateAction<string>>;
  size: string;
  setSize: Dispatch<SetStateAction<string>>;
  modelType: string;
  setModelType: Dispatch<SetStateAction<string>>;
  handleDropdown: any;
  unitType: string;
  bathroom: string;
  setBathroom: Dispatch<SetStateAction<string>>;
  exposure: string;
  outdoorSize: string;
  setOutdoorSize: Dispatch<SetStateAction<string>>;
  outdoorType: string;
}

export default Unit;
