import { useContext, useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Dropzone, { useDropzone } from 'react-dropzone';
import { gql, useMutation } from '@apollo/client';
import { Box, Grid, Button, FormGroup, Autocomplete, TextField, FormControlLabel, Checkbox, Typography } from '@mui/material';
import { numToCurrency } from '../../../utils/Functions';
import { UnitContext } from '../../../context/UnitContext';
import { selectProject } from '../../../features/project/projectSlice';
import { selectUser } from '../../../features/auth/authSlice';
import { useCreateActivity } from '../../../features/activity/activityHooks';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../features/snackbar/snackbarSlice';
import { useAppDispatch } from '../../../app/hooks';
import { accessAllowed } from '../../../features/project/projectHooks';
import { baseStyle, activeStyle, acceptStyle, rejectStyle, lenders, revisions } from '../../../utils/Constants';
import TextInput from '../../common/formControls/TextInput';
import PdfCard from '../../common/PdfCard';

const Mortgage = (props: ChildProps) => {
  const { errors, setErrors, createMiscellaneous, deleteSupport, handleDrop, mpaImage, setMpaImage } = props;
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const { filteredDeal, setFilteredDeal } = useContext(UnitContext);
  const createActivity = useCreateActivity();
  const [mortgageAmount, setMortgageAmount] = useState<number>(0);

  useEffect(() => {
    if (filteredDeal) {
      handleMortgageCalculation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredDeal]);

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg, image/png, application/pdf',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const [updateMortgage] = useMutation(UPDATE, {
    onCompleted: (data) => {
      setFilteredDeal({
        ...filteredDeal,
        mortgage: data.dealUpdateById.record.mortgage,
      });

      let description: string = '';

      if (mpaImage.file) {
        description += `MPA Image Uploaded.\n`;
      }
      description += `Lender ${data.dealUpdateById.record.mortgage.lender}.\n`;
      description += `Amount ${
        data.dealUpdateById.record.mortgage.amount ? numToCurrency.format(data.dealUpdateById.record.mortgage.amount) : '$0'
      }.\n`;
      description += `Revisions ${
        data.dealUpdateById.record.mortgage.revisions.length ? data.dealUpdateById.record.mortgage.revisions.join(', ') : 'No Revisions'
      }.\n`;

      createActivity({
        project: project._id,
        user: user?._id,
        deal: filteredDeal._id,
        title: `Mortgage Update`,
        content: description,
      });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  useEffect(() => {
    if (filteredDeal && filteredDeal.mortgage.getUrl) {
      handleImageState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredDeal.mortgage]);

  const handleImageState = () => {
    if (!mpaImage.file) {
      setMpaImage({
        getUrl: filteredDeal.mortgage.getUrl,
        file: '',
      });
    }
  };

  const saveMpa = async () => {
    if (errors.amount) {
      storeDispatch(showErrorSnackbar('Mortgage Pre-Approval amount is too low'));
      return;
    }

    const { _id, totalPrice, ...newObject } = filteredDeal;

    let object = {
      lender: newObject.mortgage.lender,
      amount: newObject.mortgage.amount,
      revisions: newObject.mortgage.revisions,
    };

    newObject.mortgage = object;

    if (mpaImage.file) {
      await createMiscellaneous({
        variables: { id: filteredDeal._id, name: `${mpaImage.file.name}`, file: mpaImage.file, type: 'mortgage' },
      });
    }
    await updateMortgage({ variables: { _id: filteredDeal._id, record: { mortgage: object } } });
    await storeDispatch(showSuccessSnackbar('Mortgage Pre-Approval has been saved!'));
  };

  const handleMortgageCalculation = () => {
    let totalOptions = filteredDeal.totalPrice;
    let sum = filteredDeal.deposit.reduce(function (s: any, a: any) {
      return s + a.amount;
    }, 0);
    if (filteredDeal.basePrice) {
      totalOptions = filteredDeal.options.reduce((a: any, b: any) => {
        return a + b.amount;
      }, filteredDeal.basePrice);
    }
    let mortgageAmount = filteredDeal.basePrice ? totalOptions - sum : filteredDeal.totalPrice - sum;
    setMortgageAmount(mortgageAmount);
  };

  const handleCheckbox = (e: any) => {
    let checkboxes = [...filteredDeal.mortgage.revisions];

    if (filteredDeal.mortgage.revisions.includes(e.target.value)) {
      checkboxes = filteredDeal.mortgage.revisions.filter((revision: string) => revision !== e.target.value);
    } else {
      checkboxes.push(e.target.value);
    }

    if (e.target.value === 'No MPA Required') {
      if (filteredDeal.mortgage.revisions.includes('No MPA Required')) {
        checkboxes = [];
      } else {
        checkboxes = ['No MPA Required'];
      }
    }

    setFilteredDeal({
      ...filteredDeal,
      mortgage: {
        ...filteredDeal.mortgage,
        revisions: checkboxes,
      },
    });
  };

  const handleFileImage = () => {
    deleteSupport({ variables: { _id: filteredDeal._id, documentId: filteredDeal.mortgage._id, deleteFile: true, type: 'mortgage' } });
    setFilteredDeal({
      ...filteredDeal,
      mortgage: {
        ...filteredDeal.mortgage,
        getUrl: null,
      },
    });
    setMpaImage({
      getUrl: null,
      file: '',
    });
  };

  const handleLenderChange = (event: any, value: string) => {
    setFilteredDeal({
      ...filteredDeal,
      mortgage: {
        ...filteredDeal.mortgage,
        lender: value,
      },
    });
  };

  const handleLenderType = (event: any) => {
    if (event) {
      setFilteredDeal({
        ...filteredDeal,
        mortgage: {
          ...filteredDeal.mortgage,
          lender: event.target.value,
        },
      });
    }
  };

  const handleMortgageInput = (event: any) => {
    if (parseInt(event.target.value, 10) < mortgageAmount - mortgageAmount * (project.mortgageLimit / 100)) {
      setErrors({
        ...errors,
        amount: 'Too low',
      });
    } else {
      setErrors({
        ...errors,
        amount: null,
      });
    }
    setFilteredDeal({
      ...filteredDeal,
      mortgage: {
        ...filteredDeal.mortgage,
        amount: parseFloat(event?.target.value),
      },
    });
  };

  return (
    <div>
      <Typography variant="h2" sx={{ mb: 1 }}><strong>Mortgage Pre-Approval</strong></Typography>
      <Box sx={{ mb: 3 }}>
        The minimum amount required for the mortgage pre-approval is <strong>{numToCurrency.format(mortgageAmount)}</strong>
      </Box>
      <Grid container spacing={2}>
        {mpaImage.getUrl ? (
          <Grid item sm={4} xs={12}>
            <PdfCard
              file={mpaImage.getUrl}
              title={mpaImage.file ? mpaImage.file.name : `Mortgage PreApproval`}
              id={'0'}
              handleDelete={handleFileImage}
              download={true}
            />
          </Grid>
        ) : (
          <Grid item sm={4} xs={12}>
            <Dropzone onDrop={(files) => handleDrop(files, 'mpa')} accept="image/jpg, image/jpeg, image/png, application/pdf">
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps({ style: { ...style, margin: '0 !important' } })}>
                    <input disabled={filteredDeal.mortgage.revisions.includes('No MPA Required')} {...getInputProps()} />
                    <p>Drag and Drop or Upload Mortgage Pre-Approval</p>
                  </div>
                </section>
              )}
            </Dropzone>
          </Grid>
        )}
        <Grid item xs={12} sm={8}>
          <Box>
            <Autocomplete
              title={'Mortgage Lender'}
              id={'mortgageLender'}
              freeSolo
              onChange={handleLenderChange}
              onInputChange={handleLenderType}
              value={filteredDeal.mortgage.lender}
              options={lenders}
              disabled={filteredDeal.mortgage.revisions.includes('No MPA Required')}
              renderInput={(params) => <TextField {...params} label="Mortgage Lender" />}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextInput
              name={'amount'}
              type={'number'}
              title={'Mortgage Amount'}
              handleTextInput={(e: HTMLInputElement) => handleMortgageInput(e)}
              value={!filteredDeal.mortgage.amount ? '' : filteredDeal.mortgage.amount}
              adornment={'$'}
              disabled={filteredDeal.mortgage.revisions.includes('No MPA Required')}
              helperText={errors.amount ? 'This amount is too low' : ''}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Box>Revisions Needed</Box>
            <Grid sx={{ mt: 1 }} container spacing={2}>
              {revisions.map((revision: string, index: number) => {
                return (
                  <Grid
                    item
                    sm={6}
                    md={3}
                    xs={12}
                    sx={{
                      '&.MuiGrid-item': {
                        pt: '0px !important',
                      },
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={revision}
                            checked={filteredDeal.mortgage.revisions.includes(revision)}
                            onChange={(e: any) => handleCheckbox(e)}
                            color={
                              filteredDeal.mortgage.revisions.includes(revision) && revision === 'No MPA Required' ? 'success' : 'primary'
                            }
                          />
                        }
                        label={revision}
                      />
                    </FormGroup>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {accessAllowed(user, project._id, 'editDeals') ? (
        <Button sx={{ mt: 2 }} onClick={() => saveMpa()} name={'update'} color="success" variant="contained">
          Save Mortgage Pre-Approval
        </Button>
      ) : null}
    </div>
  );
};

interface ChildProps {
  errors: any;
  setErrors: any;
  createMiscellaneous: any;
  deleteSupport: any;
  handleDrop: any;
  mpaImage: any;
  setMpaImage: any;
}

const UPDATE = gql`
  mutation dealUpdateById($_id: MongoID!, $record: UpdateByIdDealInput!) {
    dealUpdateById(_id: $_id, record: $record) {
      record {
        project {
          _id
        }
        mortgage {
          _id
          revisions
          lender
          amount
          getUrl
        }
        solicitor {
          solicitor
          firm
          streetAddress
          city
          province
          postalCode
          email
          primaryPhone
        }
        deposit {
          name
          amount
          dueDate
          accountNumber
          chequeNumber
          clearedDate
          chequeDate
        }
        thirdParty {
          fullName
          streetAddress
          dob
          primaryPhone
          email
          occupation
          corpNumber
          relationship
        }
      }
    }
  }
`;

export default Mortgage;
