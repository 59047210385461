import { useState, SetStateAction, Dispatch, useMemo } from 'react';
import axios from 'axios';
import { Buffer } from 'buffer';
import { useSelector } from 'react-redux';
import Dropzone, { useDropzone } from 'react-dropzone';
import { gql, useQuery } from '@apollo/client';
import {
  Typography,
  Grid,
  TextField,
  Autocomplete,
  FormControlLabel,
  Switch,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { packageHeaders, sortOrders } from '../../../utils/Constants';
import TextEditor from '../../common/textEditor/TextEditor';
import { IPackage } from '../../../types/package';
import { IUnitData, IUnit } from '../../../types/unit';
import { selectProject } from '../../../features/project/projectSlice';
import { Container, Flex, FlexEnd } from '../../../commonStyles';
import { camelToTitle, numToCurrency } from '../../../utils/Functions';
import { useAppDispatch } from '../../../app/hooks';
import { showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';
import PdfCard from '../../common/PdfCard';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '../../../utils/Constants';

const EditPackage = (props: ChildProps) => {
  const project = useSelector(selectProject);
  const storeDispatch = useAppDispatch();
  const { modelPackage, setView, addModelPackage, editModelPackage } = props;
  const [name, setName] = useState<string>(modelPackage ? modelPackage.name : '');
  const [unitTypes, setUnitTypes] = useState<IUnit[]>([]);
  const [units, setUnits] = useState<IUnit[]>(modelPackage ? modelPackage.units : []);
  const [columns, setColumns] = useState<string[]>(modelPackage ? modelPackage.columns : []);
  const [active, setActive] = useState<boolean>(modelPackage ? modelPackage.active : true);
  const [sortOrder, setSortOrder] = useState<string>(modelPackage ? modelPackage.sortOrder : 'basePrice');
  const [qrCode, setQrCode] = useState<boolean>(modelPackage ? modelPackage.qrCode : false);
  const [title, setTitle] = useState<string>(modelPackage ? modelPackage.title : '');
  const [pdf, setPdf] = useState<any>(null);
  const [pdfImage, setPdfImage] = useState<any>(modelPackage ? modelPackage.packageGetUrl : null);
  const [descriptions, setDescriptions] = useState<any[]>(
    modelPackage && modelPackage.descriptions.length
      ? modelPackage.descriptions
      : [
          [
            {
              type: 'paragraph',
              content: [
                {
                  text: 'Description 1',
                  type: 'text',
                  marks: [],
                },
              ],
            },
          ],
          [
            {
              type: 'paragraph',
              content: [
                {
                  text: 'Description 2',
                  type: 'text',
                  marks: [],
                },
              ],
            },
          ],
          [
            {
              type: 'paragraph',
              content: [
                {
                  text: 'Description 3',
                  type: 'text',
                  marks: [],
                },
              ],
            },
          ],
          [
            {
              type: 'paragraph',
              content: [
                {
                  text: 'Disclaimer',
                  type: 'text',
                  marks: [],
                },
              ],
            },
          ],
        ]
  );

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg, image/jpg',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  useQuery<IUnitData>(UNITS, {
    variables: { filter: { project: project._id, OR: [{ status: 'HL' }, { status: 'PL' }] } },
    onCompleted: (data) => {
      let modelTypes = [...data.unitMany].sort((a, b) => a.modelType.localeCompare(b.modelType) || a.basePrice - b.basePrice);
      setUnitTypes(modelTypes);
    },
  });

  const savePackage = async (e: any) => {
    e.preventDefault();
    if (modelPackage) {
      editModelPackage({
        variables: {
          _id: modelPackage._id,
          record: {
            name: name,
            project: project._id,
            active: active,
            units: units.map((unit: IUnit) => unit._id),
            columns: columns,
            sortOrder: sortOrder,
            qrCode: qrCode,
            title: title,
            descriptions: descriptions,
          },
        },
      }).then(async (res: any) => {
        if (pdf) {
          const buffer = Buffer.from(pdfImage.replace(/^data:application\/\w+;base64,/, ''), 'base64');
          const options = {
            headers: { 'Content-Type': 'application/pdf' },
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
          };
          await axios.put(res.data.modelPackageUpdateById.record.packagePutUrl!, buffer, options);
        }
      });
    } else {
      addModelPackage({
        variables: {
          record: {
            name: name,
            project: project._id,
            active: active,
            units: units.map((unit: IUnit) => unit._id),
            columns: columns,
            sortOrder: sortOrder,
            qrCode: qrCode,
            title: title,
            descriptions: descriptions,
          },
        },
      }).then(async (res: any) => {
        if (pdf) {
          const buffer = Buffer.from(pdfImage.replace(/^data:application\/\w+;base64,/, ''), 'base64');
          const options = {
            headers: { 'Content-Type': pdf.type },
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
          };
          await axios.put(res.data.modelPackageCreateOne.record.packagePutUrl!, buffer, options);
        }
      });
    }
  };

  const handleTextChange = (text: any, numIndex: number) => {
    let newDescriptions = text.content.map((editor: any, index: number) => {
      return {
        type: editor.type,
        content: editor.content
          ? editor.content.map((content: any) => {
              if (content.text) {
                return {
                  text: content.text,
                  type: content.type,
                  marks: content.marks ? content.marks.map((mark: any) => mark.type) : [],
                };
              } else if (content.content) {
                return {
                  text: content.content[0].content[0].text,
                  type: content.type,
                  marks: content.content[0].content[0].marks ? content.content[0].content[0].marks.map((mark: any) => mark.type) : [],
                };
              } else {
                return {
                  text: content.text ? content.text : '',
                  type: content.type,
                  marks: content.marks ? content.marks.map((mark: any) => mark.type) : [],
                };
              }
            })
          : [
              {
                text: '',
                type: 'paragraph',
                marks: [],
              },
            ],
      };
    });

    let allDescriptions = descriptions.map((description: any, index: number) => {
      if (index === numIndex) {
        return newDescriptions;
      } else return description;
    });

    setDescriptions(allDescriptions);
  };

  const deserialize = (description: any) => {
    let content = {
      type: 'doc',
      content: description.map((description: any) => {
        if (description.type === 'bulletList') {
          return {
            type: 'bulletList',
            content: description.content.map((content: any) => {
              return {
                type: 'listItem',
                content: [
                  {
                    attrs: {
                      display: 'block',
                      indent: 0,
                      lineHeight: 1.5,
                      textAlign: 'left',
                      textIndent: 0,
                    },
                    type: 'paragraph',
                    content: [
                      {
                        type: 'text',
                        text: content.text,
                        marks: content.marks.map((marks: string) => {
                          return {
                            type: marks,
                          };
                        }),
                      },
                    ],
                  },
                ],
              };
            }),
          };
        } else {
          return {
            ...description,
            content: description.content.map((content: any) => {
              return {
                ...content,
                marks: content.marks.map((marks: string) => {
                  return {
                    type: marks,
                  };
                }),
              };
            }),
            attrs: {
              display: 'block',
              indent: 0,
              lineHeight: 1.5,
              textAlign: 'left',
              textIndent: 0,
            },
          };
        }
      }),
    };
    return content;
  };

  const handleDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length === 0) {
      storeDispatch(showErrorSnackbar('This file type is not allowed'));
      return;
    }
    const file = acceptedFiles[0];
    const fileReader = new FileReader();
    if (file) {
      fileReader.readAsDataURL(file);
    }
    fileReader.onloadend = async () => {
      setPdf(acceptedFiles[0]);
      setPdfImage(fileReader.result);
    };
  };

  return (
    <form onSubmit={savePackage}>
      <Flex sx={{ mb: 2 }}>
        <ArrowBackIcon sx={{ cursor: 'pointer', mr: 1, alignSelf: 'center' }} onClick={() => setView('list')} />
        <Typography sx={{ alignSelf: 'center', mb: 0 }} variant={'h5'} gutterBottom>
          <strong>{modelPackage ? `Edit ${modelPackage.name}` : 'Create Package'}</strong>
        </Typography>
      </Flex>
      <Container sx={{ borderRadius: '8px' }}>
        <Typography variant="h2" sx={{ mb: 2 }}>
          <strong>Price List</strong>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              title={'Package Name'}
              name={'package'}
              fullWidth
              required
              value={name}
              label={'Package Name'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControlLabel
              onClick={(e) => e.stopPropagation()}
              control={<Switch checked={active} onChange={() => setActive(!active)} />}
              label={'Active'}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControlLabel
              onClick={(e) => e.stopPropagation()}
              control={<Switch checked={qrCode} onChange={() => setQrCode(!qrCode)} />}
              label={'QR Code'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              title={'Title of Table'}
              name={'title'}
              fullWidth
              required
              value={title}
              label={'Title of Table'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-label">Default Sort (Ascending)</InputLabel>
              <Select
                label="Default Sort (Ascending)"
                labelId="default-sort"
                id="default-sort"
                value={sortOrder}
                onChange={(e: any) => {
                  setSortOrder(e.target.value);
                }}
              >
                {sortOrders.map((order: string) => {
                  return <MenuItem value={order}>{camelToTitle(order)}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={unitTypes}
              getOptionLabel={(option) => {
                return `${option.modelType}-${numToCurrency.format(option.basePrice)}-${option.suite}`;
              }}
              isOptionEqualToValue={(option, value) => option === value}
              freeSolo={false}
              value={units}
              onChange={(event: any, newValue: any | null) => {
                setUnits(newValue.map((option: string) => option));
              }}
              renderInput={(params) => <TextField {...params} label="Units" size="medium" />}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={packageHeaders}
              getOptionLabel={(option: string) => option}
              isOptionEqualToValue={(option, value) => option === value}
              freeSolo={false}
              value={columns}
              onChange={(event: any, newValue: any | null) => {
                setColumns(newValue.map((option: string) => option));
              }}
              renderInput={(params) => <TextField {...params} label="Package Column Headers" size="medium" />}
            />
          </Grid>
          {descriptions.map((html: any, index: number) => {
            return (
              <Grid sx={{ my: 1 }} item xs={12}>
                <Typography variant={'h4'}>
                  <strong>
                    {index === 3 ? 'Disclaimer' : 'Description'} {index !== 3 ? index + 1 : null}
                  </strong>
                </Typography>
                <TextEditor initContent={deserialize(html)} index={index} handleChange={handleTextChange} />
              </Grid>
            );
          })}
          <Grid item xs={12} sm={6} xl={4}>
            <Typography variant={'h4'}>
              <strong>Package PDF</strong>
            </Typography>
            {pdfImage ? (
              <PdfCard file={pdfImage} title={'Package PDF'} id={'0'} handleDelete={() => setPdfImage(null)} download={true} />
            ) : (
              <Dropzone onDrop={(files) => handleDrop(files)} accept="application/pdf">
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      <p>Drag and Drop or Upload Package PDF</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
          </Grid>
        </Grid>
      </Container>
      <FlexEnd sx={{ mt: 2 }}>
        <Button type="submit" variant="contained" color="success">
          {modelPackage ? 'Edit Package' : 'Create Package'}
        </Button>
      </FlexEnd>
    </form>
  );
};

interface ChildProps {
  modelPackage: IPackage | null;
  setView: Dispatch<SetStateAction<string>>;
  addModelPackage: any;
  editModelPackage: any;
}

const UNITS = gql`
  query unitMany($filter: FilterFindManyUnitInput) {
    unitMany(filter: $filter, limit: 10000) {
      _id
      basePrice
      modelType
      bathroom
      size
      outdoorSize
      level
      unitType
      exposure
      status
      suite
      totalSize
      tier
      rental
    }
  }
`;

export default EditPackage;
