import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { subHours, getMilliseconds } from 'date-fns';
import { gql, useQuery } from '@apollo/client';
import { Box, Button, Typography, Autocomplete, TextField, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import {
  numToCurrency,
  exportClosing,
  dateDiffInDays,
  netHST,
  numToCurrencyDecimal,
  sortSuites,
  convertAllDates,
} from '../../utils/Functions';
import { selectProject } from '../../features/project/projectSlice';
import VirtualizedTable from '../tables/VirtualizedTable';
import { IUnit, IUnitClosing, IDocuments, IUnitHistory } from '../../types/unit';
import { IOption } from '../../types/project';
import { IDeal, IDealDeposit, IDealRealtor, IPurchaserInfo, IThirdParty } from '../../types/CreateDealForm';
import { IUpgrade } from '../../types/colourSelection';
import PageTitle from '../common/PageTitle';
import LoadingWrapper from '../common/LoadingWrapper';
import { GlobalModal } from '../../features/modal/Modal';
import { FlexBetween, Flex } from '../../commonStyles';
import { useAppDispatch } from '../../app/hooks';
import { handleModal } from '../../features/modal/modalSlice';
import { selectUser } from '../../features/auth/authSlice';
import StandardTable from '../tables/StandardTable';
import { camelToTitle } from '../../utils/Functions';
import '../../css/closingTable.css';

const ClosingTable = () => {
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const [allColumns, setAllColumns] = useState<any[]>([]);
  const [units, setUnits] = useState<IUnit[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<any[]>([
    {
      Header: 'Suite',
      width: 75,
    },
  ]);
  const [type, setType] = useState<string>('');

  const { loading } = useQuery<IUnitClosing>(UNITCLOSING, {
    variables: { project: project._id },
    onCompleted: (data) => {
      const editable = data.unitClosing.map((o) => ({ ...o }));
      let suites = sortSuites(editable, 'unit');
      let unitSuites = sortSuites(suites, 'level');
      setUnits(unitSuites);
    },
  });

  useEffect(() => {
    let newColumns = async () => {
      let depositColumns: any = [];
      let additionalDepositColumns: any = [];
      let options: any = [];
      let miscellaneous: any = [];
      let colourSelection: any = [];
      let amendments: any = [];
      let adjustments: any = [];
      if (units.length > 0) {
        let allDeals = units
          .filter((unit: IUnit) => unit.deal !== null)
          .map((unit: IUnit) => {
            return unit?.deal?.deposit?.length;
          });

        let allAdditional = units
          .filter((unit: IUnit) => unit.deal !== null)
          .map((unit: IUnit) => {
            return unit?.deal?.additionalDeposits?.length;
          });

        let largestAdditionalIndex = await Math.max(...allAdditional);

        let largestIndex = await Math.max(...allDeals);

        let selectedAdditional = units.find((unit: any) => unit.deal && unit.deal.additionalDeposits.length === largestAdditionalIndex);

        let selectedUnit = units.find((unit: any) => unit.deal && unit.deal.deposit.length === largestIndex);

        depositColumns = selectedUnit?.deal?.deposit.map((deposit: IDealDeposit) => {
          return [
            {
              Header: `${deposit.name} Status`,
              accessor: (rowData: IUnit) => {
                const result = depositType(rowData.deal, deposit?.name!, 'type');
                if (result) {
                  return camelToTitle(result.toString());
                } else {
                  return '-';
                }
              },
            },
            {
              Header: `${deposit.name} Percentage`,
              accessor: (rowData: IUnit) => {
                const result = depositAmount(rowData.deal, deposit?.name!, 'percentage');
                if (result) {
                  return result.toFixed(2) + '%';
                } else {
                  return '-';
                }
              },
            },
            {
              Header: `${deposit.name} Amount`,
              accessor: (rowData: IUnit) => {
                const result = depositAmount(rowData.deal, deposit?.name!, 'amount');
                if (result) {
                  return numToCurrency.format(result || 0);
                } else {
                  return '-';
                }
              },
            },
            {
              Header: `${deposit.name} Due Date`,
              accessor: (rowData: IUnit) => {
                const result = depositType(rowData.deal, deposit?.name!, 'dueDate');
                if (result) {
                  return subHours(new Date(result || '01/01/2010'), 4)
                    .toISOString()
                    .split('T')[0];
                } else {
                  return '-';
                }
              },
            },
            {
              Header: `${deposit.name} Account Number`,
              accessor: (rowData: IUnit) => {
                const result = depositType(rowData.deal, deposit?.name!, 'accountNumber');
                if (result) {
                  return result;
                } else {
                  return '-';
                }
              },
            },
            {
              Header: `${deposit.name} Cheque Number`,
              accessor: (rowData: IUnit) => {
                const result = depositType(rowData.deal, deposit?.name!, 'chequeNumber');
                if (result) {
                  return result;
                } else {
                  return '-';
                }
              },
            },
            {
              Header: `${deposit.name} Amount Received`,
              accessor: (rowData: IUnit) => {
                const result = depositAmount(rowData.deal, deposit?.name!, 'chequeAmount');
                if (result) {
                  return numToCurrency.format(result || 0);
                } else {
                  return '-';
                }
              },
            },
            {
              Header: `${deposit.name} Actual Date`,
              accessor: (rowData: IUnit) => {
                const result = depositType(rowData.deal, deposit?.name!, 'chequeDate');
                if (result) {
                  return subHours(new Date(result || '01/01/2010'), 4)
                    .toISOString()
                    .split('T')[0];
                } else {
                  return '-';
                }
              },
            },
          ];
        });

        additionalDepositColumns = selectedAdditional?.deal?.additionalDeposits.map((deposit: IDealDeposit, index: number) => {
          return [
            {
              Header: `Additional Deposit ${index + 1} Status`,
              accessor: (rowData: IUnit) => {
                const result = depositType(rowData.deal, deposit?.name!, 'type', true);
                if (result) {
                  return result;
                } else {
                  return '-';
                }
              },
            },
            {
              Header: `Additional Deposit ${index + 1}  Percentage`,
              accessor: (rowData: IUnit) => {
                const result = depositAmount(rowData.deal, deposit?.name!, 'percentage', true);
                if (result) {
                  return result.toFixed(2) + '%';
                } else {
                  return '-';
                }
              },
            },
            {
              Header: `Additional Deposit ${index + 1}  Amount`,
              accessor: (rowData: IUnit) => {
                const result = depositAmount(rowData.deal, deposit?.name!, 'amount', true);
                if (result) {
                  return numToCurrency.format(result || 0);
                } else {
                  return '-';
                }
              },
            },
            {
              Header: `Additional Deposit ${index + 1}  Due Date`,
              accessor: (rowData: IUnit) => {
                const result = depositType(rowData.deal, deposit?.name!, 'dueDate', true);
                if (result) {
                  return subHours(new Date(result || '01/01/2010'), 4)
                    .toISOString()
                    .split('T')[0];
                } else {
                  return '-';
                }
              },
            },
            {
              Header: `Additional Deposit ${index + 1}  Account Number`,
              accessor: (rowData: IUnit) => {
                const result = depositType(rowData.deal, deposit?.name!, 'accountNumber', true);
                if (result) {
                  return result;
                } else {
                  return '-';
                }
              },
            },
            {
              Header: `Additional Deposit ${index + 1}  Cheque Number`,
              accessor: (rowData: IUnit) => {
                const result = depositType(rowData.deal, deposit?.name!, 'chequeNumber', true);
                if (result) {
                  return result;
                } else {
                  return '-';
                }
              },
            },
            {
              Header: `Additional Deposit ${index + 1}  Amount Received`,
              accessor: (rowData: IUnit) => {
                const result = depositAmount(rowData.deal, deposit?.name!, 'chequeAmount', true);
                if (result) {
                  return numToCurrency.format(result || 0);
                } else {
                  return '-';
                }
              },
            },
            {
              Header: `Additional Deposit ${index + 1}  Actual Date`,
              accessor: (rowData: IUnit) => {
                const result = depositType(rowData.deal, deposit?.name!, 'chequeDate', true);
                if (result) {
                  return subHours(new Date(result || '01/01/2010'), 4)
                    .toISOString()
                    .split('T')[0];
                } else {
                  return '-';
                }
              },
            },
          ];
        });

        options = await project.options.map((option: IOption) => {
          return [
            {
              Header: `${option.name}`,
              accessor: (rowData: IUnit) => {
                const result = parkingTotal(rowData.deal, option.name);
                return result;
              },
            },
            {
              Header: `${option.name} Purchased`,
              accessor: (rowData: IUnit) => {
                const result = parkingAmount(rowData.deal, option.name);
                return result;
              },
            },
          ];
        });

        amendments = [
          {
            Header: `Amendments`,
            accessor: (rowData: IUnit) => {
              const result = getAmendments(rowData);
              return result;
            },
          },
          {
            Header: `Broker Coops`,
            accessor: (rowData: IUnit) => {
              const result = getBrokerCoops(rowData);
              return result;
            },
          },
        ];

        miscellaneous = [
          {
            Header: '3rd Party Verification (Name)',
            accessor: (rowData: IUnit) => {
              if (rowData.deal) {
                if (rowData.deal.thirdParty.length > 0) {
                  let result = rowData.deal.thirdParty.map((thirdParty: IThirdParty) => {
                    return `${thirdParty.fullName}`;
                  });
                  return result.join();
                } else return '-';
              } else return '-';
            },
          },
          {
            Header: 'Sales Agent',
            accessor: (rowData: IUnit) => {
              if (rowData.deal) {
                if (rowData.deal.salesRep) {
                  return rowData.deal.salesRep.fullName;
                } else return '-';
              } else return '-';
            },
          },
          {
            Header: 'Waitlist',
            accessor: (rowData: IUnit) => {
              if (rowData.deal) {
                if (rowData.deal.joinWaitlist.length) {
                  let result = rowData.deal.joinWaitlist.map((waitlist: string) => {
                    return `${waitlist}`;
                  });
                  return result.join(', ');
                } else return '-';
              } else return '-';
            },
          },
          {
            Header: 'Custom',
            accessor: (rowData: IUnit) => {
              if (rowData.custom) {
                return rowData.custom
              } else return '-';
            },
          },
          {
            Header: 'Discount (%)',
            accessor: (rowData: IUnit) => '-',
          },
          {
            Header: 'Total Price Discount',
            accessor: (rowData: IUnit) => '-',
          },
          {
            Header: 'Tags',
            accessor: (rowData: IUnit) => {
              if (rowData.deal) {
                let result = rowData.deal.tags.map((tag: string) => tag);
                return result.join(', ');
              } else return '';
            },
          },
          {
            Header: 'List Price',
            accessor: (rowData: IUnit) => {
              return numToCurrency.format(rowData.basePrice);
            },
          },
        ];

        colourSelection = [
          {
            Header: 'UPG Session 1',
            accessor: (rowData: IUnit) => {
              if (rowData.deal) {
                if (rowData.deal.upgradeSetOne) {
                  return numToCurrency.format(
                    rowData.deal.upgradeSetOne.upgradeSet
                      .filter((upgrade: IUpgrade) => upgrade.mainCategory !== 'Credit')
                      .reduce((a: any, b: any) => {
                        return a + b.price;
                      }, 0)
                  );
                } else return '-';
              } else return '-';
            },
          },
          {
            Header: 'Decor Specialist 1',
            accessor: (rowData: IUnit) => {
              if (rowData.deal) {
                if (rowData.deal.upgradeSetOne) {
                  return rowData.deal.upgradeSetOne.salesRep ? rowData.deal.upgradeSetOne.salesRep.fullName : '-';
                } else return '-';
              } else return '-';
            },
          },
          {
            Header: 'UPG Session 2',
            accessor: (rowData: IUnit) => {
              if (rowData.deal) {
                if (rowData.deal.upgradeSetTwo) {
                  return numToCurrency.format(
                    rowData.deal.upgradeSetTwo.upgradeSet
                      .filter((upgrade: IUpgrade) => upgrade.mainCategory !== 'Credit')
                      .reduce((a: any, b: any) => {
                        return a + b.price;
                      }, 0)
                  );
                } else return '-';
              } else return '-';
            },
          },
          {
            Header: 'Decor Specialist 2',
            accessor: (rowData: IUnit) => {
              if (rowData.deal) {
                if (rowData.deal.upgradeSetTwo) {
                  return rowData.deal.upgradeSetTwo.salesRep ? rowData.deal.upgradeSetTwo.salesRep.fullName : '-';
                } else return '-';
              } else return '-';
            },
          },
          {
            Header: 'UPG Session 3',
            accessor: (rowData: IUnit) => {
              if (rowData.deal) {
                if (rowData.deal.upgradeSetThree) {
                  return numToCurrency.format(
                    rowData.deal.upgradeSetThree.upgradeSet
                      .filter((upgrade: IUpgrade) => upgrade.mainCategory !== 'Credit')
                      .reduce((a: any, b: any) => {
                        return a + b.price;
                      }, 0)
                  );
                } else return '-';
              } else return '-';
            },
          },
          {
            Header: 'Decor Specialist 3',
            accessor: (rowData: IUnit) => {
              if (rowData.deal) {
                if (rowData.deal.upgradeSetThree) {
                  return rowData.deal.upgradeSetThree.salesRep ? rowData.deal.upgradeSetThree.salesRep.fullName : '-';
                } else return '-';
              } else return '-';
            },
          },
          {
            Header: 'Total Decor Cost',
            accessor: (rowData: IUnit) => {
              if (rowData.deal) {
                let upgradeOne = 0;
                let upgradeTwo = 0;
                let upgradeThree = 0;
                if (rowData.deal.upgradeSetOne) {
                  upgradeOne = rowData.deal.upgradeSetOne.upgradeSet
                    .filter((upgrade: IUpgrade) => upgrade.mainCategory !== 'Credit')
                    .reduce((a: any, b: any) => {
                      return a + b.price;
                    }, 0);
                }
                if (rowData.deal.upgradeSetTwo) {
                  upgradeTwo = rowData.deal.upgradeSetTwo.upgradeSet
                    .filter((upgrade: IUpgrade) => upgrade.mainCategory !== 'Credit')
                    .reduce((a: any, b: any) => {
                      return a + b.price;
                    }, 0);
                }
                if (rowData.deal.upgradeSetThree) {
                  upgradeThree = rowData.deal.upgradeSetThree.upgradeSet
                    .filter((upgrade: IUpgrade) => upgrade.mainCategory !== 'Credit')
                    .reduce((a: any, b: any) => {
                      return a + b.price;
                    }, 0);
                }

                if (!rowData.deal.upgradeSetOne) return '-';

                return numToCurrencyDecimal.format(parseFloat((upgradeOne + upgradeTwo + upgradeThree).toFixed(2)));
              } else return '-';
            },
          },
          {
            Header: 'Upgrade Credits',
            accessor: (rowData: IUnit) => {
              if (rowData.deal && (rowData.deal.upgradeSetOne || rowData.deal.upgradeSetTwo || rowData.deal.upgradeSetTwo)) {
                let creditOne = 0;
                let creditTwo = 0;
                let creditThree = 0;
                if (rowData.deal.upgradeSetOne) {
                  let creditUpgradeSetOne = rowData.deal.upgradeSetOne.upgradeSet.find(
                    (upgrade: IUpgrade) => upgrade.mainCategory === 'Credit'
                  );
                  if (creditUpgradeSetOne) {
                    creditOne = creditUpgradeSetOne.price;
                  }
                }
                if (rowData.deal.upgradeSetTwo) {
                  let creditUpgradeSetTwo = rowData.deal.upgradeSetTwo.upgradeSet.find(
                    (upgrade: IUpgrade) => upgrade.mainCategory === 'Credit'
                  );
                  if (creditUpgradeSetTwo) {
                    creditTwo = creditUpgradeSetTwo.price;
                  }
                }
                if (rowData.deal.upgradeSetThree) {
                  let creditUpgradeSetThree = rowData.deal.upgradeSetThree.upgradeSet.find(
                    (upgrade: IUpgrade) => upgrade.mainCategory === 'Credit'
                  );
                  if (creditUpgradeSetThree) {
                    creditThree = creditUpgradeSetThree.price;
                  }
                }

                if (!rowData.deal.upgradeSetOne) return '-';

                return numToCurrency.format(creditOne + creditTwo + creditThree);
              } else return '-';
            },
          },
          {
            Header: 'Total Decor Cost Net Upgrade Credits',
            accessor: (rowData: IUnit) => {
              if (rowData.deal) {
                let upgradeOne = 0;
                let upgradeTwo = 0;
                let upgradeThree = 0;
                let creditOne = 0;
                let creditTwo = 0;
                let creditThree = 0;
                if (rowData.deal.upgradeSetOne) {
                  upgradeOne = rowData.deal.upgradeSetOne.upgradeSet
                    .filter((upgrade: IUpgrade) => upgrade.mainCategory !== 'Credit')
                    .reduce((a: any, b: any) => {
                      return a + b.price;
                    }, 0);
                  let creditUpgradeSetOne = rowData.deal.upgradeSetOne.upgradeSet.find(
                    (upgrade: IUpgrade) => upgrade.mainCategory === 'Credit'
                  );
                  if (creditUpgradeSetOne) {
                    creditOne = creditUpgradeSetOne.price;
                  }
                }
                if (rowData.deal.upgradeSetTwo) {
                  upgradeTwo = rowData.deal.upgradeSetTwo.upgradeSet
                    .filter((upgrade: IUpgrade) => upgrade.mainCategory !== 'Credit')
                    .reduce((a: any, b: any) => {
                      return a + b.price;
                    }, 0);
                  let creditUpgradeSetTwo = rowData.deal.upgradeSetTwo.upgradeSet.find(
                    (upgrade: IUpgrade) => upgrade.mainCategory === 'Credit'
                  );
                  if (creditUpgradeSetTwo) {
                    creditTwo = creditUpgradeSetTwo.price;
                  }
                }
                if (rowData.deal.upgradeSetThree) {
                  upgradeThree = rowData.deal.upgradeSetThree.upgradeSet
                    .filter((upgrade: IUpgrade) => upgrade.mainCategory !== 'Credit')
                    .reduce((a: any, b: any) => {
                      return a + b.price;
                    }, 0);
                  let creditUpgradeSetThree = rowData.deal.upgradeSetThree.upgradeSet.find(
                    (upgrade: IUpgrade) => upgrade.mainCategory === 'Credit'
                  );
                  if (creditUpgradeSetThree) {
                    creditThree = creditUpgradeSetThree.price;
                  }
                }

                if (!rowData.deal.upgradeSetOne) return '-';

                return numToCurrencyDecimal.format(
                  parseFloat((upgradeOne + upgradeTwo + upgradeThree - (creditOne + creditTwo + creditThree)).toFixed(2))
                );
              } else return '-';
            },
          },
          {
            Header: 'Total Decor Cost Net HST',
            accessor: (rowData: IUnit) => {
              if (rowData.deal) {
                let upgradeOne = 0;
                let upgradeTwo = 0;
                let upgradeThree = 0;
                let creditOne = 0;
                let creditTwo = 0;
                let creditThree = 0;
                if (rowData.deal.upgradeSetOne) {
                  upgradeOne = rowData.deal.upgradeSetOne.upgradeSet
                    .filter((upgrade: IUpgrade) => upgrade.mainCategory !== 'Credit')
                    .reduce((a: any, b: any) => {
                      return a + b.price;
                    }, 0);
                  let creditUpgradeSetOne = rowData.deal.upgradeSetOne.upgradeSet.find(
                    (upgrade: IUpgrade) => upgrade.mainCategory === 'Credit'
                  );
                  if (creditUpgradeSetOne) {
                    creditOne = creditUpgradeSetOne.price;
                  }
                }
                if (rowData.deal.upgradeSetTwo) {
                  upgradeTwo = rowData.deal.upgradeSetTwo.upgradeSet
                    .filter((upgrade: IUpgrade) => upgrade.mainCategory !== 'Credit')
                    .reduce((a: any, b: any) => {
                      return a + b.price;
                    }, 0);
                  let creditUpgradeSetTwo = rowData.deal.upgradeSetTwo.upgradeSet.find(
                    (upgrade: IUpgrade) => upgrade.mainCategory === 'Credit'
                  );
                  if (creditUpgradeSetTwo) {
                    creditTwo = creditUpgradeSetTwo.price;
                  }
                }
                if (rowData.deal.upgradeSetThree) {
                  upgradeThree = rowData.deal.upgradeSetThree.upgradeSet
                    .filter((upgrade: IUpgrade) => upgrade.mainCategory !== 'Credit')
                    .reduce((a: any, b: any) => {
                      return a + b.price;
                    }, 0);
                  let creditUpgradeSetThree = rowData.deal.upgradeSetThree.upgradeSet.find(
                    (upgrade: IUpgrade) => upgrade.mainCategory === 'Credit'
                  );
                  if (creditUpgradeSetThree) {
                    creditThree = creditUpgradeSetThree.price;
                  }
                }

                if (!rowData.deal.upgradeSetOne) return '-';

                return numToCurrencyDecimal.format(
                  parseFloat(((upgradeOne + upgradeTwo + upgradeThree - (creditOne + creditTwo + creditThree)) / 1.13).toFixed(2))
                );
              } else return '-';
            },
          },
          {
            Header: 'Total Decor Cost Net HST + Total Purchase Price Net HST',
            accessor: (rowData: IUnit) => {
              if (rowData.deal) {
                let upgradeOne = 0;
                let upgradeTwo = 0;
                let upgradeThree = 0;
                let creditOne = 0;
                let creditTwo = 0;
                let creditThree = 0;
                if (rowData.deal.upgradeSetOne) {
                  upgradeOne = rowData.deal.upgradeSetOne.upgradeSet
                    .filter((upgrade: IUpgrade) => upgrade.mainCategory !== 'Credit')
                    .reduce((a: any, b: any) => {
                      return a + b.price;
                    }, 0);
                  let creditUpgradeSetOne = rowData.deal.upgradeSetOne.upgradeSet.find(
                    (upgrade: IUpgrade) => upgrade.mainCategory === 'Credit'
                  );
                  if (creditUpgradeSetOne) {
                    creditOne = creditUpgradeSetOne.price;
                  }
                }
                if (rowData.deal.upgradeSetTwo) {
                  upgradeTwo = rowData.deal.upgradeSetTwo.upgradeSet
                    .filter((upgrade: IUpgrade) => upgrade.mainCategory !== 'Credit')
                    .reduce((a: any, b: any) => {
                      return a + b.price;
                    }, 0);
                  let creditUpgradeSetTwo = rowData.deal.upgradeSetTwo.upgradeSet.find(
                    (upgrade: IUpgrade) => upgrade.mainCategory === 'Credit'
                  );
                  if (creditUpgradeSetTwo) {
                    creditTwo = creditUpgradeSetTwo.price;
                  }
                }
                if (rowData.deal.upgradeSetThree) {
                  upgradeThree = rowData.deal.upgradeSetThree.upgradeSet
                    .filter((upgrade: IUpgrade) => upgrade.mainCategory !== 'Credit')
                    .reduce((a: any, b: any) => {
                      return a + b.price;
                    }, 0);
                  let creditUpgradeSetThree = rowData.deal.upgradeSetThree.upgradeSet.find(
                    (upgrade: IUpgrade) => upgrade.mainCategory === 'Credit'
                  );
                  if (creditUpgradeSetThree) {
                    creditThree = creditUpgradeSetThree.price;
                  }
                }

                return numToCurrencyDecimal.format(
                  parseFloat(
                    (
                      (upgradeOne + upgradeTwo + upgradeThree - (creditOne + creditTwo + creditThree)) / 1.13 +
                      netHST(rowData.deal.totalPrice!)
                    ).toFixed(2)
                  )
                );
              } else return '-';
            },
          },
        ];

        adjustments = [
          // {
          //   Header: 'Adjustment Total',
          //   accessor: (rowData: IUnit) => {
          //     if (rowData.deal) {
          //       console.log(rowData.deal.adjustments, 'adjustments');
          //       if (rowData.deal.adjustments.length) {
          //         let capsTotal = rowData.deal.adjustments
          //           .filter((adjustment: any) => adjustment.type === 'Charge')
          //           .reduce((a: any, b: any) => {
          //             return a + b.value;
          //           }, 0);
          //         let chargePerDepositTotal = rowData.deal.adjustments
          //           .filter((adjustment: any) => adjustment.type === 'ChargePerDeposit)')
          //           .reduce((a: any, b: any) => {
          //             return a + b.value * rowData.deal.deposit.length;
          //           }, 0);
          //         let creditTotal = rowData.deal.adjustments
          //           .filter((adjustment: any) => adjustment.type === 'Credit')
          //           .reduce((a, b) => a + b.value, 0);
          //         return numToCurrencyDecimal.format(capsTotal + chargePerDepositTotal - creditTotal);
          //       } else return '-';
          //     } else return '-';
          //   },
          // },
          // {
          //   Header: 'Total Price Net HST with Adjustment',
          //   accessor: (rowData: IUnit) => {
          //     if (rowData.deal) {
          //       let upgradeOne = 0;
          //       let upgradeTwo = 0;
          //       let upgradeThree = 0;
          //       let creditOne = 0;
          //       let creditTwo = 0;
          //       let creditThree = 0;
          //       let capsTotal = 0;
          //       let creditTotal = 0;
          //       let chargePerDepositTotal = 0;
          //       if (rowData.deal.upgradeSetOne) {
          //         upgradeOne = rowData.deal.upgradeSetOne.upgradeSet
          //           .filter((upgrade: IUpgrade) => upgrade.mainCategory !== 'Credit')
          //           .reduce((a: any, b: any) => {
          //             return a + b.price;
          //           }, 0);
          //         let creditUpgradeSetOne = rowData.deal.upgradeSetOne.upgradeSet.find(
          //           (upgrade: IUpgrade) => upgrade.mainCategory === 'Credit'
          //         );
          //         if (creditUpgradeSetOne) {
          //           creditOne = creditUpgradeSetOne.price;
          //         }
          //       }
          //       if (rowData.deal.upgradeSetTwo) {
          //         upgradeTwo = rowData.deal.upgradeSetTwo.upgradeSet
          //           .filter((upgrade: IUpgrade) => upgrade.mainCategory !== 'Credit')
          //           .reduce((a: any, b: any) => {
          //             return a + b.price;
          //           }, 0);
          //         let creditUpgradeSetTwo = rowData.deal.upgradeSetTwo.upgradeSet.find(
          //           (upgrade: IUpgrade) => upgrade.mainCategory === 'Credit'
          //         );
          //         if (creditUpgradeSetTwo) {
          //           creditTwo = creditUpgradeSetTwo.price;
          //         }
          //       }
          //       if (rowData.deal.upgradeSetThree) {
          //         upgradeThree = rowData.deal.upgradeSetThree.upgradeSet
          //           .filter((upgrade: IUpgrade) => upgrade.mainCategory !== 'Credit')
          //           .reduce((a: any, b: any) => {
          //             return a + b.price;
          //           }, 0);
          //         let creditUpgradeSetThree = rowData.deal.upgradeSetThree.upgradeSet.find(
          //           (upgrade: IUpgrade) => upgrade.mainCategory === 'Credit'
          //         );
          //         if (creditUpgradeSetThree) {
          //           creditThree = creditUpgradeSetThree.price;
          //         }
          //       }
          //       if (rowData.deal.adjustments.length) {
          //         capsTotal = rowData.deal.adjustments
          //           .filter((adjustment: any) => adjustment.type === 'Charge')
          //           .reduce((a: any, b: any) => {
          //             return a + b.value;
          //           }, 0);
          //         chargePerDepositTotal = rowData.deal.adjustments
          //           .filter((adjustment: any) => adjustment.type === 'ChargePerDeposit)')
          //           .reduce((a: any, b: any) => {
          //             return a + (b.value * rowData.deal.deposit.length)
          //           }, 0);
          //         creditTotal = rowData.deal.adjustments
          //           .filter((adjustment: any) => adjustment.type === 'Credit')
          //           .reduce((a, b) => a + b.value, 0);
          //         return numToCurrencyDecimal.format(
          //           parseFloat(
          //             (
          //               (upgradeOne + upgradeTwo + upgradeThree - (creditOne + creditTwo + creditThree)) / 1.13 +
          //               netHST(rowData.deal.totalPrice!) +
          //               capsTotal +
          //               chargePerDepositTotal -
          //               creditTotal
          //             ).toFixed(2)
          //           )
          //         );
          //       } else
          //         return numToCurrencyDecimal.format(
          //           parseFloat(
          //             (
          //               (upgradeOne + upgradeTwo + upgradeThree - (creditOne + creditTwo + creditThree)) / 1.13 +
          //               netHST(rowData.deal.totalPrice!) +
          //               capsTotal -
          //               creditTotal
          //             ).toFixed(2)
          //           )
          //         );
          //     } else return '-';
          //   },
          // },
          {
            Header: 'Rental Guarantee',
            accessor: (rowData: IUnit) => {
              if (rowData) {
                if (rowData.rental) {
                  return numToCurrency.format(rowData.rental);
                }
              } else return '-';
            },
          },
          {
            Header: 'Tier',
            accessor: (rowData: IUnit) => {
              if (rowData) {
                if (rowData.tier) {
                  return rowData.tier;
                }
              } else return '-';
            },
          },
          {
            Header: 'Assignment Date',
            accessor: (rowData: IUnit) => {
              if (rowData) {
                if (rowData.assignment) {
                  return convertAllDates(rowData.assignment.signDate, 'PP');
                }
              } else return '-';
            },
          },
          {
            Header: 'Assignees',
            accessor: (rowData: IUnit) => {
              if (rowData) {
                if (rowData.assignment) {
                  return rowData.assignment.purchasers.map((purchaser: IPurchaserInfo) => purchaser.fullName).join(', ');
                }
              } else return '-';
            },
          },
          {
            Header: 'RDS Reviewer',
            accessor: (rowData: IUnit) => {
              if (rowData.deal) {
                if (rowData.deal.auditor) {
                  return rowData.deal.auditor.fullName;
                } else return '-';
              } else return '-';
            },
          },
          {
            Header: 'RDS Review Date',
            accessor: (rowData: IUnit) => {
              if (rowData.deal) {
                if (rowData.deal.auditDate) {
                  return convertAllDates(rowData.deal.auditDate, 'PPpp');
                } else return '-';
              } else return '-';
            },
          },
          {
            Header: 'Identifier',
            accessor: (rowData: IUnit) => {
              if (rowData.deal) {
                let uniqueDate = getMilliseconds(new Date(rowData.deal.createdAt)).toString();
                let uniqueId = rowData.deal._id!.slice(-5);
                let unitTransfer = rowData.history.find(
                  (history: IUnitHistory) => history && history.type === 'Unit Transfer' && history.description.includes('transferred to')
                );
                if (unitTransfer) {
                  let previousSuite = unitTransfer.description.match('Transfer(.*)transferred');
                  if (previousSuite) {
                    return uniqueDate.concat(uniqueId).concat(`-${previousSuite[1].trim()}`);
                  }
                } else {
                  return uniqueDate.concat(uniqueId);
                }
              } else return '-';
            },
          },
        ];
      }

      const subItems = depositColumns ? depositColumns.flat() : [];
      const subAdditionalItems = additionalDepositColumns ? additionalDepositColumns.flat() : [];
      let subOptions = options.flat();
      const totalColumns = [
        ...columns,
        ...subOptions,
        ...secondColumn,
        ...subItems,
        ...subAdditionalItems,
        ...amendments,
        ...miscellaneous,
        ...colourSelection,
        ...adjustments,
      ];
      setAllColumns(totalColumns);
    };
    newColumns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [units]);

  const getColumnWidth = (rows: any, accessor: string, headerText: string) => {
    const maxWidth = 600;
    const magicSpacing = 20;
    const cellLength = Math.max(...rows.map((row: any) => (`${row[accessor]}` || '').length), headerText.length);

    return Math.min(maxWidth, cellLength * magicSpacing);
  };

  const depositType = (deal: IDeal, depositName: string, type: string, additional: boolean = false) => {
    if (deal) {
      let deposit;
      if (additional) {
        deposit = deal.additionalDeposits.find((d) => d.name === depositName);
      } else {
        deposit = deal.deposit.find((d) => d.name === depositName);
      }
      if (deposit) {
        if (type === 'type') {
          return deposit ? deposit.chequeType : null;
        } else if (type === 'dueDate') {
          return deposit?.dueDate ? deposit.dueDate : null;
        } else if (type === 'chequeDate') {
          return deposit?.chequeDate ? deposit.chequeDate : null;
        } else if (type === 'accountNumber') {
          return deposit?.accountNumber ? deposit.accountNumber : null;
        } else if (type === 'chequeNumber') {
          return deposit?.chequeNumber ? deposit.chequeNumber : null;
        } else if (type === 'amountReceived') {
          return deposit?.chequeAmount ? deposit.chequeAmount : null;
        }
      } else return null;
    } else return null;
  };

  const depositAmount = (deal: IDeal, depositType: string, type: string, additional: boolean = false) => {
    if (deal) {
      let deposit;
      if (additional) {
        deposit = deal.additionalDeposits.find((d) => d.name === depositType);
      } else {
        deposit = deal.deposit.find((d) => d.name === depositType);
      }
      if (type === 'amount') {
        return deposit ? deposit.amount : null;
      } else if (type === 'chequeAmount') {
        return deposit ? deposit.chequeAmount : null;
      } else if (type === 'percentage') {
        return deposit ? (deposit?.amount! / deal?.totalPrice!) * 100 : null;
      }
    } else return null;
  };

  const parkingTotal = (deal: IDeal, type: string) => {
    if (deal) {
      const option = deal.options.find((d) => d.name === type);
      return option ? numToCurrency.format(option?.amount!) : '-';
    } else return '-';
  };

  const parkingAmount = (deal: IDeal, type: string) => {
    if (deal) {
      const option = deal.options.find((d) => d.name === type);
      return option ? option.purchaseAmount : '-';
    } else return '-';
  };

  const getAmendments = (unit: IUnit) => {
    if (unit.deal) {
      let result = unit.deal.documents.map((document: IDocuments) => {
        if (document.type !== 'Coop') {
          return `${document.name} - ${document.status}`;
        }
      });
      return result.join(', ');
    } else return '-';
  };

  const getBrokerCoops = (unit: IUnit) => {
    if (unit.deal) {
      let result = unit.deal.documents.find((document: IDocuments) => {
        return document.type === 'Coop';
      });
      if (result) {
        return result.status;
      } else return '-';
    } else return '-';
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Suite',
        accessor: (rowData: IUnit) => rowData.suite,
        width: 75,
      },
      {
        Header: 'Count',
        accessor: (rowData: IUnit, index: number) => index + 1,
        width: 75,
      },
      {
        Header: 'Status',
        accessor: (rowData: IUnit) => rowData.status,
        width: 75,
      },
      {
        Header: 'Allocation',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              let realtors = rowData.deal.realtor
                .map((realtor: IDealRealtor) => {
                  return `${realtor.fullName}`;
                })
                .join(', ');
              return realtors;
            } else return '';
          } else {
            if (rowData.allocation) {
              return rowData.allocation.fullName;
            } else if (rowData.tempAllocation) {
              return rowData.tempAllocation;
            } else return '';
          }
        },
      },
      {
        Header: 'Allocation Type',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal?.tags.includes('Friends and Family')) {
              return 'Friends and Family';
            } else if (rowData.deal?.tags.includes('Public')) {
              return 'Public';
            } else return 'Agent';
          } else return '';
        },
      },
      {
        Header: 'Unit',
        accessor: (rowData: IUnit) => rowData.unit,
      },
      {
        Header: 'Level',
        field: 'level',
        accessor: (rowData: IUnit) => rowData.level,
      },
      {
        Header: 'Model',
        field: 'model',
        accessor: (rowData: IUnit) => rowData.modelType,
      },
      {
        Header: 'Outdoor Type',
        field: 'outdoorType',
        accessor: (rowData: IUnit) => rowData.outdoorType,
      },
      {
        Header: 'Size',
        field: 'size',
        accessor: (rowData: IUnit) => rowData.size,
      },
      {
        Header: 'Unit Type',
        field: 'unitType',
        accessor: (rowData: IUnit) => rowData.unitType,
      },
      {
        Header: 'Exposure',
        field: 'exposure',
        accessor: (rowData: IUnit) => rowData.exposure,
      },
      {
        Header: 'Bathroom',
        field: 'bathroom',
        accessor: (rowData: IUnit) => rowData.bathroom,
      },
      {
        Header: 'Outdoor Size',
        field: 'outdoorSize',
        accessor: (rowData: IUnit) => rowData.outdoorSize,
      },
      {
        Header: 'PPSF',
        field: 'PPSF',
        accessor: (rowData: IUnit) => numToCurrency.format(rowData.basePrice / rowData.size),
      },
      {
        Header: 'Unit Price',
        field: 'unitPrice',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return numToCurrency.format(rowData.deal.basePrice);
          } else {
            return numToCurrency.format(rowData.basePrice);
          }
        },
      },
      {
        Header: 'Unit Price Net of HST',
        field: 'unitPrice',
        accessor: (rowData: IUnit) =>
          rowData.type === 'commercial'
            ? numToCurrencyDecimal.format(rowData.basePrice)
            : numToCurrencyDecimal.format(netHST(rowData.basePrice)),
      },
      {
        Header: 'Total Price',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return numToCurrency.format(rowData.deal?.totalPrice!);
          } else return '-';
        },
      },
      {
        Header: 'Total Price Net of HST',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return rowData.type === 'commercial'
              ? numToCurrencyDecimal.format(rowData.deal?.totalPrice!)
              : numToCurrencyDecimal.format(netHST(rowData.deal?.totalPrice!));
          } else return '-';
        },
      },
      {
        Header: 'Deal Sent Date',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            let result = convertAllDates(rowData.deal.createdAt, 'PP');
            return result;
          } else return '-';
        },
      },
      {
        Header: 'Signing Date',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.signDate) {
              return convertAllDates(rowData.deal.signDate, 'PP');
            } else return '-';
          } else return '-';
        },
      },
      {
        Header: 'Execute Date',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.executeDate) {
              return convertAllDates(new Date(rowData.deal.executeDate), 'PP');
            } else return '-';
          } else return '-';
        },
      },
      {
        Header: 'Firming Date',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.firmDate) {
              return convertAllDates(new Date(rowData.deal.firmDate), 'PP');
            } else return '-';
          } else return '-';
        },
      },
      {
        Header: 'Extension',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal?.signDate || rowData.deal?.firmDate) {
              const signDate = new Date(rowData.deal?.signDate!);
              const firmDate = new Date(rowData.deal?.firmDate!);
              const dateDifference = dateDiffInDays(signDate, firmDate);
              return dateDifference > 10 ? 'Yes' : 'No';
            } else return '-';
          }
          return '-';
        },
      },
    ],
    []
  );

  const secondColumn = useMemo(
    () => [
      {
        Header: 'Purchaser One First Name',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return rowData.deal.purchasers[0].firstName;
          } else return '-';
        },
        width: getColumnWidth(allColumns, 'accessor', 'Purchaser One First Name'),
      },
      {
        Header: 'Purchaser One Last Name',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return rowData.deal.purchasers[0].lastName;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser One D.O.B',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return subHours(new Date(rowData.deal.purchasers[0].dob), 5).toISOString().split('T')[0];
          } else return '-';
        },
      },
      {
        Header: 'Purchaser One Street Address',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return `${rowData.deal.purchasers[0].unit ? rowData.deal.purchasers[0].unit : ''} ${rowData.deal.purchasers[0].streetAddress}`;
          } else return '-';
        },
        width: getColumnWidth(allColumns, 'accessor', 'Purchaser One Street Address'),
      },
      {
        Header: 'Purchaser One City',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return rowData.deal.purchasers[0].city;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser One Province',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return rowData.deal.purchasers[0].province;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser One Postal Code',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return rowData.deal.purchasers[0].postalCode;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser One Phone',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return rowData.deal.purchasers[0].primaryPhone;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser One E-mail',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return rowData.deal.purchasers[0].email;
          } else return '-';
        },
        width: getColumnWidth(allColumns, 'accessor', 'Purchaser One E-mail'),
      },
      {
        Header: 'Purchaser One SIN',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && (user.type === 'Manager' || user.type === 'Developer')) {
            return rowData.deal.purchasers[0].sin;
          } else if (rowData.deal && rowData.deal.purchasers[0] && rowData.deal.purchasers[0].sin) {
            return 'received';
          } else return 'N/A';
        },
      },
      {
        Header: 'Purchaser One Employer',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return rowData.deal.purchasers[0].employer;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser One Occupation',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return rowData.deal.purchasers[0].occupation;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser One Purchaser Type',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return rowData.deal.purchasers[0].purchaserType;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser One ID Type',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return rowData.deal.purchasers[0].idType;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser One ID Number',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return rowData.deal.purchasers[0].idNumber;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser One ID Expiry',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return subHours(new Date(rowData.deal.purchasers[0].idExpiry), 5).toISOString().split('T')[0];
          } else return '-';
        },
      },
      {
        Header: 'Purchaser One ID Jurisdiction',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return rowData.deal.purchasers[0].idJurisdiction;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser One Proof Type',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return rowData.deal.purchasers[0].proofType ? rowData.deal.purchasers[0].proofType : '-';
          } else return '-';
        },
      },
      {
        Header: 'Purchaser One Proof Number',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return rowData.deal.purchasers[0].proofNumber ? rowData.deal.purchasers[0].proofNumber : '-';
          } else return '-';
        },
      },
      {
        Header: 'Purchaser One Proof Expiry',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return rowData.deal.purchasers[0].proofExpiry
              ? subHours(new Date(rowData.deal.purchasers[0].proofExpiry), 5).toISOString().split('T')[0]
              : '-';
          } else return '-';
        },
      },
      {
        Header: 'Purchaser One Business Number',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return rowData.deal.purchasers[0].businessNumber ? rowData.deal.purchasers[0].businessNumber : '-';
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Two First Name',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 1) {
            return rowData.deal.purchasers[1].firstName;
          } else return '-';
        },
        width: getColumnWidth(allColumns, 'accessor', 'Purchaser Two First Name'),
      },
      {
        Header: 'Purchaser Two Last Name',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 1) {
            return rowData.deal.purchasers[1].lastName;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Two D.O.B',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 1) {
            return subHours(new Date(rowData.deal.purchasers[1].dob), 5).toISOString().split('T')[0];
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Two Street Address',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 1) {
            return `${rowData.deal.purchasers[1].unit ? rowData.deal.purchasers[1].unit : ''} ${rowData.deal.purchasers[1].streetAddress}`;
          } else return '-';
        },
        width: getColumnWidth(allColumns, 'accessor', 'Purchaser One Street Address'),
      },
      {
        Header: 'Purchaser Two City',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 1) {
            return rowData.deal.purchasers[1].city;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Two Province',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 1) {
            return rowData.deal.purchasers[1].province;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Two Postal Code',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 1) {
            return rowData.deal.purchasers[1].postalCode;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Two Phone',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 1) {
            return rowData.deal.purchasers[1].primaryPhone;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Two E-mail',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 1) {
            return rowData.deal.purchasers[1].email;
          } else return '-';
        },
        width: getColumnWidth(allColumns, 'accessor', 'Purchaser One E-mail'),
      },
      {
        Header: 'Purchaser Two SIN',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 1 && (user.type === 'Manager' || user.type === 'Developer')) {
            return rowData.deal.purchasers[1].sin;
          } else if (rowData.deal && rowData.deal.purchasers[1] && rowData.deal.purchasers[1].sin) {
            return 'received';
          } else return 'N/A';
        },
      },
      {
        Header: 'Purchaser Two Employer',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 1) {
            return rowData.deal.purchasers[1].employer;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Two Occupation',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 1) {
            return rowData.deal.purchasers[1].occupation;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Two Purchaser Type',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 1) {
            return rowData.deal.purchasers[1].purchaserType;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Two ID Type',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 1) {
            return rowData.deal.purchasers[1].idType;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Two ID Number',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 1) {
            return rowData.deal.purchasers[1].idNumber;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Two ID Expiry',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 1) {
            return subHours(new Date(rowData.deal.purchasers[1].idExpiry), 5).toISOString().split('T')[0];
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Two ID Jurisdiction',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 1) {
            return rowData.deal.purchasers[1].idJurisdiction;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Two Proof Type',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 1) {
            return rowData.deal.purchasers[1].proofType ? rowData.deal.purchasers[1].proofType : '-';
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Two Proof Number',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 1) {
            return rowData.deal.purchasers[1].proofNumber ? rowData.deal.purchasers[1].proofNumber : '-';
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Two Proof Expiry',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 1) {
            return rowData.deal.purchasers[1].proofExpiry
              ? subHours(new Date(rowData.deal.purchasers[1].proofExpiry), 5).toISOString().split('T')[0]
              : '-';
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Two Business Number',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 1) {
            return rowData.deal.purchasers[1].businessNumber ? rowData.deal.purchasers[1].businessNumber : '-';
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Three First Name',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 2) {
            return rowData.deal.purchasers[2].firstName;
          } else return '-';
        },
        width: getColumnWidth(allColumns, 'accessor', 'Purchaser One First Name'),
      },
      {
        Header: 'Purchaser Three Last Name',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 2) {
            return rowData.deal.purchasers[2].lastName;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Three D.O.B',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 2) {
            return subHours(new Date(rowData.deal.purchasers[2].dob), 5).toISOString().split('T')[0];
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Three Street Address',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 2) {
            return `${rowData.deal.purchasers[2].unit ? rowData.deal.purchasers[2].unit : ''} ${rowData.deal.purchasers[2].streetAddress}`;
          } else return '-';
        },
        width: getColumnWidth(allColumns, 'accessor', 'Purchaser One Street Address'),
      },
      {
        Header: 'Purchaser Three City',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 2) {
            return rowData.deal.purchasers[2].city;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Three Province',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 2) {
            return rowData.deal.purchasers[2].province;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Three Postal Code',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 2) {
            return rowData.deal.purchasers[2].postalCode;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Three Phone',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 2) {
            return rowData.deal.purchasers[2].primaryPhone;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Three E-mail',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 2) {
            return rowData.deal.purchasers[2].email;
          } else return '-';
        },
        width: getColumnWidth(allColumns, 'accessor', 'Purchaser One E-mail'),
      },
      {
        Header: 'Purchaser Three SIN',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 2 && (user.type === 'Manager' || user.type === 'Developer')) {
            return rowData.deal.purchasers[2].sin;
          } else if (rowData.deal && rowData.deal.purchasers[2] && rowData.deal.purchasers[2].sin) {
            return 'received';
          } else return 'N/A';
        },
      },
      {
        Header: 'Purchaser Three Employer',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 2) {
            return rowData.deal.purchasers[2].employer;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Three Occupation',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 2) {
            return rowData.deal.purchasers[2].occupation;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Three Purchaser Type',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 2) {
            return rowData.deal.purchasers[2].purchaserType;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Three ID Type',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 2) {
            return rowData.deal.purchasers[2].idType;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Three ID Number',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 2) {
            return rowData.deal.purchasers[2].idNumber;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Three ID Expiry',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 2) {
            return subHours(new Date(rowData.deal.purchasers[2].idExpiry), 5).toISOString().split('T')[0];
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Three ID Jurisdiction',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 2) {
            return rowData.deal.purchasers[2].idJurisdiction;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Three Proof Type',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 2) {
            return rowData.deal.purchasers[2].proofType ? rowData.deal.purchasers[2].proofType : '-';
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Three Proof Number',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 2) {
            return rowData.deal.purchasers[2].proofNumber ? rowData.deal.purchasers[2].proofNumber : '-';
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Three Proof Expiry',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 2) {
            return rowData.deal.purchasers[2].proofExpiry
              ? subHours(new Date(rowData.deal.purchasers[2].proofExpiry), 5).toISOString().split('T')[0]
              : '-';
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Three Business Number',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 2) {
            return rowData.deal.purchasers[2].businessNumber ? rowData.deal.purchasers[2].businessNumber : '-';
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Four First Name',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 3) {
            return rowData.deal.purchasers[3].firstName;
          } else return '-';
        },
        width: getColumnWidth(allColumns, 'accessor', 'Purchaser One First Name'),
      },
      {
        Header: 'Purchaser Four Last Name',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 3) {
            return rowData.deal.purchasers[3].lastName;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Four D.O.B',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 3) {
            return subHours(new Date(rowData.deal.purchasers[3].dob), 5).toISOString().split('T')[0];
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Four Street Address',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 3) {
            return `${rowData.deal.purchasers[3].unit ? rowData.deal.purchasers[3].unit : ''} ${rowData.deal.purchasers[3].streetAddress}`;
          } else return '-';
        },
        width: getColumnWidth(allColumns, 'accessor', 'Purchaser One Street Address'),
      },
      {
        Header: 'Purchaser Four City',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 3) {
            return rowData.deal.purchasers[3].city;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Four Province',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 3) {
            return rowData.deal.purchasers[3].province;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Four Postal Code',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 3) {
            return rowData.deal.purchasers[3].postalCode;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Four Phone',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 3) {
            return rowData.deal.purchasers[3].primaryPhone;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Four E-mail',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 3) {
            return rowData.deal.purchasers[3].email;
          } else return '-';
        },
        width: getColumnWidth(allColumns, 'accessor', 'Purchaser One E-mail'),
      },
      {
        Header: 'Purchaser Four SIN',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 3 && (user.type === 'Manager' || user.type === 'Developer')) {
            return rowData.deal.purchasers[3].sin;
          } else if (rowData.deal && rowData.deal.purchasers[3] && rowData.deal.purchasers[3].sin) {
            return 'received';
          } else return 'N/A';
        },
      },
      {
        Header: 'Purchaser Four Employer',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 3) {
            return rowData.deal.purchasers[3].employer;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Four Occupation',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 3) {
            return rowData.deal.purchasers[3].occupation;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Four Purchaser Type',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 3) {
            return rowData.deal.purchasers[3].purchaserType;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Four ID Type',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 3) {
            return rowData.deal.purchasers[3].idType;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Four ID Number',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 3) {
            return rowData.deal.purchasers[3].idNumber;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Four ID Expiry',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 3) {
            return subHours(new Date(rowData.deal.purchasers[3].idExpiry), 5).toISOString().split('T')[0];
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Four ID Jurisdiction',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 3) {
            return rowData.deal.purchasers[3].idJurisdiction;
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Four Proof Type',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 3) {
            return rowData.deal.purchasers[3].proofType ? rowData.deal.purchasers[3].proofType : '-';
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Four Proof Number',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 3) {
            return rowData.deal.purchasers[3].proofNumber ? rowData.deal.purchasers[3].proofNumber : '-';
          } else return '-';
        },
      },
      {
        Header: 'Purchaser Four Proof Expiry',
        accessor: (rowData: IUnit) => {
          if (rowData.deal && rowData.deal.purchasers.length > 3) {
            return rowData.deal.purchasers[3].proofExpiry
              ? subHours(new Date(rowData.deal.purchasers[3].proofExpiry), 5).toISOString().split('T')[0]
              : '-';
          } else return '-';
        },
      },
      {
        Header: 'Realtor 1 Full Name',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              return rowData.deal.realtor[0].fullName;
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 1 Email',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              return rowData.deal.realtor[0].email;
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 1 Brokerage',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              return rowData.deal.realtor[0].brokerage;
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 1 Street Address',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              return rowData.deal.realtor[0].streetAddress;
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 1 City',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              return rowData.deal.realtor[0].city;
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 1 Province',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              return rowData.deal.realtor[0].province;
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 1 Country',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              return rowData.deal.realtor[0].country;
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 1 Postal Code',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              return rowData.deal.realtor[0].postalCode;
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 1 Brokerage Phone',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              return rowData.deal.realtor[0].brokeragePhone;
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 1 Direct Phone',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              return rowData.deal.realtor[0].directPhone;
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 1 Brokerage Fax',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              return rowData.deal.realtor[0].brokerageFax;
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 1 First Commission Type',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              return rowData.deal.realtor[0].coopRates.length ? rowData.deal.realtor[0].coopRates[0].type : '-';
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 1 First Commission Percent',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              return rowData.deal.realtor[0].coopRates.length && rowData.deal.realtor[0].coopRates[0].percentage
                ? `${rowData.deal.realtor[0].coopRates[0].percentage}%`
                : '-';
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 1 First Commission Date',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              if (rowData.deal.realtor[0].coopRates.length && rowData.deal.realtor[0].coopRates[0].date) {
                return convertAllDates(rowData.deal.realtor[0].coopRates[0].date, 'PP');
              } else return '-';
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 1 First Commission Amount',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              if (rowData.deal.realtor[0].coopRates.length && rowData.deal.realtor[0].coopRates[0].fixedAmount) {
                return numToCurrencyDecimal.format(parseFloat(rowData.deal.realtor[0].coopRates[0].fixedAmount));
              } else if (rowData.deal.realtor[0].coopRates.length && rowData.deal.realtor[0].coopRates[0].percentage) {
                if (project.commissionIncludeOptions) {
                  return numToCurrencyDecimal.format(
                    netHST(
                      rowData.basePrice +
                        rowData.deal.options.reduce((a, b) => {
                          return a + b?.amount!;
                        }, 0)
                    ) *
                      (parseFloat(rowData.deal.realtor[0].coopRates[0].percentage) / 100)
                  );
                } else {
                  return numToCurrencyDecimal.format(
                    netHST(rowData.basePrice) * (parseFloat(rowData.deal.realtor[0].coopRates[0].percentage) / 100)
                  );
                }
              } else return '-';
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 1 Second Commission Type ',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              return rowData.deal.realtor[0].coopRates.length > 1 ? rowData.deal.realtor[0].coopRates[1].type : '-';
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 1 Second Commission Percent',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              return rowData.deal.realtor[0].coopRates.length > 1 && rowData.deal.realtor[0].coopRates[1].percentage
                ? `${rowData.deal.realtor[0].coopRates[1].percentage}%`
                : '-';
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 1 Second Commission Date',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              if (rowData.deal.realtor[0].coopRates.length > 1 && rowData.deal.realtor[0].coopRates[1].date) {
                return convertAllDates(rowData.deal.realtor[0].coopRates[1].date, 'PP');
              } else return '-';
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 1 Second Commission Amount',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              if (rowData.deal.realtor[0].coopRates.length > 1 && rowData.deal.realtor[0].coopRates[1].fixedAmount) {
                return numToCurrencyDecimal.format(parseFloat(rowData.deal.realtor[0].coopRates[1].fixedAmount));
              } else if (rowData.deal.realtor[0].coopRates.length > 1 && rowData.deal.realtor[0].coopRates[1].percentage) {
                if (project.commissionIncludeOptions) {
                  return numToCurrencyDecimal.format(
                    netHST(
                      rowData.basePrice +
                        rowData.deal.options.reduce((a, b) => {
                          return a + b?.amount!;
                        }, 0)
                    ) *
                      (parseFloat(rowData.deal.realtor[0].coopRates[1].percentage) / 100)
                  );
                } else {
                  return numToCurrencyDecimal.format(
                    netHST(rowData.basePrice) * (parseFloat(rowData.deal.realtor[0].coopRates[1].percentage) / 100)
                  );
                }
              } else return '-';
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 1 Third Commission Type ',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              return rowData.deal.realtor[0].coopRates.length > 2 ? rowData.deal.realtor[0].coopRates[2].type : '-';
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 1 Third Commission Percent',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              return rowData.deal.realtor[0].coopRates.length > 2 && rowData.deal.realtor[0].coopRates[2].percentage
                ? `${rowData.deal.realtor[0].coopRates[2].percentage}%`
                : '-';
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 1 Third Commission Date',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              if (rowData.deal.realtor[0].coopRates.length > 2 && rowData.deal.realtor[0].coopRates[2].date) {
                return convertAllDates(rowData.deal.realtor[0].coopRates[2].date, 'PP');
              } else return '-';
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 1 Third Commission Amount',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              if (rowData.deal.realtor[0].coopRates.length > 2 && rowData.deal.realtor[0].coopRates[2].fixedAmount) {
                return numToCurrencyDecimal.format(parseFloat(rowData.deal.realtor[0].coopRates[2].fixedAmount));
              } else if (rowData.deal.realtor[0].coopRates.length > 2 && rowData.deal.realtor[0].coopRates[2].percentage) {
                if (project.commissionIncludeOptions) {
                  return numToCurrencyDecimal.format(
                    netHST(
                      rowData.basePrice +
                        rowData.deal.options.reduce((a, b) => {
                          return a + b?.amount!;
                        }, 0)
                    ) *
                      (parseFloat(rowData.deal.realtor[0].coopRates[2].percentage) / 100)
                  );
                } else {
                  return numToCurrencyDecimal.format(
                    netHST(rowData.basePrice) * (parseFloat(rowData.deal.realtor[0].coopRates[2].percentage) / 100)
                  );
                }
              } else return '-';
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 2 Full Name',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length > 1) {
              return rowData.deal.realtor[1].fullName;
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 2 Email',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length > 1) {
              return rowData.deal.realtor[1].email;
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 2 Brokerage',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length > 1) {
              return rowData.deal.realtor[1].brokerage;
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 2 Street Address',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length > 1) {
              return rowData.deal.realtor[1].streetAddress;
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 2 City',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length > 1) {
              return rowData.deal.realtor[1].city;
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 2 Province',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length > 1) {
              return rowData.deal.realtor[1].province;
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 2 Country',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length > 1) {
              return rowData.deal.realtor[1].country;
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 2 Postal Code',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length > 1) {
              return rowData.deal.realtor[1].postalCode;
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 2 Brokerage Phone',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length > 1) {
              return rowData.deal.realtor[1].brokeragePhone;
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 2 Direct Phone',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length > 1) {
              return rowData.deal.realtor[1].directPhone;
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 2 Brokerage Fax',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length > 1) {
              return rowData.deal.realtor[1].brokerageFax;
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 2 First Commission Type',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length > 1) {
              return rowData.deal.realtor[1].coopRates.length ? rowData.deal.realtor[1].coopRates[0].type : '-';
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 2 First Commission Percent',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length > 1) {
              return rowData.deal.realtor[1].coopRates.length && rowData.deal.realtor[1].coopRates[0].percentage
                ? `${rowData.deal.realtor[1].coopRates[0].percentage}%`
                : '-';
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 2 First Commission Date',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length > 1) {
              if (rowData.deal.realtor[1].coopRates.length && rowData.deal.realtor[1].coopRates[0].date) {
                return convertAllDates(rowData.deal.realtor[1].coopRates[0].date, 'PP');
              } else return '-';
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 2 First Commission Amount',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length > 1) {
              if (rowData.deal.realtor[1].coopRates.length && rowData.deal.realtor[1].coopRates[0].fixedAmount) {
                return numToCurrencyDecimal.format(parseFloat(rowData.deal.realtor[1].coopRates[0].fixedAmount));
              } else if (rowData.deal.realtor[1].coopRates.length && rowData.deal.realtor[1].coopRates[0].percentage) {
                if (project.commissionIncludeOptions) {
                  return numToCurrencyDecimal.format(
                    netHST(
                      rowData.basePrice +
                        rowData.deal.options.reduce((a, b) => {
                          return a + b?.amount!;
                        }, 0)
                    ) *
                      (parseFloat(rowData.deal.realtor[1].coopRates[0].percentage) / 100)
                  );
                } else {
                  return numToCurrencyDecimal.format(
                    netHST(rowData.basePrice) * (parseFloat(rowData.deal.realtor[1].coopRates[0].percentage) / 100)
                  );
                }
              } else return '-';
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 2 Second Commission Type ',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length > 1) {
              return rowData.deal.realtor[1].coopRates.length > 1 ? rowData.deal.realtor[1].coopRates[1].type : '-';
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 2 Second Commission Percent',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length > 1) {
              return rowData.deal.realtor[1].coopRates.length > 1 && rowData.deal.realtor[1].coopRates[1].percentage
                ? `${rowData.deal.realtor[1].coopRates[1].percentage}%`
                : '-';
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 2 Second Commission Date',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length > 1) {
              if (rowData.deal.realtor[1].coopRates.length > 1 && rowData.deal.realtor[1].coopRates[1].date) {
                return convertAllDates(rowData.deal.realtor[1].coopRates[1].date, 'PP');
              } else return '-';
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 2 Second Commission Amount',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length > 1) {
              if (rowData.deal.realtor[1].coopRates.length > 1 && rowData.deal.realtor[1].coopRates[1].fixedAmount) {
                return numToCurrencyDecimal.format(parseFloat(rowData.deal.realtor[1].coopRates[1].fixedAmount));
              } else if (rowData.deal.realtor[1].coopRates.length > 1 && rowData.deal.realtor[1].coopRates[1].percentage) {
                if (project.commissionIncludeOptions) {
                  return numToCurrencyDecimal.format(
                    netHST(
                      rowData.basePrice +
                        rowData.deal.options.reduce((a, b) => {
                          return a + b?.amount!;
                        }, 0)
                    ) *
                      (parseFloat(rowData.deal.realtor[1].coopRates[1].percentage) / 100)
                  );
                } else {
                  return numToCurrencyDecimal.format(
                    netHST(rowData.basePrice) * (parseFloat(rowData.deal.realtor[1].coopRates[1].percentage) / 100)
                  );
                }
              } else return '-';
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 2 Third Commission Type ',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length > 1) {
              return rowData.deal.realtor[1].coopRates.length > 2 ? rowData.deal.realtor[1].coopRates[2].type : '-';
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 2 Third Commission Percent',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length > 1) {
              return rowData.deal.realtor[1].coopRates.length > 2 && rowData.deal.realtor[1].coopRates[2].percentage
                ? `${rowData.deal.realtor[1].coopRates[2].percentage}%`
                : '-';
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 2 Third Commission Date',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length > 1) {
              if (rowData.deal.realtor[1].coopRates.length > 2 && rowData.deal.realtor[1].coopRates[2].date) {
                return convertAllDates(rowData.deal.realtor[1].coopRates[2].date, 'PP');
              } else return '-';
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Realtor 2 Third Commission Amount',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length > 1) {
              if (rowData.deal.realtor[1].coopRates.length > 2 && rowData.deal.realtor[1].coopRates[2].fixedAmount) {
                return numToCurrencyDecimal.format(parseFloat(rowData.deal.realtor[1].coopRates[2].fixedAmount));
              } else if (rowData.deal.realtor[1].coopRates.length > 2 && rowData.deal.realtor[1].coopRates[2].percentage) {
                if (project.commissionIncludeOptions) {
                  return numToCurrencyDecimal.format(
                    netHST(
                      rowData.basePrice +
                        rowData.deal.options.reduce((a, b) => {
                          return a + b?.amount!;
                        }, 0)
                    ) *
                      (parseFloat(rowData.deal.realtor[1].coopRates[2].percentage) / 100)
                  );
                } else {
                  return numToCurrencyDecimal.format(
                    netHST(rowData.basePrice) * (parseFloat(rowData.deal.realtor[1].coopRates[2].percentage) / 100)
                  );
                }
              } else return '-';
            } else return '-';
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Total Commission %',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              let totalPercentage = 0;
              rowData.deal.realtor.forEach((realtor: IDealRealtor) => {
                let coop = realtor.coopRates.reduce((a: any, b: any) => a + b.percentage, 0);
                totalPercentage += coop;
              });
              if (totalPercentage) {
                return `${totalPercentage}%`;
              } else return '-';
            } else return '-';
          } else return '-';
        },
      },
      {
        Header: 'Total Commission Amount',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              let totalAmount = 0;
              rowData.deal.realtor.forEach((realtor: IDealRealtor) => {
                let amount = realtor.coopRates.reduce((a: any, b: any) => {
                  if (b.fixedAmount) {
                    return a + b.fixedAmount;
                  } else {
                    if (project.commissionIncludeOptions) {
                      return (
                        a +
                        netHST(
                          rowData.basePrice +
                            rowData.deal.options.reduce((a, b) => {
                              return a + b?.amount!;
                            }, 0)
                        ) *
                          (parseFloat(b.percentage) / 100)
                      );
                    } else {
                      return a + netHST(rowData.basePrice) * (parseFloat(b.percentage) / 100);
                    }
                  }
                }, 0);
                totalAmount += amount;
              });
              if (totalAmount) {
                return numToCurrencyDecimal.format(totalAmount);
              } else return '-';
            } else return '-';
          } else return '-';
        },
      },
      {
        Header: 'Mortgage Received',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.mortgage.revisions.includes('No MPA Required')) {
              return 'No MPA Required';
            } else {
              return rowData.deal.mortgage.amount && rowData.deal.mortgage.getUrl
                ? numToCurrency.format(rowData.deal.mortgage.amount)
                : '-';
            }
          } else return '-';
        },
      },
      {
        Header: 'Mortgage Required',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return numToCurrency.format(
              rowData.deal?.totalPrice! - rowData.deal.deposit.map((deposit) => deposit.amount || 0).reduce((a, b) => a + b, 0)
            );
          } else return '-';
        },
      },
      {
        Header: 'Mortgage Lender',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            if (rowData.deal.mortgage.lender) {
              return rowData.deal.mortgage.lender;
            } else return '-';
          }
          return '-';
        },
      },
      {
        Header: 'Total Deposits',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return numToCurrency.format(rowData.deal.deposit.map((deposit) => deposit.amount || 0).reduce((a, b) => a + b, 0));
          } else return '-';
        },
      },
      {
        Header: 'Total Deposits (%)',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            const total = rowData.deal.deposit.map((deposit) => deposit.amount || 0).reduce((a, b) => a + b, 0);
            return Math.round((total / rowData.deal?.totalPrice!) * 10000) / 100 + '%';
          } else return '-';
        },
      },
      {
        Header: 'Total Deposits Cleared',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            return numToCurrency.format(
              rowData.deal.deposit
                .filter((deposit) => deposit.chequeType === 'cleared')
                .map((deposit) => deposit.amount || 0)
                .reduce((a, b) => a + b, 0)
            );
          } else return '-';
        },
      },
      {
        Header: 'Total Deposits Cleared (%)',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            const total = rowData.deal.deposit
              .filter((deposit) => deposit.chequeType === 'cleared')
              .map((deposit) => deposit.amount || 0)
              .reduce((a, b) => a + b, 0);
            return Math.round((total / rowData.deal?.totalPrice!) * 10000) / 100 + '%';
          } else return '-';
        },
      },
      {
        Header: 'All Deposits Check',
        accessor: (rowData: IUnit) => {
          if (rowData.deal) {
            const allDeposits = rowData.deal.deposit.every(
              (deposit) =>
                deposit.chequeType === 'received' ||
                deposit.chequeType === 'distributed' ||
                deposit.chequeType === 'cleared' ||
                deposit.chequeType === 'wire' ||
                deposit.chequeType === 'floating' ||
                deposit.name === 'Occupancy Deposit' ||
                deposit.name === 'Occupancy'
            );
            if (allDeposits) {
              return 'All Deposits Received';
            } else {
              return 'Deposits Missing';
            }
          } else return '-';
        },
      },
    ],
    []
  );

  const handleModalType = (type: string) => {
    storeDispatch(handleModal(true));
    setType(type);
  };

  const modalContent = () => {
    if (type === 'custom') {
      <Box>
        <Typography variant={'h5'}>Add Columns</Typography>
        <p>Select the columns you would like to export</p>
        <Box sx={{ mb: 2 }}>
          <Autocomplete
            options={allColumns.slice(1)}
            getOptionLabel={(option: any) => option.Header}
            disableClearable={false}
            freeSolo={false}
            onChange={(event: any, newValue: any | null) => {
              if (newValue) {
                setSelectedColumns([...selectedColumns, newValue]);
              }
            }}
            renderInput={(params) => <TextField {...params} label="Columns" size="small" />}
          />
        </Box>
        <p>
          <strong>Selected Columns</strong>
        </p>
        <Flex sx={{ my: 2, flexWrap: 'wrap' }}>
          {selectedColumns.map((column: any, index: number) => {
            return (
              <Box
                key={index}
                sx={{
                  padding: '10px',
                  border: '1px solid #00142a',
                  borderRadius: '8px',
                  display: 'flex',
                  flexWrap: 'wrap',
                  marginRight: '10px',
                  mt: 1,
                }}
              >
                <span>
                  {index + 1}. {column.Header}
                </span>
                {column.Header !== 'Suite' ? (
                  <CloseIcon
                    sx={{ cursor: 'pointer', ml: 1 }}
                    color="secondary"
                    onClick={() => setSelectedColumns(selectedColumns.filter((columns: any, numIndex: number) => numIndex !== index))}
                  />
                ) : null}
              </Box>
            );
          })}
        </Flex>
        <FlexBetween>
          <Button onClick={() => storeDispatch(handleModal(false))} variant="contained" color="info">
            Cancel
          </Button>
          <Box>
            <Button
              sx={{ mr: 1 }}
              onClick={() =>
                exportClosing(`${project.name} ${convertAllDates(new Date(), 'P')} Custom Closing.csv`, selectedColumns, units)
              }
              variant="contained"
              color="success"
            >
              Download Excel
            </Button>
          </Box>
        </FlexBetween>
      </Box>;
    } else if (type === 'price') {
      let data = units.filter((unit: IUnit) => unit.deal && unit.basePrice !== unit.deal.basePrice);
      const columns = [
        {
          Header: 'Suite',
          accessor: (rowData: any) => rowData.suite,
        },
        {
          Header: 'Unit Price',
          accessor: (rowData: any) => numToCurrency.format(rowData.basePrice),
        },
        {
          Header: 'Deal Price',
          accessor: (rowData: any) => numToCurrency.format(rowData.deal.basePrice),
        },
      ];
      return <StandardTable data={data} columns={columns} />;
    } else if (type === 'options') {
      let data = units
        .map((unit: IUnit) => {
          let options: any[] = [];
          if (unit.deal) {
            options = unit.deal.options.map((option: any) => {
              return { ...option, suite: unit.suite, status: unit.status };
            });
          }
          return options;
        })
        .flat();

      let headers = [
        ...new Set(
          data.map((item) => {
            return item.amount / item.purchaseAmount;
          })
        ),
      ].sort(function (a, b) {
        return a - b;
      });
      let optionHeaders = [...new Set(data.map((item) => item.name))];
      let newData = optionHeaders.map((option: string) => {
        let keys = headers.reduce((acc: any, curr: any) => ((acc[curr] = 0), acc), {});
        for (const item of data) {
          if (item.name === option) {
            keys[item.amount] += item.purchaseAmount;
          }
        }
        return {
          name: option,
          ...keys,
        };
      });

      let columnHeaders = headers.map((header: number) => {
        return {
          Header: `${numToCurrency.format(header)}`,
          accessor: (rowData: any) => {
            let suites = data.filter((option: any) => option.name === rowData.name && option.amount / option.purchaseAmount === header);
            let value: any = `${rowData[header]}`;
            return (
              <Tooltip
                disableHoverListener={!rowData[header]}
                title={suites.length ? `${suites.map((option: any) => `${option.suite} (${option.status})`).join(', ')}` : ''}
              >
                <div>{value}</div>
              </Tooltip>
            );
          },
        };
      });

      const columns = [
        {
          Header: 'Name',
          accessor: (rowData: any) => {
            return rowData.name;
          },
        },
        ...columnHeaders,
      ];
      return <StandardTable data={newData} columns={columns} />;
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      {loading ? (
        <LoadingWrapper title="Closing Report is loading..." modal={false} />
      ) : (
        <div>
          <GlobalModal>{modalContent()}</GlobalModal>
          <div>
            <PageTitle title={'Closing Report'} />
          </div>
          <Box sx={{ mb: 1 }}>
            <Button
              onClick={() => exportClosing(`${project.name} ${convertAllDates(new Date(), 'P')} Closing.csv`, allColumns, units)}
              variant="contained"
              color="primary"
              sx={{ mr: 1 }}
            >
              Export to CSV
            </Button>
            <Button onClick={() => handleModalType('custom')} variant="contained" color="primary">
              Create Custom Closing
            </Button>
            {user.type === 'Manager' || user.type === 'Admin' ? (
              <Button sx={{ ml: 1 }} onClick={() => handleModalType('price')} variant="contained" color="primary">
                Price Sync
              </Button>
            ) : null}
            {user.type === 'Manager' || user.type === 'Admin' ? (
              <Button sx={{ ml: 1 }} onClick={() => handleModalType('options')} variant="contained" color="primary">
                Options Sync
              </Button>
            ) : null}
          </Box>
          <VirtualizedTable itemHeight={100} columns={allColumns} data={units} filter={true} tableHeight={1000} />
        </div>
      )}
    </Box>
  );
};

const UNITCLOSING = gql`
  query unitClosing($project: MongoID!) {
    unitClosing(project: $project) {
      _id
      suite
      unit
      modelType
      unitType
      bathroom
      size
      outdoorType
      basePrice
      level
      exposure
      outdoorSize
      status
      custom
      type
      assignment {
        purchasers {
          fullName
        }
        signDate
      }
      allocation {
        fullName
      }
      allocatedDate
      tempAllocation
      tier
      rental
      history {
        type
        description
        timestamp
      }
      deal {
        _id
        signDate
        firmDate
        executeDate
        auditor {
          fullName
        }
        auditDate
        unit {
          suite
        }
        project {
          _id
        }
        purchasers {
          _id
          email
          dob
          streetAddress
          city
          province
          country
          postalCode
          employer
          occupation
          purchaserType
          primaryPhone
          idType
          idNumber
          idExpiry
          proofExpiry
          proofNumber
          proofType
          unit
          idJurisdiction
          sin
          firstName
          lastName
          fullName
        }
        realtor {
          _id
          email
          firstName
          lastName
          fullName
          brokerage
          streetAddress
          city
          province
          country
          postalCode
          brokeragePhone
          brokerageFax
          directPhone
          coopRates {
            type
            days
            date
            description
            percentage
            fixedAmount
          }
        }
        deposit {
          name
          amount
          dueDate
          accountNumber
          chequeNumber
          chequeDate
          chequeType
          chequeAmount
        }
        additionalDeposits {
          name
          amount
          dueDate
          accountNumber
          chequeNumber
          chequeDate
          chequeType
          chequeAmount
        }
        options {
          name
          purchaseAmount
          amount
        }
        basePrice
        totalPrice
        tags
        adjustments {
          name
          type
          value
          approval
        }
        upgradeSetOne {
          date
          salesRep {
            fullName
          }
          collectionTemplate {
            name
          }
          upgradeSet {
            price
            quantity
            mainCategory
          }
        }
        upgradeSetTwo {
          date
          salesRep {
            fullName
          }
          collectionTemplate {
            name
          }
          upgradeSet {
            price
            quantity
            mainCategory
          }
        }
        upgradeSetThree {
          date
          salesRep {
            fullName
          }
          collectionTemplate {
            name
          }
          upgradeSet {
            price
            quantity
            mainCategory
          }
        }
        mortgage {
          lender
          revisions
          amount
          getUrl
          putUrl
        }
        documents {
          status
          type
          createdAt
          name
        }
        solicitor {
          solicitor
          firm
          streetAddress
          city
          province
          postalCode
          email
          primaryPhone
        }
        salesRep {
          fullName
          firstName
        }
        thirdParty {
          fullName
        }
        joinWaitlist
        createdAt
      }
    }
  }
`;

export default ClosingTable;
