import React, { useContext, useEffect, useState } from 'react';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { Box, Grid, Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../../app/hooks';
import { selectProject } from '../../../features/project/projectSlice';
import { showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';
import DealOptions from '../DealOptions';
import { UnitContext } from '../../../context/UnitContext';
import DealDeposits from '../DealDeposits';
import Dropdown from '../../common/formControls/Dropdown';
import TextInput from '../../common/formControls/TextInput';
import { IMerge, IMergeData } from '../../../types/merge';
import { IDealDeposit } from '../../../types/CreateDealForm';
import { IOption } from '../../../types/project';
import { numToCurrency, optionsLeft, createPdf } from '../../../utils/Functions';
import { FlexBetween, FlexEnd } from '../../../commonStyles';
import { selectMerges } from '../../../features/merge/mergeSlice';
import { selectUser } from '../../../features/auth/authSlice';
import { accessAllowed } from '../../../features/project/projectHooks';
import { useCreateActivity } from '../../../features/activity/activityHooks';
import { showSuccessSnackbar } from '../../../features/snackbar/snackbarSlice';

const OptionInfo = (props: any) => {
  const {
    createDeposits,
    openDialog,
    createAmendment,
    handleOptionDropdown,
    optionValue,
    handleOptionInput,
    depositList,
    addPendingDeposits,
    handleOptions,
    pendingOptions,
    setPendingOptions,
    selectedDeposit,
    setSelectedDeposit,
    keepDeposits,
    setKeepDeposits,
  } = props;
  const { unit, deals, filteredDeal, pendingDeposits, setPendingDeposits, document, setFilteredDeal, documents } = useContext(UnitContext);
  const storeDispatch = useAppDispatch();

  const user = useSelector(selectUser);
  const createActivity = useCreateActivity();
  const project = useSelector(selectProject);
  const merges = useSelector(selectMerges);
  const [selectedOption, setSelectedOption] = useState('');
  const [optionMerges, setOptionMerges] = useState<any[]>([]);
  const [waitlistChoices, setWaitlistChoices] = useState<string[]>([]);
  const [errors, setErrors] = useState<any>(null);

  // Queries and Mutations

  const [getOptionMerges] = useLazyQuery<IMergeData>(MERGETEMPLATES, {
    variables: { filter: { project: project._id, type: 'Deposit' } },
    onCompleted: (data) => {
      setOptionMerges(data.mergeTemplateMany);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [saveWaitlist] = useMutation(SAVEWAITLIST, {
    onCompleted: (data) => {
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Waitlist`,
        content: `Waitlist has been updated`,
      });
      storeDispatch(showSuccessSnackbar(`Waitlist has been updated`));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [saveOptions] = useMutation(SAVEWAITLIST, {
    onCompleted: (data) => {
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Option`,
        content: `Options has been manually updated`,
      });
      storeDispatch(showSuccessSnackbar(`Options has been updated`));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  useEffect(() => {
    if (optionMerges.length > 0) {
      setSelectedOption(optionMerges[0].name);
    }
    let projectOptions = project.options.map((option: IOption) => option.name);
    let combinedWaitlist = [...new Set([...filteredDeal.joinWaitlist, ...projectOptions])];
    setWaitlistChoices(combinedWaitlist);
  }, [merges]);

  // Functions

  const totalOptionsPrice = (type: string) => {
    let totalOptions = unit.basePrice;
    if (type === 'options') {
      totalOptions = 0;
    }

    if (type === 'total') {
      if (filteredDeal.pendingOptions.length > 0) {
        totalOptions = filteredDeal.pendingOptions.reduce((a: any, b: any) => {
          return a + b.amount;
        }, unit.basePrice);
      } else if (pendingOptions.length > 0) {
        totalOptions = pendingOptions.reduce((a: any, b: any) => {
          return a + b.amount;
        }, unit.basePrice);
      }
      return totalOptions;
    } else if (type === 'options') {
      if (filteredDeal.pendingOptions.length > 0) {
        totalOptions = filteredDeal.pendingOptions.reduce((a: any, b: any) => {
          return a + b.amount;
        }, 0);
      } else if (pendingOptions.length > 0) {
        totalOptions = pendingOptions.reduce((a: any, b: any) => {
          return a + b.amount;
        }, 0);
      }
      return totalOptions;
    }
  };

  const handleOptionsSubmit = async (e: any) => {
    e.preventDefault();
    if (!selectedOption) {
      storeDispatch(showErrorSnackbar(`No Amendment Selected`));
      return;
    }
    if (errors) {
      storeDispatch(showErrorSnackbar(`Invalid Date`));
      return;
    }

    let optionsAmendment = optionMerges.find((merge: IMerge) => {
      return merge.name === selectedOption;
    });

    if (!optionsAmendment) {
      storeDispatch(showErrorSnackbar(`No option amendment found`));
      return;
    }

    let pdf = await createPdf(
      optionsAmendment,
      project,
      unit,
      filteredDeal.purchasers,
      filteredDeal.deposit,
      filteredDeal.options,
      null,
      null,
      pendingOptions,
      pendingDeposits,
      filteredDeal
    );

    let sign = optionsAmendment?.signFields.map((sign: any) => {
      return {
        index: sign.index,
        key: sign.key,
        pageNumber: sign.pageNumber,
        x: sign.x,
        y: sign.y,
      };
    });
    const args = {
      status: 'Prepared',
      name: optionsAmendment?.name,
      project: project._id,
      deal: filteredDeal._id,
      signFields: sign,
      // signers,
      type: 'Options',
      isAPS: false,
    };

    let newDeposit = pendingDeposits.map((pendingDeposits: IDealDeposit, index: number) => {
      if (
        filteredDeal.deposit[index] &&
        pendingDeposits.amount === filteredDeal.deposit[index].amount &&
        pendingDeposits.name === filteredDeal.deposit[index].name &&
        new Date(pendingDeposits.dueDate!).setHours(0, 0, 0, 0) === new Date(filteredDeal.deposit[index].dueDate).setHours(0, 0, 0, 0)
      ) {
        return {
          accountNumber: filteredDeal.deposit[index].accountNumber,
          chequeAmount: filteredDeal.deposit[index].chequeAmount,
          chequeDate: filteredDeal.deposit[index].chequeDate,
          chequeNumber: filteredDeal.deposit[index].chequeNumber,
          chequeType: filteredDeal.deposit[index].chequeType,
          amount: filteredDeal.deposit[index].amount,
          deal: filteredDeal._id,
          dueDate: filteredDeal.deposit[index].dueDate,
          name: filteredDeal.deposit[index].name,
        };
      } else {
        return {
          accountNumber: pendingDeposits.accountNumber,
          amount: pendingDeposits.amount,
          deal: filteredDeal._id,
          dueDate: pendingDeposits.dueDate,
          name: pendingDeposits.name,
        };
      }
    });

    let newOptions = pendingOptions.map((pendingOption: any) => {
      return {
        ...pendingOption,
        purchaseAmount: parseFloat(pendingOption.purchaseAmount),
      };
    });

    setPendingOptions(newOptions);

    await createAmendment({ variables: { record: args, file: pdf } });

    if (pendingDeposits.length) {
      await createDeposits({ variables: { records: newDeposit } });
    }
  };

  const handleJoinWaitlist = (title: string) => {
    let waitlist = filteredDeal.joinWaitlist.find((waitlist: string) => waitlist === title);

    if (waitlist) {
      setFilteredDeal({
        ...filteredDeal,
        joinWaitlist: filteredDeal.joinWaitlist.filter((waitlist: string) => title !== waitlist),
      });
    } else {
      setFilteredDeal({
        ...filteredDeal,
        joinWaitlist: [...filteredDeal.joinWaitlist, title],
      });
    }
  };

  return (
    <form onSubmit={handleOptionsSubmit} autoComplete="off">
      <div>
        <>
          <h3>
            <em>Waitlist Options</em>
          </h3>
          {waitlistChoices.length ? (
            <Grid container spacing={2}>
              {waitlistChoices.map((waitlist: string) => {
                return (
                  <Grid item sm={6} md={4} lg={3}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filteredDeal.joinWaitlist.find((selected: string) => waitlist === selected) ? true : false}
                            onClick={() => handleJoinWaitlist(waitlist)}
                          />
                        }
                        label={waitlist}
                      />
                    </FormGroup>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Box>There are currently no options for this project</Box>
          )}
        </>
        <h3>
          <em>Current Options</em>
        </h3>
        <DealOptions options={filteredDeal.options} disabled={false} />
        {filteredDeal.pendingOptions.length > 0 && document === 'Options' ? (
          <div>
            <h3
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <em>Pending Options</em>
            </h3>
            <em>
              These options are currently pending until the amendment has been executed. After the amendment has been executed, it will
              replace the options above. If you would like to remove these options, please delete the Option/Unit Transfer Amendment in the
              amendments section
            </em>
            <Box sx={{ mt: 2 }}>
              Total Price for Pending Options: <strong>{numToCurrency.format(totalOptionsPrice('total'))}</strong>
            </Box>
            <DealOptions options={filteredDeal.pendingOptions} disabled={true} />
          </div>
        ) : null}
        {pendingOptions.length > 0 && document === 'Options' && filteredDeal.pendingOptions.length === 0 ? (
          <>
            <h3>Option Amendment</h3>
            <Box sx={{ mt: 2 }}>
              <div>
                Unit Price: <strong>{numToCurrency.format(unit.basePrice)}</strong>
              </div>
              <div>
                Total Options Price: <strong>{numToCurrency.format(totalOptionsPrice('options'))}</strong>
              </div>
              <div>
                Total Price: <strong>{numToCurrency.format(totalOptionsPrice('total'))}</strong>
              </div>
            </Box>
            {/* <Box sx={{ mt: 2, color: 'error.main' }}>
              <strong>
                <em>
                  All SELECTED OPTIONS BELOW WILL REPLACE THE CURRENT OPTIONS (IF APPLICABLE). If you are adding an option after firm and do
                  not want to change the deposit structure, please click on the checkbox below. Ex. If you currently have a parking and
                  would like to add locker after firm, select both the parking and locker to retain the original option. If you have an
                  questions, please contact Matthew or Anastasia.
                </em>
              </strong>
            </Box>
            <Box sx={{ mt: 2 }}>
              <FormControlLabel
                control={<Checkbox color="primary" checked={optionsOnly} onClick={() => setOptionsOnly(!optionsOnly)} />}
                label={'Create Amendment only for Total Options Price'}
              />
            </Box> */}
            <Grid sx={{ mt: 2 }} container spacing={2}>
              {project.options.map((option: IOption, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <Grid
                      sx={{
                        alignSelf: 'center',
                        fontSize: '20px',
                      }}
                      item
                      xs={12}
                      sm={3}
                    >
                      <div>
                        <strong>{option.name}</strong>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Dropdown
                        title={`Number of ${option.name} (${option.totalAvailable - optionsLeft(deals, option.name)} / ${
                          option.totalAvailable
                        })`}
                        menuList={['0', '1', '2', '3']}
                        name={'purchaseAmount'}
                        handleSelect={(e: any) => handleOptionDropdown(e, index, option.name)}
                        value={optionValue(option.name, 'purchaseAmount', index)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextInput
                        title={`${option.name} Total Amount`}
                        type="number"
                        name={'totalAmount'}
                        handleTextInput={(e: any) => handleOptionInput(e, option.name)}
                        value={optionValue(option.name, 'amount', index)}
                        adornment={'$'}
                        required={false}
                      />
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
            <Box sx={{ mt: 2 }}>
              <FormControlLabel
                control={<Checkbox color="primary" checked={keepDeposits} onClick={() => setKeepDeposits(!keepDeposits)} />}
                label={'Keep Deposit Structure Amounts'}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Dropdown
                title={'Deposit Type'}
                menuList={depositList()}
                name={'depositType'}
                handleSelect={(event: any) => setSelectedDeposit(event.target.value)}
                value={selectedDeposit}
              />
            </Box>
            <DealDeposits
              deposit={pendingDeposits}
              basePrice={totalOptionsPrice('total')}
              handleDepositsArray={(array: any[]) => setPendingDeposits(array)}
              disable={false}
              setErrors={setErrors}
            />
            <Box sx={{ mt: 2 }}>
              <Dropdown
                title={'Select Options Amendment'}
                menuList={optionMerges.map((option: IOption) => option.name)}
                name={'options'}
                handleSelect={(e: any) => setSelectedOption(e.target.value)}
                value={selectedOption ? selectedOption : ''}
              />
            </Box>
            {accessAllowed(user, project._id, 'editDeals') &&
            !filteredDeal.cancelled.dateCancelled &&
            !filteredDeal.rescission.dateRescinded ? (
              <FlexBetween sx={{ mt: 2 }}>
                <div>
                  <Button onClick={() => addPendingDeposits()} name={'update'} color="primary" variant="contained">
                    Add Pending Deposit
                  </Button>
                </div>
                <FlexEnd>
                  <Button sx={{ mr: 1 }} onClick={() => openDialog('deleteDeposits')} name={'update'} color="error" variant="contained">
                    Delete Pending Deposits
                  </Button>
                  <Button type="submit" name={'update'} color="success" variant="contained">
                    Create Options Amendment
                  </Button>
                </FlexEnd>
              </FlexBetween>
            ) : null}
          </>
        ) : !filteredDeal.cancelled.dateCancelled && !filteredDeal.rescission.dateRescinded ? (
          <FlexEnd sx={{ mt: 2 }}>
            {!documents.length || (documents[0].type === 'APS' && documents[0].status === 'Completed' && !documents[0].dsEnvelopeId) ? (
              <Button
                sx={{ mr: 1 }}
                onClick={() => saveOptions({ variables: { _id: filteredDeal._id, record: { options: filteredDeal.options } } })}
                color="success"
                variant="contained"
              >
                Save Options
              </Button>
            ) : null}
            <Button
              sx={{ mr: 1 }}
              onClick={() => saveWaitlist({ variables: { _id: filteredDeal._id, record: { joinWaitlist: filteredDeal.joinWaitlist } } })}
              color="success"
              variant="contained"
            >
              Save Waitlist
            </Button>
            {filteredDeal.pendingOptions.length === 0 && !document ? (
              <Button
                onClick={() => {
                  getOptionMerges();
                  handleOptions('Options');
                }}
                name={'update'}
                color="primary"
                variant="contained"
              >
                Create Option Amendment
              </Button>
            ) : null}
          </FlexEnd>
        ) : null}
      </div>
    </form>
  );
};

const SAVEWAITLIST = gql`
  mutation dealUpdateById($_id: MongoID!, $record: UpdateByIdDealInput!) {
    dealUpdateById(_id: $_id, record: $record) {
      record {
        purchasers {
          _id
          firstName
          lastName
        }
      }
    }
  }
`;

const MERGETEMPLATES = gql`
  query mergeTemplateMany($filter: FilterFindManyMergeTemplateInput) {
    mergeTemplateMany(filter: $filter, limit: 10000) {
      _id
        project {
          _id
        }
        name
        mergeFields {
          key
          index
          pageNumber
          x
          y
          fontSize
          format
          wrap
        }
        signFields {
          key
          index
          pageNumber
          x
          y
          name
        }
        default
        totalPages
        type
        getUrl
        putUrl
    }
  }
`;

export default OptionInfo;
