import React from 'react';
import { Grid } from '@mui/material';
import GooglePlaces from '../../common/formControls/GooglePlaces';
import TextInput from '../../common/formControls/TextInput';
import { ISolicitor } from '../../../types/CreateDealForm';

const SolicitorForm = (props: ChildProps) => {
  const { handleTextInput, handleStreetAddress, error, handleDetails, solicitorInfo } = props;

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'Solicitor'}
            name={'solicitor'}
            handleTextInput={(e: any) => handleTextInput(e)}
            value={solicitorInfo.solicitor}
            required={true}
            helperText={error.firstName}
            error={error.firstName ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'Firm'}
            name={'firm'}
            handleTextInput={(e: any) => handleTextInput(e)}
            value={solicitorInfo.firm}
            required={true}
            helperText={error.firstName}
            error={error.firstName ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <GooglePlaces
            handleStreetAddress={handleStreetAddress}
            name={'streetAddress'}
            handleDetails={handleDetails}
            address={solicitorInfo?.streetAddress!}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'City'}
            name={'city'}
            handleTextInput={(e: any) => handleTextInput(e)}
            value={solicitorInfo.city}
            required={true}
            helperText={error.city}
            error={error.city ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'Province/State'}
            name={'province'}
            handleTextInput={(e: any) => handleTextInput(e)}
            value={solicitorInfo.province}
            required={true}
            helperText={error.province}
            error={error.province ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'Postal/Zip Code'}
            name={'postalCode'}
            handleTextInput={(e: any) => handleTextInput(e)}
            value={solicitorInfo.postalCode}
            required={true}
            helperText={error.postalCode}
            error={error.postalCode ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'Phone Number (xxx-xxx-xxxx)'}
            name={'primaryPhone'}
            handleTextInput={(e: any) => handleTextInput(e)}
            value={solicitorInfo.primaryPhone}
            required={true}
            helperText={error.primaryPhone}
            error={error.primaryPhone ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'Email'}
            type={'email'}
            name={'email'}
            handleTextInput={(e: any) => handleTextInput(e)}
            value={solicitorInfo.email}
            required={true}
            helperText={error.email}
            error={error.email ? true : false}
          />
        </Grid>
      </Grid>
    </div>
  );
};

interface ChildProps {
  solicitorInfo: ISolicitor;
  handleTextInput: (e: any) => void;
  handleDetails: (city: string, province: string, postalCode: string, country: string, address: string) => void;
  handleStreetAddress: any;
  error: any;
  type?: number;
}

export default SolicitorForm;
