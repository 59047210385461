import { useState, useContext } from 'react';
import { gql, useSubscription } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { selectProject } from '../../features/project/projectSlice';
import { selectUser } from '../../features/auth/authSlice';
import { IPriceList, IUnit } from '../../types/unit';
import { PriceGridContext } from '../../context/PriceGridContext';
import GridComponent from './GridComponent';
import Legend from './Legend';
import LoadingWrapper from '../common/LoadingWrapper';
import Stack from './stack/Stack';
import { downloadDraft, exportStack, convertAllDates } from '../../utils/Functions';
import { theme } from '../../theme';
import './mediaPrint.css';
import GridHeader from './GridHeader';
import StackHeader from './stack/StackHeader';

const PriceGrid = () => {
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const {
    units,
    setUnits,
    selectingUnits,
    setSelectingUnits,
    setSelectedUnits,
    noUnits,
    draft,
    setDraft,
    loading,
    setPriceListName,
    selectedPriceList,
    setSelectedPriceList,
    setOldStack,
    setAllCards,
    setStackRow,
    stack,
    setStack,
  } = useContext(PriceGridContext);

  const [printState, setPrintState] = useState<boolean>(false);
  const [showStack, setShowStack] = useState<boolean>(false);
  const [legend, setLegend] = useState<boolean>(user.type === 'Sales' ? false : true);

  useSubscription(STACKSUBSCRIPTION, {
    variables: { projectId: project._id },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      let updatedStacks = stack.map((suite: any) => (suite._id === data.logStack._id ? data.logStack : suite));
      let newStacks: any = updatedStacks.map((stack: any) => {
        return {
          ...stack,
          stack: true,
        };
      });
      let allCards = [...units, ...newStacks];
      setStackRow(
        Math.max.apply(
          Math,
          allCards.map((card: any) => card.stackRow)
        )
      );

      setStack(newStacks);
      setOldStack(newStacks);
      setAllCards(allCards);
    },
  });

  useSubscription(UNITSUBSCRIPTION, {
    variables: { projectId: project._id },
    onSubscriptionData: ({ subscriptionData: { data } }: any) => {
      let newUnits = units.map((unit: IUnit) => {
        let logUnits = data.logUnits.find((logUnit: IUnit) => logUnit._id === unit._id);
        if (logUnits) {
          return logUnits;
        } else return unit;
      });
      setUnits(newUnits);
      setAllCards([...newUnits, ...stack]);
    },
  });

  const handlePrint = async () => {
    await setPrintState(true);
    await setTimeout(() => {
      document.title = project.name;
      window.print();
      document.title = 'RDS Real Estate';
      setPrintState(false);
    }, 1000);
  };

  const handleExport = () => {
    exportStack(`${project.name} ${convertAllDates(new Date(), 'P')} Stacking`, units, stack, project);
  };

  // Draft

  const handleBack = () => {
    setDraft(false);
    setSelectedPriceList(null);
    setPriceListName('');
    setUnits();
  };

  const download = (priceList: IPriceList | null) => {
    let draft = {
      draft: units,
      name: priceList?.name,
      project: priceList?.project,
    };

    downloadDraft(draft);
  };

  return (
    <Box
      sx={{
        height: 'calc(100vh - 69px)',
        minHeight: 'calc(100vh - 69px)',
        backgroundColor: '#f5f5f5',
      }}
    >
      <Box
        sx={
          !printState
            ? {
                padding: '24px 0 0 24px',
                backgroundColor: '#f5f5f5',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                height: 'calc(100vh - 70px)',
                breakInside: 'avoid',
              }
            : {
                padding: 0,
                backgroundColor: '#f5f5f5',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                height: '6000px !important',
                width: '50000px !important',
                breakInside: 'avoid',
              }
        }
      >
        {loading ? (
          <LoadingWrapper title="Price Grid is loading..." modal={false} />
        ) : (
          <>
            <Box
              className="titleContainer"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                sx={{
                  [theme.breakpoints.down('sm')]: {
                    display: 'none',
                  },
                }}
                variant={'h2'}
              >
                <strong>{selectedPriceList && draft ? selectedPriceList.name : 'Price List'}</strong>
              </Typography>
              {!showStack ? (
                <GridHeader setShowStack={setShowStack} download={download} handleBack={handleBack} setLegend={setLegend} legend={legend} />
              ) : (
                <StackHeader setShowStack={setShowStack} handlePrint={handlePrint} handleExport={handleExport} />
              )}
            </Box>
            {!showStack ? (
              <Legend units={units} legend={legend} setSelectingUnits={setSelectingUnits} setSelectedUnits={setSelectedUnits} />
            ) : null}
            {units.length > 0 ? (
              showStack ? (
                <>
                  <Stack units={units} printState={printState} />
                </>
              ) : !noUnits ? (
                <GridComponent type="temp" selectingUnits={selectingUnits} printState={printState} draft={draft} />
              ) : null
            ) : null}
          </>
        )}
      </Box>
    </Box>
  );
};

const UNITSUBSCRIPTION = gql`
  subscription logUnits($projectId: String!) {
    logUnits(projectId: $projectId) {
      _id
      suite
      modelType
      unitType
      bathroom
      size
      unit
      outdoorType
      basePrice
      level
      exposure
      outdoorSize
      allocation {
        _id
        fullName
      }
      stackCol
      stackRow
      row
      col
      allocatedDate
      tempAllocation
      status
      custom
      flag
      tier
      rental
      type
    }
  }
`;

const STACKSUBSCRIPTION = gql`
  subscription logStack($projectId: String!) {
    logStack(projectId: $projectId) {
      _id
      unit
      modelType
      unitType
      outdoorType
      bathroom
      exposure
      size
      outdoorSize
      stackCol
      stackRow
    }
  }
`;

export default PriceGrid;
